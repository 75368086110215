import * as React from 'react';
import { useAppDispatch } from '../Config/Hooks/useAppDispatch';
import { BEButton } from '../Components/BEFormItems/BEButton';

import ReactDOMServer from 'react-dom/server';
import html2pdf from 'html2pdf.js';
import ReactDOM from 'react-dom';
import { ApproveReport } from '../Features/Reporting/Views/ApproveReport';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export interface ITestComponentProps {
}

const DownloadComponent: React.FC<any> = (props) => {
  return (
    <div>
      <h1>Download Component</h1>
      <BEButton className='primary'>Downloaded with css</BEButton>
    </div>
  );
}


export function TestComponent(props: ITestComponentProps) {
  const dispatch = useAppDispatch();

  //approve-report-box

  const generatePDF = (): void => {
    const input = document.getElementById('componentToCapture');
    if (input) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();

        // Adjusting the call to `addImage` to match expected signatures
        // Assuming you want to place the image at x=0, y=0, with the width and height
        // matching the dimensions of the canvas (scaled to fit the PDF page if necessary).
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

        pdf.save(`data.pdf`);
      }).catch((error) => {
        console.error("Error generating PDF: ", error);
      });
    }
  };

  return (
    <div style={{position:'relative'}}>
      <div style={{
      position: 'absolute',
      top:'9999px'
      }}>
        <ApproveReport isPDFTemplate  />
      </div>
      <BEButton
        onClick={async () => { generatePDF() }}
      >Download PDF</BEButton>
    </div>
  );
}
