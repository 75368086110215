import React from 'react'
import IconProps from '../Interface/IconProps';

const CommIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 18}
      height={props.inheritSize ? "100%" : 13}
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.49935 7.95768C4.54935 7.95768 0.666016 8.93268 0.666016 10.8743V12.3327H12.3327V10.8743C12.3327 8.93268 8.44935 7.95768 6.49935 7.95768ZM2.61602 10.666C3.31602 10.1827 5.00768 9.62435 6.49935 9.62435C7.99102 9.62435 9.68268 10.1827 10.3827 10.666H2.61602ZM6.49935 6.49935C8.10768 6.49935 9.41602 5.19102 9.41602 3.58268C9.41602 1.97435 8.10768 0.666016 6.49935 0.666016C4.89102 0.666016 3.58268 1.97435 3.58268 3.58268C3.58268 5.19102 4.89102 6.49935 6.49935 6.49935ZM6.49935 2.33268C7.19102 2.33268 7.74935 2.89102 7.74935 3.58268C7.74935 4.27435 7.19102 4.83268 6.49935 4.83268C5.80768 4.83268 5.24935 4.27435 5.24935 3.58268C5.24935 2.89102 5.80768 2.33268 6.49935 2.33268ZM12.366 8.00768C13.3327 8.70768 13.9993 9.64102 13.9993 10.8743V12.3327H17.3327V10.8743C17.3327 9.19102 14.416 8.23268 12.366 8.00768ZM11.4993 6.49935C13.1077 6.49935 14.416 5.19102 14.416 3.58268C14.416 1.97435 13.1077 0.666016 11.4993 0.666016C11.0493 0.666016 10.6327 0.774349 10.2493 0.957682C10.7743 1.69935 11.0827 2.60768 11.0827 3.58268C11.0827 4.55768 10.7743 5.46602 10.2493 6.20768C10.6327 6.39102 11.0493 6.49935 11.4993 6.49935Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
    </svg>
  );
}

export default CommIcon