import RootRoutes from "./Navigation/RootRoutes";
import './Config/GlobalCss/index.scss'
import './App.css'

function App() {
  return (
     <RootRoutes/>
  );
}

export default App;
 