import * as React from 'react';
import { BEDrawer } from '../../../../Components/BEDrawer';
import { Row } from 'antd';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { CalculatedMetrics } from '../CalculatedMetrics/calculatedMetrics';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { getDerivedMetricsRepresentationGraph, getMetricsChildrenFromBEcode, nestDataBasedOnBECodes } from '../../../Actions/calculatedMetricsActions';
import { setChildDerivedMetricsTobeAdded, setChildSelectedMetricsTobeAdded } from '../../../../Redux/SettingsReducer';

export interface ICalculatedMetricsDrawerProps {
    open: boolean;
    setOpen: Function;
    data: any;
    forDataManager: boolean;
}

export function CalculatedMetricsDrawer(props: ICalculatedMetricsDrawerProps) {
    const derivedMetricsRepresentationGraph = useTypedSelector((state) => state.settings.derivedMetricsRepresentationGraph);
    const selectedMetrics = useTypedSelector((state) => state.onBoarding.metrics.selectedMetrics);
    const myDerivedMetrics = useTypedSelector(state => state.dataManager.metrics.myDerivedMetrics);
    const [childrenData, setChildrenData] = React.useState<any[]>([]);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (derivedMetricsRepresentationGraph.status === "idle")
            dispatch(getDerivedMetricsRepresentationGraph());
    }, []);

    React.useEffect(() => {
        if (derivedMetricsRepresentationGraph.status === "success") {
            const bcodes: string[] = getMetricsChildrenFromBEcode(derivedMetricsRepresentationGraph.data, props.data?.bcode);
            let data = dispatch(nestDataBasedOnBECodes(bcodes, derivedMetricsRepresentationGraph.data, props.forDataManager));
            setChildrenData(data.filter((item) => item.bcode !== undefined));
        }

    }, [props.open]);


    return (
        <BEDrawer
            heading={props.data?.title}
            open={props.open}
            width='75vw'
            setOpen={props.setOpen}
            closeIconFunction={() => {
                // dispatch(setChildSelectedMetricsTobeAdded([]));
                // dispatch(setChildDerivedMetricsTobeAdded([]));
            }}
            footer={
                <div style={{ display: "flex", gap: "1rem" }}>
                    {!props.forDataManager &&
                    <BEButton
                    size='large'
                    onClick={() => props.setOpen(false)}
                    className="primary">Add Metrics</BEButton>}
                    <BEButton
                    size='large'
                    onClick={() => {
                        // dispatch(setChildSelectedMetricsTobeAdded([]));
                        // dispatch(setChildDerivedMetricsTobeAdded([]));
                        props.setOpen(false);
                    }}
                    >Cancel</BEButton>
                </div>
            }
        >
            <Row style={{ fontStyle: "italic", padding: "1rem", fontSize: "0.9rem" }}>
                <p>“Metric name” is composed of the below metrics. if you choose to report on any one of them, then they all will be added to your metric {'\n'}selection. You can choose to omit reporting on a metric, in the data manager. </p>
                <p>Every metric marked with 'C' is a calculated metric, further composed of metrics that add up to it. You can expand the row to see the {'\n'}composite metrics.</p>
            </Row>
            <CalculatedMetrics data={childrenData} />
        </BEDrawer>
    );
}
