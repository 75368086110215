import * as React from 'react';
import nopermission from '../../../assets/images/NoPermission.svg';
import { BEButton } from '../../../Components/BEFormItems/BEButton';
import CompanyLogo from "../../../assets/images/Company/Logo.png";
import { useNavigate } from 'react-router';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import { useLocation } from 'react-router-dom';
import { onGoogleLoginFailed } from '../../Actions/LoginActions';

export interface INoPermissionProps {
    is404?: boolean;
}

export function NoPermission(props: INoPermissionProps) {
    const navigate = useNavigate();
    const location = useLocation();
    const [msg, setMsg] = React.useState('');

    React.useEffect(() => {
        const msg = onGoogleLoginFailed(location);
        setMsg(msg);
    }, []);

    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100vw", height: "100vh", alignItems: "center" }}>
            <div style={{ display: "flex", padding: "1rem", marginRight: "auto" }}>
                <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                    <div
                        style={{ display: "flex", justifyContent: "space-around" }}
                        className="logo"
                        onClick={() => navigate("/home/dashboard")}
                    >
                        <img
                            src={CompanyLogo}
                            height={"22px"}
                        />{" "}
                        BREATHE ESG
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
                <div style={{ width: "100px", height: "100px", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50%", backgroundColor: PrimaryTheme.primaryGreenLight }}>
                    <img width={50} src={nopermission} alt="No Permission" />
                </div>
                <h3 style={{maxWidth: 470, textAlign:"center"}}>{props.is404 ? "Oops! Page does not exists." : msg !== '' ? msg : "Looks like you don’t have permission for this screen."}</h3>
                <BEButton size="large" className="primary" onClick={() => navigate("/home/dashboard")}> {msg !== '' ? 'Go to login page' : 'Go to dashboard'}</BEButton>
            </div>
        </div>

    );

}
