import * as React from 'react';
import { BEDrawer } from '../../../../Components/BEDrawer';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import './style.scss';
import { Checkbox, Form, Select } from 'antd';
import { LableRequired } from '../../../../Components/BEFormItems/LableRequired';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { showFYFormatByYear } from '../../../../Config/Functions/UsefullFunctions';
import { addFinancialYear } from '../../../../Redux/CommonReducer';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { addMetrics } from '../../../Targets/Actions/targetAction';
import { postMyDerivedMetrics } from '../../../DataManager/Actions';
import { BEMessage } from '../../../../Components/BEMessage';
import { setMetricCurrentYear } from '../../../../Redux/OnboardingReducer';
import { defaultDerivedMetrics, defaultMetrics } from '../../../../Config/Data/dashboardData';

export interface IAddyearProps {
  open: boolean;
  setOpen: Function;
}

export function Addyear(props: IAddyearProps) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const yearEnd = useTypedSelector(state => state.companies.companyDetails.data?.reporting_year_end);
  const existingYears = useTypedSelector(state => state.common.financialYears.data);
  const [selectedYear, setSelectedYear] = React.useState<number | undefined>(undefined);
  const [applyPreviousYear, setApplyPreviousYear] = React.useState<boolean>(false);
  const selectedMetrics = useTypedSelector(state => state.onBoarding.metrics.allSelectedMetrics.data);
  const selectedDerivedMetrics = useTypedSelector(state => state.dataManager.metrics.allFullMyDerivedMetrics.data);
  const allMetrics = useTypedSelector(state => state.onBoarding.metrics.allMetrics.data);
  const currentYear = useTypedSelector(state => state.onBoarding.metrics.currentYear);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setSelectedYear(undefined);
    setApplyPreviousYear(false);
    form.resetFields();
  }, [props.open]);

  const addCurrentYearMetrics = async (year: number) => {
    const metricsToSave = selectedMetrics.filter((item: any) => item.year === currentYear && !defaultMetrics.includes(item.bcode))
      .map((item: any) => {
        return {
          year: year,
          metric: item.metric,
          bcode: item.bcode,
        }
      })
    const derivedMetricsToSave = selectedDerivedMetrics.filter((item: any) => item.year === currentYear && !defaultDerivedMetrics.includes(item.bcode))
      .map((item: any) => {
        return {
          year: year,
          derived_metric: item.derived_metric,
        }
      })
    let [res1, res2] = await Promise.all([dispatch(addMetrics(metricsToSave)), dispatch(postMyDerivedMetrics(derivedMetricsToSave))])
    if (res1 && res2) {
      // BEMessage.success('Year added and current metrics are applied successfully');
    }
    else {
      BEMessage.error('Something went wrong');
    }
  }

  const addDefaultMetrics = async () => {
    let metricsToAdd: any[] = [];
    // if (!applyPreviousYear) {
    //   metricsToAdd = defaultMetrics.map((item) => {
    //     return {
    //       year: selectedYear,
    //       metric: allMetrics.find((metric) => metric.bcode === item)?.id,
    //       bcode: item,
    //     }
    //   });
    // } else {
    //   metricsToAdd = selectedMetrics
    //     .map((item) => allMetrics.find((metric: any) => metric.id === item.metric))
    //     .filter((item: any) => !defaultMetrics.includes(item?.bcode))
    //     .map((item: any) => {
    //       return {
    //         year: selectedYear,
    //         metric: item?.id,
    //         bcode: item?.bcode,
    //       }
    //     });
    // }
    metricsToAdd = defaultMetrics.map((item) => {
      return {
        year: selectedYear,
        metric: allMetrics.find((metric) => metric.bcode === item)?.id,
        bcode: item,
      }
    });
    await dispatch(addMetrics(metricsToAdd));
    const derivedMetricsToAdd = defaultDerivedMetrics.map((bcode) => {
      return {
        year: selectedYear,
        derived_metric: allMetrics?.find((metric) => metric.bcode === bcode)?.id
    }})
    await dispatch(postMyDerivedMetrics(derivedMetricsToAdd));
  }

  const onSubmit = async (values: any) => {
    console.log(values);
    console.log(selectedYear, applyPreviousYear);
    dispatch(addFinancialYear(selectedYear));
    if (applyPreviousYear && selectedYear) {
      setLoading(true);
      await addCurrentYearMetrics(selectedYear);
      setLoading(false);
    }
    setLoading(true);
    await addDefaultMetrics();
    setLoading(false);
    dispatch(setMetricCurrentYear(selectedYear));
    props.setOpen(false);
    BEMessage.success('Year added successfully');
  };
  return (
    <BEDrawer
      width='fit-content'
      heading='Add Year'
      open={props.open}
      setOpen={props.setOpen}
      footer={
        <>
          <BEButton size='large' className='primary' onClick={() => form.submit()}
            loading={loading}
          >Save Changes</BEButton>
          <BEButton size='large' onClick={() => props.setOpen(false)}>Cancel</BEButton>
        </>
      }
    >
      <Form
        onFinish={onSubmit}
        form={form}
      >
        <LableRequired>
          Select Year
        </LableRequired>
        <Form.Item
          name={'year'}
          rules={[{ required: true, message: 'Please select a year' }]}>
          <Select size='large'
            value={selectedYear}
            onChange={(value) => setSelectedYear(value)}
          >
            {
              //past 10 years to future 5 years
              Array.from({ length: 15 }, (v, k) => k - 10).filter((year) => !existingYears.includes(currentYear + year)).
                map((year) => {
                  return (
                    <Select.Option value={currentYear + year} key={currentYear + year}>
                      {showFYFormatByYear(currentYear + year, yearEnd || '')}
                    </Select.Option>
                  );
                })
            }
          </Select>
        </Form.Item>
        <Form.Item>
          <Checkbox style={{ width: '381px' }} onChange={(e) => setApplyPreviousYear(e.target.checked)} checked={applyPreviousYear}>
              Select to apply the current years ESG metric selection
              to the new year
          </Checkbox>
        </Form.Item>
      </Form>
    </BEDrawer>
  );
}
