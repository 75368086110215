import { ColumnsType } from "antd/es/table";
import React, { useEffect } from "react";
import { BETable } from "../../../../Components/BETable";
import dayjs from "dayjs";
import BEStatusTag from "../../../../Components/BEStatusTag";
import { useTypedSelector } from "../../../../Config/Hooks/useTypedSelector";
import { Progress } from "antd";
import { PrimaryTheme } from "../../../../Config/Theme/theames";
import { BEEyeButton } from "../../../../Components/BEEyeButton";
import ToolTip from "../../../../Components/BEToolTip";
import { getTopicStatusDM } from "../../Actions";
import { useAppDispatch } from "../../../../Config/Hooks/useAppDispatch";
import { isDateInFY } from "../../../../Config/Functions/UsefullFunctions";

const TrackerTable = () => {
  const currentMonth = useTypedSelector((state) => state.dataManager.metrics.dateSlicer.currentMonth);
  const yearEnd = useTypedSelector((state) => state.companies.companyDetails.data?.reporting_year_end);
  const currentYear = useTypedSelector((state) => state.dataManager.metrics.dateSlicer.currentYear);
  const myMetricsData = useTypedSelector(state => state.dataManager.metrics.myMetricsData);
  const [tableData, setTableData] = React.useState<any>([]);
  const businessUnits = useTypedSelector(state => state.entity.BusinessUnits);
  const businessGroup = useTypedSelector(state => state.entity.BusinessGroups);
  const myMetrics = useTypedSelector(state => state.dataManager.metrics.myMetrics);
  const businessUnitRefs = useTypedSelector(state => state.entity.BusinessUnitRefs);
  const currentBusinessGroup = useTypedSelector(state => state.common.homePage.currentBusinessGroup);
  const currentBusinessUnit = useTypedSelector(state => state.common.homePage.currentBusinessUnit);
  const omittedMetrics = useTypedSelector(state => state.dataManager.metrics.omittedMetrics);
  const currentDepartmentDM = useTypedSelector(state => state.dataManager.filter.DepartmentTypeDM);
  const assignedDepartmentByCategory = useTypedSelector(state => state.dataManager.assignedDepartmentByCategory);
  const role = localStorage.getItem('role');
  const dispatch = useAppDispatch();

  const columns: ColumnsType<any> = [
    {
      title: "Month",
      dataIndex: "month",
      key: "month",
      render: (text: any, record: any) => {
        return <p>{dayjs().month(record.month - 1).format("MMMM")}</p>;
      },
      sorter: (a: any, b: any) => (a.month > b.month) ? 1 : -1,
    },
    {
      title: "Business Unit",
      dataIndex: "businessUnit",
      key: "businessUnit",
      render: (text: any, record: any) => {
        return <p>{
          businessUnits.data.find((item: any) => item.id === record.businessUnit)?.name
        }</p>;
      },
      filters: businessUnits.data.map((item: any) => {
        return {
          text: item.name,
          value: item.id
        }
      }),
      onFilter: (value: any, record: any) => record.businessUnit === value,
    },
    {
      title: "Business Group",
      key: "businessGroup",
      render: (text: any, record: any) => {
        if (role !== 'CONTRIBUTOR') return <ToolTip
          title={
            businessUnitRefs.data.filter((item: any) => item.business_unit === record.businessUnit).map((item: any) => {
              return <p>{businessGroup.data.find((i: any) => i.id === item.business_group)?.name}
              </p>
            })
          }
        >
          {
            businessGroup.data.find((item: any) => businessUnitRefs.data.filter((i: any) => i.business_unit === record.businessUnit)[0]?.business_group === item.id)?.name
          }{
            businessUnitRefs.data.filter((item: any) => item.business_unit === record.businessUnit).length > 1 && <>+{businessUnitRefs.data.filter((item: any) => item.business_unit === record.businessUnit).length - 1}</>
          }
        </ToolTip>
        else return <p>{record.business_group_name}</p>
      },
      filters: businessGroup.data.map((item: any) => {
        return {
          text: item.name,
          value: item.id
        }
      }),
      onFilter: (value: any, record: any) => businessGroup.data.find((item: any) => item.id === businessUnits.data?.find((item: any) => item.id === record.businessUnit)?.business_group)?.id === value,
    },
    {
      title: "Total Metrics",
      dataIndex: "total",
      key: "total",
      width: '50px',
    },
    {
      title: "Pending",
      dataIndex: "pending",
      key: "pending",
      sorter: (a: any, b: any) => (a.pending - b.pending),
    },

    {
      title: "Filled",
      dataIndex: "filled",
      key: "filled",
      sorter: (a: any, b: any) => (a.filled - b.filled),
    },
    {
      title: "Approved",
      dataIndex: "approved",
      key: "approved",
      sorter: (a: any, b: any) => (a.approved - b.approved),
    },
    {
      title: "Omitted",
      dataIndex: "omitted",
      key: "omitted",
      sorter: (a: any, b: any) => (a.omitted - b.omitted)
    },
    {
      title: "COMPLETENESS"
      // <BEEyeButton
      //   title={'Completeness'}
      //   discription={'This denotes the number of metrics with overall status "Approved".'}
      // >COMPLETENESS
      // </BEEyeButton>
      ,
      dataIndex: 'completeness',
      key: 'completeness',
      render: (text: any, record: any) => {
        const mets = myMetricsData.data.filter((item: any) => item.business_unit === record.businessUnit && item.year === currentYear && Number(item.date_of_entry.split('-')[1]) === record.month);
        const filled = mets.filter(async (item) => {
          const status = await dispatch(getTopicStatusDM(item.my_metric));
          return status;
        }).length;
        return <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* <Progress
            showInfo={false}
            percent={
              Math.round((
                filled
                / myMetrics.data.length
              ) * 100)
            } strokeColor={PrimaryTheme.primaryGreen} /> */}
          <BEStatusTag status={(record.approved / record.total) < 0.30 ? "error" : ((record.approved / record.total) < 100 ? "info" : "success")}>
            <p style={{ padding: "0 8px" }}>{
              (record.approved / record.total * 100).toFixed(2)
            }
              {" %"}
            </p>
          </BEStatusTag>
        </div>
      },
      sorter: (a: any, b: any) =>
        ((a.approved / a.total) - (b.approved / b.total))
      ,
    }
  ];

  interface tableItem {
    month: number,
    businessUnit: string,
    approved: number,
    filled: number,
    pending: number,
    total: number,
    omitted: number
  }

  const setTableDatafunction = async () => {
    let allBUs: any = []            // filter data by business unit and business group
    let allMonths: any = currentMonth === 0 ? Array.from({ length: 12 }, (v, k) => k + 1) : [currentMonth];
    if (currentBusinessUnit !== 0) allBUs = [currentBusinessUnit];
    else if (currentBusinessGroup !== 0) {
      const busOfBG = businessUnitRefs.data.filter((item: any) => { return item.business_group === currentBusinessGroup }).map((item: any) => item.business_unit);
      allBUs = busOfBG;
    }
    else allBUs = businessUnits.data.map((bu: any) => bu?.id);

    const assignedCategoriesForCurrentDepartment = assignedDepartmentByCategory.data.filter((item: any) => item.department === currentDepartmentDM).map((item: any) => item.category);
    let dataToFilter = myMetrics.data.filter((item: any) => assignedCategoriesForCurrentDepartment.includes(item.tab));
    dataToFilter = currentDepartmentDM === 'All' ? myMetrics.data : dataToFilter;
    let tempData: tableItem[] = [];                     // filter data by month

    for (let i = 0; i < allMonths.length; i++) {
      let thisMonth = allMonths[i];
      for (let j = 0; j < allBUs.length; j++) {
        let thisBusinessUnit = allBUs[j];
        let tempTotal: number = 0;
        let tempApproved: number = 0;
        let tempFilled: number = 0;
        let tempPending: number = 0;
        let tempOmitted: number = 0;
        //0=pending, 1=filled, 2=approved
        for (let k = 0; k < dataToFilter.length; k++) {
          let item = dataToFilter[k];
          let tempStatus = await dispatch(getTopicStatusDM(item.id, thisBusinessUnit, thisMonth));
          let isOmitted = omittedMetrics.data.some((metric) => metric.my_metric_id === item.metricId && metric.year === currentYear && metric.business_unit === thisBusinessUnit);
          if (!item.hasOwnProperty('direct')) {
            tempTotal++;
            if (isOmitted) tempOmitted++;
            if (tempStatus === 0 && !isOmitted) tempPending++;
            else if (tempStatus === 1 && !isOmitted) tempFilled++;
            else if (tempStatus === 2 && !isOmitted) tempApproved++;
          }
        }
        tempData.push({
          month: thisMonth,
          businessUnit: thisBusinessUnit,
          approved: tempApproved,
          filled: tempFilled,
          pending: tempPending,
          total: tempTotal,
          omitted: tempOmitted
        })
      }
    }
    setTableData(tempData);
  }

  useEffect(() => {
    setTableDatafunction();
  }, [myMetricsData, currentMonth, currentYear, yearEnd, currentBusinessGroup, currentBusinessUnit, businessUnitRefs, myMetrics, currentDepartmentDM, assignedDepartmentByCategory, omittedMetrics]);


  return (
    <div style={{ margin: "2rem 0" }}>
      <BETable columns={columns} data={
        tableData.sort((a: any, b: any) => a.month - b.month)
      } />
    </div>
  );
};

export default TrackerTable;
