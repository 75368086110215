import type { StatusType } from "../Types/userTypes";
import { EmisionsState } from "../Types/emisionsTypes";

import {
  Reducer,
  createSlice,
} from "@reduxjs/toolkit";
import { clearStore } from "../ExtraReducrActions";


const initialState: EmisionsState = {
  configurations: {
    scope1Configurations: {
      status: "idle",
      data: [],
    },
    currentScope1Configuration: null,
    scope2Configurations: {
      status: "idle",
      data: [],
    },
    scope2Specifications: []
  },
  calculations: {
    scope1calculationForMonths: {
      status: "idle",
      data: [],
      forYear: 0,
    },
    scope2calculationForMonths: {
      status: "idle",
      data: [],
      forYear: 0,
    },
    scope3calculationForMonths: {
      status: "idle",
      data: [],
      forYear: 0,
    },
  },
  dateSlicer: {
    currentYear: 0,
    currentMonth: 0,
  },
};
export const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: (builder) => builder.addCase(clearStore, () => initialState),
  reducers: {
    setScope1Configurations: (state, action) => {
      state.configurations.scope1Configurations = action.payload;
    },
    setScope2Configurations: (state, action) => {
      state.configurations.scope2Configurations = action.payload
    },
    setCurrentScope1Configuration: (state, action) => {
      state.configurations.currentScope1Configuration = action.payload;
    },
    setDateSlicerYear: (state, action) => {
      state.dateSlicer.currentYear = action.payload;
    },
    setDateSlicerMonth: (state, action) => {
      state.dateSlicer.currentMonth = action.payload;
    },
    setScope2Specifications: (state, action) => {
      state.configurations.scope2Specifications = action.payload
    },
    setScope1calculationForMonths: (state, action) => {
      state.calculations.scope1calculationForMonths = action.payload
    },
    setScope2calculationForMonths: (state, action) => {
      state.calculations.scope2calculationForMonths = action.payload
    },
    setScope3calculationForMonths: (state, action) => {
      state.calculations.scope3calculationForMonths = action.payload
    }
  },
});

export const {
  setScope1Configurations,
  setScope2Configurations,
  setCurrentScope1Configuration,
  setDateSlicerYear,
  setDateSlicerMonth,
  setScope2Specifications,
  setScope1calculationForMonths,
  setScope2calculationForMonths,
  setScope3calculationForMonths,
} = userSlice.actions;

const EmisionsReducer: Reducer<EmisionsState> = userSlice.reducer;

export default EmisionsReducer;
