import React from 'react'
import IconProps from './Interface/IconProps';

const DataEntryIcon = (props:IconProps) => {
  return (
    <svg  width={props.inheritSize ? "100%" : 20}
    height={props.inheritSize ? "100%" : 20} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.83325 15.1668H15.1666M6.2777 5.5372H7.01844M6.2777 8.50016H7.01844M6.2777 11.4631H7.01844M9.9814 5.5372H10.7221M9.9814 8.50016H10.7221M9.9814 11.4631H10.7221M3.31473 15.1668V3.31498C3.31473 2.92206 3.47082 2.54524 3.74865 2.26741C4.02648 1.98958 4.4033 1.8335 4.79622 1.8335H12.2036C12.5965 1.8335 12.9734 1.98958 13.2512 2.26741C13.529 2.54524 13.6851 2.92206 13.6851 3.31498V15.1668"
 stroke={props.stroke ? props.stroke : "gray"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  );
}

export default DataEntryIcon