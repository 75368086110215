import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { setDateSlicerMonth, setDateSlicerYear } from '../../../../../Redux/EmmisionsReducer';
import { GlobalMonthsSlicer } from '../../../../../Components/BEMultipleSelect/MonthSlicer';
import { setDateSlicerMonthDM, setDateSlicerYearDM } from '../../../../../Redux/DataManagerReducer';

const MonthSlicer = () => {
  const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const currentMonth = useTypedSelector((state) => state.dataManager.metrics.dateSlicer.currentMonth);
  return (
    <GlobalMonthsSlicer 
      setDateSlicerMonth={setDateSlicerMonthDM}
      setDateSlicerYear={setDateSlicerYearDM}
      currentMonth={currentMonth}
      currentYear={currentYear}
    />
  );
}

export default MonthSlicer