import React from 'react'
import IconProps from '../Interface/IconProps';

const OccupancyIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 20}
      height={props.inheritSize ? "100%" : 11}
      viewBox="0 0 20 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.55833 5.5H11.4417C12.2167 5.5 12.8 4.80833 12.675 4.04167L12.425 2.55C12.225 1.36667 11.2 0.5 10 0.5C8.8 0.5 7.775 1.36667 7.575 2.55833L7.325 4.05C7.2 4.80833 7.78333 5.5 8.55833 5.5ZM9.21667 2.825C9.28333 2.44167 9.60833 2.16667 10 2.16667C10.3917 2.16667 10.7167 2.44167 10.7833 2.825L10.95 3.83333H9.05L9.21667 2.825Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
      <path
        d="M1.38333 4.75833C1.275 4.975 1.23333 5.23333 1.3 5.49167C1.43333 6.06667 1.93333 6.35 2.575 6.325C2.575 6.325 3.81667 6.325 4.2 6.325C4.89167 6.325 5.45833 5.84167 5.45833 5.25C5.45833 5.13333 5.43333 5.025 5.4 4.91667C5.39167 4.89167 5.39167 4.875 5.40833 4.85C5.48333 4.71667 5.525 4.56667 5.525 4.40833C5.525 4.15 5.40833 3.90833 5.225 3.725C5.2 3.7 5.2 3.675 5.20833 3.64167C5.26667 3.475 5.26667 3.28333 5.21667 3.1C5.08333 2.74167 4.75833 2.5 4.39167 2.48333C4.36667 2.48333 4.35 2.475 4.33333 2.45833C4.19167 2.28333 3.93333 2.16667 3.64167 2.16667C3.39167 2.16667 3.16667 2.25 3.01667 2.38333C2.99167 2.40833 2.96667 2.40833 2.94167 2.4C2.825 2.35 2.69167 2.325 2.55833 2.325C2.01667 2.325 1.575 2.73333 1.525 3.25833C1.525 3.275 1.51667 3.29167 1.5 3.30833C1.25833 3.525 1.11667 3.85 1.15833 4.18333C1.18333 4.36667 1.25833 4.54167 1.36667 4.68333C1.39167 4.7 1.39167 4.73333 1.38333 4.75833Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
      <path
        d="M13.5333 6.875C12.5583 6.44167 11.3583 6.125 10 6.125C8.64167 6.125 7.44167 6.45 6.46667 6.875C5.56667 7.275 5 8.175 5 9.15833V10.5H15V9.15833C15 8.175 14.4333 7.275 13.5333 6.875ZM6.725 8.83333C6.8 8.64167 6.95 8.48333 7.13333 8.4C8.05 7.99167 9.01667 7.79167 9.99167 7.79167C10.975 7.79167 11.9333 8 12.85 8.4C13.0417 8.48333 13.1833 8.64167 13.2583 8.83333H6.725Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
      <path
        d="M1.01667 7.65C0.4 7.91667 0 8.51667 0 9.19167V10.5H3.75V9.15833C3.75 8.46667 3.94167 7.81667 4.275 7.25C3.96667 7.2 3.65833 7.16667 3.33333 7.16667C2.50833 7.16667 1.725 7.34167 1.01667 7.65Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
      <path
        d="M18.9833 7.65C18.275 7.34167 17.4917 7.16667 16.6667 7.16667C16.3417 7.16667 16.0333 7.2 15.725 7.25C16.0583 7.81667 16.25 8.46667 16.25 9.15833V10.5H20V9.19167C20 8.51667 19.6 7.91667 18.9833 7.65Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
      <path
        d="M18.3333 4.66667V4.25C18.3333 3.33333 17.5833 2.58333 16.6667 2.58333H15C14.65 2.58333 14.4583 2.98333 14.675 3.25833L15.2583 3.78333C15.1 4.04167 15 4.34167 15 4.66667C15 5.58333 15.75 6.33333 16.6667 6.33333C17.5833 6.33333 18.3333 5.58333 18.3333 4.66667Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
    </svg>
  );
}

export default OccupancyIcon