import { Checkbox, Col, Row } from 'antd';
import * as React from 'react';
import './style.scss';
import { GresbMain } from '../GresbMain';
import { arrangeGresbData, postGresbEntityDataToPortal } from '../../../Actions/gresbActions';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { fillReportData } from '../../../Actions/template';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { UploadEvidenceDrawer } from '../UploadEvidence';

export interface ICheckboxListProps {
  data: any[];
}

export function CheckboxList(props: ICheckboxListProps) {

  const [checked, setChecked] = React.useState<string[]>([]);
  const [arrangedData, setArrangedData] = React.useState<any[]>([]);
  const [open, setOpen] = React.useState<boolean>(false);
  const [evidenceData, setEvidenceData] = React.useState<any>(null);
  const currentCategory = useTypedSelector(state => state.reporting.selectedTemplateCategory);
  const currentReport = useTypedSelector(state => state.reporting.currentReport);
  const reportData = useTypedSelector(state => state.reporting.reportData.data);
  const dispatch = useAppDispatch();

  // arrange Data on basis of unique topics
  React.useEffect(() => {
    const arrangedData = arrangeGresbData(props.data);
    let checkedData: string[] = [];
    props.data.forEach((item) => {
      const data = populateData(item);
      if (data) {
        checkedData.push(item.disclosure_detail_code);
      }
    });
    setChecked(checkedData);
    setArrangedData(arrangedData);
  }, [props.data, currentCategory, reportData]);

  const onChange = async (e: any, disclosureDetailCode: string) => {
    // setChecked(e.target.checked ? [...checked, disclosureDetailCode] :
    //   checked.filter((item) => item !== disclosureDetailCode));
    await handleAddData(e, disclosureDetailCode);
  };

  // To populate data in the form
  const populateData = (data: any) => {
    const topicId = data?.id;
    const topicData = reportData[topicId!] ? reportData[topicId!][0!] : null;
    const value = topicData?.data;
    if (Number(value) === 1) {
      return data.disclosure_detail_code;
    }
    return null;
  }


  // function to handle saving the data
  const handleAddData = async (e: any, disclosure_detail_code: string) => {
    const data = props.data.find((item) => item.disclosure_detail_code === disclosure_detail_code);
    const value = e.target.checked ? 1 : 0;
    const dataToSend = {
      data: value
    }
    await dispatch(fillReportData({
      ...dataToSend,
      esg_report: currentReport?.id,
      topic: data?.id,
    })).then(async () => {
      const portalDataUpdated = await dispatch(postGresbEntityDataToPortal(disclosure_detail_code, String(value)));
    });
  }

  const containsEvidence = (children: any[]) => {
    return children.some((child) => child.responseType === 'Evidence' || child.responseType === 'Evidence(H)' || child.responseType === 'Evidence(F,H)');
  }

  const getEvidenceData = (children: any[]) => {
    return children.find((child) => child.responseType === 'Evidence' || child.responseType === 'Evidence(H)' || child.responseType === 'Evidence(F,H)')?.children[0];
  }


  return (
    <div className='checkbox-list-parent'>
      {arrangedData.map((data, index1) => {
        return (
          <div key={index1} className='checkbox-question-container'>
            <p className='checkbox-list-question'>
              {data?.children.length > 1 ? data?.topic : ''}
            </p>
            <div className='checkbox-list-container'>
              {
                data.children?.map((item: any, index: number) => {
                  return (
                    <div key={index}>
                      <Row
                        className={
                          `checkbox-list-item
                    ${item.children.length > 0 ?
                            checked.includes(item.disclosure_detail_code) ?
                              'selected' : '' : 'not-expandable'
                          } `}
                        align={"middle"}
                      >
                        <Col>
                          <Checkbox
                            onChange={(e) => {
                              onChange(e, item.disclosure_detail_code);
                            }}
                            checked={checked.includes(item.disclosure_detail_code)}
                          />
                        </Col>
                        <Col>
                          <p className='checkbox-list-option-text'>
                            {data?.children.length > 1 ? item?.accounting_metric : item?.topic}
                          </p>
                        </Col>
                        <div style={{ flexGrow: 1 }} />
                        {
                          containsEvidence(item.children) && checked.includes(item.disclosure_detail_code) &&
                          <Col>
                            <span
                              onMouseOver={() => {
                                setEvidenceData(getEvidenceData(item.children));
                              }}
                              onClick={() => {
                                setOpen(true);
                              }}
                              className='checkbox-list-upload'
                            >
                              Upload evidence
                            </span>
                          </Col>
                        }
                      </Row>
                      {
                        checked.includes(item.disclosure_detail_code) &&
                        item.children.length > 0 &&
                        <Row
                          className='checkbox-list-child-container'
                        >
                          <GresbMain data={item.children} />
                        </Row>
                      }
                    </div>
                  )
                })
              }
            </div>
          </div>
        )
      })}
      <UploadEvidenceDrawer
        open={open}
        setOpen={setOpen}
        evidenceData={evidenceData}
      />

    </div>
  );
}
