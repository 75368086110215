import { Switch } from 'antd';
import { SwitchChangeEventHandler, SwitchProps } from 'antd/es/switch';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import { BEMessage } from '../../BEMessage';

export interface IBESwitchProps extends SwitchProps{
  checked: boolean;
  loading: boolean;
  onClick: SwitchChangeEventHandler;
  children?: React.ReactNode;
}

const BESwitch = (props: IBESwitchProps) => {
  const {children, ...rest} = props;
  const role = localStorage.getItem('role');
  const handleCLick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (role == 'AUDITOR') {
      BEMessage.error('You are not authorized to perform this action');
      return;
    }
    props.onClick && props.onClick(props.checked,e);
  }
  return (
    <Switch
      {...rest}
      loading={props.loading}
      style={{ backgroundColor: props.checked ? PrimaryTheme.primaryGreen : PrimaryTheme.primaryGreyLight }}
      checked={props.checked}
      onClick={(e: any) => handleCLick(e)}
    />
  )
}

export default BESwitch
