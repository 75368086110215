import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import './style.scss';
import EditIcon from '../../../../../Components/BEIcons/EditIcon';
import { BEInput } from '../../../../../Components/BEFormItems/BEInput';
import { BETable } from '../../../../../Components/BETable';
import { ColumnsType } from 'antd/es/table';
import { SetupTargets } from '../SetupTargets';
import { selectedMetricType } from '../../../../../Redux/Types/dataManagerTypes';
import { calcProgress, getTargetValue, updateTarget } from '../../../Actions/targetAction';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import BESwitch from '../../../../../Components/BEFormItems/BESwitch';
import BEStatusTag from '../../../../../Components/BEStatusTag';
import { AddCommaToNumber } from '../../../../../Config/Functions/UsefullFunctions';

export interface IAddTargetsProps {
  open: boolean;
  setOpen: Function
}

export function AddTargets(props: IAddTargetsProps) {
  const dispatch = useAppDispatch();
  const myMetrics = useTypedSelector((state) => state.onBoarding.metrics.allSelectedMetrics);
  const [page, setPage] = React.useState(1);
  const myMetricsMapper = useTypedSelector(state => state.onBoarding.metrics.allMetrics);
  const myMetricsData = useTypedSelector((state) => state.dataManager.metrics.myMetricsData);
  const [openSetuptargets, setOpenSetupTargets] = React.useState(false);
  const [load, setLoad] = React.useState(myMetrics.status === "loading");
  const assessmentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const [switchLoad, setSwitchLoad] = React.useState(false);
  const [currTarget, setCurrTarget] = React.useState(null);
  const [targetData, setTargetData] = React.useState<selectedMetricType>({} as selectedMetricType);
  const [filteredMetrics, setFilteredMetrics] = React.useState<any>([]);

  const getCurrentValue = (id: number) => {
    return myMetricsData.data.filter((item) => item.my_metric === id).reduce((total: number, currentItem: any) => {
      return total + currentItem.value;
    }, 0);
  }

  const options = [
    { value: 'A', label: 'absolute increase' },
    { value: 'B', label: 'absolute decrease' },
    { value: 'C', label: '% increase' },
    { value: 'D', label: '% decrease' }
];

  const columns: ColumnsType<any> = [
    {
      title: 'Sr. No.',
      key: 'sno',
      render: (text, object, index) => (page-1)*10 + index + 1
    },
    {
      title: 'Metric Name',
      dataIndex: 'title',
      render: (text, record) =>
        <div className='metric-title'>
          {record.title}
          {getTag(record.title) === 2 && <BEStatusTag status='success'>A</BEStatusTag>}
          {getTag(record.title) === 1 && <BEStatusTag status='success'>HQ-A</BEStatusTag>}
        </div>
    },
    {
      title: 'Current Value',
      dataIndex: 'curr_value',
      render: (text, record) => AddCommaToNumber(Number(getCurrentValue(record.id)), false, true) + ` ${getMetricUnit(record.title)}`
    },
    {
      title: 'Type',
      dataIndex: 'target_type',
      render: (text, record) => (record.target_value !== "-1.00" ? options.filter((item) => item.value === record.target_type)[0].label : "")
    },
    {
      title: 'Target Value',
      dataIndex: 'target_value',
      sorter: (a, b) => (a.target_value !== '-1.00' || a.status ? 1 : -1),
      sortOrder: "descend",
      render: (text, record) => (record.target_value === "-1.00" ? "" : AddCommaToNumber(Number(getTargetValue(record)), false, true) + ` ${getMetricUnit(record.title)}`)
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text, record) => (
      <div onMouseEnter={() => {
        setCurrTarget(record.id);
      }}>
        <BESwitch
          loading={switchLoad && currTarget === record.id}
          checked={record.status}
          onClick={async () => {
            const body = {
              status: record.status ? 0 : 1,
              year: assessmentYear
            };
            if (!record.status) {
              console.log("printing")
              setTargetData(record);
              setOpenSetupTargets(true);
            } else {
              setSwitchLoad(true);
              await dispatch(updateTarget(record.id, body));
              setSwitchLoad(false);
            }
          }}
        />
        </div>)
    },
    // {
    //   title: 'Selected',
    //   dataIndex: 'selected',
    //   render: (text, record) => record.target_value !== '-1.00' && record.year === assessmentYear ? "YES" : "NO",
    //   sorter: (a, b) => (a.target_value !== '-1.00' || a.status ? 1 : -1),
    //   sortOrder: "descend"
    // },
    {
      title: 'Edit',
      dataIndex: 'edit',
      render: (text, record) =>(record.status ?
        <div style={{ width: "1rem" }} onClick={() => {
          setTargetData(record);
          setOpenSetupTargets(true);
        }}>
          <EditIcon inheritSize={true} stroke={PrimaryTheme.primaryGreyDark} />
        </div> : '')
    }
  ]

  React.useEffect(() => {
    setFilteredMetrics(myMetrics.data);
    setLoad(myMetrics.status === "loading");
  }, [myMetrics]);

  const onCancel = () => {
    props.setOpen(false)
  }

  const changePage = (page: number) => {
    setPage(page)
  }

  const getTag = (title: string) => {
    const metric = myMetricsMapper.data.filter((item) => item.title === title)[0]
    const tag = metric?.annual ? (metric.applicable_to_all ? 2 : 1) : 0
    return tag
  }

  const getMetricUnit = (title: string) => {
    const metric = myMetricsMapper.data.find((item) => item.title === title)
    return metric?.unit
  };

  return (
    <BEDrawer
      open={props.open}
      setOpen={props.setOpen}
      heading='Add targets'
      width='fit-content'
      footer={
        <>
          <BEButton size='large'
            onClick={onCancel}
          >Close</BEButton>
        </>
      }
    >
      <div className='add-materiality-assessment'>
        <BEInput
          style={{ width: "313px" }}
          search
          placeholder="Search Targets"
          size="large"
          onChange={(e) => {
            const value = e.target.value.toLowerCase();
            const filteredData = myMetrics.data?.filter((item: any) => {
              return item.title.toLowerCase().includes(value);
            });
            setFilteredMetrics(filteredData);
          }}
        />
        <BETable
          loading={load}
          columns={columns}
          data={filteredMetrics.filter((item: any) => item.year === assessmentYear).sort((objA: any, objB: any) => {
            return objA.title.localeCompare(objB.title);
          })}
          handleChangePage={changePage}
          pagination
        />
      </div>
      <SetupTargets open={openSetuptargets} setOpen={setOpenSetupTargets} targetData={targetData} />
    </BEDrawer>
  );
}
