import React from 'react'
import IconProps from '../Interface/IconProps';

const EHSIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 14}
      height={props.inheritSize ? "100%" : 17}
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.75065 9.33268H3.66732V6.83268H5.75065V4.74935H8.25065V6.83268H10.334V9.33268H8.25065V11.416H5.75065V9.33268ZM7.00065 0.166016L0.333984 2.66602V7.74102C0.333984 11.9493 3.17565 15.8743 7.00065 16.8327C10.8257 15.8743 13.6673 11.9493 13.6673 7.74102V2.66602L7.00065 0.166016ZM12.0007 7.74102C12.0007 11.0743 9.87565 14.1577 7.00065 15.0993C4.12565 14.1577 2.00065 11.0827 2.00065 7.74102V3.82435L7.00065 1.94935L12.0007 3.82435V7.74102Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
    </svg>
  );
}

export default EHSIcon