import * as React from 'react';
import './style.scss';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector'
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import {
  getSuppliers,
  deleteGlobalSupplier,
  bulkUploadGlobalSuppliersByExcel
} from '../../Actions/supplierAction';
import { BETable } from '../../../../Components/BETable';
import { ColumnsType } from 'antd/es/table';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { Col, Row } from 'antd';
import { BEInput } from '../../../../Components/BEFormItems/BEInput';
import DeleteIcon from '../../../../Components/BEIcons/DeleteIcon';
import AddSupplier from '../Drawers/AddSupplier';
import InviteSuppliers from '../Drawers/InviteSuppliers';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import BEConfirmModal from '../../../../Components/BEConfirmModal';
import Upload from "antd/es/upload/Upload";
import { bulkUploadSuppliers } from '../../Actions/supplierAction';
import DownloadTemplate from "../../../../Utils/Download/Excell/DownloadTemplate";
import { BEBulkUploadDrawer } from '../../../../Components/BEDrawer/BEBulkUploadDrawer';

export interface IAllSuppliersProps {
}


export function AllSuppliers(props: IAllSuppliersProps) {
  const dispatch = useAppDispatch();
  const suppliers = useTypedSelector(state => state.supplier.Suppliers);
  const [openAddSupplier, setOpenAddSupplier] = React.useState(false);
  const [openBulkUpload, setOpenBulkUpload] = React.useState(false);
  const [openInvite, setOpenInvite] = React.useState(false)
  const [filteredSuppliers, setFilteredSuppliers] = React.useState<any>([])
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = React.useState(false)
  const [selectedSupplier, setSelectedSupplier] = React.useState<any>(null)

  const bulkUpload = async (file: any) => {
    const formData = {
      SUPPLIERS: file,
    }
    await dispatch(bulkUploadSuppliers(formData));
  }

  React.useEffect(() => {
    dispatch(getSuppliers());
  }, []);

  React.useEffect(() => {
    if (suppliers.status === 'success') {
      setFilteredSuppliers(suppliers.data)
    }
  }, [suppliers])

  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Point of Contact',
      dataIndex: 'poc',
      key: 'poc',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Industry',
      dataIndex: 'industry',
      key: 'industry',
    },
    {
      title: 'Last Compliance FY',
      dataIndex: 'last_compliance_fy',
      key: 'last_compliance_fy',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <div style={{ height: '1rem', float: "left" }} onClick={() => setOpenDeleteConfirmationModal(true)} onMouseEnter={() => setSelectedSupplier(record)}>
          <DeleteIcon inheritSize stroke={PrimaryTheme.primaryGray} />
        </div>
      )
    }

  ];

  return (
    <div className="all-suppliers">
      <Row style={{ margin: '0 auto' }}>
        <Col span={8}><BEInput search size="large" placeholder="Search for suppliers" onChange={(e) => {
          const filtered = suppliers.data.filter((sup) => sup.name.toLowerCase().includes(e.target.value.toLowerCase()))
          setFilteredSuppliers(filtered)
        }} /></Col>
        <Col span={8}></Col>
        <Col span={8} style={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end' }}>
          {/* <BEButton size="large" onClick={() => setOpenInvite(true)} style={{marginRight: '1rem'}}>
            + Invite to Assessment
          </BEButton> */}
          <BEButton className="primary" size="large" onClick={() => setOpenAddSupplier(true)}>
            + Add Supplier(s)
          </BEButton>

          {/* <BEButton size="large" className="primary"
              onClick={()=>{
                DownloadTemplate(['name','poc','email','industry', 'internal', 'last_compliance_year'])
              }}
            >
              Download Template
          </BEButton>

          <Upload
                beforeUpload={(e) => {
                  bulkUpload(e);
                  return false;
                }}
                action={undefined}
                showUploadList={false}
                multiple={false}
                fileList={[]}
              >
                {" "}
                <BEButton size="large" className="primary">
                 Bulk Upload
                </BEButton>
            </Upload> */}
            <BEButton size="large" className="primary"
              onClick={() => setOpenBulkUpload(true)}
            >Bulk Upload</BEButton>
         
        </Col>
      </Row>

      <AddSupplier open={openAddSupplier} setOpen={setOpenAddSupplier} />
      <InviteSuppliers open={openInvite} setOpen={setOpenInvite} />
      <BETable
        columns={columns}
        data={filteredSuppliers.filter((sup: any) => { return sup.internal === false })}
        loading={suppliers.status === "loading"}
      />

      <BEConfirmModal
        title="Delete Supplier"
        message="Are you sure you want to delete this supplier?"
        visible={openDeleteConfirmationModal}
        setVisible={setOpenDeleteConfirmationModal}
        onConfirm={async () => { await dispatch(deleteGlobalSupplier(selectedSupplier.id)) }}

      ></BEConfirmModal>
       <BEBulkUploadDrawer
            fileKey='SUPPLIERS'
            open={openBulkUpload}
            setOpen={setOpenBulkUpload}
            heading="Bulk Upload Suppliers"
            uploadFunction={bulkUploadGlobalSuppliersByExcel}
            columnArray={['name', 'poc', 'email', 'industry']}
            fileName='SuppliersTemplate'
          />
    </div>
  );
}
