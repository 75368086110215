import React from 'react'
import IconProps from './Interface/IconProps'

const CompanyIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 24}
      height={props.inheritSize ? "100%" : 24}
      viewBox="0 0 24 24"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 22H22M8.66667 7.55556H9.77778M8.66667 12H9.77778M8.66667 16.4444H9.77778M14.2222 7.55556H15.3333M14.2222 12H15.3333M14.2222 16.4444H15.3333M4.22222 22V4.22222C4.22222 3.63285 4.45635 3.06762 4.8731 2.65087C5.28984 2.23413 5.85507 2 6.44444 2H17.5556C18.1449 2 18.7102 2.23413 19.1269 2.65087C19.5437 3.06762 19.7778 3.63285 19.7778 4.22222V22"
        stroke={props.stroke ? props.stroke : "white"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default CompanyIcon