import React from 'react'
import IconProps from './Interface/IconProps'

const ChevronLeftIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 24}
      height={props.inheritSize ? "100%" : 24}
      viewBox="0 0 24 24"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9995 6.70461C14.6095 6.31461 13.9795 6.31461 13.5895 6.70461L8.99953 11.2946C8.60953 11.6846 8.60953 12.3146 8.99953 12.7046L13.5895 17.2946C13.9795 17.6846 14.6095 17.6846 14.9995 17.2946C15.3895 16.9046 15.3895 16.2746 14.9995 15.8846L11.1195 11.9946L14.9995 8.11461C15.3895 7.72461 15.3795 7.08461 14.9995 6.70461Z"
        fill={props.fill ? props.fill : "#333333"}
      />
    </svg>
  );
}

export default ChevronLeftIcon