import React from 'react'
import IconProps from '../Interface/IconProps';

const EnergyIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 10}
      height={props.inheritSize ? "100%" : 15}
      viewBox="0 0 10 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33333 5V8.88333L5.41667 11.8083V13.3333H4.58333V11.8083L1.66667 8.875V5H8.33333ZM8.33333 0H6.66667V3.33333H3.33333V0H1.66667V3.33333H1.65833C0.75 3.325 0 4.075 0 4.98333V9.58333L2.91667 12.5V15H7.08333V12.5L10 9.575V5C10 4.08333 9.25 3.33333 8.33333 3.33333V0Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
    </svg>
  );
}

export default EnergyIcon