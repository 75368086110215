import API from "../../API";

export const GET_MATTASS_RESPONDER_DATA = async (id: number) =>
  await API.GET(`/enterprise/materiality-assessment-responder/${id}/`);

export const SUBMIT_MATTASS_RESPONDER_DATA = async (email: string,token: string,data: any)=>
  await API.POST(`/enterprise/materiality-assessment-survey-answers/?email=${email}&token=${token}`,
data
);

export const GET_MATTASS_STATUS = async (token: any) => {
  return await API.GET(`/enterprise/materiality-assessment-status/?token=${token}`);
};
