import React from 'react'
import IconProps from "./Interface/IconProps";

const CancleIcon = (props: IconProps) => {
    return (
        <svg width={props.inheritSize ? "100%" : 20}
        height={props.inheritSize ? "100%" : 20}
         viewBox="0 0 14 14" fill={props.fill?props.fill:"#9F9F9F"}
         xmlns="http://www.w3.org/2000/svg">
            <path d="M13.2987 0.710703C12.9087 0.320703 12.2787 0.320703 11.8887 0.710703L6.99875 5.5907L2.10875 0.700703C1.71875 0.310703 1.08875 0.310703 0.69875 0.700703C0.30875 1.0907 0.30875 1.7207 0.69875 2.1107L5.58875 7.0007L0.69875 11.8907C0.30875 12.2807 0.30875 12.9107 0.69875 13.3007C1.08875 13.6907 1.71875 13.6907 2.10875 13.3007L6.99875 8.4107L11.8887 13.3007C12.2787 13.6907 12.9087 13.6907 13.2987 13.3007C13.6887 12.9107 13.6887 12.2807 13.2987 11.8907L8.40875 7.0007L13.2987 2.1107C13.6787 1.7307 13.6787 1.0907 13.2987 0.710703Z" fill={props.fill?props.fill:"#9F9F9F"} />
        </svg>

    );
}

export default CancleIcon