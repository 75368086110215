import * as React from 'react';
import { ButtonProps, Button } from 'antd';
import './style.scss'
import useDebounce from '../../../Config/Hooks/useDebouncedClick';
import { clickDelay } from '../../../Config/Constants';
import { BEMessage } from '../../BEMessage';

export interface IBEButtonProps extends ButtonProps {
  allowAuditorClick?: boolean;
}

//ClassNames --> primary,disabled
export function BEButton(props: IBEButtonProps) {
  const { children, ...otherProps } = props;
  const role = localStorage.getItem('role');
  // preventing multiple clicks
  // useDebounce is a custom hook that debounces the click event
  const handleClick =(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    console.log(role, 'role')
    if(role == 'AUDITOR' && !props.allowAuditorClick){
      console.log(role, 'role')
      BEMessage.error('You are not authorized to perform this action');
      return;
    }
    otherProps.onClick && otherProps.onClick(e);
  }
  const debouncedClick = useDebounce(otherProps.onClick ? handleClick: () => {},clickDelay); 

  return (
    <Button 
    {...otherProps} 
    onClick={otherProps?.onClick ? debouncedClick : () => {}}
    style={{ ...otherProps.style }} className={
      `be-button ${otherProps.className ? otherProps.className : ''}`
    }>
      {children}
    </Button>
  );
}
