import API from "../../API";

export const ADD_SUPPLIER_ASSESSMENT = async (body: any) => {
    return await API.POST("/enterprise/csa-assessment/", body);
}

export const PATCH_CORE_SUPPLIER_ASSESSMENT = async (body: any, id: number) => {
    return await API.PATCH(`/enterprise/csa-assessment/${id}/`, body);
}

export const DELETE_SUPPLIER_ASSESSMENT = async (id: number) => {
    return await API.DELETE(`/enterprise/csa-assessment/${id}/`);
}

export const EDIT_SUPPLIER_ASSESSMENT = async (body: any, id: number) => {
    return await API.PATCH(`/enterprise/brsr-core-assessment/${id}/`, body);
}

export const GET_ALL_SUPPLIER_ASSESSMENTS = async () => {
    return await API.GET("/enterprise/csa-assessment/");
}

export const ADD_GLOBAL_SUPPLIER = async (body: any) => {
    return await API.POST("/enterprise/suppliers/", body);
}

export const BULK_UPLOAD_GLOBAL_SUPPLIERS_BY_EXCEL = async (body: any) => {
    return await API.POST("/enterprise/supplier-bulk-upload/", body, true);
}

export const BULK_UPLOAD_SUPPLIERS = async (body: any) => {
    return await API.POST("/enterprise/supplier-bulk-upload/", body, true);
}


export const ADD_SUPPLIER_REFRENCE = async (body: any) => {
    return await API.POST("/enterprise/assessment-suppliers/", body);
}

export const GET_ALL_SUPPLIERS = async () => {
    return await API.GET("enterprise/suppliers/");
}

export const GET_ALL_ASSESMENT_SUPPLIER_REF = async (id:number) => {
    return await API.GET(`/enterprise/assessment-suppliers/${id}/`);
}

export const DELETE_SUPPLIER_REFRENCE = async (id: Number) => {
    return await API.DELETE(`/enterprise/assessment-suppliers/${id}/`);
}

export const GET_SUPPLIER_QUESTIONS = async () => {
    return await API.GET("/enterprise/brsr-library-bulk-upload/");
}

export const GET_SUPPLIER_SELECTED_QUESTIONS = async (token:string) => {
    return await API.GET(`/enterprise/csa-questions/?token=${token}`);
}

export const ADD_SUPPLIER_QUESTIONS = async (body: any) => {
    return await API.POST("/enterprise/my-csa-questions/", body);
}

export const DELETE_SUPPLIER_QUESTIONS = async (id: Number) => {
    return await API.DELETE(`/enterprise/my-csa-questions/${id}/`);
}

export const GET_SUPPLIER_ADDED_QUESTIONS = async (id: Number) => {
    return await API.GET(`/enterprise/my-csa-questions/${id}/`);
}

export const GET_SUPPLIER_OPTIONS = async () => {
    return await API.GET("/enterprise/brsr-core-options/");
}

export const SEND_SUPPLIER_ASSESSMENT = async (body: any,id: any) => {
    return await API.POST("/enterprise/invite-suppliers/", body);
}

export const GET_SUPPLIER_STATUS = async (id: number) => {
    return await API.GET(`/enterprise/invite-suppliers/${id}/`);
}

export const GET_SUPPLIER_ASSESSMENT_ANSWER_STATUS = async (assessment: Number, supplier: Number) => {
    return await API.GET(`/enterprise/brsr-assessment-status/${assessment}/${supplier}/`);
}

export const PATCH_SUPPLIER_ASSESSMENT_ANSWER_STATUS = async (body: any, assessment: Number, supplier: Number, token: string) => {
    return await API.PATCH(`/enterprise/brsr-assessment-status/${assessment}/${supplier}/?token=${token}`, body);
}

export const POST_SUPPLIER_ASSESSMENT_ANSWERS = async (body: any, token: string) => {
    return await API.POST(`/enterprise/csa-answers/?token=${token}`, body);
}

export const GET_SUPPLIER_ASSESSMENT_ANSWERS = async ( token: string) => {
    return await API.GET(`/enterprise/csa-answers/?token=${token}`);
}

export const GET_SUPPLIER_ASSESSMENT_SCORE = async (assessment: Number) => {
    return await API.GET(`/enterprise/brsr-core-results/${assessment}/`);
}

export const GET_SUPPLIER_ASSESSMENT_SCORE_BY_ID = async (assessment: Number, supplier: Number) => {
    return await API.GET(`/enterprise/brsr-core-results/${assessment}/${supplier}/`);
}

export const INVITE_SECTION_SUPPLIER = async (body: any, token: string) => {
    return await API.POST(`/enterprise/brsr-core-section-invite/?token=${token}`, body);
}

export const GET_SECTION_QUESTIONS = async (token: string) => {
    return await API.GET(`/enterprise/brsr-core-section-invite/?token=${token}`);
}

export const POST_SECTION_ANSWERS = async (body: any, token: string, assessment: Number, supplier: Number) => {
    return await API.POST(`/enterprise/brsr-core-co-suppliers-answers/?token=${token}&assessment=${assessment}&supplier=${supplier}`, body);
}

export const GET_ANSWERS_FILLED_BY_COSUPPLIERS = async (token: string, assessment: Number, supplier: Number) => {
    return await API.GET(`/enterprise/brsr-core-answers/${assessment}/${supplier}/?token=${token}`);
}

export const GET_CO_SUPPLIERS_INFO = async (token: string, assessment: Number, supplier: Number) => {
    return await API.GET(`/enterprise/brsr-core-co-suppliers/${assessment}/${supplier}/?token=${token}`);
}

export const DELETE_SUPPLIRE_REFRENCE = async (id: Number) => {
    return await API.DELETE(`/enterprise/assessment-suppliers/${id}/`);
}

export const EDIT_SUPPLIER_ANSWER = async (body: any, token: string,id:number) => {
    return await API.PATCH(`/enterprise/csa-answers/${id}/?token=${token}`, body);
}

export const GET_SUPPLIER_ANSWERS_BY_ASSESSMENT = async (assessment: Number) => {
    return await API.GET(`/enterprise/csa-answers/${assessment}/`);
}

export const EDIT_SUPPLIER_DETAILS = async (body: any, id: Number) => {
    return await API.PATCH(`/enterprise/suppliers/${id}/`, body);
}

export const DELETE_GLOBAL_SUPPLIER = async (id: Number) => {
    return await API.DELETE(`/enterprise/suppliers/${id}/`);
}

export const GET_RISK_METRICS = async (assessment: Number) => {
    return await API.GET(`/enterprise/brsr-core-risk-matrix/${assessment}/`);
}

export const CHANGE_SUPPLIER_STATUS = async (token:string) => {
    return await API.POST(`/enterprise/csa-answer-status/?token=${token}`);
}

export const GET_SUPPLIER_SCORE = async (id:any) => {
    return await API.GET("/enterprise/csa-answer-score/"+id+"/");
}

export const GET_BRSR_RISK_METRICS = async (assessment: Number) => {
    return await API.GET(`/enterprise/brsr-supplier-risk-matrix/${assessment}/`);
}

export const SEND_REMAINDER_TO_SUPPLIER = async (body: any) => {
    return await API.POST("/enterprise/send-csa-reminder/", body);
}