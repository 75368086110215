import { BEMessage } from "../../../Components/BEMessage";

export const handleAPICall = async (apiCall: Promise<any>) => {
    try {
        const response = await apiCall;
        return [response.data, null];
    } catch (error:any) {
        console.error("API error:", error);
        // if(error?.code === "ERR_NETWORK") BEMessage.error("Network Error. Try again later")
        return [null, error?.response?.data];
    }
};