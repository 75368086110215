import React, { useEffect } from "react";
import GeneralSettings from "./GeneralSettings";
import Users from "./Users";
import Notifications from "./Notifications";
import './styles.scss'
import { useTypedSelector } from "../../Config/Hooks/useTypedSelector";
import { Outlet } from "react-router-dom";
import { navigateTo } from "../../Navigation/NavigationManager";
import { Logs } from "./Logs";
import { Permissions } from "./Permissions";
import SettingsIcon from "../../Components/BEIcons/SettingsIcon";
import { PrimaryTheme } from "../../Config/Theme/theames";
import UsersIcon from "../../Components/BEIcons/UsersIcon";
import NotificationIcon from "../../Components/BEIcons/NotificationIcon";
import LabelIcon from "../../Components/BEIcons/labelcon";
import { setSettingsActiveTab } from "../../Redux/SettingsReducer";
import { useAppDispatch } from "../../Config/Hooks/useAppDispatch";
import IntegrationIcon from "../../Components/BEIcons/IntegrationIcon";
import Layers from "../../Components/BEIcons/Layers";
import { Labels } from "./Labels";
import { DataDownload } from "./DataDownload";
import DownloadIcon from "../../Components/BEIcons/DownloadIcon";

const Settings = () => {
  const activeState = useTypedSelector((state) => state.settings.activeTab);
  const dispatch = useAppDispatch();
  const role = localStorage.getItem('role');

  useEffect(() => {
    if (window.location.pathname.includes('/home/settings/vault')) {
      dispatch(setSettingsActiveTab(3));
    } else if (window.location.pathname === '/home/settings') {
      dispatch(setSettingsActiveTab(0));
    }
  }, [window.location.pathname])

  const settingsTabs = [
    {
      key: 1,
      name: "General Settings",
      icon: (
        <SettingsIcon
          fill={PrimaryTheme.primaryGreen}
          inheritSize
        />
      ),
      path: '/home/settings/general-settings'
    },
    {
      key: 2,
      name: "Users",
      icon: (
        <UsersIcon
          fill={PrimaryTheme.primaryGreen}
          inheritSize
        />
      ),
      path: '/home/settings/users'
    },
    {
      key: 3,
      name: "Vault",
      icon: (
        <Layers
          fill={PrimaryTheme.primaryGreen}
          inheritSize
        />
      ),
      path: '/home/settings/vault'
    },
    {
      key: 4,
      name: "Logs",
      icon: (
        <IntegrationIcon
          fill={PrimaryTheme.primaryGreen}
          inheritSize
        />
      ),
      path: '/home/settings/logs'
    },
    {
      key: 5,
      name: "Notifications",
      icon: (
        <NotificationIcon
          fill={PrimaryTheme.primaryGreen}
          inheritSize
        />
      ),
      path: '/home/settings/notifications'
    },
    {
      key: 6,
      name: "Permissions",
      icon: (
        <IntegrationIcon
          fill={PrimaryTheme.primaryGreen}
          inheritSize
        />
      ),
      path: '/home/settings/permissions'
    },
    {
      key: 7,
      name: "Labels",
      icon: (
        <LabelIcon
          fill={PrimaryTheme.primaryGreen}
          inheritSize
        />
      ),
      path: '/home/settings/labels'
    },
    {
      key: 8,
      name: "Data Download",
      icon: (
        <DownloadIcon
          fill={PrimaryTheme.primaryGreen}
          inheritSize
        />
      ),
      path: '/home/settings/data-download'
    }
  ];

  const restrictedTabs = ['Permissions', 'Data Download']

  return (
    <div className="settings">
      {/*<TopSection />*/}
      {activeState === 1 && <GeneralSettings />}
      {/* {activeState === 1 && <Integrations />} */}
      {activeState === 2 && <Users />}
      {/* {activeState === 4 && <BreatheSuite />} */}
      {activeState === 3 && <Outlet />}
      {activeState === 4 && <Logs />}
      {activeState === 5 && <Notifications />}
      {activeState === 6 && <Permissions />}
      {activeState === 7 && <Labels />}
      {activeState === 8 && <DataDownload />}
      {activeState === 0 &&
        <div className="settings-container">
          {
            (role === 'SUPER_ADMIN' ? settingsTabs: settingsTabs.filter(tab => !restrictedTabs.includes(tab.name))).
            map((tab, index) => (
              <div
                key={index}
                className='new-folder'
                onClick={() => {
                  dispatch(setSettingsActiveTab(tab.key));
                  navigateTo(tab.path);
                }}
              >
                <div className='circle'>
                  <div className='settings-icon'>
                  {tab.icon}
                  </div>
                </div>
                <span style={{ fontSize: "14px", lineHeight: "21px", fontWeight: "500" }}>{tab.name}</span>
              </div>
            ))
          }
          
        </div>}
    </div>
  );
};

export default Settings;
