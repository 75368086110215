import React from 'react'
import IconProps from './Interface/IconProps';

const Dashboard = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 20}
      height={props.inheritSize ? "100%" : 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <path
          d="M6.66671 7.50016H3.33337V16.6668H6.66671V7.50016Z"
          fill={props.stroke ? props.stroke : "white"}
        />
        <path
          d="M16.6667 10.8335H13.3334V16.6668H16.6667V10.8335Z"
          fill={props.stroke ? props.stroke : "white"}
        />
        <path
          d="M11.6667 3.3335H8.33337V16.6668H11.6667V3.3335Z"
          fill={props.stroke ? props.stroke : "white"}
        />
      </g>
    </svg>
  );
}

export default Dashboard