import { BEMessage } from "../../../Components/BEMessage";
import { handleAPICall } from "../../../Config/Functions/HandleAPICall";
import {
  setCurrentAddTemplateState,
  setCurrentTemplate,
  setSelectedIndustry,
  setTemplateTopics,
  setTemplatesDrafts,
  setReportBusinessUnits,
  setAllReports,
  setReportData,
  setEditReportStep,
  setTemplateFrameworkTopics,
  setReportingEvidence,
  setRportingTopicStatus,
  setAssignedDepartmentByReportTopics,
  setOmittedReportTopics,
  setSelectedGresbAssetTopics,
  setSelectedGresbEntityTopics,
  setCurrentEntity,
} from "../../../Redux/ReportingReducer";
import { selectedMetricType } from "../../../Redux/Types/dataManagerTypes";
import { reportDataTypes, ReportingTopicStatusTypes } from "../../../Redux/Types/reportingTypes";
import { AppDispatch, RootState } from "../../../Redux/store";
import exportToCSV from "../../../Utils/Download/Excell/ExportExcell";
import {
  GET_TEMPLATE_DRAFTS,
  GET_TEMPLATE_TOPICS,
  POST_TEMPLATE_DRAFT,
  POST_TEMPLATE_TOPICS, POST_REPORT_BUS,
  POST_REPORT_TEMPLATE,
  GET_ALL_REPORT_BUS,
  GET_REPORTS,
  PATCH_REPORT_TEMPLATE,
  GET_TOPICS_OF_TEMPLATE,
  POST_REPORT_DATA,
  GET_REPORT_DATA,
  PATCH_REPORT_DATA,
  DELETE_REPORT_TEMPLATE,
  REPORT_AUTO_FILL,
  PATCH_TEMPLATE_DRAFT,
  DELETE_TEMPLATE_TOPIC,
  EDIT_REPORT_TEMPLATE,
  DELETE_REPORT_BUS,
  GET_REPORT_BUSINESS_UNITS,
  DELETE_REPORT,
  GET_REPORT_EVEDENCE,
  POST_REPORT_EVEDENCE,
  DELETE_REPORT_EVEDENCE,
  GET_REPORT_TOPIC_STATUS,
  POST_REPORT_TOPIC_STATUS,
  PATCH_REPORT_TOPIC_STATUS,
  GET_ASSIGNED_DEPARTMENT_BY_REPORT_SECTION,
  POST_ASSIGNED_DEPARTMENT_BY_REPORT_SECTION,
  PATCH_ASSIGNED_DEPARTMENT_BY_REPORT_SECTION,
  DELETE_ASSIGNED_DEPARTMENT_BY_REPORT_SECTION,
  GET_OMITTED_REPORT_TOPICS,
  POST_OMITTED_REPORT_TOPICS,
  DELETE_OMITTED_REPORT_TOPICS,
  GET_REPORT_PDF,
} from "../../../Utils/Routes/ReportingRoutes";
import { postGresbEntities, updateAssetDetails } from "./gresbActions";

export const getReportPDF = (id: any) => async (dispatch: AppDispatch) => {
  const [data, error] = await handleAPICall(GET_REPORT_PDF(id));
  if (data)
    return data;
  else {
    BEMessage.error("Couldn't fetch report pdf");
    console.log(error);
    return null;
  }
}


export const getReportingTopicStatus = (id: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const reportTopicStatus = getState().reporting.topicStatus;
  dispatch(setRportingTopicStatus({
    status: 'loading',
    data: reportTopicStatus.data
  }))
  const [data, error] = await handleAPICall(GET_REPORT_TOPIC_STATUS(id));
  if (data) {
    dispatch(setRportingTopicStatus({
      status: 'success',
      data: data.data
    }));
  } else {
    BEMessage.error("Couldn't fetch topic status");
    console.log(error);
  }
}

export const postReportingTopicStatus = (body: any, notGet?: boolean) => async (dispatch: AppDispatch) => {
  const [data, error] = await handleAPICall(POST_REPORT_TOPIC_STATUS(body));
  if (data) {
    if (!notGet)
      dispatch(getReportingTopicStatus(body.esg_report))
    return true;
  } else {
    BEMessage.error("Couldn't add topic status");
    console.log(error);
    return false;
  }
}

export const patchReportingTopicStatus = (id: any, body: any, notFetch?: boolean) => async (dispatch: AppDispatch) => {
  const [data, error] = await handleAPICall(PATCH_REPORT_TOPIC_STATUS(id, body));
  if (data) {
    if (!notFetch) dispatch(getReportingTopicStatus(body.esg_report))
    return true;
  } else {
    BEMessage.error("Couldn't update topic status");
    console.log(error);
    return false;
  }
}

export const getReportingEvidence = (id: any) => async (dispatch: AppDispatch) => {
  dispatch(setReportingEvidence({
    status: 'loading',
    data: []
  }))
  const [data, error] = await handleAPICall(GET_REPORT_EVEDENCE(id));
  if (data) {
    dispatch(setReportingEvidence({
      status: 'success',
      data: data.data
    }));
  } else {
    BEMessage.error("Couldn't fetch evidence");
    console.log(error);
  }
}

export const postReportingEvidence = (body: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const [data, error] = await handleAPICall(POST_REPORT_EVEDENCE(body));
  if (data) {
    BEMessage.success("Evidence added successfully")
    const currentReport = getState().reporting.currentReport;
    dispatch(getReportingEvidence(currentReport?.id))
  } else {
    BEMessage.error("Couldn't add evidence");
    console.log(error);
  }
}

export const deleteReportingEvidence = (id: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const [data, error] = await handleAPICall(DELETE_REPORT_EVEDENCE(id));
  if (data) {
    const currentReport = getState().reporting.currentReport;
    BEMessage.success("Evidence deleted successfully")
    dispatch(getReportingEvidence(currentReport?.id))
  } else {
    BEMessage.error("Couldn't delete evidence");
    console.log(error);
  }
}

export const exportReport = (body: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
  let state = getState()
  const allTemplates = state.reporting.TemplateDrafts
  let data: any = [];
  const reportData = await dispatch(getReportData(body.id))
  if (reportData.data.length === 0) {
    BEMessage.error('Please fill the report before downloading')
    return;
  }
  const templateTopics = await dispatch(getTemplateTopics(body.template))
  if (reportData && templateTopics) {
    templateTopics.map((topic: any) => {
      let tempData = reportData.data.filter((data: any) => data.topic === topic.id).sort((a: any, b: any) => a.unique_code - b.unique_code)
      let currentReportFramework = allTemplates.data.find((template: any) => template.id === body.template)?.framework
      if (tempData.length > 0) {
        console.log(tempData, 'tempData')
        tempData.map((item: any) => {
          const feildToUse = allTemplates.data.find((template: any) => template.id === body.template)?.framework === 'GRI' ? "disclosure_detail_code" : "disclosure_code"
          const unit = topic.suggested_unit_of_measurement ? topic.suggested_unit_of_measurement : topic.unit
          if (currentReportFramework !== 'ESRS') {
            data.push({
              'Dimension': topic.dimension === 'nan' ? '-' : topic.dimension,
              'Topic': topic.topic === 'nan' ? '-' : topic.topic,
              "Ref. Code": topic[feildToUse] === 'nan' ? '-' : topic[feildToUse],
              "Accounting Metric": topic.accounting_metric,
              "Data Label": topic.data_label === 'nan' ? '-' : topic.data_label,
              "Data Label 2": topic.data_label_2 === 'nan' ? '-' : topic.data_label_2,
              "Value": item.data,
              "Unit": unit,
              'Description': item.additional_information,
            })
          }
          else data.push({
            'Dimension': topic.dimension === 'nan' ? '-' : topic.dimension,
            "Category": topic.category,
            "Standard": topic.standard,
            "Ref. Code": topic[feildToUse] === 'nan' ? '-' : topic[feildToUse],
            "metric": topic.metric,
            "Data Label": topic.data_label === 'nan' ? '-' : topic.data_label,
            "Data Label 2": topic.data_label_2 === 'nan' ? '-' : topic.data_label_2,
            "Value": item.data,
            "Unit": unit,
            'Description': item.additional_information,
          })
        })
      }
    })
    console.log(data)
    exportToCSV(data, body.name)
  }
}

export const postReportAutoFill = (id: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const templateTopics = getState().reporting.selectedTemplateTopics;
  let tempData: any = []
  templateTopics.data.map((item: any) => {
    let obj = {
      "bcode": item.bcode,
      "topic": item.id,
      'fy': item.fy === 'nan' ? 0 : Number(item.fy)
    }
    if (obj.bcode !== 'nan') tempData.push(obj)
  })
  let i, j, temparray: any = [], chunk = 50, dataToDispatch: any = [], noError = true;
  for (i = 0, j = tempData.length; i < j; i += chunk) {
    temparray = tempData.slice(i, i + chunk);
    dataToDispatch.push(temparray);
  }

  let data: { msg: string, data: any[] } = { msg: 'loading', data: [] }
  let error: any = null;

  let temp: any;
  await Promise.all([...dataToDispatch.map(async (item: any) => {
    console.log(item);
    [temp, error] = await handleAPICall(REPORT_AUTO_FILL(item, id));
    if (temp) {
      data.msg = 'success';
      data.data = [...data.data, ...temp.data]
    }
  })]);

  if (!error) {
    if (!data.data.length) {
      BEMessage.warning(`Selected metrics need to be filled/updated in the data manager.`)
    }
    else {
      let reportData = getState().reporting.reportData?.data;
      if (Object.keys(reportData).length === 0) await dispatch(patchReportTemplate(id, { stage: 2 }, true))

      let newData: any = await dispatch(getReportData(id))
      if (newData) await dispatch(changeStatusOfAllTopicsIfAllFilled(newData.data))
      if (Array.from(new Set(newData.data.map((item: any) => item.topic))).length === templateTopics.data.length) {
        await dispatch(patchReportTemplate(id, { stage: 4 }, true))
        BEMessage.success('Report is completed successfully')
      }
      BEMessage.success(`${temp.data.length} data points auto filled successfully`)
    }
  } else {
    BEMessage.error("Couldn't auto fill report");
    console.log(error);
  }
}

const changeStatusOfAllTopicsIfAllFilled = (data: reportDataTypes[]) => async (dispatch: AppDispatch, getSatate: () => RootState) => {
  //map all data using topic id
  //get all unique disclosure codes
  //for each disclosure code get all topics
  //if all topics have data and status doesn't exist then add status 1
  let dataMap: any = {}
  data.map((item: any) => {
    if (dataMap.hasOwnProperty(item.topic))
      dataMap[item.topic].push(item)
    else
      dataMap[item.topic] = [item]
  })

  let allTopics = getSatate().reporting.selectedTemplateTopics.data;
  let allUniCodes = Array.from(new Set(allTopics.map((item) => item.unique_code)));
  let topicsStatus = getSatate().reporting.topicStatus.data;

  let dataToSet: any = [];
  allUniCodes.map((uniqueCode: any) => {
    let topicsOfThisUniCode = allTopics.filter((item) => item.unique_code === uniqueCode);
    let topicsOfThisUniCodeHaveData = Object.keys(dataMap).filter((item) => topicsOfThisUniCode.find((topic: any) => topic.id === Number(item))).length;
    let topicsOfThisUniCodeHaveDataStatus = topicsStatus.find((item) => item.unique_code === uniqueCode);
    if (topicsOfThisUniCode.length === topicsOfThisUniCodeHaveData && !topicsOfThisUniCodeHaveDataStatus) {
      dataToSet.push({
        status: 1,
        esg_report: data[0].esg_report,
        unique_code: uniqueCode
      })
    }
  })
  if (dataToSet.length) {
    await Promise.all([...dataToSet.map(async (item: any) => {
      await dispatch(postReportingTopicStatus(item, true))
    })]);
    await dispatch(getReportingTopicStatus(data[0].esg_report))
  }
}


export const getReportData = (id: any) => async (dispatch: AppDispatch) => {
  dispatch(setReportData({
    status: 'loading',
    data: {}
  }))
  const [data, error] = await handleAPICall(GET_REPORT_DATA(id));
  if (data) {
    const dataToSet: any = {}
    data.data.map((item: any) => {
      if (dataToSet.hasOwnProperty(item.topic))
        dataToSet[item.topic].push(item)
      else
        dataToSet[item.topic] = [item]
    })
    dispatch(setReportData({
      status: 'success',
      data: dataToSet
    }));
    return data; //array format for export
  } else {
    BEMessage.error("Couldn't fetch report data");
    console.log(error);
    return null;
  }
}




export const postReportData = (body: any) => async (dispatch: AppDispatch, getSatate: () => RootState) => {
  let reportData = getSatate().reporting.reportData?.data
  let topicId = body.topic;
  let dataToSet: any = { ...reportData };
  if (dataToSet.hasOwnProperty(topicId)) dataToSet = { ...reportData, [topicId]: [...reportData[topicId], body] };
  else dataToSet[topicId] = [body];
  dispatch(setReportData({
    status: 'success',
    data: { ...reportData, ...dataToSet }
  }));
  const [data, error] = await handleAPICall(POST_REPORT_DATA([body]));
  if (data) {
    let reportDataNew = getSatate().reporting.reportData?.data
    let newDataToSet: any = { ...reportDataNew };
    let newThisTopicData = reportDataNew[topicId].map((item: any) => {
      if (!item.hasOwnProperty('id')) return data.data[0]
      else return item
    })
    newDataToSet[body.topic] = newThisTopicData;
    dispatch(setReportData({
      status: 'success',
      data: newDataToSet
    }));
    return true;
  } else {
    BEMessage.error("Couldn't save report data");
    console.log(error);
    return false;
  }
}

const patchReportData = (id: any, body: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const reportData = getState().reporting.reportData.data;

  let dataToSet: any = {
    ...reportData,
    [body.topic]: [
      ...reportData[body.topic].map((item: any) => {
        if (item.id == id)
          return { ...body, id }
        else
          return item
      })
    ]
  };
  dispatch(setReportData({
    status: 'success',
    data: dataToSet
  }));
  const [data, error] = await handleAPICall(PATCH_REPORT_DATA(id, body));
  if (data) {
    // BEMessage.success("Report data updated")

  } else {
    BEMessage.error("Couldn't update report data");
    console.log(error);
  }
}

export const fillReportData = (body: any, index?: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
  //index to handle multiple data for same topic (table type 1)
  if (body.data === '') return;
  let reportData = getState().reporting.reportData?.data;
  let topicStatus = getState().reporting.topicStatus;
  let selectedTopics = getState().reporting.selectedTemplateTopics;
  let thisReportStage = getState().reporting.currentReport?.stage;
  const currentGRESBSection = getState().reporting.currentGRESBSection;
  let thisTopic = topicStatus.data.find((item) => item.unique_code === selectedTopics.data.find((item) => item.id === body.topic)?.unique_code);
  if (Object.keys(reportData).length === 0) dispatch(patchReportTemplate(body.esg_report, { stage: 2 }, true))
  let topicId = body.topic;

  let dataExist: boolean = false;
  let thisTopicID: number = 0;
  for (let key in reportData) {
    if (key == topicId) {
      if (index !== undefined) {
        if (reportData[key].length > index) {
          dataExist = true;
          thisTopicID = reportData[key][index].id;
        }
      }
      else {
        dataExist = true;
        thisTopicID = reportData[key][0].id;
        console.log(reportData[key])
      }
      break;
    }
  }

  if (dataExist) {   //after approv/reject change status of whole reort and topic if any data is edited 
    dispatch(patchReportData(thisTopicID, body))
    if (thisReportStage && thisReportStage > 3) dispatch(patchReportTemplate(body.esg_report, { stage: 3 }, true))
    console.log('patching data', thisTopic, body)
    if (thisTopic && thisTopic.status > 1) dispatch(patchReportingTopicStatus(thisTopic.id, { status: 1, esg_report: body.esg_report }))
  }
  else {
    dispatch(changeStatusOfTopicOnPostData(body, index))
    dispatch(postReportData(body))
  }
}
//exeption is when table type is 1 and report is approved or rejected and extra row is added

export const changeStatusOfTopicOnPostData = (body: any, index?: number) => async (dispatch: AppDispatch, getSatate: () => RootState) => {
  const topicStatus = getSatate().reporting.topicStatus;
  const selectedTopics = getSatate().reporting.selectedTemplateTopics.data;
  const thisUniqueCode = selectedTopics.find((item) => item.id === body.topic)?.unique_code;
  const thisUnit = selectedTopics.find((item) => item.id === body.topic)?.unit;
  const reportData = getSatate().reporting.reportData.data;
  let noOfTopicsOfthisUniCode = selectedTopics.filter((item) => item.unique_code === thisUniqueCode).length;
  let idsOfTopicsOfthisUniCode = selectedTopics.filter((item) => item.unique_code === thisUniqueCode).map((item) => item.id);
  let noOfTopicsOfthisUniCodeHaveData = Object.keys(reportData).filter((item) => idsOfTopicsOfthisUniCode.includes(Number(item))).length;
  if (index !== undefined) {
    //this code is specifically for table type 1 
    //check for each topic in this unique code except this one, if any have data length < index then patch status to 0 else patch status to 1
    let topicsOfThisUniCode = selectedTopics.filter((item) => item.unique_code === thisUniqueCode && item.id !== body.topic);
    let maxIndex = Math.max(...topicsOfThisUniCode.map((item) => (reportData[item.id]?.length || 0)), index + 1);
    let topicsHaveDataLessThanIndex: boolean = topicsOfThisUniCode.filter((item) => (!reportData[item.id]?.length || (reportData[item.id]?.length || 0) < maxIndex)).length > 0;
    let statusID = topicStatus.data.find((item) => item.unique_code === thisUniqueCode)?.id;
    if (topicsHaveDataLessThanIndex) {
      if (statusID !== undefined && topicStatus.data.find((item) => item.unique_code === thisUniqueCode)?.status === 1)
        dispatch(patchReportingTopicStatus(statusID, { status: 0, esg_report: body.esg_report }))
    }
    else {
      if (statusID === undefined) dispatch(postReportingTopicStatus({ status: 1, esg_report: body.esg_report, unique_code: thisUniqueCode }))
      else dispatch(patchReportingTopicStatus(statusID, { status: 1, esg_report: body.esg_report }))
      dispatch(markReportAsCompleated(body.esg_report))
    }
    return;
  }
  if (noOfTopicsOfthisUniCode - 1 <= noOfTopicsOfthisUniCodeHaveData || thisUnit === 'Checkbox') {
    if (!topicStatus.data.find((item) => item.unique_code === thisUniqueCode)) {
      console.log('posting status')
      dispatch(postReportingTopicStatus({
        status: 1,
        esg_report: body.esg_report,
        unique_code: thisUniqueCode
      }))
    }
    dispatch(markReportAsCompleated(body.esg_report))
  }
}


// check for report compleation,,
//***** only dispatch when change in status of topic is made
const markReportAsCompleated = (id: any) => async (dispatch: AppDispatch, getSatate: () => RootState) => {
  const selectedTopics = getSatate().reporting.selectedTemplateTopics.data;
  const allUniCodes = Array.from(new Set(selectedTopics.map((item) => item.unique_code)));
  const topicsStatus = getSatate().reporting.topicStatus.data;
  const allCompleatedStatus = topicsStatus.filter((item) => item.status === 1).map((item) => item.unique_code);
  if (allUniCodes.length - 1 <= allCompleatedStatus.length) {
    dispatch(patchReportTemplate(id, { stage: 3 }, true))
    // BEMessage.success('Report is compleated successfully')
  }
}

export const getAllTemplateDrafts = () => async (dispatch: AppDispatch) => {
  dispatch(
    setTemplatesDrafts({
      status: "loading",
      data: [],
    })
  );

  const [data, error] = await handleAPICall(GET_TEMPLATE_DRAFTS())
  if (data) {
    dispatch(
      setTemplatesDrafts({
        status: "success",
        data: data.data,
      })
    );
  }
  else {
    console.log(error)
    BEMessage.error("Couldn't fetch template drafts")
    dispatch(
      setTemplatesDrafts({
        status: "error",
        data: [],
      })
    );
  }
}

export const createTemplateDraft = (body: any) => async (dispatch: AppDispatch) => {
  const [data, error] = await handleAPICall(POST_TEMPLATE_DRAFT(body));
  if (data) {
    BEMessage.success("Successfully added template draft")
    dispatch(setCurrentTemplate(data.data));
    return data.data;
  } else {
    BEMessage.error("Couldn't save template drafts");
    console.log(error);
    return null;
  }
}

export const patchTemplateDraft = (id: any, body: any) => async (dispatch: AppDispatch) => {
  const [data, error] = await handleAPICall(PATCH_TEMPLATE_DRAFT(id, body));
  if (data) {
    BEMessage.success("Successfully updated template draft")
    dispatch(setCurrentTemplate(data.data));
    dispatch(getAllTemplateDrafts())
  } else {
    BEMessage.error("Couldn't update template drafts");
    console.log(error)
  }
}

export const getTopicsForGRESB = () => async (dispatch: AppDispatch, getState: () => RootState) => {

  const currentGRESBSection = getState().reporting.currentGRESBSection;
  dispatch(setSelectedGresbAssetTopics({
    status: 'loading',
    data: []
  }));
  dispatch(setSelectedGresbEntityTopics({
    status: 'loading',
    data: []
  }));
  const [assetData, error] = await handleAPICall(GET_TEMPLATE_TOPICS("GRESBAsset"));
  let dataToSet = []
  if (assetData) {
    dispatch(setSelectedGresbAssetTopics({
      status: 'success',
      data: assetData.data
    }));
    dataToSet = assetData.data;
  }
  const [entityData, error2] = await handleAPICall(GET_TEMPLATE_TOPICS("GRESBEntity"));
  if (entityData) {
    dispatch(setSelectedGresbEntityTopics({
      status: 'success',
      data: entityData.data
    }));
    dataToSet = [...dataToSet, ...entityData.data];
  }

  dispatch(setTemplateFrameworkTopics({
    status: 'success',
    data: dataToSet
  }));

  if (currentGRESBSection === 'asset') {
    return assetData.data;
  }

  if (currentGRESBSection === 'entity') {
    return entityData.data;
  }

  if (!assetData || !entityData) {
    BEMessage.error("Couldn't fetch GRESB topics");
    console.log(error);
    console.log(error2);
    return null;
  }
}

export const getTopicsByFramework = (framework: string) => async (dispatch: AppDispatch) => {

  if (framework === 'GRESB') {
    return await dispatch(getTopicsForGRESB());
  }

  dispatch(setTemplateFrameworkTopics({
    status: 'loading',
    data: []
  }))
  const [data, error] = await handleAPICall(GET_TEMPLATE_TOPICS(framework));
  let dataToAdd = data.data.sort((a: any, b: any) => a.id - b.id);
  if (data) {
    dispatch(setTemplateFrameworkTopics({
      status: 'success',
      data: dataToAdd
    }));
    return dataToAdd;
  } else {
    BEMessage.error("Couldn't fetch template topics");
    console.log(error);
    return null;
  }
}

export const createTemplateTopics = (body: any) => async (dispatch: AppDispatch) => {
  const [data, error] = await handleAPICall(POST_TEMPLATE_TOPICS(body));
  if (data) {
    // BEMessage.success('Topics added successfully & Template is created')
    // dispatch(getAllTemplateDrafts())
    return true;
  } else {
    // BEMessage.error("Couldn't save template topics");
    console.log(error);
    return false;
  }
}

export function comparewDisclosureDetailCode(a: any, b: any) {
  //its in the format of 11.12.12 and 11.12.2
  //split by . and compare each index
  //start from 0 index and keep comparing till the end until one is greater than the other
  let aArray = a.disclosure_detail_code.split('.');
  let bArray = b.disclosure_detail_code.split('.');
  let i = 0;
  while (i < aArray.length && i < bArray.length) {
    if (Number(aArray[i]) < Number(bArray[i])) return -1;
    else if (Number(aArray[i]) > Number(bArray[i])) return 1;
    i++;
  }
  if (aArray.length < bArray.length) return -1;
  else if (aArray.length > bArray.length) return 1;
  return 1; // if both are equal
}

export const getTemplateTopics = (id: any, notSetsetValue?: boolean) => async (dispatch: AppDispatch) => {
  console.log(id)
  if (!notSetsetValue)
    dispatch(setTemplateTopics({
      status: 'loading',
      data: []
    }))
  const [data, error] = await handleAPICall(GET_TOPICS_OF_TEMPLATE(id));
  if (data) {
    let dataToAdd = data.data.sort((a: any, b: any) => comparewDisclosureDetailCode(a, b));
    if (!notSetsetValue)
      dispatch(setTemplateTopics({
        status: 'success',
        data: dataToAdd
      }));
    return dataToAdd;
  } else {
    BEMessage.error("Couldn't fetch template topics");
    if (!notSetsetValue)
      dispatch(setTemplateTopics({
        status: 'error',
        data: []
      }));
    console.log(error);
  }
  return null;
}

export const deleteTemplateTopic = (id: any) => async (dispatch: AppDispatch) => {
  const [data, error] = await handleAPICall(DELETE_TEMPLATE_TOPIC(id));
  if (data) {
    return true;
  } else {
    BEMessage.error("Couldn't delete topic");
    console.log(error);
    return false;
  }
}

export const createReportTemplate = (body: any, buArrey: any, entityCreated?: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const currentTemplate = getState().reporting.currentTemplate;
  const [data, error] = await handleAPICall(POST_REPORT_TEMPLATE(body));
  if (data) {
    if (currentTemplate?.framework === 'GRESB') {
      const dataToSend = {
        ...entityCreated,
        report: data.data.id,
        token: localStorage.getItem('gresb_token')
      }
      const currentEntity = await dispatch(postGresbEntities(dataToSend));
      dispatch(setCurrentEntity(currentEntity));
    } else {
      let busToAdd = buArrey.map((bu: any) => {
        return {
          business_unit: bu,
          esg_report: data.data.id
        }
      })
      await dispatch(addReportBusinessUnits(busToAdd))
    }
    await dispatch(getAllReports());
    BEMessage.success("Report created successfully")
    return true;
  } else {
    BEMessage.error("Couldn't create report");
    console.log(error);
  }
}

export const editReportTemplate = (id: any, body: any) => async (dispatch: AppDispatch) => {
  const [data, error] = await handleAPICall(EDIT_REPORT_TEMPLATE(id, body));
  if (data) {
    dispatch(getAllReports())
    return true;
  } else {
    BEMessage.error("Couldn't update report");
    console.log(error);
    return false;
  }
}


export const addReportBusinessUnits = (body: any) => async (dispatch: AppDispatch) => {
  const [data, error] = await handleAPICall(POST_REPORT_BUS(body));
  if (data) {
    return true;
  } else {
    BEMessage.error("Couldn't add business units");
    console.log(error);
    return false;
  }
}


export const deleteReportBusinessUnit = (id: any) => async (dispatch: AppDispatch) => {
  const [data, error] = await handleAPICall(DELETE_REPORT_BUS(id));
  if (data) {
    return true;
  } else {
    BEMessage.error("Couldn't delete report");
    console.log(error);
    return false;
  }
}

export const getReportBusinessUnits = (id: any) => async (dispatch: AppDispatch) => {
  dispatch(setReportBusinessUnits({
    status: 'loading',
    data: []
  }))
  const [data, error] = await handleAPICall(GET_REPORT_BUSINESS_UNITS(id));
  if (data) {
    dispatch(setReportBusinessUnits({
      status: 'success',
      data: data.data
    }));
    return data.data;
  } else {
    BEMessage.error("Couldn't fetch reports");
    console.log(error);
    return null;
  }
}

export const getAllReports = () => async (dispatch: AppDispatch) => {
  dispatch(setAllReports({
    status: 'loading',
    data: []
  }))
  const [data, error] = await handleAPICall(GET_REPORTS());
  if (data) {
    dispatch(setAllReports({
      status: 'success',
      data: data.data
    }));
  } else {
    BEMessage.error("Couldn't fetch reports");
    console.log(error);
  }
}

export const getAllReportBusinessUnits = () => async (dispatch: AppDispatch) => {
  dispatch(setReportBusinessUnits({
    status: 'loading',
    data: []
  }))
  const [data, error] = await handleAPICall(GET_ALL_REPORT_BUS());
  if (data) {
    dispatch(setReportBusinessUnits({
      status: 'success',
      data: data.data
    }));
  } else {
    BEMessage.error("Couldn't fetch reports");
    console.log(error);
  }
}

export const patchReportTemplate = (id: any, body: any, noMessage?: boolean) => async (dispatch: AppDispatch) => {
  const [data, error] = await handleAPICall(PATCH_REPORT_TEMPLATE(id, body));
  if (data) {
    if (!noMessage)
      BEMessage.success("Report updated")
    dispatch(getAllReports())
    return true;
  } else {
    BEMessage.error("Couldn't update report");
    console.log(error);
    return false;
  }
}

export const deleteReportTemplate = (id: any) => async (dispatch: AppDispatch) => {
  const [data, error] = await handleAPICall(DELETE_REPORT_TEMPLATE(id));
  if (data) {
    BEMessage.success("Template deleted")
    dispatch(getAllTemplateDrafts())
  } else {
    console.log(error);
    BEMessage.error(error?.error || "Couldn't delete template");
    console.log(error);
  }
}

export const deleteReport = (id: any) => async (dispatch: AppDispatch) => {
  const [data, error] = await handleAPICall(DELETE_REPORT(id));
  dispatch(getAllReports());
  BEMessage.success("Report deleted");
  // data and error both coming null from backend
  // if (data) {
  //   BEMessage.success("Report deleted")
  //   dispatch(getAllReports())
  // } else {
  //   BEMessage.error("Couldn't delete report");
  //   console.log(error);
  // }
}

export const getAssignedDepartmentByReportTopics = () => async (dispatch: AppDispatch) => {
  dispatch(setAssignedDepartmentByReportTopics({
    status: "loading",
    data: []
  }));
  const [data, error] = await handleAPICall(GET_ASSIGNED_DEPARTMENT_BY_REPORT_SECTION());
  if (data) {
    dispatch(setAssignedDepartmentByReportTopics({
      status: "success",
      data: data.data
    }));
  }
  else {
    dispatch(setAssignedDepartmentByReportTopics({
      status: "error",
      data: []
    }));
    console.log(error);
  }
}

export const postAssignedDepartmentByReportTopics = (data: any) => async (dispatch: AppDispatch) => {
  const [dataRes, error] = await handleAPICall(POST_ASSIGNED_DEPARTMENT_BY_REPORT_SECTION(data));
  if (dataRes) {
    BEMessage.success("Department Saved Successfully");
    dispatch(getAssignedDepartmentByReportTopics());
  }
  else {
    console.log(error);
  }
}

export const patchAssignedDepartmentByReportTopics = (id: any, data: any) => async (dispatch: AppDispatch) => {
  const [dataRes, error] = await handleAPICall(PATCH_ASSIGNED_DEPARTMENT_BY_REPORT_SECTION(id, data));
  if (dataRes) {
    BEMessage.success("Data Updated Successfully");
    dispatch(getAssignedDepartmentByReportTopics());
  }
  else {
    console.log(error);
  }
}

export const deleteAssignedDepartmentByReportTopics = (id: any) => async (dispatch: AppDispatch) => {
  const [dataRes, error] = await handleAPICall(DELETE_ASSIGNED_DEPARTMENT_BY_REPORT_SECTION(id));
  if (dataRes) {
    BEMessage.success("Data Deleted Successfully");
    dispatch(getAssignedDepartmentByReportTopics());
  }
  else {
    console.log(error);
  }
}

export const getOmittedReportTopics = () => async (dispatch: AppDispatch) => {
  dispatch(setOmittedReportTopics({
    status: 'loading',
    data: []
  }))
  const [data, error] = await handleAPICall(GET_OMITTED_REPORT_TOPICS());
  if (data) {
    dispatch(setOmittedReportTopics({
      status: 'success',
      data: data.data
    }));
  } else {
    dispatch(setOmittedReportTopics({
      status: 'error',
      data: []
    }));
    console.log(error);
  }
}

export const postOmittedReportTopics = (body: any) => async (dispatch: AppDispatch) => {
  const [data, error] = await handleAPICall(POST_OMITTED_REPORT_TOPICS(body));
  if (data) {
    BEMessage.success("Topic omitted successfully");
    dispatch(getOmittedReportTopics());
  }
  else {
    console.log(error);
  }
}

export const deleteOmittedReportTopics = (id: any) => async (dispatch: AppDispatch) => {
  const [data, error] = await handleAPICall(DELETE_OMITTED_REPORT_TOPICS(id));
  if (data) {
    BEMessage.success("Topic unomitted successfully");
    dispatch(getOmittedReportTopics());
  }
  else {
    console.log(error);
  }
}