import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { setDateSlicerMonthDM, setDateSlicerYearDM } from '../../../../../Redux/DataManagerReducer';
import { GlobalMonthsSlicer } from '../../../../../Components/BEMultipleSelect/MonthSlicer';

export interface IMonthsSlicerProps {
}

export function MonthsSlicer(props: IMonthsSlicerProps) {
  const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const currentMonth = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentMonth);

  return (
    <GlobalMonthsSlicer
      setDateSlicerMonth={setDateSlicerMonthDM}
      setDateSlicerYear={setDateSlicerYearDM}
      currentMonth={currentMonth}
      currentYear={currentYear}
    />
  );
}
