import React from "react";
import IconProps from "../Interface/IconProps";

const ListViewIcon = (props: IconProps) => {
  return (
    <svg width={props.inheritSize ? "100%" : 24}
      height={props.inheritSize ? "100%" : 24} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 18H18V16H0V18ZM0 14H18V12H0V14ZM0 10H18V8H0V10ZM0 6H18V4H0V6ZM0 0V2H18V0H0Z"
        fill={props.fill ? props.fill : "none"} />
    </svg>
  );
};

export default ListViewIcon;
