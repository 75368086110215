import React from 'react'
import IconProps from './Interface/IconProps';

const ESGMetricsIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 24}
      height={props.inheritSize ? "100%" : 24}
      viewBox="0 0 24 24"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1388 14.8599H12.5674V17.7171H11.1388V14.8599ZM14.7102 13.4314H16.1388V17.7171H14.7102V13.4314ZM7.56738 10.5742H8.99595V17.7171H7.56738V10.5742Z"
        fill={props.fill ? props.fill : "white"}
      />
      <path
        d="M18.2857 4.14286H16.1429V3.42857C16.1429 3.04969 15.9923 2.68633 15.7244 2.41842C15.4565 2.15051 15.0932 2 14.7143 2H9C8.62112 2 8.25776 2.15051 7.98985 2.41842C7.72194 2.68633 7.57143 3.04969 7.57143 3.42857V4.14286H5.42857C5.04969 4.14286 4.68633 4.29337 4.41842 4.56128C4.15051 4.82919 4 5.19255 4 5.57143V20.5714C4 20.9503 4.15051 21.3137 4.41842 21.5816C4.68633 21.8495 5.04969 22 5.42857 22H18.2857C18.6646 22 19.028 21.8495 19.2959 21.5816C19.5638 21.3137 19.7143 20.9503 19.7143 20.5714V5.57143C19.7143 5.19255 19.5638 4.82919 19.2959 4.56128C19.028 4.29337 18.6646 4.14286 18.2857 4.14286ZM9 3.42857H14.7143V6.28571H9V3.42857ZM18.2857 20.5714H5.42857V5.57143H7.57143V7.71429H16.1429V5.57143H18.2857V20.5714Z"
        fill={props.fill ? props.fill : "white"}
      />
    </svg>
  );
}

export default ESGMetricsIcon