

import React from 'react'
import IconProps from '../Interface/IconProps';

const RefregerantIcon = (props:IconProps) => {
  return (
    <svg 
    width={props.inheritSize ? "100%" : 16}
    height={props.inheritSize ? "100%" : 20}
     viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.20001 14.1992C4.85687 14.1992 6.20001 12.8561 6.20001 11.1992C6.20001 9.54236 4.85687 8.19922 3.20001 8.19922C1.54316 8.19922 0.200012 9.54236 0.200012 11.1992C0.200012 12.8561 1.54316 14.1992 3.20001 14.1992Z" 
    fill={props.stroke ? props.stroke : "#333333"}
    />
    <path d="M7.20001 6.19922C8.85687 6.19922 10.2 4.85607 10.2 3.19922C10.2 1.54236 8.85687 0.199219 7.20001 0.199219C5.54316 0.199219 4.20001 1.54236 4.20001 3.19922C4.20001 4.85607 5.54316 6.19922 7.20001 6.19922Z" 
    fill={props.stroke ? props.stroke : "#333333"}
    />
    <path d="M12.8 17.7992C14.4569 17.7992 15.8 16.4561 15.8 14.7992C15.8 13.1424 14.4569 11.7992 12.8 11.7992C11.1432 11.7992 9.80001 13.1424 9.80001 14.7992C9.80001 16.4561 11.1432 17.7992 12.8 17.7992Z"
     fill={props.stroke ? props.stroke : "#333333"}
     />
    </svg>
  );
}

export default RefregerantIcon