import React from 'react'
import IconProps from '../Interface/IconProps';

const GHGEmissions = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 18}
      height={props.inheritSize ? "100%" : 17}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3327 16.8332H0.666016V6.83317L6.49935 4.33317V5.99984L10.666 4.33317V6.83317H13.166L13.9993 0.166504H16.4993L17.3327 6.83317V16.8332ZM8.99935 6.7915L4.83268 8.45817V6.83317L2.33268 7.93317V15.1665H15.666V8.49984H8.99935V6.7915ZM8.16602 13.4998H9.83268V10.1665H8.16602V13.4998ZM4.83268 13.4998H6.49935V10.1665H4.83268V13.4998ZM13.166 10.1665H11.4993V13.4998H13.166V10.1665Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
    </svg>
  );
}

export default GHGEmissions