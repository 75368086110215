import {
    GET_METRICS_DATA_BY_BU_ID, POST_METRICS_DATA,
    EDIT_METRICS_DATA, DELETE_METRICS_DATA,
    GET_AGGREGATED_METRICS_DATA,
    GET_ALL_METRICS_DATA, EDIT_MULTIPLE_METRICS_DATA,
    GET_METRICS_STATUS,
    POST_METRICS_STATUS,
    PATCH_METRICS_STATUS,
    GET_METRICS_DATA_BY_BG_ID,
    GET_DERIVED_METRICS,
    GET_MY_DERIVED_METRICS,
    DELETE_MY_DERIVED_METRICS,
    POST_MY_DERIVED_METRICS,
    GET_DERIVED_METRICS_DATA,
    GET_ASSIGNED_DEPARTMENT_BY_CATEGORY,
    POST_ASSIGNED_DEPARTMENT_BY_CATEGORY,
    PATCH_ASSIGNED_DEPARTMENT_BY_CATEGORY,
    DELETE_ASSIGNED_DEPARTMENT_BY_CATEGORY,
    GET_OMITTED_METRICS,
    POST_OMITTED_METRICS,
    PATCH_OMITTED_METRICS,
    DELETE_OMITTED_METRICS,
    BULK_UPLOAD_MY_METRICS_DATA,
    GET_DERIVED_METRICS_DATA_BY_MONTH,
    GET_MY_METRIC_DATA_LABELS,
    POST_MY_METRIC_DATA_LABELS,
    DELETE_MY_METRIC_DATA_LABELS,
    GET_CONTRIBUTORS_BY_METRICS,
    POST_CONTRIBUTORS_BY_METRICS,
    DELETE_CONTRIBUTORS_BY_METRICS,
    GET_MY_METRIC_DATA_BY_MONTH,
    BULK_UPLOAD_MY_METRICS_DATA_ALL_BU
} from "../../../Utils/Routes/DataEntryRoutes";
import { handleAPICall } from "../../../Config/Functions/HandleAPICall";
import { AppDispatch, RootState } from "../../../Redux/store";
import { BEMessage } from "../../../Components/BEMessage";
import {
    setMyMetricsDataDM,
    setMetricStatusDM,
    setDerivedMetricsDM,
    setAllFullMyDerivedMetricsDM,
    setAssignedDepartmentByCategory,
    setOmittedMetrics,
    setMyDerivedMetricsDataByMonthDM,
    setLabels,
    setContributorsByMetrics,
    setMyMetricsDataByMonth,
} from "../../../Redux/DataManagerReducer";
import { BusinessUnitRefType, BusinessUnitType } from "../../../Redux/Types/entityTypes";
import { getStartDateAndEndDate } from "../../../Config/Functions/UsefullFunctions";
import { getLogs } from "../../Settings/Actions";
import { metricDataType, metricType } from "../../../Redux/Types/dataManagerTypes";
import dayjs from "dayjs";



// export const getMyDerivedMetricsData = (currentYear:number) => async (dispatch:AppDispatch,getState:()=>RootState) => {
//     dispatch(setMyDerivedMetricsDataDM({
//             status: "loading",
//             data: []
//         }));
//     const yearEnd = getState().companies.companyDetails.data?.reporting_year_end;
//     const dates = getStartDateAndEndDate(currentYear,yearEnd || "12-31");
//     if(dates === null) return;
//     const [data, error] = await handleAPICall(GET_DERIVED_METRICS_DATA(dates?.startDate ,dates?.endDate));
//     if (data) {
//         dispatch(setMyDerivedMetricsDataDM({
//             status: "success",
//             data: data.data
//         }));
//     }
//     else{
//         console.log(error);
//         dispatch(setMyDerivedMetricsDataDM({
//             status: "error",
//             data: []
//         }));
//     }
// }

export const getTopicStatusDM = (id: number, businessUnit?: number, month?: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    let topicsData = getState().dataManager.metrics.tableDataDM;
    let status: any = 0; //0=pending, 1=filled, 2=approved
    if (!topicsData.hasOwnProperty(id) || topicsData[id!]?.length === 0) return status;
    for (let i = 0; i < topicsData[id].length; i++) {
        let monthOfEntry = dayjs(topicsData[id][i].date_of_entry).month() + 1;
        if (topicsData[id][i].status === 2 && (!businessUnit || topicsData[id][i].business_unit === businessUnit) && (!month || monthOfEntry === month)) {
            if (status === 0) {
                status = 2;
            }
        }
        if (topicsData[id][i].status === 1 && (!businessUnit || topicsData[id][i].business_unit === businessUnit) && (!month || monthOfEntry === month)) {
            status = 1;
        }
    }
    return status;
}

export const getTotalMetricValueDM = (metric: metricType) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const tableDataDM = getState().dataManager.metrics.tableDataDM;
    const currentBusinessUnit = getState().common.homePage.currentBusinessUnit;
    const currentBusinessGroup = getState().common.homePage.currentBusinessGroup;
    const businessUnitRefs = getState().entity.BusinessUnitRefs;
    let totalValue: any = 0;
    if (!tableDataDM.hasOwnProperty(metric.id)) return totalValue;
    // totalValue = tableDataDM[metric.id]?.reduce((a: any, b: any) => b.status !== 3 ? (a + (b['value'] || 0)) : a, 0)
    if (metric.annual === false) {
        totalValue = tableDataDM[metric.id]?.reduce((a: any, b: any) => b.status !== 3 ? (a + (b['value'] || 0)) : a, 0)
    }
    else {
        if (currentBusinessUnit) {
            totalValue = [...tableDataDM[metric.id]].sort((a: metricDataType, b: metricDataType) => ((a.date_of_entry < b.date_of_entry)) ? 1 : -1).filter((item: metricDataType) => item.status !== 3)[0!]?.value || 0;
        } else if (currentBusinessGroup) {
            const uniqueBusinessUnits = businessUnitRefs.data.filter((bu: BusinessUnitRefType) => bu.business_group === currentBusinessGroup).map((bu: BusinessUnitRefType) => bu.business_unit);
            uniqueBusinessUnits.forEach((bu: number) => {
                let value = [...tableDataDM[metric.id]].sort((a: metricDataType, b: metricDataType) => ((a.date_of_entry < b.date_of_entry)) ? 1 : -1).filter((item: metricDataType) => item.status !== 3 && item.business_unit === bu)[0!]?.value || 0;
                totalValue += value;
            });

        } else {
            const uniqueBusinessUnits = Array.from(new Set(tableDataDM[metric.id].map((item: metricDataType) => item.business_unit)));
            uniqueBusinessUnits.forEach((bu: number) => {
                let value = [...tableDataDM[metric.id]].sort((a: metricDataType, b: metricDataType) => ((a.date_of_entry < b.date_of_entry)) ? 1 : -1).filter((item: metricDataType) => item.status !== 3 && item.business_unit === bu)[0!]?.value || 0;
                totalValue += value;
            });
        }
        
    }
    return totalValue;
}

export const getApprovedMetricValueDM = (metric: metricType) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const tableDataDM = getState().dataManager.metrics.tableDataDM;
    const currentBusinessUnit = getState().common.homePage.currentBusinessUnit;
    const currentBusinessGroup = getState().common.homePage.currentBusinessGroup;
    const businessUnitRefs = getState().entity.BusinessUnitRefs;
    let totalValue: any = 0;
    if (!tableDataDM.hasOwnProperty(metric.id)) return totalValue;
    // totalValue = tableDataDM[metric.id]?.reduce((a: any, b: any) => b.status !== 3 ? (a + (b['value'] || 0)) : a, 0)
    if (metric.annual === false) {
        totalValue = tableDataDM[metric.id]?.reduce((a: any, b: any) => b.status === 2 ? (a + (b['value'] || 0)) : a, 0)
    }
    else {
        if (currentBusinessUnit) {
            totalValue = [...tableDataDM[metric.id]].sort((a: metricDataType, b: metricDataType) => ((a.date_of_entry < b.date_of_entry)) ? 1 : -1).filter((item: metricDataType) => item.status === 2)[0!]?.value || 0;
        } else if (currentBusinessGroup) {
            const uniqueBusinessUnits = businessUnitRefs.data.filter((bu: BusinessUnitRefType) => bu.business_group === currentBusinessGroup).map((bu: BusinessUnitRefType) => bu.business_unit);
            uniqueBusinessUnits.forEach((bu: number) => {
                let value = [...tableDataDM[metric.id]].sort((a: metricDataType, b: metricDataType) => ((a.date_of_entry < b.date_of_entry)) ? 1 : -1).filter((item: metricDataType) => item.status === 2 && item.business_unit === bu)[0!]?.value || 0;
                totalValue += value;
            });

        } else {
            const uniqueBusinessUnits = Array.from(new Set(tableDataDM[metric.id].map((item: metricDataType) => item.business_unit)));
            uniqueBusinessUnits.forEach((bu: number) => {
                let value = [...tableDataDM[metric.id]].sort((a: metricDataType, b: metricDataType) => ((a.date_of_entry < b.date_of_entry)) ? 1 : -1).filter((item: metricDataType) => item.status === 2 && item.business_unit === bu)[0!]?.value || 0;
                totalValue += value;
            });
        }
        
    }
    return totalValue;
}

export const getDerivedMetricDataById = (currentYear: number, id: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const yearEnd = getState().companies.companyDetails.data?.reporting_year_end;
    const dates = getStartDateAndEndDate(currentYear, yearEnd || "12-31");
    if (dates === null) return;
    const [data, error] = await handleAPICall(GET_DERIVED_METRICS_DATA(dates?.startDate, dates?.endDate, id));
    if (data) {
        return data.data
    }
    else {
        console.log(error);
        return null;
    }
}

export const calculateTotalValueByMonthandBU = (bu: number, month: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
    return null;
}

export const getMyDerivedMetrics = () => async (dispatch: AppDispatch) => {
    dispatch(setAllFullMyDerivedMetricsDM({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_MY_DERIVED_METRICS());
    if (data) {
        dispatch(setAllFullMyDerivedMetricsDM({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setAllFullMyDerivedMetricsDM({
            status: "error",
            data: []
        }));
    }
}

export const postMyDerivedMetrics = (data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(POST_MY_DERIVED_METRICS(data));
    if (dataRes) {
        await dispatch(getMyDerivedMetrics());
        return true;
    }
    else {
        console.log(error);
        return null
    }
}

export const deleteMyDerivedMetrics = (id: any, setLoading?: Function) => async (dispatch: AppDispatch) => {
    setLoading && setLoading(true);
    const [dataRes, error] = await handleAPICall(DELETE_MY_DERIVED_METRICS(id));
    if (dataRes) {
        BEMessage.success("Metric Deleted Successfully");
        dispatch(getMyDerivedMetrics());
    }
    else {
        console.log(error);
    }
    setLoading && setLoading(false);
}

export const getDerivedMetrics = () => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(GET_DERIVED_METRICS());
    if (data) {
        let filteredDerivedMetrics: any = [];
        data.data.forEach((d: any) => {
            if (d.pillar !== "0" && d.pillar !== null) {
                filteredDerivedMetrics.push(d);
            }
        });
        dispatch(setDerivedMetricsDM({
            status: "success",
            data: filteredDerivedMetrics
        }));
        return filteredDerivedMetrics
    }
    else {
        console.log(error);
        dispatch(setDerivedMetricsDM({
            status: "error",
            data: []
        }));
        return null;
    }
}


export const getMetricsStatus = () => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(GET_METRICS_STATUS());
    if (data) {
        dispatch(setMetricStatusDM({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setMetricStatusDM({
            status: "error",
            data: []
        }));
    }
}

export const postMetricsStatus = (data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(POST_METRICS_STATUS(data));
    if (dataRes) {
        BEMessage.success("Data Saved Successfully");
        dispatch(getMetricsStatus());
    }
    else {
        console.log(error);
    }
}

export const patchMetricsStatus = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(PATCH_METRICS_STATUS(id, data));
    if (dataRes) {
        BEMessage.success("Data Updated Successfully");
        dispatch(getMetricsStatus());
    }
    else {
        console.log(error);
    }
}


export const getAllMetricsData = () => async (dispatch: AppDispatch) => {
    dispatch(setMyMetricsDataDM({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_ALL_METRICS_DATA());
    if (data) {
        let dataToSet = data.data.sort((a: any, b: any) => (a.timestamp > b.timestamp) ? 1 : -1);
        dispatch(setMyMetricsDataDM({
            status: "success",
            data: dataToSet
        }));
    }
    else {
        console.log(error);
        dispatch(setMyMetricsDataDM({
            status: "error",
            data: []
        }));
    }
}

export const getAllMetricsDataByBG = (businessUnits: BusinessUnitType[], currentBusinessGroup: number, businessUnitRefs: BusinessUnitRefType[]) => async (dispatch: AppDispatch) => {
    dispatch(setMyMetricsDataDM({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_ALL_METRICS_DATA());
    if (data) {
        let businessUnitsOfBG: any = {};
        // businessUnits.forEach((bu:BusinessUnitType) => {
        //     if(bu.business_group===currentBusinessGroup){
        //         businessUnitsOfBG[bu.id]=true;
        //     }
        // });
        businessUnitRefs.forEach((bu: BusinessUnitRefType) => {
            if (bu.business_group === currentBusinessGroup) {
                businessUnitsOfBG[bu.business_unit] = true;
            }
        });
        let newData: any = [];
        data.data.forEach((d: any) => {
            if (businessUnitsOfBG.hasOwnProperty(d.business_unit)) {
                newData.push(d);
            }
        });
        dispatch(setMyMetricsDataDM({
            status: "success",
            data: newData
        }));
    }
    else {
        console.log(error);
        dispatch(setMyMetricsDataDM({
            status: "error",
            data: []
        }));
    }

}


export const getMyMetricsData = (id: any) => async (dispatch: AppDispatch) => {
    dispatch(setMyMetricsDataDM({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_METRICS_DATA_BY_BU_ID(id));
    if (data) {
        dispatch(setMyMetricsDataDM({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setMyMetricsDataDM({
            status: "error",
            data: []
        }));
    }
}

export const getMyMetricsDataByBG = (id: any) => async (dispatch: AppDispatch) => {
    dispatch(setMyMetricsDataDM({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_METRICS_DATA_BY_BG_ID(id));
    if (data) {
        dispatch(setMyMetricsDataDM({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setMyMetricsDataDM({
            status: "error",
            data: []
        }));
    }
}

export const postMetricsData = (data: any) => async (dispatch: AppDispatch, setState: () => RootState) => {
    const [dataRes, error] = await handleAPICall(POST_METRICS_DATA(data));
    if (dataRes) {
        BEMessage.success("Data Saved Successfully");
        let state = setState();
        let currentBU = state.common.homePage.currentBusinessUnit;
        dispatch(getAllMetricsData());
        dispatch(getLogs());
        return dataRes;
    }
    else {
        BEMessage.error("Something went wrong, Please try again");
        console.log(error);
        return null;
    }
}

export const editMetricsData = (id: any, data: any) => async (dispatch: AppDispatch, setState: () => RootState) => {
    const [dataRes, error] = await handleAPICall(EDIT_METRICS_DATA(id, data));
    if (dataRes) {
        BEMessage.success("Data Updated Successfully");
        let state = setState();
        let currentBU = state.common.homePage.currentBusinessUnit;
        dispatch(getAllMetricsData());
        return dataRes;
    }
    else {
        console.log(error);
        return null;
    }
}

export const deleteMetricsData = (id: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(DELETE_METRICS_DATA(id));
    if (dataRes) {
        BEMessage.success("Data Deleted Successfully");
        dispatch(getAllMetricsData());
    }
    else {
        console.log(error);
    }
}

export const getDerivedMetricDataByMonth = () => async (dispatch: AppDispatch) => {
    dispatch(setMyDerivedMetricsDataByMonthDM({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_DERIVED_METRICS_DATA_BY_MONTH());
    if (data) {
        dispatch(setMyDerivedMetricsDataByMonthDM({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setMyDerivedMetricsDataByMonthDM({
            status: "error",
            data: []
        }));
    }
}

export const getAggregatedMetricsData = (year: any) => async (dispatch: AppDispatch) => {
    dispatch(setMyMetricsDataDM({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_AGGREGATED_METRICS_DATA(year));
    if (data) {
        dispatch(setMyMetricsDataDM({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setMyMetricsDataDM({
            status: "error",
            data: []
        }));
    }
}

export const editMultipleMetricsData = (data: any, business_unit: number) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(EDIT_MULTIPLE_METRICS_DATA(data));
    if (dataRes) {
        if (data[0].status === 2) BEMessage.success('Data Approved Successfully')
        else if (data[0].status === 3) BEMessage.success('Data Rejected Successfully')
        else BEMessage.success('Data Submited Successfully')
        dispatch(getAllMetricsData());
    }
    else {
        console.log(error);
    }
}

export const getAssignedDepartmentByCategory = () => async (dispatch: AppDispatch) => {
    dispatch(setAssignedDepartmentByCategory({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_ASSIGNED_DEPARTMENT_BY_CATEGORY());
    if (data) {
        dispatch(setAssignedDepartmentByCategory({
            status: "success",
            data: data.data
        }));
    }
    else {
        dispatch(setAssignedDepartmentByCategory({
            status: "error",
            data: []
        }));
        console.log(error);
    }
}

export const postAssignedDepartmentByCategory = (data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(POST_ASSIGNED_DEPARTMENT_BY_CATEGORY(data));
    if (dataRes) {
        BEMessage.success("Data Saved Successfully");
        dispatch(getAssignedDepartmentByCategory());
    }
    else {
        console.log(error);
    }
}

export const patchAssignedDepartmentByCategory = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(PATCH_ASSIGNED_DEPARTMENT_BY_CATEGORY(id, data));
    if (dataRes) {
        BEMessage.success("Data Updated Successfully");
        dispatch(getAssignedDepartmentByCategory());
    }
    else {
        console.log(error);
    }
}

export const deleteAssignedDepartmentByCategory = (id: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(DELETE_ASSIGNED_DEPARTMENT_BY_CATEGORY(id));
    if (dataRes) {
        BEMessage.success("Data Deleted Successfully");
        dispatch(getAssignedDepartmentByCategory());
    }
    else {
        console.log(error);
    }
}

export const getOmittedMetrics = () => async (dispatch: AppDispatch) => {
    dispatch(setOmittedMetrics({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_OMITTED_METRICS());
    if (data) {
        dispatch(setOmittedMetrics({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setOmittedMetrics({
            status: "error",
            data: []
        }));
    }
}

export const postOmittedMetrics = (data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(POST_OMITTED_METRICS(data));
    if (dataRes) {
        BEMessage.success("Metric omitted successfully.");
        dispatch(getOmittedMetrics());
    }
    else {
        console.log(error);
    }
}

export const patchOmittedMetrics = (id: any, data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(PATCH_OMITTED_METRICS(id, data));
    if (dataRes) {
        BEMessage.success("Data Updated Successfully");
        dispatch(getOmittedMetrics());
    }
    else {
        console.log(error);
    }
}

export const deleteOmittedMetrics = (id: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(DELETE_OMITTED_METRICS(id));
    if (dataRes) {
        BEMessage.success("Metric unomitted successfully.");
        dispatch(getOmittedMetrics());
    }
    else {
        console.log(error);
    }
}

export const bulkUploadMetricData = (buId: number, my_metric_id: number, data: any) => async (dispatch: AppDispatch) => {
    console.log(data);
    let [dataRes, error]: any = [null, null];
    if (buId !== 0) {
        [dataRes, error] = await handleAPICall(BULK_UPLOAD_MY_METRICS_DATA(buId, my_metric_id, data));
    } else {
        [dataRes, error] = await handleAPICall(BULK_UPLOAD_MY_METRICS_DATA_ALL_BU(my_metric_id, data));
    }
    
    if (dataRes) {
        dispatch(getAllMetricsData());
        BEMessage.success("Bulk upload successfull");
        return dataRes;
    }
    else {
        console.log(error);
        return null;
    }
}

export const getDataLabels = () => async (dispatch: AppDispatch) => {
    dispatch(setLabels({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_MY_METRIC_DATA_LABELS());
    if (data) {
        dispatch(setLabels({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setLabels({
            status: "error",
            data: []
        }));
    }
}

export const postDataLabels = (data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(POST_MY_METRIC_DATA_LABELS(data));
    if (dataRes) {
        dispatch(getDataLabels());
    }
    else {
        console.log(error);
    }
}

export const deleteDataLabels = (label: string) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(DELETE_MY_METRIC_DATA_LABELS(label));
    if (dataRes) {
        BEMessage.success("Label Deleted Successfully");
        dispatch(getDataLabels());
    }
    else {
        console.log(error);
        BEMessage.error("Something went wrong, Please try again");
    }
}

export const getContributorsByMetrics = () => async (dispatch: AppDispatch) => {
    dispatch(setContributorsByMetrics({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_CONTRIBUTORS_BY_METRICS());
    if (data) {
        dispatch(setContributorsByMetrics({
            status: "success",
            data: data.data
        }));
        return data.data;
    }
    else {
        dispatch(setContributorsByMetrics({
            status: "error",
            data: []
        }));
        console.log(error);
        return null;
    }
}

export const postContributorsByMetrics = (data: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(POST_CONTRIBUTORS_BY_METRICS(data));
    if (dataRes) {
        BEMessage.success("Contributor added successfully.");
        dispatch(getContributorsByMetrics());
        return dataRes.data;
    }
    else {
        BEMessage.error("Something went wrong, Please try again");
        console.log(error);
        return null;
    }
}

export const deleteContributorsByMetrics = (id: any) => async (dispatch: AppDispatch) => {
    const [dataRes, error] = await handleAPICall(DELETE_CONTRIBUTORS_BY_METRICS(id));
    if (dataRes) {
        BEMessage.success("Contributor removed successfully.");
        dispatch(getContributorsByMetrics());
    }
    else {
        console.log(error);
    }
}

export const getMyMetricsDataByMonth = () => async (dispatch: AppDispatch) => {
    dispatch(setMyMetricsDataByMonth({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_MY_METRIC_DATA_BY_MONTH());
    if (data) {
        dispatch(setMyMetricsDataByMonth({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setMyMetricsDataByMonth({
            status: "error",
            data: []
        }));
    }
} 
