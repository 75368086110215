import * as React from 'react';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { NoMetricMessage } from './NoMetricsMessage';
import { BETable } from '../../../../Components/BETable';
import { ColumnType } from 'antd/es/table';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { BEInput } from '../../../../Components/BEFormItems/BEInput';
import { deleteMetrics } from '../../../Actions/OnboardingStep4Actions';
import { deleteMyDerivedMetrics } from '../../../../Features/DataManager/Actions';
import BEStatusTag from '../../../../Components/BEStatusTag';
import ToolTip from '../../../../Components/BEToolTip';
import { defaultDerivedMetrics, defaultMetrics } from '../../../../Config/Data/dashboardData';
import { BEMessage } from '../../../../Components/BEMessage';
import { BEEyeButton } from '../../../../Components/BEEyeButton';

export interface IInnerContentProps {
    setOpenAdd: Function
}

export function InnerContent(props: IInnerContentProps) {
    const dispatch = useAppDispatch()
    const currentPillar = useTypedSelector(state => state.onBoarding.metrics.currentPillar)
    const currentCategory = useTypedSelector(state => state.onBoarding.metrics.currentCategory)
    const allMetrics = useTypedSelector(state => state.onBoarding.metrics.allMetrics.data)
    const currentGroup = useTypedSelector(state => state.onBoarding.metrics.currentGroup)
    const selectedMetrics = useTypedSelector(state => state.onBoarding.metrics.selectedMetrics.data)
    const selectedMetricsStatus = useTypedSelector(state => state.onBoarding.metrics.selectedMetrics.status)
    const selectedDerivedMetrics = useTypedSelector(state => state.dataManager.metrics.fullMyDerivedMetrics)
    const [thisGroupMetrics, setThisGroupMetrics] = React.useState<any>([])
    const [loading, setLoading] = React.useState<boolean>(false);
    const [filteredMetrics, setFilteredMetrics] = React.useState<any>([]);

    const columns: ColumnType<any>[] = [
        {
            title: 'Name',
            key: 'title',
            render: (text: any, record: any) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '10px' }}>{
                            // record.hasOwnProperty('direct') ? record.metric :
                            record.title
                        }</span>
                        {record.description !== '' && record.description !== "nan" &&
                            <BEEyeButton title={undefined} discription={record.description}>
                            </BEEyeButton>
                        }
                        {
                            record.hasOwnProperty('direct') &&
                            <ToolTip title='The values for C metrics are calculated.'>
                                <BEStatusTag status='success'>C</BEStatusTag>
                            </ToolTip>

                        }
                    </div>
                )
            }
        },
        {
            title: 'Unit',
            dataIndex: 'unit',
            key: 'unit'
        }
    ];

    React.useEffect(() => {
        let selectedMetricsMap: any = {};
        let derivedMetricsMap: any = {};
        selectedMetrics?.map((item: any) => {
            selectedMetricsMap[item.metric] = item.id
        })
        selectedDerivedMetrics?.data.map((item: any) => {
            derivedMetricsMap[item.derived_metric] = item.id
        })
        let tempMetrics: any = [];
        if (currentCategory !== 'Energy' && currentCategory !== 'Waste' && currentCategory !== 'Water') {
            allMetrics?.map((item: any) => {
                if (!item.hasOwnProperty('direct')) {
                    if (item.pillars === currentPillar && item.category === currentCategory && item.group === currentGroup && selectedMetricsMap.hasOwnProperty(item.id)) {
                        const tempItem = {
                            ...item,
                            id: selectedMetricsMap[item.id],
                        }
                        tempMetrics.push(tempItem)
                    }
                }
                else {
                    if (item.pillars === currentPillar && item.category === currentCategory && item.group === currentGroup && derivedMetricsMap.hasOwnProperty(item.id)) {
                        const tempItem = {
                            ...item,
                            id: derivedMetricsMap[item.id],
                        }
                        tempMetrics.push(tempItem)
                    }
                }
            });
        }
        else {
            allMetrics?.map((item: any) => {
                if (!item.hasOwnProperty('direct')) {
                    if (item.pillars === currentPillar && item.category === currentCategory && item.tab === currentGroup && selectedMetricsMap.hasOwnProperty(item.id)) {
                        const tempItem = {
                            ...item,
                            id: selectedMetricsMap[item.id],
                        }
                        tempMetrics.push(tempItem)
                    }
                }
                else {
                    if (item.pillars === currentPillar && item.category === currentCategory && item.tab === currentGroup && derivedMetricsMap.hasOwnProperty(item.id)) {
                        const tempItem = {
                            ...item,
                            id: derivedMetricsMap[item.id],
                        }
                        tempMetrics.push(tempItem)
                    }
                }
            });
        }
        setThisGroupMetrics(tempMetrics)
    }, [currentGroup, selectedMetrics, selectedDerivedMetrics])

    React.useEffect(() => {
        setFilteredMetrics(thisGroupMetrics)
    }, [thisGroupMetrics])

    return (
        <div>
            {
                thisGroupMetrics.length === 0 ?
                    <NoMetricMessage setOpenAdd={props.setOpenAdd} />
                    :
                    <div className='current-group-metrics'>
                        <div className='current-group-metrics-bar'>
                            <BEInput
                                onChange={(e: any) => {
                                    const value = e.target.value;
                                    if (value === '') setFilteredMetrics(thisGroupMetrics)
                                    else {
                                        const filteredData = thisGroupMetrics.filter((item: any) => item.title.toLowerCase().includes(value.toLowerCase()))
                                        setFilteredMetrics(filteredData)
                                    }
                                }
                                }
                                style={{ width: '313px' }}
                                className='search-input'
                                placeholder='Search in metrics'
                                search
                            />
                            <BEButton
                                onClick={() => props.setOpenAdd && props.setOpenAdd(true)}
                                style={{ marginLeft: 'auto' }}
                                className='primary'
                                size='large'
                            > + Add Metrics</BEButton>
                        </div>
                        <BETable
                            loading={loading || selectedMetricsStatus === 'loading'}
                            handleDelete={
                                (item: any) => {
                                    if (defaultMetrics.includes(item.bcode) || defaultDerivedMetrics.includes(item.bcode)) {
                                        BEMessage.info("Default metrics cannot be deleted")
                                        return;
                                    } else {
                                        // dispatch(deleteMetrics(item.id, setLoading))
                                        if (item.hasOwnProperty('direct')) {
                                            dispatch(deleteMyDerivedMetrics(item.id, setLoading))
                                        }
                                        else {
                                            dispatch(deleteMetrics(item.id, setLoading))
                                        }
                                    }
                                }
                            }
                            data={filteredMetrics}
                            columns={columns}
                            rowKey='id'
                        />

                    </div>
            }
        </div>
    );
}
