import { Form, Select } from 'antd';
import * as React from 'react';
import { arrangeGresbData, getDropDownOptionsForGRESB, postGresbEntityDataToPortal } from '../../../Actions/gresbActions';
import './style.scss';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { fillReportData } from '../../../Actions/template';

export interface IDropdownListProps {
    data: any[];
    resType?: string;
}

export function DropdownList (props: IDropdownListProps) {
    const [form] = Form.useForm();
    const [formValues, setFormValues] = React.useState<any>({});
    const [arrangedData, setArrangedData] = React.useState<any[]>([]);
    const currentReport = useTypedSelector(state => state.reporting.currentReport);
    const reportData = useTypedSelector(state => state.reporting.reportData.data);
    const gresbSlugData = useTypedSelector(state => state.reporting.gresbSlugData.data);
    const dispatch = useAppDispatch();

    // arrange Data on basis of unique topics
    React.useEffect(() => {
        const arrangedData = arrangeGresbData(props.data);
        let formData: any = {};
        props.data.forEach((item) => {
            const data = populateData(item);
            if (data) {
                formData = { ...formData, ...data };
            }
        });
        setFormValues(formData);
        form.setFieldsValue(formData);
        setArrangedData(arrangedData);
    }, [props.data, reportData]);

    // To populate data in the form
    const populateData = (data: any) => {
        const topicId = data?.id;
        const topicData = reportData[topicId!] ? reportData[topicId!][0!] : null;
        const value = topicData?.data;
        if (value) {
            return {
                [data.variable_code]: value
            }
        }
        return {
            [data.variable_code]: null
        }
    }

    // Function to handle saving the data
    const handleAddData = async (value: string, disclosure_detail_code: string) => {
        const data = props.data.find((item) => item.disclosure_detail_code === disclosure_detail_code);
        const dataToSend = {
            data: value
        }

        await dispatch(fillReportData({
            ...dataToSend,
            esg_report: currentReport?.id,
            topic: data?.id
        })).then(async () => {
            const portalDataUpdated = await dispatch(postGresbEntityDataToPortal(disclosure_detail_code, String(value)));
        });
    }

    return (
        <div className='dropdown-list-parent'>
            {arrangedData.map((data, index1) => {
                return (
                    <div key={index1} className='dropdown-question-container'>
                        <p className='dropdown-list-question'>
                            {(data?.accounting_metric !== 'nan' && data?.topic).charAt(0).toUpperCase() + (data?.topic).slice(1)}
                        </p>
                        <Form form={form}>
                            {
                                data.children?.map((item: any, index: any) => {
                                    const dropDownOptions = getDropDownOptionsForGRESB(item, gresbSlugData);
                                    return (
                                        <Form.Item
                                            key={index}
                                            name={item.variable_code}
                                        >
                                            <p style={{ marginBottom: "0.5rem" }}>
                                                {item.accounting_metric !== 'nan' && item.accounting_metric}
                                            </p>
                                            <Select
                                                value={formValues[item.variable_code]}
                                                onChange={(value) => handleAddData(value, item.disclosure_detail_code)}
                                            >
                                                {
                                                    dropDownOptions.map((option: any, index: any) => {
                                                        return (
                                                            <Select.Option
                                                                key={index}
                                                                value={option.value}
                                                            >
                                                                {option.label}
                                                            </Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    )
                                })
                            }
                        </Form>
                    </div>
                )
            })}
        </div>
    );
}
