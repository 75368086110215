import * as React from 'react';
import { Col, Drawer, DrawerProps, Row } from 'antd';
import './style.scss';
import CancleIcon from '../../BEIcons/CancleIcon';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import { GlobalLoader } from '../../GlobalLoader';

export interface IBEDrawerProps extends DrawerProps {
    open: boolean;
    setOpen: Function;
    secondaryColorTheme?: 'light' | 'dark';
    secondaryHeading?: string;
    secondarySubHeading?: string;
    secondryHeaderRight?: React.ReactNode;
    heading?: string;
    footer?: React.ReactNode;
    headingLight?: string;
    headerRight?: React.ReactNode;
    rightBody?: React.ReactNode;
    leftBody?: React.ReactNode;
    leftFooter?: React.ReactNode;
    rightFooter?: React.ReactNode;
    loading?: boolean;

}

export function BEDrawerType2(props: IBEDrawerProps) {

    return (
        <Drawer
            width="100vw"
            placement="right"
            onClose={() => { props.setOpen(false) }}
            open={props.open}
            closable={false}
        >
            <Row className='header-type2'>
                <div>
                    {props.heading + " "}
                    {props.headingLight &&
                        <span style={{ fontWeight: "200" }}>
                            | {props.headingLight}
                        </span>
                    }
                </div>
                <div style={{ flexGrow: 1 }} />
                <div className='header-type2-right'>
                    {props.headerRight}
                </div>
                <div className='cancel' onClick={() => props.setOpen(false)}>
                    <CancleIcon fill={PrimaryTheme.primaryGray} inheritSize />
                </div>
            </Row>
            <Row>
                <div className={`secondry-heading-type2 ${props.secondaryColorTheme ? props.secondaryColorTheme : 'dark'}`}
                    style={{ height: "4rem" }}
                >
                    <div>
                        <span className='sec-head'>{props.secondaryHeading}</span>
                        <p className='sec-sub-head'>{props.secondarySubHeading}</p>
                    </div>
                    <div className='sub-head-right'>
                        {props.secondryHeaderRight}
                    </div>
                </div>
            </Row>
            <Row className='drawer-type2-body'>
                <Col className='left-body'>
                    {props.loading ? <GlobalLoader height='50vh' /> :
                        (props.leftBody)
                    }
                </Col>
                <Col className='right-body'>
                    <div className='right-scroll'>
                    {props.loading ? <GlobalLoader height='50vh' /> :
                        (props.rightBody)
                    }
                    </div>
                    <div className='footer'>
                        <div className='footer-left'>
                            {props.leftFooter}
                        </div>
                        <div className='footer-right'>
                            {props.rightFooter}
                        </div>
                    </div>
                </Col>
            </Row>

        </Drawer>
    );
}
