import * as React from 'react';
import { Select } from 'antd';
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';
import dayjs from 'dayjs';
import { showFYFormatByYear } from '../../../Config/Functions/UsefullFunctions';

export interface IYearSelectOptionsProps {
  onChange: Function;
  value: number;
  disabled?: boolean;
}

export function YearSelectOptions(props: IYearSelectOptionsProps) {
  const yearEnd = useTypedSelector(state => state.companies.companyDetails.data?.reporting_year_end);
  const recentYears = useTypedSelector(state => state.common.financialYears.data);
  const recentYearsStatus = useTypedSelector(state => state.common.financialYears.status);


  return (
    <Select
    loading={recentYearsStatus === 'loading'}
      style={{ width: 150 }}
      value={props.value}
      size="large"
      onChange={(value) => props.onChange(Number(value))}
      disabled={props.disabled}
    >
      {
        recentYears
        .map((year) => (
          <Select.Option value={year}>{
            showFYFormatByYear(year, yearEnd || '')
          }</Select.Option>
        ))
      }
    </Select>
  );
}
