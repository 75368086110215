import React from 'react'
import IconProps from './Interface/IconProps';

const SaveIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 24}
      height={props.inheritSize ? "100%" : 24}
      viewBox="0 0 24 24"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1111 1H3.44444C2.08778 1 1 2.1 1 3.44444V20.5556C1 21.9 2.08778 23 3.44444 23H20.5556C21.9 23 23 21.9 23 20.5556V5.88889L18.1111 1ZM20.5556 20.5556H3.44444V3.44444H17.0967L20.5556 6.90333V20.5556ZM12 12C9.97111 12 8.33333 13.6378 8.33333 15.6667C8.33333 17.6956 9.97111 19.3333 12 19.3333C14.0289 19.3333 15.6667 17.6956 15.6667 15.6667C15.6667 13.6378 14.0289 12 12 12ZM4.66667 4.66667H15.6667V9.55556H4.66667V4.66667Z"
        fill={props.fill ? props.fill : "white"}
      />
    </svg>
  );
}

export default SaveIcon