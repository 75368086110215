import React from 'react'
import IconProps from './Interface/IconProps'

const EntityMappingIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 24}
      height={props.inheritSize ? "100%" : 24}
      viewBox="0 0 24 24"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 14C4.53043 14 5.03914 13.7893 5.41421 13.4142C5.78929 13.0391 6 12.5304 6 12C6 11.4696 5.78929 10.9609 5.41421 10.5858C5.03914 10.2107 4.53043 10 4 10C3.46957 10 2.96086 10.2107 2.58579 10.5858C2.21071 10.9609 2 11.4696 2 12C2 12.5304 2.21071 13.0391 2.58579 13.4142C2.96086 13.7893 3.46957 14 4 14ZM21 4C21.2652 4 21.5196 3.89464 21.7071 3.70711C21.8946 3.51957 22 3.26522 22 3C22 2.73478 21.8946 2.48043 21.7071 2.29289C21.5196 2.10536 21.2652 2 21 2C20.7348 2 20.4804 2.10536 20.2929 2.29289C20.1054 2.48043 20 2.73478 20 3C20 3.26522 20.1054 3.51957 20.2929 3.70711C20.4804 3.89464 20.7348 4 21 4ZM21 13C21.2652 13 21.5196 12.8946 21.7071 12.7071C21.8946 12.5196 22 12.2652 22 12C22 11.7348 21.8946 11.4804 21.7071 11.2929C21.5196 11.1054 21.2652 11 21 11C20.7348 11 20.4804 11.1054 20.2929 11.2929C20.1054 11.4804 20 11.7348 20 12C20 12.2652 20.1054 12.5196 20.2929 12.7071C20.4804 12.8946 20.7348 13 21 13ZM21 22C21.2652 22 21.5196 21.8946 21.7071 21.7071C21.8946 21.5196 22 21.2652 22 21C22 20.7348 21.8946 20.4804 21.7071 20.2929C21.5196 20.1054 21.2652 20 21 20C20.7348 20 20.4804 20.1054 20.2929 20.2929C20.1054 20.4804 20 20.7348 20 21C20 21.2652 20.1054 21.5196 20.2929 21.7071C20.4804 21.8946 20.7348 22 21 22Z"
        stroke={props.stroke ? props.stroke : "white"}
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M15.999 3H9.99902V21H15.999M5.99902 12H15.999"
        stroke={props.stroke ? props.stroke : "white"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default EntityMappingIcon