import * as React from 'react';
import './style.scss';
import companyLogo from '../../../assets/images/Company/LogoHorizontalDarkGreen.svg';
import { BEButton } from '../../../Components/BEFormItems/BEButton';
import { Row, Col } from 'antd';
import ArrowDownIcon from '../../../Components/BEIcons/ArrowDownIcon';

export interface IWelcomeScreenProps {
  buttonText: string;
  description: string;
  image: any;
  onClick: any;
  heading?: string;
}

export function WelcomeScreen(props: IWelcomeScreenProps) {
  return (
    <div className='welcome-screen-box'>
      <div className='welcome-screen'>
        <div>
          <Row>
            
            <Col span={12}>
              <div className='right-section'>
                <div>
                  <img src={props.image} alt="image" className='imag1' />
                </div>
              </div>
            </Col>
            <Col span={12} >
              <div className='left-section'>
                <div>
                  <p className='welcome-to'>Welcome To</p>
                  <img src={companyLogo} alt="logo" className='logo-img' />
                  {
                    props.heading && <p className='heading'>
                      {props.heading}
                    </p>
                  }
                  <p className='description'>
                    {props.description}
                  </p>
                  <BEButton size='large' className='primary'
                    onClick={props.onClick}
                  >
                   <div  style={{display:'flex',alignItems:'center',justifyContent:'center',gap:'1rem'}}>
                      {props.buttonText}
                      <div style={{
                        transform: 'rotate(-90deg)',
                      }}>
                        <ArrowDownIcon fill='white'/>
                      </div>
                   </div>
                  </BEButton>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}


