import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from '../../../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../../../Config/Hooks/useAppDispatch';
import { NewBETable } from '../../../../../../../Components/BETable/newBETable';
import { ColumnsType } from 'antd/es/table';
import { DownloadIcon } from '../../../../../../../stories/Icon.stories';
import '../../../index.scss';
export interface IMetricFolderProps {
}

export function MetricFolder(props: IMetricFolderProps) {
  const { fy_id, metric_id } = useParams();
  const FYData = useTypedSelector(state => state.vault.FYData);
  const myMetrics = useTypedSelector(state => state.dataManager.metrics.myMetrics);
  const [tableData, setTableData] = React.useState<any>([]);

  const columns: ColumnsType<any> = [
    {
      title:'S. No.',
      dataIndex:'sno',
      key:'sno',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Evidence',
      dataIndex: 'evidence',
      key: 'evidence',
      render: (text: any) => {
        // open the file in a new tab
        return (
          <a href={text} target="_blank">Evidence</a>
        )
      }
    },
    {
      title: 'Download',
      dataIndex: 'download',
      key: 'download',
      render: (text: any) => {
        // download the file
        return (
          <a href={text} ><DownloadIcon/></a>
        )
      }
    },
  ];

  React.useEffect(() => {
    if (FYData.status === 'success' && myMetrics.status === 'success') {
      setTableData(
        FYData.data[Number(fy_id)][Number(metric_id)]
      )
    }
  }, [fy_id, metric_id, FYData]);
  return (
    <div className='system-folder-container'>
        <h3>
          {
            myMetrics.data.filter((metric: any) => metric.id == metric_id)[0]?.title
          }
        </h3><br/>
        {
          //all files of this metrics  to be displayed here
          <NewBETable
            data={tableData}
            columns={columns}
          />
        }
    </div>
  );
}
