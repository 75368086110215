import type { StatusType } from "../Types/userTypes";
import { CompanyState } from "../Types/companyTypes";

import {
    Reducer,
    createAction,
    createSlice,
} from "@reduxjs/toolkit";
import { clearStore } from "../ExtraReducrActions";




const initialState: CompanyState = {
    companies: {
        status: 'idle',
        data: []
    },
    companyDetails:{
        status: 'idle',
        data: null
    },
    yearEnd:0,
}
export const userSlice = createSlice({
    name: "user",
    initialState,
    extraReducers: (builder) => builder.addCase(clearStore, () => initialState),
    reducers: {
        setCompanies: (state, action) => {
            state.companies = action.payload;
        },
        setCompanyDetails: (state, action) => {
            state.companyDetails = action.payload;
        },
        setYearEnd: (state, action) => {
            state.yearEnd = action.payload;
        }
    }
});

export const {
    setCompanies,
    setCompanyDetails,
    setYearEnd,
} = userSlice.actions;

const companiesReducer: Reducer<CompanyState> = userSlice.reducer;

export default companiesReducer;
