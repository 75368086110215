// import { CompanyState } from "../Types/companyTypes";
import { clearStore } from "../ExtraReducrActions";
import { onboardingState, stage1Interface } from "../Types/onBoarding";

import { Reducer, createSlice } from "@reduxjs/toolkit";

const initialState: onboardingState = {
  stage: 0,
  industries: {
    status: "idle",
    data:[]
  },
  stage1Data: {
    status: 'idle',
    data: null
  },
  metrics: {
    currentYear: 0,
    allMetrics:{
      status: 'idle',
      data: []
    },
    allSelectedMetrics: {
      status: 'idle',
      data: []
    },
    selectedMetrics: {
      status: 'idle',
      data: []
    },
    currentPillar: '',
    currentCategory: '',
    currentGroup: '',
  },
  frameworks: {
    selectedFrameworks: []
  }
};

export const onBoardingSlice = createSlice({
  name: "onboarding",
  initialState,
  extraReducers: (builder) => builder.addCase(clearStore, () => initialState),
  reducers: {
    setOnBoardingStep1: (state, action) => {
      state.stage = 1;
      state.stage1Data = action.payload;
    },
    setIndustries: (state, action) => {
      state.industries = action.payload;
    },
    setMetricCurrentYear: (state, action) => {
      state.metrics.currentYear = action.payload;
    },
    setAllMetrics: (state, action) => {
      state.metrics.allMetrics = action.payload;
    },
    setAllMetricsStatus: (state, action) => {
      state.metrics.allMetrics.status = action.payload;
    },
    setAllSelectedMetrics: (state, action) => {
      state.metrics.allSelectedMetrics = action.payload;
    },
    setSelectedMetrics: (state, action) => {
      state.metrics.selectedMetrics = action.payload;
    },
    setSelectedMetricsStatus: (state, action) => {
      state.metrics.selectedMetrics.status = action.payload;
    },
    setCurrentPillar: (state, action) => {
      state.metrics.currentPillar = action.payload;
    },
    setCurrentCategory: (state, action) => {
      state.metrics.currentCategory = action.payload;
    },
    setCurrentGroup: (state, action) => {
      state.metrics.currentGroup = action.payload;
    },
    setSelectedFrameworks: (state, action) => {
      state.frameworks.selectedFrameworks = action.payload
    }
  },
});

export const {
  setOnBoardingStep1,
  setIndustries,
  setMetricCurrentYear,
  setAllMetrics,
  setAllMetricsStatus,
  setAllSelectedMetrics,
  setSelectedMetrics,
  setSelectedMetricsStatus,
  setCurrentPillar,
  setCurrentCategory,
  setCurrentGroup,
  setSelectedFrameworks,
} = onBoardingSlice.actions;

const onBoadingReducer: Reducer<onboardingState> = onBoardingSlice.reducer;

export default onBoadingReducer;