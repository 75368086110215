import * as React from 'react';
import './style.scss';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { getSupplierSurveys } from '../../Actions/supplierAction';
import { BEInput } from '../../../../Components/BEFormItems/BEInput';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import AddSupplierAssessment from '../Drawers/AddSupplierAssessment';
import { BETable } from '../../../../Components/BETable';
import { ColumnsType } from 'antd/es/table';
import { setCreateSurveyStep } from '../../../../Redux/SupplierReducer';
import { navigateTo } from '../../../../Navigation/NavigationManager';
import { ChangeDateFormat } from '../../../../Config/Functions/UsefullFunctions';
import { deleteSupplierAssessment } from '../../Actions/supplierAction';
import BEStatusTag from '../../../../Components/BEStatusTag';
import EditIcon from '../../../../Components/BEIcons/EditIcon';
import DeleteIcon from '../../../../Components/BEIcons/DeleteIcon';
import dayjs from 'dayjs';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { setCurrentAssessment } from '../../../../Redux/SupplierReducer';
import { filterDataByYearEnd } from '../../../../Config/Functions/UsefullFunctions';
import BEConfirmModal from '../../../../Components/BEConfirmModal';

export interface IAllAsessmentsProps {
}

export const SupplierRiskClassificationThreshold = {
  Low: 80.00, // Low and above
  Medium: 50.00, // meadium to low
  High: 0 // high to medium
}

export function AllAsessments(props: IAllAsessmentsProps) {
  const dispatch = useAppDispatch();
  const allSurveys = useTypedSelector((state) => state.supplier.SuppliersSurveys);
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const yearEnd = useTypedSelector(state => state.companies.companyDetails.data?.reporting_year_end);
  const currentAssessment = useTypedSelector(state => state.supplier.currentAssessment);

  const columns: ColumnsType<any> = [
    {
      title: 'ASSESSMENT NAME',
      dataIndex: 'title',
      key: 'title',
      render: (text: any, data: any) =>
        <span
          onMouseEnter={() => {
            dispatch(setCurrentAssessment(data));
          }}
          style={{
            fontWeight: data.status > 1 ? 'bold' : 'normal',
            textDecoration: data.status > 1 ? 'underline' : 'none',
            cursor: data.status > 1 ? 'pointer' : 'default'
          }}
          onClick={() => {
            if (data.status > 1)
              navigateTo(`/home/suppliers/assessments/${data.id}`)
          }}
        >{text}</span>,
      sorter: (a: any, b: any) => (a.title > b.title) ? -1 : 1
    }, {
      title: 'TYPE',
      dataIndex: 'type',
      filters: [
        { text: 'BRSR-CORE', value: 'BRSR-CORE' },
        { text: 'CUSTOM', value: 'CUSTOM' },
      ],
      onFilter: (value: any, record: any) => record.type.indexOf(value) === 0,
    }, {
      title: 'NO. OF SUPPLIERS',
      dataIndex: 'suppliers',
    }, {
      title: 'SCORE',
      dataIndex: 'percentage_score',
      render: (text: any, record: any) => {
        return <span>{
          record.suppliers_with_status_done === 0 ? <p>-</p> :
            <p> {text.toFixed(2)}%</p>
        }
        </span>
      },
      sorter: (a: any, b: any) => (a.percentage_score > b.percentage_score) ? -1 : 1
    },{
      title:'COMPLETION %',
      render:(text,record)=>(
        <span>
          {
            record.suppliers === 0 ? <p>-</p> :
            <p> {((record.suppliers_with_status_done/record.suppliers)*100).toFixed(2)}%</p>
          }
        </span>
      ),
    }
    ,
    {
      title: 'Risk Classification',
      dataIndex: 'percentage_score',
      render: (text: any, record: any) => {
        return <span>{
          record.suppliers_with_status_done === 0 ? <p>-</p> :
            text > SupplierRiskClassificationThreshold.Low ? <BEStatusTag status='success'>Low</BEStatusTag> :
              text > SupplierRiskClassificationThreshold.Medium ? <BEStatusTag status='warning'>Medium</BEStatusTag> :
                <BEStatusTag status='error'>High</BEStatusTag>
        }</span>
      },
      filters: [
        { text: 'Low', value: 'Low' },
        { text: 'Medium', value: 'Medium' },
        { text: 'High', value: 'High' },
      ],
      onFilter: (value: any, record: any) => {
        const score = record.percentage_score;
        return value === 'Low' ? score > SupplierRiskClassificationThreshold.Low :
          value === 'Medium' ? score > SupplierRiskClassificationThreshold.Medium && score < SupplierRiskClassificationThreshold.Low :
            score < SupplierRiskClassificationThreshold.Medium && record.suppliers_with_status_done !== 0
      }
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   render: (text: any) => {
    //     return <span>{
    //       text === 1 ? <BEStatusTag status='warning'>Incomplete</BEStatusTag> :
    //         <BEStatusTag status='success'>Complete</BEStatusTag>
    //     }</span>
    //   }
    // },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text: any, data: any) => {
        return (
          <div style={{ display: 'flex', gap: '0.5rem' }}
            onMouseEnter={() => {
              dispatch(setCurrentAssessment(data));
            }}
          >
            {
              data.suppliers_with_status_done === 0 &&
              <div style={{ height: '1rem' }}
                onMouseEnter={() => {
                  dispatch(setCurrentAssessment(data));
                }}
                onClick={() => {
                  dispatch(setCreateSurveyStep(2));
                  setOpen(true);
                }}
              >
                <EditIcon inheritSize stroke={PrimaryTheme.primaryGray} />
              </div>
            }
            <div style={{ height: '1rem' }}
              onClick={() => {
                setOpenDelete(true);
              }}
            >
              <DeleteIcon inheritSize stroke={PrimaryTheme.primaryGray} />
            </div>
          </div>
        )
      }
    }
  ]

  React.useEffect(() => {
    if(allSurveys.status === 'idle')
      dispatch(getSupplierSurveys());
  }, []);

  return (
    <div className='all-ssupplier-assessments'>
      <div className='head'>
        {/* <BEInput placeholder='Search for an assessment' search style={{ width: '313px' }} size='large' /> */}
        <BEButton className='primary' size='large'
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            dispatch(setCurrentAssessment(null));
            dispatch(setCreateSurveyStep(1));
            setOpen(true);
          }}
        >
          + Add Assessment
        </BEButton>
      </div>
      <div className='table'>
        <BETable
          columns={columns}
          loading={allSurveys.status === 'loading'}
          // data={allSurveys.data.filter((item: any) => Number(dayjs(item.reporting_year).format('YYYY')) === currentYear)}
          data={filterDataByYearEnd(
            allSurveys.data.filter((item: any) => item.internal === false)
            , currentYear, yearEnd || '', 'reporting_year')}
        />
      </div>
      {
        open && <AddSupplierAssessment open={open} setOpen={setOpen} />
      }
      {
        openDelete && <BEConfirmModal title={`Delete ${currentAssessment?.title}`}
         visible={openDelete} setVisible={setOpenDelete} onConfirm={async() => {
          await dispatch(deleteSupplierAssessment(currentAssessment?.id));
        }} message='Are you sure you want to delete this assessment?' />
      }
    </div>
  );
}
