import * as React from 'react';
import { getAllUsers } from '../../../../Actions/UsersManagementActions';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { BETable } from '../../../../../Components/BETable';
import { ColumnsType } from 'antd/es/table';

export interface IReviewStep3Props {
}

export function ReviewStep3(props: IReviewStep3Props) {
    const dispatch = useAppDispatch();
    const users = useTypedSelector(state => state.userMgmt);

    const columns: ColumnsType<any> = [
        {
          title: "NAME",
          dataIndex: "name",
          sorter: (a, b) => (a < b ? -1 : 1),
          render: (value, record) => {
            return `${record.first_name} ${record.last_name}`;
          },
        },
        {
          title: "EMAIL",
          dataIndex: "email",
        },
        {
          title: "ROLE",
          dataIndex: "role",
          sorter: (a, b) => (a < b ? -1 : 1),
        },
        {
          title: "DEPARTMENT",
          dataIndex: "department",
        },
        // {
        //   title: "STATUS",
        //   dataIndex: "status",
        //   sorter: (a, b) => (a < b ? -1 : 1),
        // },
      ];

    React.useEffect(() => {
        dispatch(getAllUsers());
    }, []);

    return (
        <div className='step-3'>
            <BETable
             data={users.data.slice().sort((a,b)=>a.first_name?.localeCompare(b.first_name))
            }
             loading={users.status === 'loading'}
             columns={columns}
             />
        </div>
    );
}
