import { handleAPICall } from "../../../Config/Functions/HandleAPICall";
import { navigateTo } from "../../../Navigation/NavigationManager";
import { AppDispatch } from "../../../Redux/store";
import { BEMessage } from "../../../Components/BEMessage";
import {
    GET_MATERIALITY_SURVEY_ASSESSMENT_LIST,
    POST_MATERIALITY_SURVEY_ASSESSMENT_LIST,
    GET_MATERIALITY_ASSESSMENT_INDUSTRIES,
    GET_MATERIALITY_ASSESSMENT_TOPICS_BY_ID,
    POST_MATERIALITY_ASSESSMENT_SURVEY_TOPICS,
    POST_MATERIALITY_ASSESSMENT_STAKEHOLDER_BY_SURVEY_ID,
    GET_MATERIALITY_ASSESSMENT_STAKEHOLDERS_BY_SURVEY_ID,
    GET_MATERIALITY_ASSESSMENT_SURVEY_ANSWERS,
    GET_MATERIALITY_ASSESSMENT_TOPICS_BY_SURVEY_ID,
    GET_MATERIALITY_ASSESSMENT_RESPONDENTS,
    SEND_MATASS_REMAINDER,
    ENGAGE_STAKEHOLDER,
    DELETE_MATERIALITY_ASSESSMENT_STAKEHOLDER_BY_SURVEY_ID,
    PATCH_MATERIALITY_ASSESSMENT_STAKEHOLDER_BY_SURVEY_ID,
    BULK_UPLOAD_MATERIALITY_ASSESSMENT_STAKEHOLDER,
    PATCH_MATERIALITY_ASSESSMENT_SURVEY,
    GET_MATERIALITY_ASSESSMENT_STAKEHOLDERS,
    DELETE_MATERIALITY_ASSESSMENT_SURVEY,
    GET_ALL_STAKEHOLDERS,
    POST_GLOBAL_STAKEHOLDER,
    PATCH_GLOBAL_STAKEHOLDER,
    DELETE_GLOBAL_STAKEHOLDER,
    GET_MATERIALITY_ASSESSMENT_SURVEY_TOPICS_BY_ID,
    DELETE_MATERIALITY_ASSESSMENT_SURVEY_TOPICS,
} from "../../../Utils/Routes/MatirialityRoutes";

import {
    setAllSurveys,
    setIndustries,
    setTopics,
    setStakeholders,
    setCurrentMaterialitySurvey,
    setCreateSurveyStep,
    setRespondantsToAssessment,
    setSurveyTopics,
    setSurveyAnswers,
    setStakeholdersToAssessment,
    setSurveyResponseStatus,
    setSurveyResponderData,
    setGlobalStakeholders
} from "../../../Redux/MatirialityReducer";
import {
    GET_MATTASS_RESPONDER_DATA,
    GET_MATTASS_STATUS,
    SUBMIT_MATTASS_RESPONDER_DATA
} from "../../../Utils/Routes/SurveyResponses";



export const getMaterialitySurveys = () => async (dispatch: AppDispatch) => {
    dispatch(setAllSurveys({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_MATERIALITY_SURVEY_ASSESSMENT_LIST());
    if (data) {
        dispatch(setAllSurveys({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setAllSurveys({
            status: "error",
            data: []
        }));
    }
}

export const createMaterialitySurveys = (body: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(POST_MATERIALITY_SURVEY_ASSESSMENT_LIST(body));
    if (data) {
        BEMessage.success("Survey Created Successfully");
        dispatch(setCurrentMaterialitySurvey(data.data));
        dispatch(setCreateSurveyStep(2));
    }
    else {
        BEMessage.error(error.response.status === 400 ? 'Assessment with the same name already exists' : 'Error creating assessment. Please try again')
    }
}

export const deleteMaterialitySurvey = (id: number) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(DELETE_MATERIALITY_ASSESSMENT_SURVEY(id));
    if (data) {
        BEMessage.success("Survey Deleted Successfully");
        dispatch(getMaterialitySurveys());
        dispatch(setCurrentMaterialitySurvey(null))
    }
    else {
        console.log(error)
        BEMessage.error(error?.response?.data?.error ? error.response?.data?.error : "Survey couldn't be deleted")
    }
}

export const getMaterialityIndustries = () => async (dispatch: AppDispatch) => {
    dispatch(setIndustries({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_MATERIALITY_ASSESSMENT_INDUSTRIES());
    if (data) {
        dispatch(setIndustries({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setIndustries({
            status: "error",
            data: []
        }));
    }
}

export const getMaterialityTopics = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(setTopics({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_MATERIALITY_ASSESSMENT_TOPICS_BY_ID(id));
    if (data) {
        dispatch(setTopics({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setTopics({
            status: "error",
            data: []
        }));
    }
}

export const postMaterialityTopics = (body: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(POST_MATERIALITY_ASSESSMENT_SURVEY_TOPICS(body));
    if (data) {
        return true;
    }
    else {
        console.log(error);
        return false;
    }
}

export const deleteMaterialityTopics = (id: number) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(DELETE_MATERIALITY_ASSESSMENT_SURVEY_TOPICS(id));
    if (data) {
        return true;
    }
    else {
        return false;
    }
}

export const getMaterialityTopicsBySurveyId = (id: number) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(GET_MATERIALITY_ASSESSMENT_TOPICS_BY_SURVEY_ID(id));
    if (data) {
        return data.data;
    }
    else {
        console.log(error);
        BEMessage.error("Error fetching topics");
        return null;
    }
}

export const postMaterialityStakeholders = (body: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(POST_MATERIALITY_ASSESSMENT_STAKEHOLDER_BY_SURVEY_ID(body));
    if (data) {
        BEMessage.success("Stakeholders added to assessment successfully");
        dispatch(getMaterialityStakeholders(body[0].survey));
    }
    else {
        console.log(error);
    }
}

export const getMaterialityStakeholders = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(setStakeholders({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_MATERIALITY_ASSESSMENT_STAKEHOLDERS_BY_SURVEY_ID(id));
    if (data) {
        dispatch(setStakeholders({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setStakeholders({
            status: "error",
            data: []
        }));
    }
}


export const getMaterialitySurveyAnswers = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(setSurveyAnswers({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_MATERIALITY_ASSESSMENT_SURVEY_ANSWERS(id));
    if (data) {
        dispatch(setSurveyAnswers({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setSurveyAnswers({
            status: "error",
            data: []
        }));
    }
}

export const getMaterialitySurveyTopics = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(setSurveyTopics({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_MATERIALITY_ASSESSMENT_TOPICS_BY_SURVEY_ID(id));
    if (data) {
        dispatch(setSurveyTopics({
            status: "success",
            data: data.data
        }));
    }
    else {
        console.log(error);
        dispatch(setSurveyTopics({
            status: "error",
            data: []
        }));
    }
}


export const getAllRespondantsForAssessment = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(
        setRespondantsToAssessment({
            status: "loading",
            data: [],
        })
    );
    const [data, error] = await handleAPICall(GET_MATERIALITY_ASSESSMENT_RESPONDENTS(id));
    if (data) {
        dispatch(setRespondantsToAssessment({
            status: 'success',
            data: data?.data ? data.data : []
        }))
    }
    else {
        dispatch(setRespondantsToAssessment({
            status: 'error',
            data: []
        }))
    }
}

export const getAllStakeholdersForAssessment = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(
        setStakeholdersToAssessment({
            status: "loading",
            data: [],
        })
    );
    const [data, error] = await handleAPICall(
        GET_MATERIALITY_ASSESSMENT_STAKEHOLDERS(id)
    );
    if (data) {
        dispatch(
            setStakeholdersToAssessment({
                status: "success",
                data: data.data,
            })
        );
    } else {
        dispatch(
            setStakeholdersToAssessment({
                status: "error",
                data: [],
            })
        );
    }
}

export const sendMatAssReminder = (body: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(SEND_MATASS_REMAINDER(body));
    if (data) {
        BEMessage.success("Reminder sent successfully");
    }
    else {
        BEMessage.error("Failed to send reminder");
    }
}


export const sendInvitations = (body: any) => async (dispatch: AppDispatch) => {
    const [data, error] = await handleAPICall(ENGAGE_STAKEHOLDER(body));
    if (data) {
        BEMessage.success("Invitation sent successfully");
    } else {
        BEMessage.error("Failed to send invitation");
    }
}

export const deleteStakeholderById = (id: number) => async (disptch: AppDispatch) => {
    const [data, error] = await handleAPICall(DELETE_MATERIALITY_ASSESSMENT_STAKEHOLDER_BY_SURVEY_ID(id))
    if (data) {
        BEMessage.success("Stakeholder successfully deleted");
    } else {
        BEMessage.error("Stakeholder could not be deleted");
    }
}

export const editAssessment = (id: number, body: any) => async (disptch: AppDispatch) => {
    const [data, error] = await handleAPICall(
        PATCH_MATERIALITY_ASSESSMENT_SURVEY(id, body)
    );
    if (data) {
        BEMessage.success("Stakeholder updated successfully");
    } else {
        BEMessage.error("Stakeholder could not be updated");
    }
}

export const getSurveyStatus = (token: string) => async (dispatch: AppDispatch) => {
    dispatch(setSurveyResponseStatus(false))
    const [data, error] = await handleAPICall(GET_MATTASS_STATUS(token))
    if (data) {
        dispatch(setSurveyResponseStatus(data.status === "True"));
    }
    else {
        console.log(error)
        BEMessage.error("Failed to fetch status");
    }
}

export const fetchResponderData = (survey_id: number) => async (dispatch: AppDispatch) => {
    let [data, error] = await handleAPICall(
        GET_MATTASS_RESPONDER_DATA(survey_id)
    );
    if (data) {
        console.log(data.data)
        dispatch(setSurveyResponderData(data.data));
    } else {
        console.log(error)
    }
};

export const submitMaterialityAssessmentResponderData = (email: string, token: string, body: any) => async (dispatch: AppDispatch) => {
    let [data, error] = await handleAPICall(SUBMIT_MATTASS_RESPONDER_DATA(email, token, body));
    if (data) {
        // BEMessage.success("Your response was saved!")
        dispatch(setSurveyResponseStatus(true))
    }
    else {
        BEMessage.error("Failed to submit response")
    }
}


export const getGlobalStakeholders = () => async (dispatch: AppDispatch) => {
    dispatch(setGlobalStakeholders({ status: 'loading', data: [] }))
    let [data, error] = await handleAPICall(
        GET_ALL_STAKEHOLDERS()
    );
    if (data) {
        console.log(data.data)
        dispatch(setGlobalStakeholders({ status: "success", data: data.data }));
    } else {
        console.log(error)
        dispatch(setGlobalStakeholders({ status: "error", data: [] }));
    }
};


export const postGlobalStakeholder = (body: any) => async (dispatch: AppDispatch) => {
    let [res, error] = await handleAPICall(POST_GLOBAL_STAKEHOLDER(body))
    if (res) {
        console.log(res)
        BEMessage.success('Global Stakeholder added successfully')
        dispatch(getGlobalStakeholders())
    }
    else {
        console.log(error)
        BEMessage.error('Stakeholder with the email ID already exists')
    }
}

export const bulkUploadGlobalStakeholder = (body: any) => async (dispatch: AppDispatch) => {
    let [data, error] = await handleAPICall(BULK_UPLOAD_MATERIALITY_ASSESSMENT_STAKEHOLDER(body))
    if (data) {
        console.log(data)
        dispatch(getGlobalStakeholders())
        return data
    }
    else {
        console.log(error)
        return null;
    }
}

export const patchGlobalStakeholder = (id: number, body: any) => async (dispatch: AppDispatch) => {
    let [res, error] = await handleAPICall(PATCH_GLOBAL_STAKEHOLDER(id, body))
    if (res) {
        BEMessage.success("Updated stakeholder details successfully")
        dispatch(getGlobalStakeholders());
    }
    else {
        console.log(error)
        BEMessage.error("Couldn't update stakeholder details")
    }
}

export const deleteGlobalStakeholder = (id: number) => async (dispatch: AppDispatch) => {
    let [res, error] = await handleAPICall(DELETE_GLOBAL_STAKEHOLDER(id))
    if (res) {
        BEMessage.success("Stakeholder deleted successfully");
        dispatch(getGlobalStakeholders());
    }
    else {
        console.log(error);
        BEMessage.error("Couldn't delete stakeholder details");
    }
}