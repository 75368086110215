import React from 'react'
import IconProps from './Interface/IconProps';

const AssignmentIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 18}
      height={props.inheritSize ? "100%" : 20}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 2H11.82C11.4 0.84 10.3 0 9 0C7.7 0 6.6 0.84 6.18 2H0V20H18V2ZM9 2C9.55 2 10 2.45 10 3C10 3.55 9.55 4 9 4C8.45 4 8 3.55 8 3C8 2.45 8.45 2 9 2ZM11 16H4V14H11V16ZM14 12H4V10H14V12ZM14 8H4V6H14V8Z"
        fill={props.stroke ? props.stroke : "#666666"}
      />
    </svg>
  );
}

export default AssignmentIcon