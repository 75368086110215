import React from 'react'
import IconProps from './Interface/IconProps';

const InfoFilledIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 20}
      height={props.inheritSize ? "100%" : 20}
      viewBox="0 0 20 20"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10"
        cy="10"
        r="10"
        fill={props.fill ? props.fill : "#3382DE"}
      />
      <path
        d="M9 5H11V6.90348H9V5ZM9 7.8H11V15H9V7.8Z"
        fill={props.fill ? props.fill : "white"}
      />
    </svg>
  );
}

export default InfoFilledIcon