import React from 'react'
import IconProps from "./Interface/IconProps";

const ArrowDownIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 24}
      height={props.inheritSize ? "100%" : 24}
      viewBox="0 0 24 24"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 12L18.59 10.59L13 16.17V4H11V16.17L5.42 10.58L4 12L12 20L20 12Z"
        fill={props.fill ? props.fill : "#333333"}
      />
    </svg>
  );
}

export default ArrowDownIcon