import * as React from 'react';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { settableDataDM, setFullYearTableDataDM, setFilteredLogsDM, setFullMyDerivedMetricsDM, setMyDerivedMetricsDataByMonthDM, setTableDerivedMetricsDataDM, setMyMetricsDM, setMyDerivedMetricsDM, setFullYearDerivedMetricsDataDM } from '../../../../Redux/DataManagerReducer';
import { subtractOneDay, formatToXDigits, isDateInFY, getFYByDate, getMonthsByQuarter } from '../../../../Config/Functions/UsefullFunctions';
import { setSelectedMetrics } from '../../../../Redux/OnboardingReducer';
import { current } from '@reduxjs/toolkit';
import { getAllMetricsData, getAssignedDepartmentByCategory, getDataLabels, getDerivedMetricDataById, getDerivedMetricDataByMonth, getDerivedMetrics, getMetricsStatus, getMyDerivedMetrics, getOmittedMetrics } from '../../Actions';
import { derivedMetricDataTypeByMonth } from '../../../../Redux/Types/dataManagerTypes';
import { getAllMetrics, getSelectedMetrics } from '../../../../views/Actions/OnboardingStep4Actions';
import { getLogs } from '../../../Settings/Actions';

export interface ISetTableAndGraphDataDMProps {
}

export function SetTableAndGraphDataDM(props: ISetTableAndGraphDataDMProps) {
    const dispatch = useAppDispatch();
    const myMetricsData = useTypedSelector(state => state.dataManager.metrics.myMetricsData);
    const currentMonth = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentMonth);
    const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
    const yearEnd = useTypedSelector(state => state.companies.yearEnd);
    const businessUnitsRefs = useTypedSelector(state => state.entity.BusinessUnitRefs.data);
    const currentBusinessGroup = useTypedSelector(state => state.common.homePage.currentBusinessGroup);
    const currentBusinessUnit = useTypedSelector(state => state.common.homePage.currentBusinessUnit);
    const allLogs = useTypedSelector(state => state.settings.logs);
    const reportingYearEnd = useTypedSelector(state => state.companies.companyDetails.data?.reporting_year_end);
    const allSelectedMetrics = useTypedSelector(state => state.onBoarding.metrics.allSelectedMetrics);
    const allFullMyDerivedMetrics = useTypedSelector(state => state.dataManager.metrics.allFullMyDerivedMetrics);
    const myDerivedMetrics = useTypedSelector(state => state.dataManager.metrics.myDerivedMetrics);
    const myDerivedMetricsDataByMonth = useTypedSelector(state => state.dataManager.metrics.myDerivedMetricsDataByMonth);


    const { allMetrics } = useTypedSelector(state => state.onBoarding.metrics);
    const selectedMetricsRef = useTypedSelector(state => state.onBoarding.metrics.selectedMetrics);
    const derivedMetrics = useTypedSelector(state => state.dataManager.metrics.derivedMetrics);
    const fullMyDerivedMetrics = useTypedSelector(state => state.dataManager.metrics.fullMyDerivedMetrics);
    const metricStatus = useTypedSelector(state => state.dataManager.metrics.metricStatus);
    const departmentbyCategory = useTypedSelector(state => state.dataManager.assignedDepartmentByCategory);
    const role = localStorage.getItem('role');
    const myDashboardMetrics = useTypedSelector(state => state.dataManager.metrics.myDashboardMetrics);
    const omittedMetrics = useTypedSelector(state => state.dataManager.metrics.omittedMetrics);
    const allLabels = useTypedSelector(state => state.dataManager.labels);

    // React.useEffect(() => {
    //     if (currentYear === 0 || yearEnd === 0) return;
    //     dispatch(getMyDerivedMetricsData(currentYear));
    // }, [currentYear, yearEnd, myMetricsData])

    //filter Metrics

    React.useEffect(() => {
        // ******************* setting the selected metrics and selected derived metrics for the current year from the all metrics *******************
        if (allSelectedMetrics.status === 'success') {
            dispatch(setSelectedMetrics({
                data: allSelectedMetrics.data.filter((item: any) => item.year === currentYear),
                status: 'success'
            }))
        }
        if (allFullMyDerivedMetrics.status === 'success') {
            dispatch(setFullMyDerivedMetricsDM({
                data: allFullMyDerivedMetrics.data.filter((item: any) => item.year === currentYear),
                status: 'success'
            }))
        }

        if (myDerivedMetricsDataByMonth.status === 'idle') {
            dispatch(getDerivedMetricDataByMonth());
        }
    }, [allSelectedMetrics, currentYear, allFullMyDerivedMetrics, myDerivedMetricsDataByMonth])

    React.useEffect(() => {
        if (myMetricsData.status === 'success') {
            const tempTableData: any = {}
            const tempFullYearTableData: any = {}

            let filteredData: any = [];
            let buBgs = businessUnitsRefs.filter((element: any) => element.business_group === currentBusinessGroup).map((element: any) => element.business_unit);

            if (currentBusinessGroup !== 0) {
                filteredData = myMetricsData.data.filter((element: any) => buBgs.includes(element.business_unit));
            }
            else if (currentBusinessUnit !== 0) {
                filteredData = myMetricsData.data.filter((element: any) => element.business_unit === currentBusinessUnit);
            }
            else {
                filteredData = myMetricsData.data;
            }

            Array.from(new Set(filteredData.map((item: any) => item.my_metric))).map((metricItem: any) => {
                let data: any = [];
                let fullYearData: any = [];

                filteredData.map((item: any) => {
                    if (item.my_metric === metricItem) {
                        let itemMonth = new Date(item.date_of_entry).getMonth() + 1;
                        if (getFYByDate(item.date_of_entry, reportingYearEnd || '') === currentYear) {
                            const monthsByQuarter = getMonthsByQuarter(currentMonth, yearEnd);
                            if (itemMonth === currentMonth || currentMonth === 0 || monthsByQuarter.includes(itemMonth)) data.push(item);
                            fullYearData.push(item);
                        }
                    }
                })
                tempTableData[metricItem] = data;
                tempFullYearTableData[metricItem] = fullYearData;
            })

            dispatch(settableDataDM(
                tempTableData
            ));

            dispatch(setFullYearTableDataDM(
                tempFullYearTableData
            ));
        }
    }, [myMetricsData, currentMonth, currentYear, yearEnd, currentBusinessGroup, currentBusinessUnit]);


    React.useEffect(() => {         // filter logs for current year and year end and business unit and business group
        if (allLogs.status === 'success') {
            let filteredLogsByDate: any = [];

            filteredLogsByDate = allLogs.data.filter((item: any) => {
                // let date = new Date(item.month_of_entry);
                // let year = date.getFullYear();
                // let month = date.getMonth() + 1;
                // if (month > yearEnd && year === currentYear && (currentMonth === 0 || month === currentMonth))
                //     return true;
                // if (month <= yearEnd && year === currentYear + 1 && (currentMonth === 0 || month === currentMonth))
                if (getFYByDate(item.month_of_entry, reportingYearEnd || '') === currentYear)
                    return true;
                return false;
            });

            let FIlteredLogsByBUBG: any = [];
            if (currentBusinessUnit !== 0) {
                FIlteredLogsByBUBG = filteredLogsByDate.filter((item: any) => item.business_unit === currentBusinessUnit);
            }
            else if (currentBusinessGroup !== 0) {
                const buBgs = businessUnitsRefs.filter((element: any) => element.business_group === currentBusinessGroup).map((element: any) => element.business_unit);
                FIlteredLogsByBUBG = filteredLogsByDate.filter((item: any) => buBgs.includes(item.business_unit));
            }
            else FIlteredLogsByBUBG = filteredLogsByDate;

            dispatch(setFilteredLogsDM({
                status: 'success',
                data: FIlteredLogsByBUBG
            }));
        }
    }, [allLogs, currentYear, yearEnd, currentBusinessGroup, currentBusinessUnit, currentMonth]);


    // For derived metrics data by month
    React.useEffect(() => {
        if (myDerivedMetricsDataByMonth.status === 'success') {

            dispatch(setTableDerivedMetricsDataDM({
                status: 'loading',
                data: []
            }));

            let tempTableData: any = {};
            let tempFullYearTableData: any = {};
            let filteredData: derivedMetricDataTypeByMonth[] = [];
            let buBgs = businessUnitsRefs.filter((element: any) => element.business_group === currentBusinessGroup).map((element) => element.business_unit);

            if (currentBusinessGroup !== 0) {
                filteredData = myDerivedMetricsDataByMonth.data.filter((element) => element.bu && buBgs.includes(element.business_unit));
            }
            else if (currentBusinessUnit !== 0) {
                filteredData = myDerivedMetricsDataByMonth.data.filter((element) => element.bu && element.business_unit === currentBusinessUnit);
            }
            else {
                filteredData = myDerivedMetricsDataByMonth.data.filter((element) => !element.bu);
            }

            Array.from(new Set(filteredData?.map((item) => item.my_derived_metric))).map((metricItem) => {
                let data: any = [];
                let fullYearData: any = [];

                filteredData.map((item) => {
                    if (item.my_derived_metric === metricItem) {
                        let itemMonth = item.month;
                        if (item.year === currentYear) {
                            console.log(currentMonth, itemMonth)
                            const monthsByQuarter = getMonthsByQuarter(currentMonth, yearEnd);
                            if (itemMonth === currentMonth || monthsByQuarter.includes(itemMonth)) data.push(item);
                            fullYearData.push(item);
                        }
                    }
                })
                tempTableData[metricItem] = data;
                tempFullYearTableData[metricItem] = fullYearData;
            })

            dispatch(setTableDerivedMetricsDataDM({
                status: 'success',
                data: tempTableData
            }));
            dispatch(setFullYearDerivedMetricsDataDM({
                status: 'success',
                data: tempFullYearTableData
            })
            )
        }
    }, [myDerivedMetricsDataByMonth, currentMonth, currentYear, yearEnd, currentBusinessGroup, currentBusinessUnit]);


    React.useEffect(() => {
        dispatch(setMyMetricsDM({
            status: 'loading',
            data: []
        }));
        if (allMetrics.status === 'idle')
            dispatch(getAllMetrics());
        if (derivedMetrics.status === 'idle')
            dispatch(getDerivedMetrics());
        if (fullMyDerivedMetrics.status === 'idle')
            dispatch(getMyDerivedMetrics());
        if (selectedMetricsRef.status === 'idle')
            dispatch(getSelectedMetrics());
        if (metricStatus.status === 'idle')
            dispatch(getMetricsStatus());
        if (myMetricsData.status === 'idle')
            dispatch(getAllMetricsData());
        if (allLogs.status === 'idle')
            dispatch(getLogs());
        if (departmentbyCategory.status === 'idle')
            dispatch(getAssignedDepartmentByCategory());
        if (omittedMetrics.status === 'idle')
            dispatch(getOmittedMetrics());
        if (allLabels.status === 'idle')
            dispatch(getDataLabels());
    }, []);


    React.useEffect(() => {
        if (fullMyDerivedMetrics.status === 'success' && derivedMetrics.status === 'success') {
            let modifiedDerivedMyMetrics: any = [];
            fullMyDerivedMetrics.data.map((item: any) => {
                const derivedMetric = derivedMetrics.data.filter((d: any) => d.id === item.derived_metric)[0]
                modifiedDerivedMyMetrics.push({
                    ...derivedMetric,
                    ref_id: item.id,
                    year: item.year,
                })
            })
            dispatch(setMyDerivedMetricsDM({
                status: 'success',
                data: modifiedDerivedMyMetrics
            }))
        }
    }, [derivedMetrics, currentYear, fullMyDerivedMetrics, myDashboardMetrics])


    React.useEffect(() => {
        if (allMetrics.status === 'success' && selectedMetricsRef.status === 'success' && myDerivedMetrics.status === 'success') {
            let selectedMetricsMap: any = {}
            selectedMetricsRef.data?.map((item: any) => {
                selectedMetricsMap[item.metric] = item
            })
            let tempMetrics: any = [];
            allMetrics.data?.map((item: any) => {
                if (selectedMetricsMap.hasOwnProperty(item.id)) {
                    const tempItem = {
                        ...selectedMetricsMap[item.id],
                        ...item,
                        id: selectedMetricsMap[item.id].id,
                        status: selectedMetricsMap[item.id].status,
                        metricId: item.id,
                    }
                    // if (item.annual === true) tempItem['pillars'] = 'A'  // adding a new pillar annual for annual metrics
                    if (role !== 'CONTRIBUTOR' || item.applicable_to_all !== false) //remove metrics which are not applicable to all for contributor
                        tempMetrics.push(tempItem)
                }
            });

            //for derived metrics *************************//
            myDerivedMetrics.data.map((item: any) => {
                // if (item.direct === true) {
                const tempItem = {
                    ...item,
                    id: item.ref_id,
                    metricId: item.id,
                }
                tempMetrics.push(tempItem)
                // }
            })
            dispatch(setMyMetricsDM({
                status: 'success',
                data: tempMetrics
            }));
        }
    }, [allMetrics, currentYear, selectedMetricsRef, myDashboardMetrics, myDerivedMetrics]);



    return (
        <div>

        </div>
    );
}
