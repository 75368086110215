import React from 'react'
import { TopSectionDM } from './Components/TopSectionDM';
import './style.scss';
import Tracker from './Tracker';
import { DataEntry } from './Components/DataEntry';
import { SetTableAndGraphDataDM } from './Components/SetTableAndGraphDataFunctionDM';

const DataManager = () => {
    const [section, setSection] = React.useState<'dataEntry' | 'tracker'>('dataEntry')
    return (
        <div className="data-manager">
            <TopSectionDM setSection={setSection} section={section} />
            {
                section === 'dataEntry' ?
                    <DataEntry />
                    :
                    <Tracker />
            }
            <SetTableAndGraphDataDM />
        </div>
    );
}

export default DataManager;