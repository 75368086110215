import * as React from 'react';
import { BEDrawerType1 } from '../../../../../Components/BEDrawer/BEDrawerType1';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { BEProgressBar } from '../../../../../Components/BEProgress';
import './style.scss';
import { RightSectionEditReport } from './RightBody';
import { LeftSectionEditReport } from './LeftBody';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { exportReport, getReportData, getReportingEvidence, getReportingTopicStatus, getTemplateTopics, getTopicsByFramework, patchReportTemplate, postReportAutoFill } from '../../../Actions/template';
import { setAllSelectedTemplateTopics, setCurrentGRESBSection, setSelectedTemplateTopics } from '../../../../../Redux/ReportingReducer';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { GlobalLoader } from '../../../../../Components/GlobalLoader';
import { TemplateSelectedTopicTypes, reportDataTypes, ReportingTopicStatusTypes, omittedReportTopicsTypes } from '../../../../../Redux/Types/reportingTypes';
import { BEMessage } from '../../../../../Components/BEMessage';
import { Modal } from 'antd';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import { ChooseAssets } from '../ChooseAsset';
import { arrangeGresbDataSectionWise, calculateProgress, getGresbPortalEntityData } from '../../../Actions/gresbActions';
import ToolTip from '../../../../../Components/BEToolTip';
import { checkCeleryTaskStatusPeriodically } from '../../../../../views/Actions/HomeActions';

export interface IEditReportProps {
    open: boolean;
    setOpen: Function;
}

export function EditReport(props: IEditReportProps) {
    const dispatch = useAppDispatch()
    const currentReport = useTypedSelector(state => state.reporting.currentReport);
    const currentSection = useTypedSelector(state => state.reporting.selectedTemplateSection);
    const currentGRESBSection = useTypedSelector(state => state.reporting.currentGRESBSection);
    const selectedGRESBAsset = useTypedSelector(state => state.reporting.selectedGRESBAsset);
    const templateDrafts = useTypedSelector(state => state.reporting.TemplateDrafts);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [loadingDownload, setLoadingDownload] = React.useState<boolean>(false);
    const [loadingAutoFill, setLoadingAutoFill] = React.useState<boolean>(false);
    const [loadingMarkForReview, setLoadingMarkForReview] = React.useState<boolean>(false)
    const allSelectedTopics = useTypedSelector(state => state.reporting.allSelectedTemplateTopics);
    const selectedTopics = useTypedSelector(state => state.reporting.selectedTemplateTopics);
    const omittedReportTopics = useTypedSelector(state => state.reporting.omittedReportTopics);
    const reportData = useTypedSelector(state => state.reporting.reportData);
    const topicStatus = useTypedSelector(state => state.reporting.topicStatus);
    const [progress, setProgress] = React.useState<number>(0);
    const [openChooseAsset, setOpenChooseAsset] = React.useState<boolean>(false);
    const role = localStorage.getItem('role');

    const getSelectedTopics = async () => {
        if (currentReport?.template) {
            setLoading(true)
            const res = await dispatch(getTemplateTopics(currentReport?.template, false))
            setLoading(false)
            if (res)
                dispatch(setAllSelectedTemplateTopics({
                    status: 'success',
                    data: res
                }))
        }
    }

    React.useEffect(() => {
        if (allSelectedTopics.status === 'success') {
            if (templateDrafts.data.find((item) => item.id === currentReport?.template)?.framework === 'GRESB') {
                if (currentGRESBSection === 'entity') {
                    dispatch(setSelectedTemplateTopics({
                        status: 'success',
                        data: allSelectedTopics.data.filter((topic) => topic.dimension !== 'nan')
                    }));
                } else {
                    dispatch(setSelectedTemplateTopics({
                        status: 'success',
                        data: allSelectedTopics.data.filter((topic) => topic.dimension === 'nan' && topic.asset === selectedGRESBAsset?.id)
                    }))
                }
            } else {
                dispatch(setSelectedTemplateTopics({
                    status: 'success',
                    data: allSelectedTopics.data
                }))
            }
            console.log('data', allSelectedTopics.data.filter((topic: any) => topic.bcode === 'BG005.2' || topic.bcode === 'BG005.3'), currentReport)
        }
    }, [allSelectedTopics, currentGRESBSection, props.open])

    React.useEffect(() => {
        if (props.open) {
            getSelectedTopics();
            if (currentReport?.id) {
                dispatch(getReportData(currentReport?.id))
                dispatch(getReportingEvidence(currentReport?.id))
                dispatch(getReportingTopicStatus(currentReport?.id))
            }
        }
    }, [props.open])

    React.useEffect(() => {
        if (templateDrafts.data.find((item) => item.id === currentReport?.template)?.framework === "GRESB" && currentGRESBSection === 'asset') {
            setProgress(dispatch(calculateProgress(selectedGRESBAsset?.id || 0)));
        } else {
            // dont calculate the checkbox topics in the progress calculations as they can be default false.
            const topicsWithUnitCheckbox = selectedTopics.data.filter((topic: any) => topic.unit === 'Checkbox').length
            const dataKeysWithUnitCheckbox = Object.keys(reportData.data).filter((key: string) => selectedTopics.data.find((topic: TemplateSelectedTopicTypes) => topic.id === Number(key))?.unit === 'Checkbox').length
            let tempProgress: number = 0;
            let allUniqueCodes = new Set<string>(selectedTopics.data.map((topic: TemplateSelectedTopicTypes) => topic.unique_code));
            let omitedUniqueCodes = new Set<string>(omittedReportTopics.data.map((topic: omittedReportTopicsTypes) => selectedTopics.data.find((item: TemplateSelectedTopicTypes) => item.id === topic.topic_id)?.unique_code || ''));
            let filledUniqueCodes = topicStatus.data.filter((item: ReportingTopicStatusTypes) => !omitedUniqueCodes.has(item.unique_code) && item.status === 1).length
            console.log('allUniqueCodes', allUniqueCodes.size, omitedUniqueCodes.size, filledUniqueCodes)

            tempProgress = Number(
                (((filledUniqueCodes) /
                    (allUniqueCodes.size - omitedUniqueCodes.size)) * 100).toFixed(2)
            )
            // tempProgress =
            // Number(((Object.keys(reportData.data).length - dataKeysWithUnitCheckbox)
            //     /
            //     (selectedTopics.data.filter((item) => omittedReportTopics.data.
            //     filter((topic) => topic.topic_id === item.id &&
            //     topic.report === currentReport?.id &&
            //     topic.section === currentSection)).length - topicsWithUnitCheckbox)
            //     * 100).toFixed(2))
            if (selectedTopics.data.length - topicsWithUnitCheckbox === 0) tempProgress = 100;
            setProgress(Number(tempProgress))
        }
    }, [allSelectedTopics, reportData, selectedTopics, omittedReportTopics, topicStatus])

    const handleMarkForReview = async () => {
        setLoadingMarkForReview(true);
        await dispatch(patchReportTemplate(currentReport?.id || 0, { stage: 4 }, true))
        BEMessage.success('Report marked for review successfully.')
        setLoadingMarkForReview(false)
    }

    const getNestedEntityData = async () => {
        if (allSelectedTopics.status !== 'idle') {
            const data = await dispatch(arrangeGresbDataSectionWise());
        }
    }

    React.useEffect(() => {
        getNestedEntityData();
    }, [props.open, allSelectedTopics]);

    return (
        <BEDrawerType1
            open={props.open}
            setOpen={props.setOpen}
            heading='Edit Report'
            subHeading={currentReport?.name || ' '}
            leftFooter={
                <div style={{ display: "flex", gap: "1rem" }}>
                    <BEButton className='primary' loading={loadingDownload}
                        onClick={async () => {
                            setLoadingDownload(true)
                            await dispatch(exportReport(currentReport))
                            setLoadingDownload(false)
                        }}
                    >
                        Download Report
                    </BEButton>
                    <BEButton
                        disabled={progress < 100 || (role !== 'SUPER_ADMIN' && role !== 'ADMIN')}
                        loading={loadingMarkForReview}
                        onClick={handleMarkForReview}
                    >
                        Mark for Review
                    </BEButton>
                    <BEButton
                        className='primary'
                        onClick={() => {
                            if (currentGRESBSection === 'entity') {
                                setOpenChooseAsset(true);
                                props.setOpen(false);
                                dispatch(setCurrentGRESBSection('asset'));
                            } else {
                                dispatch(setCurrentGRESBSection('entity'))
                            }
                        }}
                    >
                        Switch to {currentGRESBSection === 'entity' ? 'Asset' : 'Entity'}
                    </BEButton>
                </div>
            }
            leftBodyFooter={
                <BEProgressBar
                    width='150%'
                    textColor='black'
                    valuePercent={progress}
                />
            }
            headerRight={
                <div className='right-header'>
                    {/* <div className='last-updated'>
                        <span>Last Updated</span>
                        <span>  12/12/2023, 16:43</span>
                    </div> */}
                    {
                        currentGRESBSection === 'entity' &&
                        <BEButton className='primary' size='middle' loading={loadingAutoFill}
                            onClick={async () => {
                                setLoadingAutoFill(true);
                                const taskIds = await dispatch(getGresbPortalEntityData(currentReport?.id || 0));
                                await dispatch(checkCeleryTaskStatusPeriodically(taskIds, 90000, async () => {
                                    const reportData = await dispatch(getReportData(currentReport?.id || 0))
                                    BEMessage.success('Data imported successfully.');
                                    setLoadingAutoFill(false)
                                }));
                            }}
                        >
                            <ToolTip title='Import entity data from GRESB portal'>
                                Import data
                            </ToolTip>
                        </BEButton>
                    }
                    {
                        currentGRESBSection === null &&
                        <BEButton className='primary' size='middle' loading={loadingAutoFill}
                            onClick={async () => {
                                setLoadingAutoFill(true);
                                await dispatch(postReportAutoFill(currentReport?.id || 0))
                                setLoadingAutoFill(false)
                            }}>
                            Autofill
                        </BEButton>
                    }
                    <Modal title="" closeIcon={null} open={loadingAutoFill} onOk={() => { }} onCancel={() => { }} footer={[]}
                        width={400}
                        style={{ color: PrimaryTheme.primaryGreen, textAlign: 'center', padding: '2rem' }}
                    >
                        <h2>Autofilling...</h2>
                        <GlobalLoader height='20vh' />
                        <h4>Please wait for few minutes!</h4>
                    </Modal>
                </div>}

            leftBody={
                <LeftSectionEditReport />
            }
            rightBody={
                loading ? <GlobalLoader height='80vh' /> :
                    <>
                        <RightSectionEditReport />
                        <ChooseAssets open={openChooseAsset} setOpen={setOpenChooseAsset} />
                    </>
            }
        />
    );
}

