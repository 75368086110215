import * as React from 'react';
import { TopSection } from '../TopSection';
import { Outlet } from 'react-router-dom';

export interface IMatirialityAssessMentLayoutProps {
}

export function SupplierLayout (props: IMatirialityAssessMentLayoutProps) {

  return (
    <div style={{padding: "0 1.5rem"}}>
      <TopSection/>
      <Outlet/>
    </div>
  );
}
