import React from 'react'
import IconProps from './Interface/IconProps'

const ShareIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 12}
      height={props.inheritSize ? "100%" : 14}
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.44181 10.6921L8.75757 10.8759L9.0286 10.6309C9.2929 10.3921 9.62951 10.2467 10 10.2467C10.7972 10.2467 11.4467 10.8961 11.4467 11.6933C11.4467 12.4905 10.7972 13.14 10 13.14C9.20281 13.14 8.55333 12.4905 8.55333 11.6933C8.55333 11.5914 8.56733 11.4839 8.59307 11.3758L8.67891 11.0153L8.3589 10.8283L3.61224 8.05495L3.29224 7.86799L3.0203 8.11979C2.74562 8.37411 2.39136 8.52667 2 8.52667C1.16948 8.52667 0.5 7.85719 0.5 7.02667C0.5 6.19614 1.16948 5.52667 2 5.52667C2.39136 5.52667 2.74562 5.67922 3.0203 5.93355L3.29197 6.18509L3.61182 5.99862L8.31182 3.25862L8.62579 3.07559L8.54859 2.72045C8.51763 2.57805 8.5 2.4661 8.5 2.36C8.5 1.52948 9.16948 0.860001 10 0.860001C10.8305 0.860001 11.5 1.52948 11.5 2.36C11.5 3.19053 10.8305 3.86 10 3.86C9.60864 3.86 9.25438 3.70745 8.9797 3.45312L8.70804 3.20158L8.38818 3.38804L3.68818 6.12804L3.37421 6.31108L3.45141 6.66622C3.48237 6.80861 3.5 6.92057 3.5 7.02667C3.5 7.13277 3.48237 7.24472 3.45141 7.38712L3.37415 7.74251L3.68848 7.92547L8.44181 10.6921ZM11.1667 2.36C11.1667 1.71719 10.6428 1.19333 10 1.19333C9.35719 1.19333 8.83333 1.71719 8.83333 2.36C8.83333 3.00281 9.35719 3.52667 10 3.52667C10.6428 3.52667 11.1667 3.00281 11.1667 2.36ZM0.833333 7.02667C0.833333 7.66948 1.35719 8.19333 2 8.19333C2.64281 8.19333 3.16667 7.66948 3.16667 7.02667C3.16667 6.38386 2.64281 5.86 2 5.86C1.35719 5.86 0.833333 6.38386 0.833333 7.02667ZM8.83333 11.7067C8.83333 12.3495 9.35719 12.8733 10 12.8733C10.6428 12.8733 11.1667 12.3495 11.1667 11.7067C11.1667 11.0639 10.6428 10.54 10 10.54C9.35719 10.54 8.83333 11.0639 8.83333 11.7067Z"
        stroke={props.stroke ? props.stroke : "#9F9F9F"}
      />
    </svg>
  );
}

export default ShareIcon