import React, { useEffect, useState } from "react";
import { BEDrawer } from "../../../../../Components/BEDrawer";
import { BEButton } from "../../../../../Components/BEFormItems/BEButton";
import { Form } from "antd";
import { LableRequired } from "../../../../../Components/BEFormItems/LableRequired";
import { BEInput } from "../../../../../Components/BEFormItems/BEInput";
import { useTypedSelector } from "../../../../../Config/Hooks/useTypedSelector";
import { useAppDispatch } from "../../../../../Config/Hooks/useAppDispatch";
import { getAllIndustries } from "../../../../../views/Actions/OnboardingActions";
import BEMultipleSelect from "../../../../../Components/BEMultipleSelect";
import { addGlobalSupplier } from "../../../Actions/supplierAction";

interface AddSupplierProps {
  open: boolean;
  setOpen: Function;
}

const AddSupplier = (props: AddSupplierProps) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const allIndustries = useTypedSelector(
    (state) => state.onBoarding.industries
  );
  const [loading, setLoading] = useState(false)
  const [isNext, setIsNext] = useState(false)

  useEffect(() => {
    if (allIndustries.status === "idle") dispatch(getAllIndustries());
  }, [allIndustries]);

  const onSubmit = async (values:any) => {
    setLoading(true)
    const obj = { ...values };
    dispatch(addGlobalSupplier([obj])).then(() => {
      setLoading(false);
      if (!isNext) {
        props.setOpen(false);
      }
      form.resetFields();
    })
  };

  return (
    <BEDrawer
      open={props.open}
      zIndex={10}
      setOpen={props.setOpen}
      heading="Add Supplier"
      footer={
        <>
          <BEButton className="primary" size="large" onClick={async () => {
            form.submit();
          }} 
          onMouseEnter={()=>{
            setIsNext(false)
          }}
          loading={ loading && !isNext}>
            Save
          </BEButton>
          <BEButton size="large" onClick={async () => {
            form.submit();
          }} 
          onMouseEnter={()=>{
            setIsNext(true)
          }}
          loading={loading && isNext}>
            Save & Add Another
          </BEButton>
        </>
      }
    >
      <Form form={form} onFinish={onSubmit}>
        <LableRequired>Supplier Name</LableRequired>
        <Form.Item
          name="name"
          rules={[{ required: true, message: "Supplier Name is required" }]}
        >
          <BEInput size="large" placeholder="Name of the supplier" />
        </Form.Item>
        <LableRequired>POC</LableRequired>
        <Form.Item
          name="poc"
          rules={[{ required: true, message: "POC Name is required" }]}
        >
          <BEInput size="large" placeholder="Name of the POC" />
        </Form.Item>
        <LableRequired>POC Email</LableRequired>
        <Form.Item
          name="email"
          rules={[{ required: true, message: "POC Email is required" }]}
        >
          <BEInput size="large" placeholder="Email of the POC" />
        </Form.Item>
        <LableRequired>Industry</LableRequired>
        <Form.Item name="industry">
          <BEMultipleSelect
            data={allIndustries.data}
            name="industry"
            labelFeild="title"
            valueFeild="title"
            placeholder="Select Industry"
            single
          />
        </Form.Item>
      </Form>
    </BEDrawer>
  );
};

export default AddSupplier;
