

import React from 'react'
import IconProps from './Interface/IconProps'

const ComplaintsIcon = (props: IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 24}
      height={props.inheritSize ? "100%" : 24}
      viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 0H6L0.02 6L0 18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18V2C16 0.9 15.1 0 14 0ZM14 18H2V6.83L6.83 2H14V18ZM7 13H9V15H7V13ZM7 6H9V11H7V6Z"
        fill={props.stroke ? props.stroke : "white"}
      />
    </svg>
  );
}

export default ComplaintsIcon