import dayjs, { Dayjs } from "dayjs";
import ToolTip from "../../Components/BEToolTip";
import { AppDispatch, RootState } from "../../Redux/store";
import { shortMonths } from "../Data/genralData";


export function  generateRandomColor() {
    return `rgb(${Math.floor(Math.random() * 255)},${Math.floor(Math.random() * 255)},${Math.floor(Math.random() * 255)})`
}

export const AllMonths = ['January','February','March','April','May','June','July','August','September','October','November','December']

function AddCommaToNumberLCR(num: number, isThousandK?: boolean,fixedTo?:number): string {
  //convert to crore if greater then 1 crore upto 2 decimal
  //convert to lakh if greater then 1 lakh upto 2 decimal
  if(num == null) return '';
  if(num >= 10000000) return (AddCommaToNumberLCRWithDecimal(num/10000000) + ' cr');
  else if(num >= 100000) return (AddCommaToNumberLCRWithDecimal(num/100000) + ' lac');
  else if (isThousandK && num >= 1000) return (AddCommaToNumberLCRWithDecimal(num/1000) + 'K');
  else return AddCommaToNumberLCRWithDecimal(num);
}


function AddCommaToNumberMnBn(num: number, isThousandK?: boolean,fixedTo?:number): string {
  //convert to Billion if greater then 1 Bn upto 2 decimal
  //convert to Million if greater then 1 Mn upto 2 decimal
  if(num == null) return '';
  if(num >= 1000000000) return (AddCommaToNumberMnBnWithDecimal(num/1000000000,fixedTo) + ' Bn');
  else if(num >= 1000000) return (AddCommaToNumberMnBnWithDecimal(num/1000000,fixedTo) + ' Mn');
  else if (isThousandK && num >= 1000) return (AddCommaToNumberMnBnWithDecimal(num/1000,fixedTo) + 'K');
  else return AddCommaToNumberMnBnWithDecimal(num,fixedTo);
} 

export function AddCommaToNumber(value: number, convertLCR?: boolean, convertMnBn?: boolean, isThousandK?: boolean,fixedTO?:number): string {
  if(!value || Number.isNaN(value)) return '0';
  var num = Number(value?.toFixed(fixedTO || 2));
  if (convertLCR) {
    if(value <0) return '-' + AddCommaToNumberLCR(-num, isThousandK,fixedTO);
    else return AddCommaToNumberLCR(num, isThousandK,fixedTO);
  }
  if (convertMnBn) {
    if(value <0) return '-' + AddCommaToNumberMnBn(-num, isThousandK,fixedTO);
    else return AddCommaToNumberMnBn(num, isThousandK,fixedTO);
  }
  
  //by default convert to international numbering system
  if(num >= 0) return AddCommaToNumberMnBnWithDecimal(num, fixedTO);
  else return '-' + AddCommaToNumberMnBnWithDecimal(-num, fixedTO);
}

function AddCommaToNumberLCRWithDecimal(num: number,fixedTo?:number): string {
  //convert decimal number to comma separated number with indian numbering system without Lakh and Crore
  if(num == null) return '';
  try{
    num = Number(num.toFixed(fixedTo || 2));
  }
  catch(e){}
  var x=num.toString();
  var afterPoint = '';
  if(x.indexOf('.') > 0)
    afterPoint = x.substring(x.indexOf('.'),x.length).substring(0,fixedTo ? fixedTo+1 : 3)
  let y = x.replace(/\.\d+/, "");
  var lastThree = y.substring(y.length-3);
  var otherNumbers = y.substring(0,y.length-3);
  if(otherNumbers != '')
      lastThree = ',' + lastThree;
  var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
  return res;
}

function AddCommaToNumberMnBnWithDecimal(num: number,fixedTo?:number): string {
  //convert decimal number to comma separated number with indian numbering system without Million and Billion
  if(num == null) return '';
  try{
    num = Number(num.toFixed(fixedTo || 2));
  }
  catch(e){}
  var x=num.toString();
  var afterPoint = '';
  if(x.indexOf('.') > 0)
    afterPoint = x.substring(x.indexOf('.'),x.length).substring(0,fixedTo ? fixedTo+1 : 3)
  let y = x.replace(/\.\d+/, "");
  var comma = y.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  var res = comma + afterPoint;
  return res;
}

export function ChangeDateFormat(date: string,isDateTime?:boolean): string {
  if(isDateTime) return dayjs(date).format('DD MMM YYYY, hh:mm A');
  return dayjs(date).format('DD MMM YYYY');
}

export  function LimitWords(str: string, limit?: number) {
  if (!limit) limit = 8;
  if (!str) return "";
  const strArr = str.split(" ");
  if (strArr.length > limit) 
  return (
    <ToolTip title={str}>
     <p> {strArr.slice(0, limit).join(" ")}...</p>
    </ToolTip>
    )
  else {
    return str;
  }
};


export function StringCapitalize(str: string) {
  return str?.slice(0,1).toUpperCase() + str?.slice(1)
}

export function SortListBasedOnField(list: any, field: string) {
  const tmpArray = [...list]
  return tmpArray.sort((a:any, b:any) => {
    if (a[field] < b[field]) {
      return -1;
    }
    if (a[field] > b[field]) {
      return 1;
    }
    return 0;
  })
}

export async function getFileFromUrl(url:string, name:string, defaultType = "application/pdf") {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: defaultType,
  });
}


export function FormatUrl(str: string) {
  let re = new RegExp('^(http|https)://');
  if (re.test(str)) {
    return str;
  }
  else return `https://${str}`
}


export function rotateArray<T>(arr: T[], numToRotate: number, reverse: boolean = false): T[] {
  const length = arr.length;
  // Adjust numToRotate to be within the array's bounds
  numToRotate = numToRotate % length;
  let ReverseRotatedArray: T[] = arr.slice(numToRotate).concat(arr.slice(0, numToRotate));
  if (reverse) return ReverseRotatedArray;
  else return ReverseRotatedArray.reverse();
}


export function filterDataByYearEnd(data: any[], currentYear: number, yearEnd: string, field: string): any[] {
  return data.filter((item: any) => isDateInFY(currentYear, item[field], yearEnd));
}

export const subtractOneDay = (dateString:string) => {
  const result = dayjs(dateString).subtract(1, 'day');
  return result.format('YYYY-MM-DD');        
};

export const formatToXDigits = (num:number, digits:number) => {
  return String(num).padStart(digits, '0');
};

export const getStartDateAndEndDate = (currentYear:number,reportingYearEnd:string) => {

  if(reportingYearEnd === undefined || currentYear === undefined) return null;

  if(dayjs(reportingYearEnd).format("MM-DD") === '12-31') return {
    startDate: `${currentYear}-01-01`,
    endDate: `${currentYear}-12-31`,
  }

  let startDate = "";
  let endDate = "";
  const startingDayMon = dayjs(reportingYearEnd).add(1, "day").format("MM-DD");
  const endingDayMon = dayjs(reportingYearEnd).format("MM-DD");
  startDate = dayjs(`${startingDayMon}-${currentYear}`).format("YYYY-MM-DD");
  endDate = dayjs(`${endingDayMon}-${currentYear + 1}`).format("YYYY-MM-DD");
  return {
    startDate: startDate,
    endDate: endDate,
  }
}

export const isDateInFY = (currentYear:number,date:string,yearEnd:string) => {
  let {startDate,endDate}:any =getStartDateAndEndDate(currentYear,yearEnd);
  if(!dayjs(date).isBefore(startDate) && !dayjs(date).isAfter(endDate) ) return true;
}


export const getFYByDate =  (date:string, yearEnd:string) => {
  const yearEndMonth = dayjs(yearEnd).month();
  const currentYear = dayjs(date).year();
  const yearEndDay = dayjs(yearEnd).date();
  const currentMonth = dayjs(date).month();
  const currentDay = dayjs(date).date();

  if(yearEndMonth === 11 && yearEndDay === 31) return currentYear;
  if(currentMonth <= yearEndMonth || (currentMonth === yearEndMonth && currentDay <= yearEndDay)){
    return currentYear - 1;
  }
  return currentYear;
}

export const showFYFormatByYear = (year:number,yearEnd:string) => {
  if(dayjs(yearEnd).format("MM-DD") === '12-31') return `FY-${year}`
  return `FY-${year}-${year+1}`
}

export const filterDataByFY = (currentYear:number,data:any[],dateProperty:string,yearEnd:string)=> {
  let tempData:any = [];
  let dates: any = getStartDateAndEndDate(currentYear,yearEnd);
  console.log('dates',dates,data)
  data.map((item:any)=>{
    let date = item[dateProperty]
    if(dayjs(date).isAfter(dates?.startDate) && dayjs(date).isBefore(dates?.endDate) ){
      console.log('in') 
      tempData = [...tempData,item]
    }
  })
  return tempData;
}
  
export const getMonthsByQuarter = (quarter: number, yearEndMonth: number) => {
  console.log('quarter',quarter)
  if (quarter === 13) return [(yearEndMonth + 1) % 12, (yearEndMonth + 2) % 12, (yearEndMonth + 3) % 12];
  if (quarter === 14) return [(yearEndMonth + 4) % 12, (yearEndMonth + 5) % 12, (yearEndMonth + 6) % 12];
  if (quarter === 15) return [(yearEndMonth + 7) % 12, (yearEndMonth + 8) % 12, (yearEndMonth + 9) % 12];
  if (quarter === 16) return [(yearEndMonth + 10) % 12, (yearEndMonth + 11) % 12, yearEndMonth];
  return [-1];
}