import * as React from 'react';
import DeleteIcon from '../../BEIcons/DeleteIcon';
import EditIcon from '../../BEIcons/EditIcon';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';

export interface ITableActionsProps {
    handleDelete?: any;
    handleEdit?: any;
}

export function TableActions (props: ITableActionsProps) {
  const currentRow = useTypedSelector(state=>state.common.selectedRow);
  
  const handleDelete = () => {
    props.handleDelete(currentRow);
  }
  
  return (
    <div style={{display:'flex',alignItems:'center',gap:'10px'}}>
        { props.handleEdit &&
          <div style={{width:'16px',cursor:'pointer'}} onClick={props.handleEdit}>
            <EditIcon inheritSize stroke={PrimaryTheme.primaryGreyDark}/>
        </div>
        }
        { props.handleDelete &&
          <div style={{width:'16px',cursor:'pointer'}} onClick={handleDelete}>
            <DeleteIcon inheritSize stroke={PrimaryTheme.primaryGreyDark}/>
        </div>
        }
    </div> 
  );
}
