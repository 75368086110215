import React from 'react'
import IconProps from './Interface/IconProps';

const UploadedIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 24}
      height={props.inheritSize ? "100%" : 25}
      viewBox="0 0 24 25"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.4284 21.4289C21.4284 21.8835 21.2477 22.3196 20.9263 22.641C20.6049 22.9625 20.1688 23.1431 19.7141 23.1431H4.28557C3.83091 23.1431 3.39488 22.9625 3.07339 22.641C2.75191 22.3196 2.57129 21.8835 2.57129 21.4289V2.57171C2.57129 2.11704 2.75191 1.68102 3.07339 1.35952C3.39488 1.03803 3.83091 0.857422 4.28557 0.857422H15.4284L21.4284 6.85742V21.4289Z"
        stroke={props.stroke ? props.stroke : "white"}
        strokeWidth="1.71429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4284 11.1434L11.9999 7.71484L8.57129 11.1434"
        stroke={props.stroke ? props.stroke : "white"}
        strokeWidth="1.71429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 7.71484V17.1434"
        stroke={props.stroke ? props.stroke : "white"}
        strokeWidth="1.71429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="18"
        cy="20"
        r="5"
        fill={props.fill ? props.fill : "#00B876"}
      />
      <path
        d="M16.1611 20.748L17.0386 21.8762C17.0682 21.9147 17.1062 21.946 17.1496 21.9677C17.193 21.9894 17.2408 22.001 17.2893 22.0016C17.3371 22.0022 17.3844 21.9921 17.4278 21.9721C17.4711 21.952 17.5095 21.9226 17.5401 21.8859L20.3397 18.498"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default UploadedIcon