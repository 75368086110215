import * as React from 'react';
import './style.scss';
import { PrimaryTheme } from '../../Config/Theme/theames';

export interface IBETabButtonsProps {
    icon?: React.ReactNode;
    title: string;
    active: boolean;
    onClick: Function;
}

export function BETabButton(props: IBETabButtonsProps) {
    return (
        <div className='be-tab-button'
            onClick={() => props.onClick()}
        >
            <div className={`icon ${props.active ? 'active' : ''}`} 
            >{props.icon}</div>
            <p style={{
                color: props.active ? PrimaryTheme.primaryGreen : PrimaryTheme.primaryGray
            }}
            >
                {props.title}
            </p>
        </div>
    );
}
