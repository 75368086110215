import React from 'react'
import IconProps from './Interface/IconProps'

const EditIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 16}
      height={props.inheritSize ? "100%" : 16}
      viewBox="0 0 24 24"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1_2244)">
        <path
          d="M8.57171 20.9826L0.857422 23.1426L3.01742 15.4284L17.1431 1.37125C17.3027 1.20798 17.4935 1.07824 17.7039 0.989668C17.9144 0.901094 18.1405 0.855469 18.3689 0.855469C18.5972 0.855469 18.8233 0.901094 19.0338 0.989668C19.2442 1.07824 19.435 1.20798 19.5946 1.37125L22.6288 4.42268C22.7895 4.58204 22.917 4.77166 23.0041 4.98054C23.0912 5.18945 23.1359 5.41352 23.1359 5.63982C23.1359 5.86613 23.0912 6.0902 23.0041 6.2991C22.917 6.50801 22.7895 6.69761 22.6288 6.85697L8.57171 20.9826Z"
          stroke={props.stroke ? props.stroke : "gray"}
          strokeWidth="1.71429"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2244">
          <rect
            width={props.inheritSize ? "100%" : 24}
            height={props.inheritSize ? "100%" : 24}
            fill={props.fill ? props.fill : "white"}
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EditIcon