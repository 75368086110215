import * as React from 'react';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { getAllMetrics, getEveryMetrics, getSelectedMetrics } from '../../../../Actions/OnboardingStep4Actions';
import { GlobalLoader } from '../../../../../Components/GlobalLoader';
import { PillerMap } from '../../../Step4/TopTabsFilter';
import { BETable } from '../../../../../Components/BETable';
import { ColumnsType } from 'antd/es/table';
import { getDerivedMetrics, getMyDerivedMetrics } from '../../../../../Features/DataManager/Actions';
import { setAllMetrics, setMetricCurrentYear, setSelectedMetrics as setSlectedReduxMetrics } from '../../../../../Redux/OnboardingReducer';
import BEStatusTag from '../../../../../Components/BEStatusTag';
import { setFullMyDerivedMetricsDM } from '../../../../../Redux/DataManagerReducer';
import { getFYByDate } from '../../../../../Config/Functions/UsefullFunctions';
import dayjs from 'dayjs';
import { YearSelectOptions } from '../../../../../Components/BEMultipleSelect/YearSelectOptions';
import ToolTip from '../../../../../Components/BEToolTip';

export interface IReviewStep4Props {
}

export function ReviewStep4(props: IReviewStep4Props) {
    const dispatch = useAppDispatch();
    const allMetrics = useTypedSelector(state => state.onBoarding.metrics.allMetrics);
    const selectedMetricsRef = useTypedSelector(state => state.onBoarding.metrics.selectedMetrics);
    const myDerivedMetrics = useTypedSelector(state => state.dataManager.metrics.fullMyDerivedMetrics);
    const [selectedMetrics, setSelectedMetrics] = React.useState<any>([]);
    const metricsCurrentYear = useTypedSelector(state => state.onBoarding.metrics.currentYear);
    const allSelectedMetrics = useTypedSelector(state => state.onBoarding.metrics.allSelectedMetrics);
    const allFullMyDerivedMetrics = useTypedSelector(state => state.dataManager.metrics.allFullMyDerivedMetrics);
    const companyDetails = useTypedSelector(state => state.companies.companyDetails);

    React.useEffect(() => {    
        dispatch(getEveryMetrics());
        if (selectedMetricsRef.status === 'idle')  dispatch(getSelectedMetrics());
        if (myDerivedMetrics.status === 'idle')  dispatch(getMyDerivedMetrics())
    }, []);

    React.useEffect(() => {
        console.log('getting all metrics', selectedMetrics);
    }, [selectedMetrics]);

    React.useEffect(() => {
        // ******************* setting the default FY for the metrics *******************
        if(companyDetails.status === 'success') {
          dispatch(setMetricCurrentYear(getFYByDate(dayjs().format('YYYY-MM-DD'),companyDetails.data?.reporting_year_end || '2024-02-01')))
        }
      },[companyDetails])

    React.useEffect(() => {                                    
        // ******************* setting the selected metrics and selected derived metrics for the current year from the all metrics *******************
            if(allSelectedMetrics.status === 'success') {
              dispatch(setSlectedReduxMetrics({
                data:allSelectedMetrics.data.filter((item:any)=>item.year === metricsCurrentYear),
                status:'success'
              }))
            }
            if(allFullMyDerivedMetrics.status === 'success') {
              dispatch(setFullMyDerivedMetricsDM({
                data:allFullMyDerivedMetrics.data.filter((item:any)=>item.year === metricsCurrentYear),
                status:'success'
              }))
            }
          },[allSelectedMetrics,metricsCurrentYear,allFullMyDerivedMetrics])


    const columns: ColumnsType<any> = [
        {
            title:'Name',
            dataIndex:'title',
            width: '40%',
            render: (text:any, record:any) => {
                return (
                    <div>
                        <p>
                            {
                            record.hasOwnProperty('direct') ? <div style={{display:"flex", flexDirection:"row", gap:"1rem"}}>{record.title}  <ToolTip title='The values for C metrics are calculated.'>
                            <BEStatusTag status='success'>C</BEStatusTag>
                          </ToolTip></div>:
                            record.title
                            }</p>
                    </div>
                )
            }
        },{
            title:'Unit',
            dataIndex:'unit',

        }
    ]
    
    React.useEffect(() => {
        if(allMetrics.status === 'success' && selectedMetricsRef.status === 'success') {
            let selectedMEtricsMap:any = {};
            let derivedMetricsMap:any = {};

            myDerivedMetrics.data.forEach((metric) => {
                derivedMetricsMap[metric.derived_metric] = true;
            });

            selectedMetricsRef.data.forEach((metric) => {
                selectedMEtricsMap[metric.metric] = true;
            });
            let temp:any = []
            allMetrics.data.forEach((metric) => {
                if((metric.hasOwnProperty('direct') && derivedMetricsMap[metric.id]) || (selectedMEtricsMap[metric.id] && !metric.hasOwnProperty('direct'))) {
                    temp.push(metric);
                }
            });
            setSelectedMetrics(temp);
        }
    }, [allMetrics, selectedMetricsRef]);

    if (allMetrics.status === 'loading' || selectedMetricsRef.status === 'loading') {
        return <GlobalLoader />
    }
    return (
        <div className='step-4'>
            <div className='outer-box'>
            <div style={{float:'right',marginTop:'1rem'}}>
                <YearSelectOptions onChange={(value:any) => dispatch(setMetricCurrentYear(value))} value={metricsCurrentYear} />
            </div>
                {
                    Array.from(new Set(selectedMetrics?.map((item: any) => item.pillars))).map((item: any, index) => {
                        return (
                            <div className='pillar' >
                                <p>{PillerMap[item]}</p>
                                    {
                                        Array.from(new Set(selectedMetrics?.filter((metric: any) => metric.pillars === item).map((item1: any) => item1.category))).map((item2: any, index) => {
                                            return (
                                                <div className='category'>
                                                        <p>{item2}</p>
                                                    {
                                                        Array.from(new Set(selectedMetrics?.filter((metric: any) => metric.category === item2 && metric.pillars === item).map((item3: any) => item3.group))).map((item4: any, index) => {
                                                            return (
                                                                <div className='group'>
                                                                    <p>{item4}</p>
                                                                    <BETable
                                                                        className='metric-table'
                                                                        columns={columns}
                                                                        data={selectedMetrics?.filter((metric: any) => metric.group === item4 && metric.category === item2 && metric.pillars === item)}
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            
                        )
                    })
                }
            </div>
        </div>
    );
}
