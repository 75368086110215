import React, { useEffect, useState } from 'react'
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { ColumnsType } from 'antd/es/table';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { bulkUploadGlobalStakeholder, getAllStakeholdersForAssessment, getGlobalStakeholders, postMaterialityStakeholders } from '../../../Actions/matirialityActions';
// import { BETable } from '../../../../../Components/BETable';
import { AddStakeHolder } from '../AddStakeholder';
import { useParams } from 'react-router-dom';
import { setRespondantsToAssessment, setStakeholdersToAssessment } from '../../../../../Redux/MatirialityReducer';
import { BEBulkUploadDrawer } from '../../../../../Components/BEDrawer/BEBulkUploadDrawer';
import { NewBETable } from '../../../../../Components/BETable/newBETable';

export interface IAddStakeHolderProps {
  open: boolean;
  setOpen: Function;
}


const AddStakeHolderToAssessment = (props: IAddStakeHolderProps) => {
    const dispatch = useAppDispatch()
    const globalStakeholders = useTypedSelector(state => state.matiriality.globalStakeholders)
    const stakeholdersForAssessment = useTypedSelector(state => state.matiriality.stakeholdersForAssessment)
    const currentMatasSurvey = useTypedSelector(state => state.matiriality.currentMaterialitySurvey)
    const [openAdd, setOpenAdd] = useState(false)
    const [selectedStakeholders, setSelectedStakeholders] = useState<any>([])
    const [defaultSelectedStakeholders, setDefaultSelectedStakeholders] = useState<any>([])
    const stakeholders = useTypedSelector((state) => state.matiriality.stakeholders);
    const [openBulkUpload, setOpenBulkUpload] = useState(false) 
    
    const [loading, setLoading] = useState(false)
    useEffect(() => {
      if (props.open) {
        dispatch(getGlobalStakeholders());
        dispatch(getAllStakeholdersForAssessment(Number(currentMatasSurvey?.id)))
      }
    }, [props.open]);

    useEffect(() => {
        if (globalStakeholders.status === 'success' && stakeholdersForAssessment.status === 'success') {
            const data = globalStakeholders.data.filter((global) => {
                return stakeholdersForAssessment.data.some((stk) => stk.email === global.email)
            }).map((stk) => stk.id)
            console.log(data)
            setDefaultSelectedStakeholders(data)
            setSelectedStakeholders(data)
        }
    }, [globalStakeholders, stakeholdersForAssessment]);
    
    const columns: ColumnsType<any> = [
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Role",
        dataIndex: "role",
      },
      {
        title: "Category",
        dataIndex: "category",
      },
      {
        title: "Type",
        dataIndex: "internalExternal",
        render: (value, record) => {
          return value === true ? "External" : "Internal";
        },
      },
    ];

    const handleSave = async () => {
      let newSelectedStakeholders = selectedStakeholders.filter((stk: any) => !defaultSelectedStakeholders.includes(stk))
        const data = globalStakeholders.data.filter((stk) => {
            return newSelectedStakeholders.some((obj:any) => obj === stk.id)
        }).map((stk) => {
            return {
              ...stk,
              organization: stk.org,
              survey: currentMatasSurvey?.id
            };
        })
        setLoading(true)
        await dispatch(postMaterialityStakeholders(data));
        setSelectedStakeholders([])
        setLoading(false)
        props.setOpen(false)

        dispatch(setStakeholdersToAssessment({ status: "idle", data: [] }));
        dispatch(setRespondantsToAssessment({ status: "idle", data: [] }));
    }
  return (
    <BEDrawer
      width="60vw"
      open={props.open}
      setOpen={props.setOpen}
      heading={"Add Stakeholder to Assessment"}
      footer={
        <>
          <BEButton
            className="primary"
            size="large"
            onClick={handleSave}
            disabled={!selectedStakeholders?.length}
            loading={loading}
          >
            Save
          </BEButton>
        </>
      }
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "1rem",
          gap: "1rem",
        }}
      >
        <BEButton
          size="large"
          onClick={() => setOpenBulkUpload(true)}
        >
          Bulk Upload
        </BEButton>
        <BEButton
          className="primary"
          size="large"
          onClick={() => setOpenAdd(true)}
        >
          Add Global Stakeholder
        </BEButton>
      </div>

      <AddStakeHolder open={openAdd} setOpen={setOpenAdd} isGlobal />
      <NewBETable
        data={globalStakeholders.data}
        columns={columns}
        selectedRows={selectedStakeholders}
        setSelectedRows={setSelectedStakeholders}
        rowKey="id"
        loading={
          globalStakeholders.status === "loading" || stakeholdersForAssessment
        .status=== 'loading'}
        disabledRows={defaultSelectedStakeholders}
      />
       <BEBulkUploadDrawer
      fileKey='STAKEHOLDERS'
      fileName='StakeholdersTemplate'
      open={openBulkUpload}
      setOpen={setOpenBulkUpload}
      heading='Bulk Upload Stakeholders'
      columnArray={['name', 'email', 'role', 'category', 'internalExternal']}
      uploadFunction={bulkUploadGlobalStakeholder}
    />
    </BEDrawer>
  );
}

export default AddStakeHolderToAssessment