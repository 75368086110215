
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip, Legend  } from 'recharts';
import { useEffect, useState } from 'react';
import { graphColors, graphColors2, graphColors3 } from '../../../Config/Theme/graphColors';
import { GlobalLoader } from '../../GlobalLoader';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import { AddCommaToNumber } from '../../../Config/Functions/UsefullFunctions';




interface ChartProps {
  xAxisLabels?: string[];
  data: any[];                          /// data should be in this format [{name:'name',value:123}]
  xAxisTitle?: string;
  yAxisTitle?: string;
  chartTitle?: string;
  loading?: boolean;
  colorTheme?: number;
}

const DonutCharts = (props: ChartProps) => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    let tempData: any = [];
    props.data.map((item: any) => {
      tempData.push({
        name: item?.name,
        value: Number(item?.value?.toFixed(2)),
      });
    });
    setData(tempData);
  }, [props.data]);

  if(props.loading)return <GlobalLoader height='400px' />

  return (
    <div style={{width:'100%',height:'400px'}}>
      <h2>{props.chartTitle}</h2>
      <ResponsiveContainer width="100%" height="100%" style={{
        fontSize: '10px', 
        border: `2px solid ${PrimaryTheme.primaryGreyLight}`, marginTop: '1rem', borderRadius: '8px'}} >
        <PieChart width={200} height={200} >
          <Pie
            data={data}
            innerRadius={60}
            outerRadius={90}
            fill="#8884d8"
            dataKey="value"
            label={(data) => AddCommaToNumber(data.value, false, true)}
          >
            {data.map((entry:any, index:number) => (
              <Cell key={`cell-${index}`} fill={
                !props.colorTheme ? graphColors[index % graphColors.length] :
                props.colorTheme === 2 ? graphColors2[index % graphColors.length] :
                props.colorTheme === 3 ?graphColors3[index % graphColors.length] :
                'white'
              } />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default DonutCharts