import React from 'react'
import IconProps from './Interface/IconProps'

const PendingResponseIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 18}
      height={props.inheritSize ? "100%" : 22}
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 11.5C10.24 11.5 8 13.74 8 16.5C8 19.26 10.24 21.5 13 21.5C15.76 21.5 18 19.26 18 16.5C18 13.74 15.76 11.5 13 11.5ZM14.65 18.85L12.5 16.7V13.5H13.5V16.29L15.35 18.14L14.65 18.85ZM16 2.5H10.82C10.4 1.34 9.3 0.5 8 0.5C6.7 0.5 5.6 1.34 5.18 2.5H0V21.5H8.11C7.52 20.93 7.04 20.25 6.69 19.5H2V4.5H4V7.5H12V4.5H14V9.58C14.71 9.68 15.38 9.89 16 10.18V2.5ZM8 4.5C7.45 4.5 7 4.05 7 3.5C7 2.95 7.45 2.5 8 2.5C8.55 2.5 9 2.95 9 3.5C9 4.05 8.55 4.5 8 4.5Z"
        fill={props.stroke ? props.stroke : "#666666"}
      />
    </svg>
  );
}

export default PendingResponseIcon