import * as React from 'react';
import { LeftSectionCard } from './LeftSectionCard';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { SupplierQuestionsTypes } from '../../../../../../Redux/Types/suppliersTypes';
import { setCurrentSection } from '../../../../../../Redux/SupplierReducer';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';

export interface ILeftSectionProps {
  isFillSection?: boolean;
  sections:any;
}

export function LeftSection (props: ILeftSectionProps) {

  return (
    <div className='left-section'>
        {
          props.sections
          .map((item:string)=>{
            return(
              <LeftSectionCard isFillSection={props.isFillSection} section={item}/>
            )
          })
        }
    </div>
  );
}
