import React from "react";
import ChevronLeftIcon from "../BEIcons/ChevronLeftIcon";
import { useNavigate } from "react-router-dom";

type BEBackButtonProps = {
  title: string;
};

const BEBackButton = (props: BEBackButtonProps) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "between",
        fontWeight: 600,
        fontSize: "15px",
        cursor: "pointer",
      }}
      onClick={() => {
        navigate(-1);
      }}
    >
      <div style={{ height: "1.5rem", marginRight: "1rem" }}>
        <ChevronLeftIcon inheritSize />
      </div>
      <div>{props.title}</div>
    </div>
  );
};

export default BEBackButton;
