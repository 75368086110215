import { PrimaryTheme } from "../../../Config/Theme/theames";
import TrackerTable from "./TrackerTable";
import BEMultipleSelect from "../../../Components/BEMultipleSelect";
import { departmentSelectTypeDM } from "../Components/DataEntry/TopTabsDM/StatusFilter";
import { setCurrentDepartmentDM } from "../../../Redux/DataManagerReducer";
import { useAppDispatch } from "../../../Config/Hooks/useAppDispatch";
import FilterIconImage from '../../../assets/images/FilterIcon.svg';

const Tracker = () => {
  const dispatch = useAppDispatch();
  return (
    <div style={{ margin: "1rem 2rem" }}>
      {/* <Row>
        <TrackerCard
          title="PENDING TRACKERS"
          body="45/60"
          icon={<TrackerIcon inheritSize stroke={PrimaryTheme.secondaryGray} />}
        />
        <TrackerCard
          title="PENDING REVIEWS"
          body="3"
          icon={<ReviewIcon inheritSize stroke={PrimaryTheme.secondaryGray} />}
        />
      </Row> */}

      <div style={{ margin: "0" }}>
        {/* <Row>
          <Col span={6}>
            <BEInput search />
          </Col>
        </Row> */}
        <div style={{ fontSize: "0.75rem", color: PrimaryTheme.primaryGray, float: "right", marginBottom: "1rem" }}>
          <div style={
            {
              display: "flex",
              justifyContent: "space-between",
              gap: "1rem",
              alignItems: "center",
              position: "relative"
            }
          }>
            
            <div className="status-filter-select" style={{ position: "absolute", left: "-12rem", bottom: "-2.2rem" }}>
              <BEMultipleSelect
                single
                placeholder='Department'
                name='Department'
                data={departmentSelectTypeDM.map((dept, index) => dept)}
                valueFeild='name'
                labelFeild='name'
                onChange={(dept: any) => {
                  dispatch(setCurrentDepartmentDM(dept));
                }}
              />
            </div>
            <span><span style={{ backgroundColor: PrimaryTheme.primaryRedError, color: PrimaryTheme.primaryRedError }}>aa</span>{" "}PENDING</span>
            <span><span style={{ backgroundColor: PrimaryTheme.amber, color: PrimaryTheme.amber }}>aa</span>{" "}FILLED</span>
            <span><span style={{ backgroundColor: PrimaryTheme.primaryGreen, color: PrimaryTheme.primaryGreen }}>aa</span>{" "}COMPLETED</span>
          </div>
        </div>
        <TrackerTable />
      </div>
    </div>
  );
};

export default Tracker;
