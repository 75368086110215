import * as React from 'react';

export interface ILableRequiredProps {
    children: React.ReactNode;
    style?: React.CSSProperties;
}

export function LableRequired (props: ILableRequiredProps) {
  return (
    <p>
        {props.children}<span style={{color:'red',...props.style }}>*</span>
    </p>
  );
}
