import * as React from 'react';
import { Modal,Row,Col } from 'antd';
import { inviteSuppliers } from '../../../../Actions/supplierAction';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import BEDatePicker from '../../../../../../Components/BEDatePicker';
import dayjs from 'dayjs';
import { BEButton } from '../../../../../../Components/BEFormItems/BEButton';
import { LableRequired } from '../../../../../../Components/BEFormItems/LableRequired';
import { PrimaryTheme } from '../../../../../../Config/Theme/theames';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { useParams } from 'react-router-dom';

export interface ISelectDeadlineProps {
  data: any;
  open: boolean;
  setOpen: Function;
  supplierSurveyRefs: any;
}

export function SelectDeadlineModal(props: ISelectDeadlineProps) {
  const {assessment_id} = useParams<any>();
  const dispatch = useAppDispatch();
  const currentAssessment = useTypedSelector(state => state.supplier.currentAssessment);
  const [deadline, setDeadline] = React.useState<any>(new Date().toISOString().slice(0, 10));
  const [loading, setLoading] = React.useState<boolean>(false);

  const IncreaseDate = (days: number) => {
    let date = dayjs(deadline);
    date = date.add(days, "day");
    setDeadline(date.toISOString().slice(0, 10));
  };

  const onChangeDate = (date: any) => {
    setDeadline(dayjs(date).format('YYYY-MM-DD'))
  };

  const onSubmit = () => {
    setLoading(true);
    const dataToSend:any = [];
    props.data.forEach((data:any) => {
      dataToSend.push({
        assessment_supplier: props.supplierSurveyRefs.data?.find((ref:any) => ref.supplier === data.id)?.id,
        assessment: Number(assessment_id),
        expires_at: deadline,
        email: data.email
      })
    });
    dispatch(inviteSuppliers(dataToSend,Number(assessment_id))).then((res: any) => {
      setLoading(false);
      props.setOpen(false);
    });
  }

  return (
    <Modal
            width={400}
                footer={null}
                open={props.open}
                onCancel={() => props.setOpen(false)}
            >
                <h2 style={{textAlign:'center',marginBottom:'1rem'}}>
                Set a deadline for the survey
                </h2>

                    <Row gutter={[12, 12]}>
                        <Col span={24}>
                            <LableRequired ><span style={{
                              fontWeight: 400,
                              color: PrimaryTheme.primaryGray
                            }}>Deadline</span></LableRequired>
                                <BEDatePicker
                                 value={deadline}
                                 handleDateChange={onChangeDate}
                                 format={'DD MMM YYYY'}
                                />
                        </Col>
                        <Col span={24}>
                            <div style={{ display: 'flex', gap: '0.5rem' }}>
                                <BEButton onClick={
                                    () => IncreaseDate(7)
                                }>+7 Day</BEButton>
                                <BEButton
                                    onClick={
                                        () => IncreaseDate(15)
                                    }
                                >+15 Days</BEButton>
                                <BEButton onClick={
                                    () => IncreaseDate(30)
                                } >+30 Days</BEButton>
                            </div>
                        </Col>
                        <Col span={24}>
                          <div style={{display:'flex',justifyContent:'center',marginTop:'1rem',gap:'1rem'}}>
                                <BEButton size='large' onClick={() => props.setOpen(false)} style={{width:'150px'}}>
                                  Cancel
                                </BEButton>
                                <BEButton 
                                loading={loading}
                                onClick={onSubmit}
                                size='large' className='primary' style={{width:'150px'}}>
                                  Send Invites
                                </BEButton>
                          </div>
                        </Col>
                    </Row>

                    
                </Modal>
  );
}
