import * as React from 'react';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { NotificationTypes } from '../../../../../../Redux/Types/commonTypes';
import { ChangeDateFormat } from '../../../../../../Config/Functions/UsefullFunctions';
import { PrimaryTheme } from '../../../../../../Config/Theme/theames';
import ToolTip from '../../../../../../Components/BEToolTip';
import { getNotifications, patchNotification } from '../../../../../Actions/HomeActions';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';

export interface INotificationBoxProps {
  notification: NotificationTypes
}

export function NotificationBox (props: INotificationBoxProps) {
  const dispatch = useAppDispatch();

  const handleChangeNotificationStatus = async() => {
    await dispatch(patchNotification(props.notification?.id, {read:!props.notification?.read}))
  }

  return (
    <div className='notification'> 
        <div>
          <p className='message'>
            {
            props.notification.message
            }
          </p>
          <p className='date-time'>
            {
              ChangeDateFormat(props.notification.created_at,true)
            }
          </p>
        </div>
        <div className='notification-status'>
            <ToolTip 
              title={!props.notification?.read ? 'Mark as Read' : 'Mark as Unread'}
            >
            <div className='notification-status-mark'
              onClick={handleChangeNotificationStatus}
              style={{backgroundColor:props.notification?.read ? PrimaryTheme.primaryGreyLight:PrimaryTheme.primaryGreen}}
            />
            </ToolTip>
        </div>
    </div>
  );
}
