import { Flex, Progress } from 'antd';
import TrackerCard from '../../../../Components/Cards/TrackerCard'
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import React from 'react';
import { calcProgress, getTargetValue } from '../../Actions/targetAction';

const Tracker = () => {
  const myMetrics = useTypedSelector((state) => state.onBoarding.metrics.allSelectedMetrics);
  const [progress, setProgress] = React.useState(0);
  const assessmentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const myMetricsData = useTypedSelector((state) => state.dataManager.metrics.myMetricsData);

  const getCurrentValue = (id: number) => {
    return myMetricsData.data.filter((item) => item.my_metric === id).reduce((total: number, currentItem: any) => {
      return total + currentItem.value;
    }, 0);
  }

  React.useEffect(() => {
    setProgress((countMetKPIs() * 100) / myMetrics.data.filter((item: any) => item.target_value !== "-1.00" && item.status && item.year === assessmentYear).length);
  }, [myMetrics, assessmentYear]);

  const countMetKPIs = (): number => {
    return myMetrics.data.filter((item: any) => item.target_value !== "-1.00" && item.status && item.year === assessmentYear).reduce((count: number, currentItem: any) => {
      const progress = (100 * getCurrentValue(currentItem.id)) / getTargetValue(currentItem);
      if (progress < 100) {
          return count;
        } else {
          return count + 1;
        }
    }, 0);
  }
  return (
    <div className='tracker'>
      <TrackerCard noHighlight={true} title={'Total KPIs'} body={myMetrics.data.filter((item: any) => item.target_value !== "-1.00" && item.status && item.year === assessmentYear).length} />
      <TrackerCard noHighlight={true} title={'No. of KPIs met'} body={countMetKPIs()} />
      <div style={{ flexGrow: 1 }} />
      {
        <div style={{ display: "flex", justifyContent:"center", gap: "10px", fontSize:"1rem", flexDirection: "column" }}>
          <span>OVERALL PROGRESS: {progress ? progress.toFixed(2): 0}%</span>
          <Progress percent={progress} size={[300, 10]} showInfo={false} />
        </div>
      }
    </div>
  )
}

export default Tracker
