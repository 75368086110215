import { useLocation } from "react-router-dom";
import { BEButton } from "../../../Components/BEFormItems/BEButton";
import { GlobalLoader } from "../../../Components/GlobalLoader";
import { useAppDispatch } from "../../../Config/Hooks/useAppDispatch";
import { useTypedSelector } from "../../../Config/Hooks/useTypedSelector";
import { navigateTo } from "../../../Navigation/NavigationManager";
import { setOnboardingStage, setShowWelcomePage } from "../../../Redux/CommonReducer";
import { getOnboardingStage, updateOnboardingStage } from "../../Actions/OnboardingActions";
import "./style.scss";
import { useEffect, useState } from "react";
import { defaultDerivedMetrics, defaultMetrics } from "../../../Config/Data/dashboardData";
import { addMetrics, getEveryMetrics } from "../../Actions/OnboardingStep4Actions";
import React from "react";
import { postMyDerivedMetrics } from "../../../Features/DataManager/Actions";

interface OnboardingTopNavProps {
  title: string
}

export function OnboardingTopNav(props: OnboardingTopNavProps) {
  const onboardingStage = useTypedSelector(
    (state) => state.common.onboardingStage
  );
  const dispatch = useAppDispatch();
  const location = useLocation()
  const [loading, setLoading] = useState(false);
  const [loadingSkip, setLoadingSkip] = useState(false);
  const currentYear = useTypedSelector(state => state.onBoarding.metrics.currentYear);
  const selectedMetrics = useTypedSelector(state => state.onBoarding.metrics.selectedMetrics.data);
  const allMetrics = useTypedSelector(state => state.onBoarding.metrics.allMetrics);

  React.useEffect(() => {
    if (allMetrics.status === "idle")
    dispatch(getEveryMetrics());
  },[]);

  const addDefaultMetrics = async () => {
    const metricsToAdd = defaultMetrics.filter((item) => !selectedMetrics.find((met) => met.bcode === item)).map((bcode) => {
      return {
        year: currentYear,
        metric: allMetrics.data?.find((metric) => metric.bcode === bcode)?.id,
        bcode: bcode,
      }
    });
    await dispatch(addMetrics(metricsToAdd));
    const derivedMetricsToAdd = defaultDerivedMetrics.map((bcode) => {
      return {
        year: currentYear,
        derived_metric: allMetrics.data?.find((metric) => metric.bcode === bcode)?.id
    }})
    await dispatch(postMyDerivedMetrics(derivedMetricsToAdd));
  }

  const handleEditConfig = async () => {
    navigateTo('/onboarding/step1')
  }
  const handleNext = async () => {
    if (
      (onboardingStage.stage === 0 &&
        location.pathname === "/onboarding/step1") ||
      (onboardingStage.stage === 1 &&
        location.pathname === "/onboarding/step2") ||
      (onboardingStage.stage === 2 &&
        location.pathname === "/onboarding/step3") ||
      (onboardingStage.stage === 3 &&
        location.pathname === "/onboarding/step4") ||
      (onboardingStage.stage === 4 && location.pathname === "/onboarding/step5")
    ) {
      setLoading(true);
      await dispatch(updateOnboardingStage(onboardingStage.stage));
      dispatch(setShowWelcomePage(true));                               // welcome page will be shown only once after onboarding is completed
      setLoading(false);
    }
    if (location.pathname === "/onboarding/step2") {
      setLoading(true);
      await addDefaultMetrics();
      setLoading(false);
      navigateTo("/onboarding/step3");
    }
    else if (location.pathname === "/onboarding/step3")
      navigateTo("/onboarding/step4");
    else if (location.pathname === "/onboarding/step4")
      navigateTo("/onboarding/step5");
    else if (location.pathname === "/onboarding/step5") {
      navigateTo("/home/dashboard");
    }
  };

  const handleSkip = async () => {
    if (
      (onboardingStage.stage === 0 &&
        location.pathname === "/onboarding/step1") ||
      (onboardingStage.stage === 1 &&
        location.pathname === "/onboarding/step2") ||
      (onboardingStage.stage === 2 &&
        location.pathname === "/onboarding/step3") ||
      (onboardingStage.stage === 3 &&
        location.pathname === "/onboarding/step4") ||
      (onboardingStage.stage === 4 &&
        location.pathname === "/onboarding/step5")
    ) {
      setLoadingSkip(true);
      await dispatch(updateOnboardingStage(onboardingStage.stage)).then(() => {
        setLoadingSkip(false);
        if (onboardingStage.stage === 5) navigateTo('/home/dashboard')
        else navigateTo(`/onboarding/step${onboardingStage.stage + 1}`);
      });
    }
    else if (location.pathname === "/onboarding/step5") navigateTo('/home/dashboard')
    else navigateTo(`/onboarding/step${Number(location.pathname.split("step")[1]) + 1}`);
  }


  return (
    <div className="onboarding-top-nav">
      <p>{props.title}</p>
      <div>
        <BEButton size="large" onClick={
          location.pathname === "/onboarding/step5" ? handleEditConfig : handleSkip
        }
          loading={loadingSkip}
        >
          {location.pathname === "/onboarding/step5" ? "Edit Configuration" : "Skip this step"}
        </BEButton>
        <BEButton size="large" className="primary" onClick={handleNext}
          loading={loading}
        >
          {location.pathname === "/onboarding/step5" ? "Save & Close" : "Save & Next"}
        </BEButton>
      </div>
    </div>
  );
}
