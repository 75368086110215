import * as React from 'react';
import ExclamationIcon from '../../../../../assets/images/ExclaimationIcon.png';
import './style.scss';

export interface IDummyLabelProps {
}

export function DummyLabel(props: IDummyLabelProps) {
  return (
    <div className='dummy-label-container'>
      <span>
        <img height={25} width={25} src={ExclamationIcon} alt='Exclamation Icon' />
      </span>
      <span>
        This indicator is reported at the asset level through the GRESB Asset spreadsheet.
      </span>
    </div>
  );
}
