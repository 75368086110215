import React, { useEffect } from 'react'
import { PDFDownloadLink, Document, Page, View, StyleSheet, Text, Image, Link } from '@react-pdf/renderer'
import image1 from '../../../../../assets//images/Report/report1img.jpg'
import Logo from '../../../../assets/images/Company/Logo_horizontal.png'
import { indexOf } from 'lodash';

//A4 size in pts 595 × 842

interface PropTypes {
  score: any,
  questions: any,
  answers: any,
  supplierData: any
}

const styles = StyleSheet.create(
  {
    pageStyle: {
      padding: '20pt', //remaining 555
      paddingBottom: '50pt'
    },
    blockWidth: {
      width: '33.33%',
      padding: '20pt', border: '1pt solid black',

    },
    blockWidthSmall: {
      width: '33.33%',
      padding: '10pt 20pt', border: '1pt solid black'
    },
    normalText: {
      fontSize: '10pt', fontWeight: 'bold'
    }
    , outerBox: {
      display: 'flex',
      flexDirection: 'row',
    },
    smallText: {
      fontSize: '7pt',
      lineHeight:'1'
    }
  }

)



const PoweredBy = () => (
  <View style={{ position: 'absolute', bottom: '10px', left: '10px' }} fixed>
    <Text style={{ color: 'grey', fontSize: '10px' }}>Created By</Text>
    <Image src={Logo} style={{ width: '100px', marginTop: '1px' }} fixed />
  </View>
)

export const IndividualSupplierTemplate = (props: PropTypes) => (
  <Document>
    <Page size='A4' style={{ ...styles.pageStyle }}>
      <View style={{ display: 'flex', justifyContent: 'center', marginTop: '20pt' }}>
        <Text style={{ fontSize: '25pt', fontWeight: 'bold' }}>Supplier Assessment Individual Response</Text>
      </View>
     
      <View style={{ marginTop: '30pt', ...styles.outerBox }}>
        <View style={{ ...styles.blockWidthSmall, backgroundColor: 'rgb(234, 209, 220)' }}>
          <Text style={{ ...styles.normalText }}>
           Supplier Name
          </Text>
        </View>
        <View style={{ ...styles.blockWidthSmall }}>
          <Text style={{ ...styles.normalText }}>
          {
                props.supplierData.name
              }
          </Text>
        </View>
      </View>
      <View style={{ ...styles.outerBox }}>
        <View style={{ ...styles.blockWidthSmall , backgroundColor: 'rgb(234, 209, 220)'}}>
          <Text style={{ ...styles.normalText }}>
          Supplier Industry
          </Text>
        </View>
        <View style={{ ...styles.blockWidthSmall }}>
          <Text style={{ ...styles.normalText }}>
          {
                props.supplierData.industry
              }
          </Text>
        </View>
      </View>
      <View style={{ ...styles.outerBox }}>
        <View style={{ ...styles.blockWidthSmall , backgroundColor: 'rgb(234, 209, 220)'}}>
          <Text style={{ ...styles.normalText }}>
          Email
          </Text>
        </View>
        <View style={{ ...styles.blockWidthSmall }}>
          <Text style={{ ...styles.normalText }}>
          {
                props.supplierData.email
              }
          </Text>
        </View>
      </View>


      <View style={{ marginTop: '30pt', ...styles.outerBox }}>
        <View style={{ ...styles.blockWidthSmall }}>
          <Text style={{ ...styles.normalText }}>
          Total score (%)
          </Text>
        </View>
        <View style={{ ...styles.blockWidthSmall, backgroundColor: 'rgb(217, 217, 217)' }}>
          <Text style={{ ...styles.normalText }}>
              {props.score}
          </Text>
        </View>
      </View>
      <View style={{ ...styles.outerBox }}>
        <View style={{ ...styles.blockWidthSmall }}>
          <Text style={{ ...styles.normalText }}>
          Risk classification
          </Text>
        </View>
        <View style={{ ...styles.blockWidthSmall, backgroundColor: 'rgb(217, 217, 217)' }}>
          <Text style={{ ...styles.normalText }}>
              {
                props.score <33.33 ? 'High ' : props.score <66.66 ? 'Medium ' : 'Low '
              }
              risk
          </Text>
        </View>
      </View>

      <View style={{ marginTop: '50pt',marginBottom:'30pt', ...styles.outerBox }}>
          <Text style={{ fontWeight:'bold',fontSize:'20pt'}}>
            Supplier Responses
          </Text>
        </View>
      {
        props.questions.map((question: any, index: number) => {
          let answers = props.answers.filter((answer: any) => answer.question === question.id).sort((a: any, b: any) => a.id - b.id)
          return (
            <View style={{ display:'flex',flexDirection:'row',gap:'20pt', marginBottom: '10pt',paddingBottom:'10pt',borderBottom:'1px solid grey' }} wrap >
              <View style={{width:'80%'}}> 
                <Text style={{ ...styles.normalText }}>
                  {question.question}
                </Text>
              </View>
              <View style={{width:'20%'}}>
               <Text style={{ ...styles.normalText }}>- 
                  {
                    // answers.map((answer: any, index: number) => {
                    //   return (
                    //     <View style={{ display: 'flex',marginBottom:'10pt', flexDirection: 'row', alignItems: 'center' }}>
                    //       <Text style={{ ...styles.normalText}}>
                    //       {
                    //        answers.length>1  && <>{index+1}.</>
                    //       }{answer===true?'Yes':'No'}
                    //       </Text>
                    //     </View>
                    //   )
                    // })
                    props.answers.find((answer:any) => answer.question === question.id)?.answer === true ? 'Yes' : 'No'
                  }
               </Text>
              </View>
            </View>
          )
        })
      }
      <PoweredBy />

    </Page>

  </Document>
)