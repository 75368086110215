import React from 'react'
import IconProps from '../Interface/IconProps';

const AirIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 18}
      height={props.inheritSize ? "100%" : 15}
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0827 12.0832C11.0827 13.4582 9.95768 14.5832 8.58268 14.5832C7.20768 14.5832 6.08268 13.4582 6.08268 12.0832H7.74935C7.74935 12.5415 8.12435 12.9165 8.58268 12.9165C9.04102 12.9165 9.41602 12.5415 9.41602 12.0832C9.41602 11.6248 9.04102 11.2498 8.58268 11.2498H0.666016V9.58317H8.58268C9.95768 9.58317 11.0827 10.7082 11.0827 12.0832ZM14.8327 3.33317C14.8327 1.72484 13.5243 0.416504 11.916 0.416504C10.3077 0.416504 8.99935 1.72484 8.99935 3.33317H10.666C10.666 2.6415 11.2243 2.08317 11.916 2.08317C12.6077 2.08317 13.166 2.6415 13.166 3.33317C13.166 4.02484 12.6077 4.58317 11.916 4.58317H0.666016V6.24984H11.916C13.5243 6.24984 14.8327 4.9415 14.8327 3.33317ZM14.416 7.08317H0.666016V8.74984H14.416C15.1077 8.74984 15.666 9.30817 15.666 9.99984C15.666 10.6915 15.1077 11.2498 14.416 11.2498V12.9165C16.0243 12.9165 17.3327 11.6082 17.3327 9.99984C17.3327 8.3915 16.0243 7.08317 14.416 7.08317Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
    </svg>
  );
}

export default AirIcon