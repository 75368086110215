import React from 'react'
import IconProps from './Interface/IconProps'

const RespondantsIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 22}
      height={props.inheritSize ? "100%" : 22}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.75 0.5H0.75V20.5L4.75 16.5H13.75V8.5H20.75V0.5Z"
        fill={props.stroke ? props.stroke : "#666666"}
      />
      <path
        d="M21.25 14.5H19.05L20.75 10.5H15.75V16.5H17.75V21.5L21.25 14.5Z"
        fill={props.stroke ? props.stroke : "#666666"}
      />
    </svg>
  );
}

export default RespondantsIcon