import { Checkbox, Col, ColorPicker, Row } from 'antd';
import * as React from 'react';
import BEStatusTag from '../../../../Components/BEStatusTag';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { setChildDerivedMetricsTobeAdded, setChildSelectedMetricsTobeAdded } from '../../../../Redux/SettingsReducer';
import { findBECodesOfAllChildren, findBECodesOfAllParents } from '../../../Actions/calculatedMetricsActions';
import ToolTip from '../../../../Components/BEToolTip';

export interface calculatedMetricData {
    id: number;
    title: string;
    group?: string;
    bcode?: string;
    unit?: string;
    checked?: boolean;
    children: calculatedMetricData[];
}

export interface ICalculatedMetricsProps {
    data: calculatedMetricData[];
}

export interface ICalculatedMetricsChildrenProps {
    data: calculatedMetricData[];
    level: number;
}

export interface ICalculatedMetricsChildProps {
    data: any;
    isParent: boolean;
    children?: React.ReactNode;
    backgroundColor?: string;
    tableTop?: boolean;
}

export function CalculatedMetricsChildren(props: ICalculatedMetricsChildrenProps) {
    const backgroundColor = ["#FAFAFA", "#F5F9FA", "#FAFAFA"];
    const marginGap = ["0.5rem", "0.3rem", "0"];
    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {props.data?.map((item, index) => {
                return (
                    <div key={index} style={{ borderRadius: "8px", marginTop: marginGap[props.level] }} >
                        <CalculatedMetricsChild data={item} isParent={item.children.length > 0} backgroundColor={backgroundColor[props.level]}>
                            {item.children.length > 0 && <CalculatedMetricsChildren data={item.children} level={props.level + 1} />}
                        </CalculatedMetricsChild>
                    </div>

                )
            })}
        </div>
    );
}

export function CalculatedMetricsChild(props: ICalculatedMetricsChildProps) {
    const childDerivedMetricsTobeAdded = useTypedSelector((state) => state.settings.childDerivedMetricsTobeAdded);
    const childSelectedMetricsTobeAdded = useTypedSelector((state) => state.settings.childSelectedMetricsTobeAdded);
    const derivedMetricsRepresentationGraph = useTypedSelector((state) => state.settings.derivedMetricsRepresentationGraph);
    const derivedMetrics = useTypedSelector((state) => state.dataManager.metrics.derivedMetrics);
    const myMetricsDM = useTypedSelector((state) => state.dataManager.metrics.myMetrics);
    const allMetrics = useTypedSelector((state) => state.onBoarding.metrics.allMetrics);
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const [checked, setChecked] = React.useState<boolean>(false);
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const dispatch = useAppDispatch();

    const onChange = (e: any) => {
        if (props.isParent) {
            const childBEcodes = findBECodesOfAllChildren(derivedMetricsRepresentationGraph.data, [props.data.bcode]);
            const derivedBEcodes = findBECodesOfAllParents(derivedMetricsRepresentationGraph.data, [props.data.bcode]);
            const allBEcodes = [...derivedBEcodes, ...childBEcodes];
            const allChild: any[] = allBEcodes.map((item) => allMetrics.data?.find((metric: any) => metric.bcode === item)) as any[];
            const derivedChildrenId: number[] = allChild.filter((item) => item.hasOwnProperty("direct")).map((item) => item.id);
            const selectedChildrenId: number[] = allChild.filter((item) => !item.hasOwnProperty("direct")).map((item) => item.id);

            if (e.target.checked) {
                dispatch(setChildDerivedMetricsTobeAdded([...childDerivedMetricsTobeAdded, ...derivedChildrenId]));
                dispatch(setChildSelectedMetricsTobeAdded([...childSelectedMetricsTobeAdded, ...selectedChildrenId]));
            } else {
                dispatch(setChildDerivedMetricsTobeAdded(childDerivedMetricsTobeAdded.filter((item) => !derivedChildrenId.includes(item))));
                dispatch(setChildSelectedMetricsTobeAdded(childSelectedMetricsTobeAdded.filter((item) => !selectedChildrenId.includes(item))));
            }
        } else {
            if (e.target.checked) {
                dispatch(setChildSelectedMetricsTobeAdded([...childSelectedMetricsTobeAdded, props.data.id]));
            } else {
                dispatch(setChildSelectedMetricsTobeAdded(childSelectedMetricsTobeAdded.filter((item) => item !== props.data.id)));
            }
        }
    }

    React.useEffect(() => {
        if (myMetricsDM.data.find((item: any) => item.bcode === props.data.bcode)) {
            setDisabled(true);
            setChecked(true);
            return;
        }

        if (props.isParent) {
            const childBEcodes = findBECodesOfAllChildren(derivedMetricsRepresentationGraph.data, [props.data.bcode]);
            const childIds: number[] = childBEcodes.map((item) => allMetrics.data?.find((metric: any) => metric.bcode === item)?.id) as number[];
            const anyChildrenChecked = childIds.some((item) => childSelectedMetricsTobeAdded.includes(item));

            if (childDerivedMetricsTobeAdded.includes(props.data.id) && !anyChildrenChecked) {
                dispatch(setChildDerivedMetricsTobeAdded(childDerivedMetricsTobeAdded.filter((item) => item !== props.data.id)));
                return;
            }

            if (!childDerivedMetricsTobeAdded.includes(props.data.id) && anyChildrenChecked) {
                dispatch(setChildDerivedMetricsTobeAdded([...childDerivedMetricsTobeAdded, props.data.id]));
                return;
            }


            if (childDerivedMetricsTobeAdded.includes(props.data.id)) {
                setChecked(true);
            } else {
                setChecked(false);
            }

        } else {
            if (childSelectedMetricsTobeAdded.includes(props.data.id)) {
                setChecked(true);
            } else {
                setChecked(false);
            }
        }
    }, [childDerivedMetricsTobeAdded, childSelectedMetricsTobeAdded, props.data]);


    return (
        <div style={{ borderRadius: "12px", border: props.isParent && expanded ? `1px solid ${PrimaryTheme.primaryGreyLight}` : "none" }}>
            <Row style={{ padding: "0 1rem", height: "3rem", backgroundColor: props.backgroundColor, fontWeight: props.tableTop ? "600" : "400" }} align={"middle"}>
                <Col span={1}>
                    {!props.tableTop && <Checkbox disabled={disabled} checked={checked} onChange={onChange} />}
                </Col>
                <Col span={23}>
                    <Row>
                        <Col span={6} style={{display: "flex", flexDirection: "row"}}>
                            {props.data.title}&nbsp;&nbsp;{props.isParent && <ToolTip title='The values for C metrics are calculated.'>
                                <BEStatusTag status='success'>C</BEStatusTag>
                            </ToolTip>}
                        </Col>
                        <Col span={6}>
                            {props.data.group}
                        </Col>
                        <Col span={6}>
                            {props.data.unit}
                        </Col>
                        {props.isParent &&
                            <Col span={6}>
                                {expanded ?
                                    <a onClick={() => setExpanded(false)}>
                                        Close
                                    </a> :
                                    <a onClick={() => setExpanded(true)}>
                                        Click to Expand
                                    </a>}
                            </Col>
                        }
                        {props.tableTop &&
                            <Col span={6}>
                                ACTION
                            </Col>
                        }
                    </Row>
                </Col>
            </Row>
            {props.isParent && expanded &&
                <Row>
                    {props.children}
                </Row>
            }
        </div>
    );
}

export function CalculatedMetrics(props: ICalculatedMetricsProps) {
    return (
        <div style={{ padding: "1rem" }}>
            <CalculatedMetricsChild data={{ title: "NAMES", group: "GROUP", unit: "UNIT" }} tableTop={true} isParent={false} backgroundColor={PrimaryTheme.primaryGreyLight} />
            <CalculatedMetricsChildren data={props.data} level={0} />
        </div>
    );
}


// Demo Data to pass
// const data: calculatedMetricData[] = [
//     {
//         id: 1,
//         title: "Metric 1",
//         group: "Group 1",
//         unit: "Unit 1",
//         checked: false,
//         children: [
//             {
//                 id: 1.1,
//                 title: "Metric 1.1",
//                 group: "Group 1.1",
//                 unit: "Unit 1.1",
//                 checked: false,
//                 children: [
//                     {
//                         id: 1.11,
//                         title: "Metric 1.1.1",
//                         group: "Group 1.1.1",
//                         unit: "Unit 1.1.1",
//                         checked: false,
//                         children: []
//                     }
//                 ]
//             },
//             {
//                 id: 1.2,
//                 title: "Metric 1.2",
//                 group: "Group 1.2",
//                 unit: "Unit 1.2",
//                 checked: false,
//                 children: []
//             }
//         ]
//     },
//     {
//         id: 2,
//         title: "Metric 2",
//         group: "Group 2",
//         unit: "Unit 2",
//         checked: false,
//         children: [
//             {
//                 id: 2.1,
//                 title: "Metric 2.1",
//                 group: "Group 2.1",
//                 unit: "Unit 2.1",
//                 checked: false,
//                 children: []
//             }
//         ]
//     },
//     {
//         id: 3,
//         title: "Metric 3",
//         group: "Group 3",
//         unit: "Unit 3",
//         checked: false,
//         children: []
//     }
// ];