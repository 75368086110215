import * as React from 'react';
import RadioList from '../RadioList';
import './style.scss';
import { CheckboxList } from '../CheckboxList';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { arrangeGresbData, arrangeGresbDataSectionWise } from '../../../Actions/gresbActions';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { TextArea } from '../TextArea';
import { TextList } from '../TextList';
import { Type1Topic } from '../../../Views/Drawers/NewEditReport/RightBody/RightBodyTabs/ChildrenRightTabs/Tables/Type1';
import { Type2Topic } from '../../../Views/Drawers/NewEditReport/RightBody/RightBodyTabs/ChildrenRightTabs/Tables/Type2';
import { DropdownList } from '../DropdownList';
import { DummyLabel } from '../DummyLabel';
import { Type4Topic } from '../../../Views/Drawers/NewEditReport/RightBody/RightBodyTabs/ChildrenRightTabs/Tables/Type4';

export interface IGresbMainProps {
    uniqueCode?: string;
    data?: any[];
}

export function GresbMain(props: IGresbMainProps) {

    const [nestedData, setNestedData] = React.useState<any[]>([]);
    const allSelectedTopics = useTypedSelector(state => state.reporting.allSelectedTemplateTopics);
    const currentCategory = useTypedSelector(state => state.reporting.selectedTemplateCategory);
    const currentSection = useTypedSelector(state => state.reporting.selectedTemplateSection);
    const currentDimension = useTypedSelector(state => state.reporting.selectedTemplateDimension);
    const dispatch = useAppDispatch();
    const role = localStorage.getItem('role');

    const getNestedEntityData = async () => {
        if (allSelectedTopics.status !== 'idle' && props.uniqueCode) {
            const data = await dispatch(arrangeGresbDataSectionWise());
            setNestedData(data.filter((item) => item.unique_code === props.uniqueCode));
        }
    }

    React.useEffect(() => {
        getNestedEntityData();
    }, [allSelectedTopics, currentCategory, currentSection, currentDimension]);

    if (props?.uniqueCode)
        if (nestedData[0]?.response_type === 'Dummy Label')
            return (
                <DummyLabel />
            );
        else if (nestedData[0]?.response_type === 'Radio')
            return (
                <div className='gresb-main-container'>
                    <RadioList isFirstLevel data={nestedData} />
                </div>
            );
        else if (nestedData[0]?.response_type === 'Textarea')
            return (
                <div className='gresb-main-container'>
                    <TextArea isFirstLevel data={nestedData} />
                </div>
            );
        // From here for Assets
        else if (nestedData[0]?.table_type === 1)
            return (
                <div className='gresb-main-container'>
                    <Type1Topic uniqueCode={props.uniqueCode} data={nestedData} nonEditable={role === 'AUDITOR'} />
                </div>
            );
        else if (nestedData[0]?.table_type === 2) {

            if (nestedData[0]?.response_type === 'Checkbox')
                return (
                    <div className='gresb-main-container'>
                        <Type4Topic uniqueCode={props.uniqueCode} data={nestedData} nonEditable={role === 'AUDITOR'} />
                    </div>
                );
            else
                return (
                    <div className='gresb-main-container'>
                        <Type2Topic uniqueCode={props.uniqueCode} data={nestedData} nonEditable={role === 'AUDITOR'} />
                    </div>
                );
        } else if (nestedData[0]?.table_type === 4) {
            return (
                <div className='gresb-main-container'>
                    <Type1Topic uniqueCode={props.uniqueCode} data={nestedData} fixedRows={1} nonEditable={role === 'AUDITOR'} />
                </div>
            );
        }


    return (
        <div style={{ width: "100%" }}>
            {
                props?.data?.map((item) =>
                    item.responseType === 'Table1' ?
                        <div className='gresb-main-container'>
                            <Type1Topic uniqueCode={item.unique_code} data={item.children} nonEditable={role === 'AUDITOR'} />
                        </div>
                        :
                        item.responseType === 'Table2' ?
                            <div className='gresb-main-container'>
                                <Type2Topic uniqueCode={item.unique_code} data={item.children} nonEditable={role === 'AUDITOR'} />
                            </div>
                            :
                            item.responseType === 'Radio' ?
                                <div className='gresb-main-container'>
                                    <RadioList data={item.children} />
                                </div>
                                : item.responseType === 'Checkbox' ?
                                    <div className='gresb-main-container'>
                                        <CheckboxList data={item.children} />
                                    </div>
                                    : item.responseType === 'Textarea' ?
                                        <div className='gresb-main-container'>
                                            <TextArea data={item.children} />
                                        </div>
                                        : item.responseType === 'Text' || item.responseType === 'Number' || item.responseType === 'Number(%)' ?
                                            <div className='gresb-main-container'>
                                                <TextList data={item.children} resType={item.responseType} />
                                            </div>
                                            : item.responseType === '<Alignment>' || item.responseType === '<Scheme name 2020>' || item.responseType === '<coverage>' ?
                                                <div className='gresb-main-container'>
                                                    <DropdownList data={item.children} resType={item.responseType} />
                                                </div> :
                                                null
                )
            }
        </div>
    );
}
