export const graphColors = [
  'rgba(255, 180, 79, 1)'//orange
  ,'rgba(249, 155, 171, 1)'//pink
  ,'rgba(155, 223, 196, 1)'//orange
  ,'rgba(17, 144, 199, 1)'//blue 2
  ,'rgba(17, 197, 209, 1)'//blue 3
  ,'rgba(170, 57, 81, 1)'//maroon
  ,'rgba(224, 107, 241, 1)'//purple
  ,'rgba(255, 123, 159, 1)'//pink
]

export const graphColors2 = [
  'rgba(242, 174, 114, 1)',//brown
  'rgba(129, 199, 212, 1)',//blue1
  'rgba(109, 142, 184, 1)',//blue2
  'rgba(195, 247, 222, 1)'//blue3
]

export const graphColors3 = [
  'rgba(31, 119, 180, 1)',//blue1
  'rgba(255, 127, 14, 1)',//orange1
  'rgba(214, 39, 40, 1)',//red1
  'rgba(148, 103, 189, 1)',//lilac1
  'rgba(140, 86, 75, 1)',//brown1
  'rgba(227, 119, 194, 1)',//pink1
  'rgba(127, 127, 127, 1)',//grey1
  'rgba(188, 189, 34, 1)',//green1
  'rgba(23, 190, 207, 1)',//blue2
  'rgba(255, 187, 120, 1)',//orange2
  'rgba(44, 160, 44, 1)',//green2
]