export const dashboardMetricsCardList = [
    "Total volume of water withdrawn (uncategorised)",
    "Total waste generated (uncategorised)",
    "Purchased grid electricity (uncategorised)",
  ];

export const dashboardMetricsGraphList = [
    "Purchased grid electricity (uncategorised)",
    "Purchased renewable electricity (uncategorised)",
    "Organic waste generated in offices",
    "Total Plastic Waste generated",
    "Amount of water recycled/re-used"
  ];

export const electricityBcodes = ["BE008.14", "BE026.10", "BE008.21", "BE026.6"];

export const wasteBcodes = ["BE014.13", "BE014.6", "BE014.25", "BE014.12"];

export const waterBcodes = ["BE033.1", "BE033.5", "BE033.3", "BE033.4", "BE033.6", "BE033.12"];

export const defaultMetrics = ["BE008.14", "BE026.10", "BE008.21", "BE026.6", "BE014.13", "BE014.6",
"BE014.25", "BE014.12", "BE033.1", "BE033.9",  "BE026.9", "BE014.1", "BE033.5", "BE033.3", "BE033.4", 
"BE033.6", "BE033.12"];

export const defaultDerivedMetrics = ["BE008.25", "BE008.24", "BE026.3", "BE026.11", "BE008.23",
"BE026.5", "BE014.34", "BE014.37", "BE014.36", "BE014.33", "BE033.14", "BE008.22", "BE008.2"];