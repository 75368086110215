import * as React from 'react';
import { BETable } from '../../../../../Components/BETable';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { getBusinessUnitContributors, deleteBusinessUnitContributor } from '../../../../Actions/BusinessUnitActions';
import { ColumnsType } from 'antd/es/table';
import DeleteIcon from '../../../../../Components/BEIcons/DeleteIcon';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import { AddContributorToBusinessUnit } from '../AddContributorToBusinessUnit';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';

export interface IBusinessUnitContributorProps {
  businessUnit?: any;
  isEdit?: boolean;
  usersToAdd: any;
  setUsersToAdd: Function;
}

export function BusinessUnitContributorTable(props: IBusinessUnitContributorProps) {
  const dispatch = useAppDispatch();
  const businessUnitContributors = useTypedSelector(state => state.entity.BusinessUnitContributors);
  const users = useTypedSelector(state => state.userMgmt);
  const [open, setOpen] = React.useState(false);


  const columns: ColumnsType<any> = [
    {
      title: 'Contributor',
      dataIndex: 'user',
      key: 'user',
      render: (text: any, record: any) => users.data.find((user: any) => user.id === record.user)?.first_name + ' ' + users.data.find((user: any) => user.id === record.user)?.last_name
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text: any, record: any) => <div style={{ height: '1rem' }}
        onClick={() => {
          console.log(record);
          console.log(props.usersToAdd);
          !props.isEdit ?
            props.setUsersToAdd(props.usersToAdd.filter((user: any) => user !== record.user))
            :
            dispatch(deleteBusinessUnitContributor(record.id, props?.businessUnit?.id));
        }}
      >
        <DeleteIcon inheritSize stroke={PrimaryTheme.primaryGray} />
      </div>
    }
  ]

  React.useEffect(() => {

  }, [props.businessUnit])

  React.useEffect(() => {
    if (props.businessUnit?.id)
      dispatch(getBusinessUnitContributors(props.businessUnit.id));
  }, [props.businessUnit]);

  return (
    <div>
      <BEButton onClick={()=>setOpen(true)} className='primary' style={{marginBottom:'0.5rem', float: "right"}}>+ Add Contributor</BEButton>
      <BETable 
        columns={columns}
        data={
          !props.isEdit ?
            props.usersToAdd.map((user: any) => ({
              user: user,
              business_unit: props.businessUnit.id
            }))
            :
            businessUnitContributors.data}
        loading={businessUnitContributors.status === 'loading'}
      />
      <AddContributorToBusinessUnit
        open={open}
        setOpen={setOpen}
        businessUnit={props.businessUnit}
        isEdit={props.isEdit}
        setUsersToAdd={props.setUsersToAdd}
        usersToAdd={props.usersToAdd}
      />
    </div>
  );
}
