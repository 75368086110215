import { useCallback } from 'react';

// TypeScript type for the callback function
type CallbackFunction = (...args: any[]) => void;

// Custom hook for creating a debounced function with TypeScript
export default function useDebounce(callback: CallbackFunction, delay: number): CallbackFunction {
  return useCallback(
    debounce(callback, delay),
    [callback, delay] // Dependencies for useCallback
  );
}

// Debounce function using vanilla JavaScript with TypeScript type annotations
function debounce(func: CallbackFunction, wait: number): CallbackFunction {
  let timeout: NodeJS.Timeout | null = null;

  return function(...args: any[]): void {
    const later = () => {
      timeout = null;
      func(...args);
    };

    if (timeout !== null) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(later, wait);
  };
}
