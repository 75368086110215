import * as React from 'react';
import { SideItem } from './SIdeItem';
import './style.scss';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { setCurrentCategory } from '../../../../Redux/OnboardingReducer';
import { metricType } from '../../../../Redux/Types/dataManagerTypes';

export interface ISIdeFilterProps {
}

export function SIdeFilter (props: ISIdeFilterProps) {
    const dispatch = useAppDispatch()
    const allMetrics = useTypedSelector(state => state.onBoarding.metrics.allMetrics.data)
    const currentCategory = useTypedSelector(state => state.onBoarding.metrics.currentCategory)
    const currentPiller = useTypedSelector(state => state.onBoarding.metrics.currentPillar)

    React.useEffect(() => {
      dispatch( setCurrentCategory(Array.from(new Set(allMetrics?.filter((item:any)=>item.pillars === currentPiller).map((item:metricType)=>item.category))).sort()[0]))
    }, [currentPiller])
    
  return (
    <div className='side-filter'>
      {

        Array.from(new Set(allMetrics?.filter((item:any)=>item.pillars === currentPiller).map((item:metricType)=>item.category))).sort()
        .map((item:any,index)=>{
            return <SideItem key={index} active={currentCategory === item} name={item} onClick={()=>dispatch(setCurrentCategory(item))}/>
        })
    }

    </div>
  );
}
