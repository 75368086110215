import * as React from 'react';
import './style.scss';
import Logo from '../../assets/images/Company/Logo.png'
import UserIcon from '../../assets/images/Avatar.png'

export interface ITopbarProps {
  children?: React.ReactNode;
}

export function Topbar (props: ITopbarProps) {
  return (
    <div className='top-bar'>
        <img className='logo' src={Logo} alt="Logo" />
        {
            props.children
        }
        {/* <div className='right-div'>
            <p>John Doe</p>
            <img className='user-icon' src={UserIcon} alt="User Icon" />
        </div> */}
    </div>
  );
}
