import React from 'react';
import { BusinessGroupsSection } from '../../views/Onboarding/Step2/BusinessGroupsSection';
import { BusinessUnitsSection } from '../../views/Onboarding/Step2/BusinessUnitsSection';

const EntityManager = () => {
  return (
    <div style={{margin: "0 1.5rem"}} className='entity-manager'>
      <BusinessGroupsSection />
      <BusinessUnitsSection />
    </div>
  )
}

export default EntityManager;