import { Input, Modal, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { LableRequired } from "../../../Components/BEFormItems/LableRequired";
import { PrimaryTheme } from "../../../Config/Theme/theames";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import GooglePlaces from "../../../Components/GooglePlaces";
import Location from "../../../assets/icons/Location.svg";
import { BEMessage } from "../../../Components/BEMessage";
import { geocodeByLatLng } from "react-google-places-autocomplete";

const containerStyle = {
  width: "100%",
  height: "400px",
  margin: "2rem auto",
};

interface LocationModalProps {
  openModal: boolean;
  setOpenModal: Function;
  form: any;
}

const LocationModal = (props: LocationModalProps) => {
  const [center, setCenter] = useState({
    lat: -3.745,
    lng: -38.523,
  });
  const [navToLoc, setNavToLoc] = useState(false);
  const [value, setValue] = useState("");
  const { isLoaded } = useJsApiLoader({
    //   id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY!,
    libraries: ["places"],
  });

  const [map, setMap] = useState<any>(/** @type google.maps.Map */ null);

  const onLoad = React.useCallback(function callback(map: any) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const navigateToCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(async (position) => {
      const obj = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      try {
        const geocode = await geocodeByLatLng({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setValue(geocode[0].formatted_address);
        props.form.setFieldValue("address", geocode[0].formatted_address);
      }
      catch (err) {
        console.log(err)
      }
      setCenter(obj);
    }, () => {
      console.log("Error")
    });
  };

  const navigateToLocation = () => {
    if (!props.form.getFieldValue("address")) {
      BEMessage.info("Please input an address in the input field!");
      return;
    }
    setNavToLoc(true);
  };

  useEffect(() => {
    if(props.openModal) navigateToCurrentLocation();
  }, [props.openModal]);

  if (!isLoaded) return <Skeleton />;
  return (
    <Modal
      className="location-selector"
      closable
      maskClosable={true}
      open={props.openModal}
      onCancel={() => props.setOpenModal(false)}
      okText="Submit"
      cancelText="Reset"
      footer={null}
      width={"60vw"}
    >
      <div
        style={{
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "21px",
          color: PrimaryTheme.secondaryGray,
          marginBottom: "1rem",
        }}
      >
        <LableRequired>Address </LableRequired>
      </div>

      <GooglePlaces
        form={props.form}
        setCenter={setCenter}
        navToLoc={navToLoc}
        setNavToLoc={setNavToLoc}
        value={value}
        setValue={setValue}
      />

      <div
        style={{
          display: "flex",
          gap: "2rem",
          margin: "1rem auto",
          fontWeight: 700,
          color: "#333333",
        }}
      >
        <div
          style={{
            padding: "0.5rem 1.5rem",
            border: "1px solid #C9C9C9",
            borderRadius: "5px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
          onClick={navigateToCurrentLocation}
        >
          <img src={Location} alt="location" style={{ marginRight: "5px" }} />{" "}
          Current Location
        </div>
        <div
          style={{
            padding: "0.5rem 1.5rem",
            border: "1px solid #C9C9C9",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={navigateToLocation}
        >
          Find on Map
        </div>
      </div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={17}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        }}
      >
        <Marker position={center} />
      </GoogleMap>
    </Modal>
  );
};

export default LocationModal;
