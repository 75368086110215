import API from "../../API";

export const REQUEST_OTP = async (data:any) => {
    return await API.POST("/request-login-otp/", data);
}

export const VERIFY_OTP = async (data:any) => {
    return await API.POST("/otp-login/", data);
}

export const GET_NEW_TOKEN = async () => {
    return await API.GET("/issue-new-token/");
}

export const LOGOUT = async (body:any) => {
    return await API.POST("/logout/", body);
}

export const GET_ALL_COMPANIES = async () => {
    return await API.GET("/esg-consultant/");
}

export const PATCH_INTERNAL_USER_COMPANY = async (id:any) => {
    return await API.PATCH(`/esg-consultant/${id}/`, {});
}

export const GET_NOTIFICATIONS = async () => {
    return await API.GET("/notifications/");
}

export const PATCH_NOTIFICATION = async (id:any,data:any) => {
    return await API.PATCH(`/notifications/${id}/`, data);
}

export const GET_ESG_CONSULTANT_COMPANIES = async () => {
    return await API.GET("/esg-consultant-company/");
}

export const GET_APP_VERSION_AND_DATE = async () => {
    return await API.GET("/app-version/");
};

export const GET_USER_PERMISSIONS = async () => {
    return await API.GET("/user-role-permission/");
}

export const POST_ROLE_PERMISSION = async (data:any[]) => {
    return await API.POST("/user-role-permission/", data);
}

export const PATCH_ROLE_PERMISSION = async (id:any,data:any) => {
    return await API.PATCH(`/user-role-permission/${id}/`, data);
}

export const GET_USER_PROFILE = async () => {
    return await API.GET(`/profile/`);
}

export const PATCH_USER_PROFILE = async (data: any, isFormData: boolean) => {
    return await API.PATCH(`/profile/`, data, isFormData)
}

export const GET_CELERY_TASKS_STATUS = async (task_id: string) => {
    return await API.GET(`/celery-task-status/${task_id}/`);
}

export const GET_FINENCIAL_YEARS = async () => {
    return await API.GET("/financial-years/");
}