import { TopSection } from '../TopSection';
import { Outlet } from 'react-router-dom';
import './style.scss';

export interface ITargetsLayoutProps {
}

export function TargetsLayout (props: ITargetsLayoutProps) {
  return (
    <div style={{padding: "0 1.5rem"}}>
      <TopSection/>
      <Outlet/>
    </div>
  );
}
