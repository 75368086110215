import { Form } from 'antd';
import * as React from 'react';
import { BEInput } from '../../../../../Components/BEFormItems/BEInput';
import './style.scss';
import { BEMessage } from '../../../../../Components/BEMessage';
import { arrangeGresbData, postGresbEntityDataToPortal } from '../../../Actions/gresbActions';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { fillReportData } from '../../../Actions/template';

export interface ITextListProps {
    data: any[];
    resType?: string;
}

export function TextList(props: ITextListProps) {
    const [form] = Form.useForm();
    const [formValues, setFormValues] = React.useState<any>({});
    const [arrangedData, setArrangedData] = React.useState<any[]>([]);
    const currentReport = useTypedSelector(state => state.reporting.currentReport);
    const reportData = useTypedSelector(state => state.reporting.reportData.data);
    const dispatch = useAppDispatch();


    // arrange Data on basis of unique topics
    React.useEffect(() => {
        const arrangedData = arrangeGresbData(props.data);
        let formValues: any = {};
        props.data.forEach((item) => {
            const data = populateData(item);
            formValues = { ...formValues, ...data };
        });
        setFormValues(formValues);
        form.setFieldsValue(formValues);
        setArrangedData(arrangedData);
    }, [props.data, reportData]);


    // To populate data in the form
    const populateData = (data: any) => {
        const topicId = data?.id;
        const topicData = reportData[topicId!] ? reportData[topicId!][0!] : null;
        const value = topicData?.data;
        if (value) {
            return {
                [data.variable_code]: value
            }
        }
        return {
            [data.variable_code]: null
        }
    }


    // Function to handle saving the data
    const handleAddData = async (variableCode: string) => {
        const data = props.data.find((item) => item.variable_code === variableCode);
        const value = formValues[variableCode!];

        if (value === undefined || value === null || value === '') {
            return;
        }

        const dataToSend = {
            data: value
        }
        await dispatch(fillReportData({
            ...dataToSend,
            esg_report: currentReport?.id,
            topic: data?.id,
        })).then(async () => {
            const portalDataUpdated = await dispatch(postGresbEntityDataToPortal(variableCode, String(value)));
        })
    }

    return (
        <div className='text-list-parent'>
            {arrangedData.map((data, index1) => {
                return (
                    <div key={index1} className='text-question-container'>
                        <p className='text-list-question'>
                            {data?.accounting_metric !== 'nan' && data?.topic}
                        </p>
                        <Form form={form}>
                            {
                                data.children?.map((item: any, index: any) => {
                                    console.log(formValues[item.variable_code!]);
                                    console.log('item', item.variable_code);
                                    return (
                                        <Form.Item
                                            key={index}
                                            name={item.variable_code}
                                            rules={[
                                                { required: true, message: `Please enter ${item.accounting_metric !== 'nan' ? item.accounting_metric : item.topic}` },
                                                {
                                                    validator: async (rule, value) => {
                                                        if (item.response_type === 'Number(%)' && (Number(value) < Number(item.min_value) || Number(value) > Number(item.max_value))) {
                                                            throw new Error('Invalid percentage value');
                                                        } else if (item.response_type === 'Number' && (Number(value) < Number(item.min_value) || Number(value) > Number(item.max_value))) {
                                                            throw new Error('Invalid number value');
                                                        } else {
                                                            return Promise.resolve();
                                                        }
                                                    }
                                                }
                                            ]}
                                        >
                                            <p style={{ marginBottom: "0.5rem" }}>
                                                {item.accounting_metric !== 'nan' && item.accounting_metric}
                                            </p>
                                            <BEInput
                                                value={formValues[item.variable_code!]}
                                                type={item.response_type === 'Number' || item.response_type === 'Number(%)' ? 'number' : 'text'}
                                                placeholder={'Enter here...'}
                                                onChange={(e) => {

                                                    // validation
                                                    if ((item.response_type === 'Number(%)' || item.response_type === 'Number') && (Number(item.min_value) !== -1 && Number(item.max_value) !== -1)) {
                                                        if (Number(e.target.value) < Number(item.min_value) || Number(e.target.value) > Number(item.max_value))
                                                            BEMessage.error(`Invalid value. Please enter a value between ${item.min_value} and ${item.max_value}`);
                                                        else if (typeof Number(e.target.value) !== 'number')
                                                            BEMessage.error('Invalid input. Please enter a number');
                                                    }

                                                    form.setFieldsValue({
                                                        [item.variable_code]: e.target.value
                                                    });

                                                    let prevFormValues = { ...formValues };
                                                    prevFormValues[item.variable_code] = e.target.value;
                                                    setFormValues(prevFormValues);
                                                }}
                                                onBlur={() => handleAddData(item.variable_code)}
                                            />
                                        </Form.Item>
                                    )
                                })
                            }
                        </Form>
                    </div>
                )
            })}
        </div>
    );
}
