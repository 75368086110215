import React, { useEffect, useState } from 'react'
import SurveyTopNav from './SurveyTopNav'
import { Card, Modal } from 'antd'
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import InfoIcon from '../../../../Components/BEIcons/InfoIcon';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { fetchResponderData, getSurveyStatus, submitMaterialityAssessmentResponderData } from '../../Actions/matirialityActions';
import { BEMessage } from '../../../../Components/BEMessage';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import BEDragList from '../../../../Components/BEDragList';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { GlobalLoader } from '../../../../Components/GlobalLoader';
import CheckFilledIcon from '../../../../Components/BEIcons/CheckFilledIcon';

const SurveyResponses = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch()
  const surveyResponse = useTypedSelector(state => state.matiriality.surveyResponse)
  const [responders, setResponders] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const token = searchParams.get("token")
  const survey_id = searchParams.get("survey_id")
  const email = searchParams.get("email");
  const [modalVisible, setModalVisible] = useState(true);
  useEffect(() => {
    if (token) dispatch(getSurveyStatus(token));
    else BEMessage.error("Invalid URL")
  }, [])

  useEffect(() => {
    if (!surveyResponse.status && survey_id) {
      setLoading(true)
      dispatch(fetchResponderData(Number(survey_id))).then(() => setLoading(false))
    }
    else if (!survey_id) BEMessage.error("Invalid URL")
  }, [surveyResponse.status])

  useEffect(() => {
    if (surveyResponse.data) setResponders(surveyResponse.data)
  }, [surveyResponse.data])

  const submitResponderData = async () => {
    // console.log(responders)
    if (!email || !token) {
      BEMessage.error("Invalid URL: No Email or Token found")
      return
    }
    setLoading(true)
    let arr = responders.map((response: any, index: number) => {
      return {
        priority: index + 1,
        survey: survey_id,
        email: email,
        topic: response.id,
      };
    })
    dispatch(submitMaterialityAssessmentResponderData(email, token, arr)).then(() => setLoading(false));
  }

  if (loading) {
    return <GlobalLoader />
  }

  return (
    <div>
      <Modal
        title="Materiality Assessment"
        footer={null}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
      >
        <p>Welcome to the Materiality Assessment survey</p>
        <br />
        <p>The survey aims to prioritise the social, governance, and environmental topics that are most important to our organisation and stakeholders. Your input is crucial is shaping our sustainability strategy and actions.</p>
        <br />
        <p>To participate, follow the following steps:</p>
        <ul>
          <li>Review the topics that are preselected by the organisation.</li>
          <li>Re arrange the topics based on your order of preference/importance.</li>
        </ul>
        <p>Your thoughtful responses will greatly contribute to our understanding of material topics, and guide our sustainability efforts. Thank you for your valuable inputs.</p>
      </Modal>
      <SurveyTopNav />
      {surveyResponse.status ? (
        <div
          style={{
            fontWeight: 600,
            fontSize: "1.2rem",
            margin: "0 auto",
            width: "50%",
            height: '80vh',
            color: PrimaryTheme.primaryGreen,
            display: "flex",
            justifyContent: "center",
            alignItems: 'center'
          }}
        >
          <CheckFilledIcon fill={PrimaryTheme.primaryGreen} />
          <span style={{ marginLeft: "1rem" }}>Your response has been submitted</span>
        </div>
      ) : (
        <div style={{ margin: "2rem auto", width: "50%" }}>
          <Card>
            <p style={{ fontWeight: 600, fontSize: "1.2rem" }}>
              Selected Topics
            </p>
            <p style={{ color: PrimaryTheme.primaryGray, margin: "1rem 0" }}>
              Please rank these topics in order of materiality as per you.
            </p>
            <div
              style={{
                padding: "0.5rem 1rem",
                background: PrimaryTheme.primaryGreenLight,
                color: PrimaryTheme.primaryGreen,
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ height: "1rem", marginRight: "0.5rem" }}>
                <InfoIcon fill={PrimaryTheme.primaryGreen} inheritSize />
              </div>
              They are currently ranked as per the sender of this Materiality
              Assessment
            </div>

            <div style={{ margin: "1rem 0" }}>
              <BEDragList
                data={surveyResponse.data}
                setData={setResponders}
                field="topic"
                value="id"
                height="55vh"
                disableRemove
              />

              <div style={{ margin: "1rem 0" }}>
                <BEButton
                  size="large"
                  className="primary"
                  onClick={submitResponderData}
                  loading={loading}
                >
                  Submit Response
                </BEButton>
              </div>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
}

export default SurveyResponses