import * as React from 'react';
import { Drawer, DrawerProps } from 'antd';
import './style.scss';
import CancleIcon from '../BEIcons/CancleIcon';
import { PrimaryTheme } from '../../Config/Theme/theames';

export interface IBEDrawerProps extends DrawerProps {
    padding?: number; //in px
    open: boolean;
    setOpen: Function;
    heading?: string;
    width?: string;
    children?: React.ReactNode;
    footer?: React.ReactNode;
    footerNotification?: React.ReactNode;
    secondryHeading?: SecondryHeadingProps;
    closeIconFunction?: Function;
    theme?: 'light' | 'dark';
    footerHeight?: number | string;
}

export const DrawerSecondryHeadingHeight = 64;

export interface SecondryHeadingProps {
    heading: string;
    subHeading: string;
    theame?:'light'|'dark';
}

const DrawerSecondryHeading = (props: SecondryHeadingProps) => {
    return (
        <div className={`secondry-heading ${props.theame}`}
            style={{ height: DrawerSecondryHeadingHeight }}
        >
            <span>{props.heading}</span>
            <p className='sub-heading'>{props.subHeading}</p>
        </div>
    );

}
export const DrawerFooterNotificationHeight = 100;

export function BEDrawer(props: IBEDrawerProps) {
    const [height, setHeight] = React.useState('calc(100vh - 293px)');
    const FooterHeight = props?.footerHeight ? Number(props.footerHeight) : 87;    //px
    const FooterNotificationHeight = 100;
    const HeadingHeight = 62;
    const defaultPadding = 21;

    React.useEffect(() => {
        let heightToSubtract = 0;
        if (props.footerNotification) heightToSubtract += DrawerFooterNotificationHeight;
        if (props.footer) heightToSubtract += FooterHeight;
        if (props.heading) heightToSubtract += HeadingHeight;
        if (props.secondryHeading) heightToSubtract += DrawerSecondryHeadingHeight;
        if (props.padding !== undefined) heightToSubtract += (props.padding * 2);
        else heightToSubtract += (defaultPadding * 2);
        setHeight(`calc(100vh - ${heightToSubtract}px)`);
    }, [props.footerNotification, props.footer, props.padding, props.heading]);

    return (
        <Drawer
            width={props.width ? props.width : undefined}
            className='be-drawer'
            placement="right"
            onClose={() => {
                props.closeIconFunction && props.closeIconFunction();
                props.setOpen(false);
            }}
            open={props.open}
            closable={false}
        >
            <div className='drawer-container'>
                {
                    props.heading &&
                    <div className={'top-section '+ props.theme}
                        style={{ height: HeadingHeight }}
                    >
                        <span>{props.heading}</span>
                        <div className='cancle' onClick={async () => {
                            props.closeIconFunction && await props.closeIconFunction();
                            props.setOpen(false);}}>
                            <CancleIcon fill={PrimaryTheme.primaryGray} inheritSize />
                        </div>
                    </div>
                }
                {
                    props.secondryHeading &&
                    <DrawerSecondryHeading {...props.secondryHeading} />
                }

                <div className='children-body'
                    style={{
                        height: height,
                        padding: props?.padding !== undefined ? `${props?.padding.toString()}px`
                            : '21px'
                    }}
                >{props.children}</div>


                {
                    props.footerNotification &&
                    <div className='footer-notification'
                        style={{ height: DrawerFooterNotificationHeight }}
                    >
                        {
                            props.footerNotification
                        }
                    </div>
                }

                {
                    props.footer &&
                    <div className={'footer ' + props.theme}
                        style={{ height: FooterHeight }}
                    >
                        {
                            props.footer
                        }
                    </div>
                }

            </div>
        </Drawer>
    );
}
