import React from 'react'
import IconProps from './Interface/IconProps';

const ErrorIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 20}
      height={props.inheritSize ? "100%" : 20}
      viewBox="0 0 20 20"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10"
        cy="10"
        r="10"
        fill={props.fill ? props.fill : "#F04F6D"}
      />
      <path
        d="M13.1494 6.85438C12.9544 6.65938 12.6394 6.65938 12.4444 6.85438L9.99937 9.29437L7.55438 6.84937C7.35938 6.65438 7.04437 6.65438 6.84937 6.84937C6.65438 7.04437 6.65438 7.35938 6.84937 7.55438L9.29437 9.99937L6.84937 12.4444C6.65438 12.6394 6.65438 12.9544 6.84937 13.1494C7.04437 13.3444 7.35938 13.3444 7.55438 13.1494L9.99937 10.7044L12.4444 13.1494C12.6394 13.3444 12.9544 13.3444 13.1494 13.1494C13.3444 12.9544 13.3444 12.6394 13.1494 12.4444L10.7044 9.99937L13.1494 7.55438C13.3394 7.36438 13.3394 7.04438 13.1494 6.85438Z"
        fill={props.stroke ? props.stroke : "white"}
      />
    </svg>
  );
}

export default ErrorIcon