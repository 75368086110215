import * as XLSX from 'xlsx';

const DownloadTemplate = (headings: string[],name?:string) => {
  // Create a workbook and a worksheet
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet([headings]); // Add headings as the first row

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Define the file name
  const fileName = `${name?name:'Template'}.xlsx`;

  // Write and download the Excel file
  XLSX.writeFile(workbook, fileName);
};

export default DownloadTemplate;
