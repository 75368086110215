import React from 'react'
import IconProps from './Interface/IconProps';

const LabelIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 24}
      height={props.inheritSize ? "100%" : 24}
      viewBox="0 0 30 24"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.5854 10.3784L23.8804 1.82469C23.6522 1.48217 23.343 1.20129 22.9801 1.007C22.6173 0.812711 22.212 0.711013 21.8004 0.710938H3.33545C2.67241 0.710938 2.03652 0.97433 1.56768 1.44317C1.09884 1.91201 0.835449 2.5479 0.835449 3.21094V19.2109C0.835449 19.874 1.09884 20.5099 1.56768 20.9787C2.03652 21.4475 2.67241 21.7109 3.33545 21.7109H21.8004C22.212 21.7109 22.6173 21.6092 22.9801 21.4149C23.343 21.2206 23.6522 20.9397 23.8804 20.5972L29.5854 12.0434C29.7499 11.7969 29.8377 11.5073 29.8377 11.2109C29.8377 10.9146 29.7499 10.6249 29.5854 10.3784ZM21.5329 18.7109H3.83545V3.71094H21.5329L26.5329 11.2109L21.5329 18.7109Z"
        fill={props.fill ? props.fill : "white"}
      />
    </svg>
  );
}

export default LabelIcon