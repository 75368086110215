import * as React from 'react';
import EditableTable, { EditableColumnTypes } from '../../../../../../../../../../Components/BETable/ExcelLikeTable';
import { BEButton } from '../../../../../../../../../../Components/BEFormItems/BEButton';
import { useTypedSelector } from '../../../../../../../../../../Config/Hooks/useTypedSelector';
import { TemplateSelectedTopicTypes } from '../../../../../../../../../../Redux/Types/reportingTypes';
import { useAppDispatch } from '../../../../../../../../../../Config/Hooks/useAppDispatch';
import { fillReportData } from '../../../../../../../../Actions/template';
import { BEMessage } from '../../../../../../../../../../Components/BEMessage';
import { getDropDownOptionsForGRESB, postGresbEntityDataToPortal, updateAssetDetails } from '../../../../../../../../Actions/gresbActions';

export interface IType2TopicProps {
  uniqueCode: string;
  nonEditable?: boolean;
  data?: any[];
}

export function Type2Topic(props: IType2TopicProps) {
  const selectedTopics = useTypedSelector(state => state.reporting.selectedTemplateTopics);
  const currentReport = useTypedSelector(state => state.reporting.currentReport);
  const currentGRESBSection = useTypedSelector(state => state.reporting.currentGRESBSection);
  const reportData = useTypedSelector(state => state.reporting.reportData.data);
  const gresbSlugData = useTypedSelector(state => state.reporting.gresbSlugData.data);
  const dispatch = useAppDispatch();
  const [dataSource, setDataSource] = React.useState<any>([]);
  const [e, setE] = React.useState<any>([]);


  const columns: EditableColumnTypes[] = [
    {
      title: '',
      dataIndex: 'name',
      width: '30%',
      render: (text: any) => <b>{text}</b>
    },
    ...Array.from(new Set(

      props.data ? props.data.map((item: any) => item.data_label_2) : // For Gresb only

        selectedTopics.data.filter(
          (item1: TemplateSelectedTopicTypes) => props.uniqueCode === item1.unique_code)
          .map((item2: TemplateSelectedTopicTypes) => item2.data_label_2)

    ))
      // .sort((a: string, b: string) => a.localeCompare(b))
      .map((item3: string) => {
        const isDropDown = props.data ? props.data.filter((item4: any) => item4.data_label_2 === item3)[0]?.response_type.includes('<') ? true : false : false; // For Gresb only
        const isDate = props.data ? props.data.filter((item4: any) => item4.data_label_2 === item3)[0]?.response_type === 'Date' ? true : false : false; // For Gresb only
        return {
          title:
            <p className='table-title'>
              {item3 + (props.data ?
                (props.data.filter((item4: any) => item4.data_label_2 === item3)[0]?.unit !== 'nan' ?
                  ' (' + props.data.filter((item4: any) => item4.data_label_2 === item3)[0]?.unit + ')' : '') :
                ' (' + selectedTopics.data.filter((item4: TemplateSelectedTopicTypes) => props.uniqueCode === item4.unique_code
                  && item4.data_label_2 === item3)[0!]?.unit + ')'

              )}
            </p>,
          dataIndex: item3,
          rules: {
            isDropDown: isDropDown,
            isNumber: selectedTopics.data.filter((item4: TemplateSelectedTopicTypes) => props.uniqueCode === item4.unique_code && item4.data_label_2 === item3)[0!]?.unit !== 'Description' ? true : false,
            dropDownOptions: props.data ? getDropDownOptionsForGRESB(props.data.find((item4: any) => item4.data_label_2 === item3), gresbSlugData) : [],
            isDate: isDate
            // isRequired: true
          },
          editable: props.nonEditable ? false : true,
        }
      })
  ]

  React.useEffect(() => {
    let allUniqueDataLable2 = Array.from(new Set(

      props.data ? props.data.map((item: any) => item.data_label_2) : // For Gresb only 
        selectedTopics.data.filter((item1: TemplateSelectedTopicTypes) => props.uniqueCode === item1.unique_code).map((item2: TemplateSelectedTopicTypes) => item2.data_label_2)

    ))
    // .sort((a: string, b: string) => a.localeCompare(b));
    let allUniqueAccountingMetric = Array.from(new Set(

      props.data ? props.data.map((item: any) => item.accounting_metric) : // For Gresb only
        selectedTopics.data.filter((item1: TemplateSelectedTopicTypes) => props.uniqueCode === item1.unique_code).map((item2: TemplateSelectedTopicTypes) => item2.accounting_metric)

    ))
    let tempData: any = [];
    let errorData: any = [];
    allUniqueAccountingMetric.forEach((accountingMetric: string) => {
      let tempObj: any = { name: accountingMetric };
      let errorObject: any = { name: accountingMetric };
      allUniqueDataLable2.forEach((dataLabel2: string) => {

        let topicID = null;

        // for gresb data
        if (props.data) {
          topicID = props.data.filter((item1: any) => item1.data_label_2 === dataLabel2 && item1.accounting_metric === accountingMetric)[0]?.id;
        } else {
          topicID = selectedTopics.data.filter((item1: TemplateSelectedTopicTypes) => props.uniqueCode === item1.unique_code && item1.data_label_2 === dataLabel2 && item1.accounting_metric === accountingMetric)[0]?.id;
        }
        if (topicID) {
          if (reportData[topicID]) {
            console.log(reportData[topicID][0]?.additional_information);
            tempObj[dataLabel2] = reportData[topicID][0]?.data;
            if (props.data)
              errorObject[dataLabel2] = reportData[topicID][0]?.additional_information;
          } else {
            tempObj[dataLabel2] = '';
            if (props.data)
              errorObject[dataLabel2] = '';
          }
        }
        else {
          tempObj[dataLabel2] = 'N/A';
        }
        // set value here

      });
      tempData.push(tempObj);
      errorData.push(errorObject);
    });
    setDataSource(tempData);
    setE(errorData);
  }, [selectedTopics, reportData, props.uniqueCode]);

  const handleSave = (row: any, dataIndex: string) => {
    // write  save logic here
    console.log(row[dataIndex], row.name);

    let topicID: any = null;
    // for gresb data
    if (props.data) {
      topicID = props.data.filter((item1: any) => item1.data_label_2 === dataIndex && item1.accounting_metric === row.name)[0]?.id;
    } else {
      topicID = selectedTopics.data.filter((item1: TemplateSelectedTopicTypes) => props.uniqueCode === item1.unique_code && item1.data_label_2 === dataIndex && item1.accounting_metric === row.name)[0]?.id;
    }

    // const topicID = selectedTopics.data.filter((item1: TemplateSelectedTopicTypes) => props.uniqueCode === item1.unique_code && item1.data_label_2 === dataIndex && item1.accounting_metric === row.name)[0]?.id;

    console.log(topicID);
    if (!topicID) {
      BEMessage.error('This topic is not applicable for this template. Please contact admin.')
      return true;
    }
    dispatch(fillReportData({
      data: row[dataIndex],
      topic: topicID,
      esg_report: currentReport?.id,
    })).then(async () => {
      if (props.data && currentGRESBSection === 'entity') {
        const variableCode = props.data.find((item1: any) => item1.data_label_2 === dataIndex && item1.accounting_metric === row.name)?.variable_code;
        if (!variableCode) {
          return;
        }
        const portalDataUpdated = await dispatch(postGresbEntityDataToPortal(variableCode, String(row[dataIndex])));
      }
      if (currentGRESBSection === 'asset' && props.data) {
        await dispatch(updateAssetDetails(topicID, row[dataIndex]));
      }
      return true;
    })
  }

  return (
      <div>
        <EditableTable
          errorRecords={props.data ? e : []}
          data={dataSource}
          columns={columns}
          handleSave={handleSave}
        />
      </div>
    );
  }
