import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { LableRequired } from '../../../../../Components/BEFormItems/LableRequired';
import { UserDependents } from '../../../../../Config/Data/genralData';
import BEMultipleSelect from '../../../../../Components/BEMultipleSelect';
import { BEEyeButton } from '../../../../../Components/BEEyeButton';
import { ColumnsType } from 'antd/es/table';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import { BETable } from '../../../../../Components/BETable';
import { BEMessage } from '../../../../../Components/BEMessage';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { deleteAssignedDepartmentByCategory, postAssignedDepartmentByCategory } from '../../../Actions';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { GlobalLoader } from '../../../../../Components/GlobalLoader';
import CrossIcon from '../../../../../Components/BEIcons/CrossIcon';
import BEConfirmModal from '../../../../../Components/BEConfirmModal';

export interface IAssignDepartmentDrawerProps {
    open: boolean;
    setOpen: Function;
}

export function AssignDepartmentDrawer(props: IAssignDepartmentDrawerProps) {
    const [loading, setLoading] = React.useState(false);
    const [tableData, setTableData] = React.useState<any[]>([]);
    const currentGroup = useTypedSelector(state => state.onBoarding.metrics.currentGroup);
    const assignedDepartmentByCategory = useTypedSelector(state => state.dataManager.assignedDepartmentByCategory);
    const [openDelete, setOpenDelete] = React.useState<boolean>(false);
    const [currentDepartment, setCurrentDepartment] = React.useState<any>(null);
    const dispatch = useAppDispatch();
    const columns: ColumnsType<any> = [
        {
            title: 'Names',
            dataIndex: 'department'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (text, record) => <>
                <div
                    onMouseEnter={() => setCurrentDepartment(record)}
                    style={{ color: PrimaryTheme.primaryGray, cursor: "pointer", height: "1rem", width: "1rem" }}
                    onClick={() => setOpenDelete(true)}
                ><CrossIcon inheritSize stroke={PrimaryTheme.primaryGray} />
                </div>
            </>
        }
    ];

    React.useEffect(() => {
        const data = assignedDepartmentByCategory.data.filter((item) => item.category === currentGroup);
        setTableData(data);
    }, [assignedDepartmentByCategory]);

    const handleAddDepartment = async (value: any) => {
        setLoading(true);
        if (tableData.length >= 3) {
            BEMessage.error("You can add maximum of 3 departments for a single category.");
            setLoading(false);
            return;
        } else {
            let data = [];
            data.push({
                category: currentGroup,
                department: value
            });
            await dispatch(postAssignedDepartmentByCategory(data));
        }
        setLoading(false);
    }

    return (
        <BEDrawer
            open={props.open}
            setOpen={props.setOpen}
            heading={"Assign Department"}
            width='450'
            footer={
                <div>
                    <BEButton
                        size="large"
                        className="primary"
                        onClick={() => {
                            props.setOpen(false);
                        }}
                    >
                        Save and close
                    </BEButton>
                    <BEButton
                        style={{ marginLeft: "0.5rem" }}
                        size="large"
                        onClick={() => {
                            props.setOpen(false);
                        }}
                    >
                        Cancel
                    </BEButton>
                </div>
            }
        >
            <div style={{ fontSize: "0.8rem" }}>

                <LableRequired>
                    Select department
                </LableRequired>
                <BEMultipleSelect single isDataStrArray data={UserDependents.filter((dept) => tableData.find((item) => item.department === dept) === undefined)} name="department"
                    onChange={(value: any) => {
                        handleAddDepartment(value);
                    }}
                />

                <span style={{ fontSize: "0.8rem", display: "flex", flexDirection: "row", gap: "0.5rem", marginBottom: "1rem" }}>
                    <BEEyeButton title={undefined} discription={undefined}>
                    </BEEyeButton>
                    <i>You can add maximum of 3 departments</i>
                </span>
                {loading ?
                    <GlobalLoader height='20vh' />
                    :
                    <BETable
                        data={tableData}
                        columns={columns}
                    />
                }
            </div>

            <BEConfirmModal
                title="Confirm Delete"
                message="Are you sure you want to unassign the department?"
                visible={openDelete}
                setVisible={setOpenDelete}
                onConfirm={async () => {
                    setLoading(true);
                    await dispatch(deleteAssignedDepartmentByCategory(currentDepartment.id));
                    setLoading(false);
                }}
            />

        </BEDrawer>
    );
}
