import React, { useEffect, useState } from 'react'
import { BEDrawer } from '../../../../../Components/BEDrawer'
import { BETable } from '../../../../../Components/BETable';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { ColumnsType } from 'antd/es/table';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { addSupplierAssessmentRef } from '../../../Actions/supplierAction';

interface InviteSupplierProps {
    open: boolean,
    setOpen: Function
}

const InviteSuppliers = (props: InviteSupplierProps) => {
    const dispatch = useAppDispatch()
    const allSuppliers = useTypedSelector((state) => state.supplier.Suppliers);
    const currentAssessment = useTypedSelector((state) => state.supplier.currentAssessment)
    const [selectedSuppliers, setSelectedSuppliers] = useState<any>([]);
    const [loading, setLoading] = useState(false);


    const columns: ColumnsType<any> = [
      {
        title: "SUPPLIER NAME",
        dataIndex: "name",
      },
      {
        title: "POC",
        dataIndex: "poc",
      },
      {
        title: "POC EMAIL",
        dataIndex: "email",
      },
      {
        title: "INDUSTRY",
        dataIndex: "industry",
      },
      {
        title: "LAST COMPLIANCE",
        dataIndex: "last_compliance_fy",
        render: (value) => {
          return !value ? "-" : value;
        },
      },
    ];

    const handleAddSuppliers = async () => {
      setLoading(true);
      const body: Array<any> = [];
      selectedSuppliers.forEach((supplier: any) => {
        body.push({
          supplier: supplier,
          assessment: currentAssessment?.id,
        });
      });
      // console.log(body)
      await dispatch(addSupplierAssessmentRef(body));
      setLoading(false);
      props.setOpen(false)
    };
  return (
    <BEDrawer
      heading="Invite Suppliers to assessment"
      open={props.open}
      setOpen={props.setOpen}
      width="60vw"
      footer={
        <>
          <BEButton
            className="primary"
            size="large"
            onClick={handleAddSuppliers}
            loading={loading}
          >
            Add Supplier
          </BEButton>
          <BEButton size="large" onClick={() => props.setOpen(false)}>
            Cancel
          </BEButton>
        </>
      }
    >
      <div style={{ margin: "1rem auto" }}>
        <BETable
          data={allSuppliers.data}
          columns={columns}
          loading={allSuppliers.status === "loading"}
          pagination
          handleRowSelection={(keys: any) => {
            setSelectedSuppliers(keys);
          }}
          rowKey="id"
        />
      </div>
    </BEDrawer>
  );
}

export default InviteSuppliers