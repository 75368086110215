import React, { useEffect, RefObject } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideClick(ref: RefObject<HTMLElement>, handleClickOutsideFun:Function) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handleClickOutsideFun();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]); // Dependency array, re-run the effect if ref changes
}

export default useOutsideClick;
