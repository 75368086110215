import React from "react";
import { IStep1Props, Step1 } from "../Steps/Step1";
import { BEDrawer } from "../../../../../Components/BEDrawer";

const EditAssessment = (props: IStep1Props) => {
  return (
    <BEDrawer
      heading="Edit Assessment"
      open={props.open}
      setOpen={props.setOpen!}
    >
      <Step1
        open={props.open}
        setOpen={props.setOpen}
        onCancle={props.onCancle}
        isEdit
        currentStakeholder={props.currentStakeholder}
      />
    </BEDrawer>
  );
};

export default EditAssessment;
