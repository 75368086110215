import type { StatusType } from "../Types/userTypes";
import { SuppliersState } from "../Types/suppliersTypes";

import {
    Reducer,
    createSlice,
} from "@reduxjs/toolkit";
import { clearStore } from "../ExtraReducrActions";


const initialState: SuppliersState = {
    SuppliersSurveys: {
        status: "idle",
        data: [],
    },
    Suppliers: {
        status: "idle",
        data: [],
    },
    SuppliersSurveyRefs: {
        status: "idle",
        data: [],
    },
    SupplierOptions: {
        status: "idle",
        data: [],
    },
    SupplierQuestions: {
        status: "idle",
        data: [],
    },
    SupplierAnswers: {
        status: "idle",
        data: [],
    },
    SupplierStatus: {
        status: "idle",
        data: [],
    },
    SupplierResults: {
        status: "idle",
        data: [],
        average: null,
        percentage: null,
    },
  currentAssessment: null,
  createSurveyStep: 1,
  SuppliersRiskMetrics: {
      status: "idle",
      data: []
  },
  AssessmentStatus: null,
  CoSuppliers: {
    status: "idle",
    data: [],
  },
  CurrentSection: '',
  AssessmentYear: new Date().getFullYear(),
  SupplierScore: {
    status: "idle",
    data: [],
  },
  allAnswers: {
    status: "idle",
    data: [],
  },
  lastAddedSupplier: null
}
export const userSlice = createSlice({
    name: "user",
    initialState,
    extraReducers: (builder) => builder.addCase(clearStore, () => initialState),
    reducers: {
      setSuppliersSurveys: (state, action) => {
        state.SuppliersSurveys = action.payload;
      },
      setSuppliers: (state, action) => {
        state.Suppliers = action.payload;
      },
      setSuppliersSurveyRefs: (state, action) => {
        state.SuppliersSurveyRefs = action.payload;
      },
      setSupplierOptions: (state, action) => {
        state.SupplierOptions = action.payload;
      },
      setSupplierQuestions: (state, action) => {
        state.SupplierQuestions = action.payload;
      },
      setSupplierAnswers: (state, action) => {
        state.SupplierAnswers = action.payload;
      },
      updateSupplierAnswers: (state, action) => {
        state.SupplierAnswers.data = {
          ...state.SupplierAnswers.data,
          ...action.payload
        }
      },
      setSupplierStatus: (state, action) => {
        state.SupplierStatus = action.payload;
      },
      setSupplierResults: (state, action) => {
        state.SupplierResults = action.payload;
      },
      setCurrentAssessment: (state, action) => {
        state.currentAssessment = action.payload;
      },
      setCreateSurveyStep: (state, action) => {
        state.createSurveyStep = action.payload
      },
      setSuppliersRiskMetrics: (state, action) => {
        state.SuppliersRiskMetrics = action.payload;
      },
      setAssessmentStatus: (state, action) => {
        state.AssessmentStatus = action.payload;
      },
      setCoSuppliers: (state, action) => {
        state.CoSuppliers = action.payload;
      },
      setCurrentSection: (state, action) => {
        state.CurrentSection = action.payload;
      },
      setAssessmentYear: (state, action) => {
        state.AssessmentYear = action.payload;
      },
      setSupplierScore: (state, action) => {
        state.SupplierScore = action.payload;
      },
      setAllAnswers: (state, action) => {
        state.allAnswers = action.payload;
      },
      setLastAddedSupplier: (state, action) => {
        state.lastAddedSupplier = action.payload
      }
    }
});

export const {
  setSuppliersSurveys,
  setSuppliers,
  setSuppliersSurveyRefs,
  setSupplierOptions,
  setSupplierQuestions,
  setSupplierAnswers,
  setSupplierStatus,
  setSupplierResults,
  setCurrentAssessment,
  setCreateSurveyStep,
  setSuppliersRiskMetrics,
  setAssessmentStatus,
  setCoSuppliers,
  setCurrentSection,
  setAssessmentYear,
  updateSupplierAnswers,
  setSupplierScore,
  setAllAnswers,
  setLastAddedSupplier,
} = userSlice.actions;

const supplierReducer: Reducer<SuppliersState> = userSlice.reducer;

export default supplierReducer;
