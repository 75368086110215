import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useAppDispatch } from "../Config/Hooks/useAppDispatch";
import { useTypedSelector } from "../Config/Hooks/useTypedSelector";
import useScrollToTop from "../Config/Hooks/scrollToTop";
import { GlobalLoader } from "../Components/GlobalLoader";
import { Suspense, useState } from "react";
import PrivateRoute from "../Config/PrivateRoute";
import { Login } from "../views/Login";
import { useEffect } from "react";
import { AutoLogin } from "../Config/Functions/Autologin";
import { setNavigateFunction } from "./NavigationManager";
import { useNavigate, useLocation } from "react-router-dom";
import { ChooseCompany } from "../views/Login/ChooseCompany";
import { OnboardingView } from "../views/Onboarding/OnboardingView";
import Step1 from "../views/Onboarding/Step1";
import { Step2 } from "../views/Onboarding/Step2";
import Step3 from "../views/Onboarding/Step3";
import HomeLayout from "../views/Home/Layout";
import { ReviewOnboarding } from "../views/Onboarding/ReviewOnboarding";
import EntityManager from "../Features/EntityManager";
import { AllMatirialityAssessMents } from "../Features/Materiality/Views/AllAssessments";
import { MatirialityAssessMentLayout } from "../Features/Materiality/Components/MatirialityLayout";
import { MatirialityViewResults } from "../Features/Materiality/Views/MatirialityViewResults";
import { AmazonDashboard } from "../Features/Dashboard/Views/AmazonDashboard";

// supplier routes
import { AssessmentsDetails } from "../Features/Suppliers/Views/AssessmentsDetails";
import { AllSuppliers } from "../Features/Suppliers/Views/AllSuppliers";
import { SupplierResult } from "../Features/Suppliers/Views/SupplierResults";
import { AllAsessments } from "../Features/Suppliers/Views/AllAssessments/AllAssessments";

// internal assessment routes
import { InternalAssessmentsDetails } from "../Features/InternalAssessment/Views/AssessmentsDetails";
import { AllInternalSuppliers } from "../Features/InternalAssessment/Views/AllSuppliers";
import { AllInternalAsessments } from "../Features/InternalAssessment/Views/AllAssessments/AllAssessments";
import { InternalAssessmentResult } from "../Features/InternalAssessment/Views/SupplierResults";
import Internal from "../Features/InternalAssessment";
import { MainInternalForm } from "../Features/InternalAssessment/Views/SupplierForm/MainSupplierForm";

import {
  DataManager,
  Analytics,
  Reporting,
  Suppliers,
  Targets,
  Settings,
} from "../Features";
import { Step4 } from "../views/Onboarding/Step4";
import AssessmentDetails from "../Features/Materiality/Views/AssessmentDetails";
import { MainSupplierForm } from "../Features/Suppliers/Views/SupplierForm/MainSupplierForm";
import { CosupplierFillAssessment } from "../Features/Suppliers/Views/SupplierForm/CoSupplierFillAssessment";
import Dashboard from "../Features/Dashboard/Views/Dashboard";
import DashboardLayout from "../Features/Dashboard/Components/DashboardLayout";
import ReportingLayout from "../Features/Reporting/Components/ReportingLayout";
import AllTemplates from "../Features/Reporting/Views/AllTemplates";
import AllReports from "../Features/Reporting/Views/AllReports";
import SurveyResponses from "../Features/Materiality/Views/SurveyResponse";
import { AllStakeholders } from "../Features/Materiality/Views/AllStakeHolders";
import EmissionsLayout from "../Features/Emissions/Components/EmissionsLayout";
import Overview from "../Features/Emissions/Views/Overview";
import Configurations from "../Features/Emissions/Views/Configurations";
import { TargetsLayout } from "../Features/Targets/Components/TargetsLayout";
import { TargetsTable } from "../Features/Targets/Views/TargetsTable";
import { LimitsTable } from "../Features/Targets/Views/LimitsTable";
import { TestComponent } from "../views/Test";

// vault routes
import { AllVaultFolders } from "../Features/Vault/Views/AllFolders";
import { FYFolders } from "../Features/Vault/Views/AllFolders/SystemFolders/FYFolders";
import { MetricFolder } from "../Features/Vault/Views/AllFolders/SystemFolders/FYFolders/MetricFolder";
import { FolderContent } from "../Features/Vault/Views/AllFolders/FolderContent";
import { VaultLayout } from "../Features/Vault/Components/VaultLayout";
import { ApproveReport } from "../Features/Reporting/Views/ApproveReport";
import { NoPermission } from "../views/Miscellaneous/NoPermission";
import { getUserPermissions } from "../views/Actions/HomeActions";
import { setIsLoggedIn } from "../Redux/UserReducer";
import Users from "../Features/Settings/Users";
import Notifications from "../Features/Settings/Notifications";
import { Logs } from "../Features/Settings/Logs";
import { Permissions } from "../Features/Settings/Permissions";
import GeneralSettings from "../Features/Settings/GeneralSettings";
import { Labels } from "../Features/Settings/Labels";
import { DataDownload } from "../Features/Settings/DataDownload";
import RadioList from "../Features/Reporting/Components/GresbComponents/RadioList";
import { CheckboxList } from "../Features/Reporting/Components/GresbComponents/CheckboxList";
import { BoschPOC } from "../Features/BoschDashboard/Views/BoschPOC";

function RootRoutes() {
  const userPermissions = useTypedSelector((state) => state.user.permissions);
  const [permissions, setPermissions] = useState<any>({});
  const isLoggedIn = useTypedSelector((state) => state.user.isLoggedIn);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(AutoLogin(navigate, location));
    setNavigateFunction(navigate);
  }, []);

  useScrollToTop();

  useEffect(() => {isLoggedIn && dispatch(getUserPermissions())}, [isLoggedIn]);
  
  useEffect(() => {
      if(userPermissions.status==="success"){
        let temp:any = {};
        for(let i=0;i<9;i++) temp[i+1] = [];
        // 1: Dashboard, 2: DataManager, 3: EntityManager, 4: Reporting, 5: Emmisions, 6: Targets, 7: Surveys,8: Settings 9:Configuration
        userPermissions.data.forEach((permission:any)=>{
          if(permission.permission) temp[permission.section].push(permission.role);
        });
        setPermissions(temp);
      }
  }, [userPermissions]);
  
  if(Object.keys(permissions).length===0 && isLoggedIn) return <GlobalLoader />;

  return (

    //fallback page to be added
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" />
        <Route path="/login" element={<Login />} />

        {/********* ESG_CONSULTANT routes ***********/}
        <Route path="/choose-company" element={<PrivateRoute roles={["ESG_CONSULTANT"]}><ChooseCompany /></PrivateRoute>} />

        {/* onboarding routes ************************/}
        <Route
          path="/onboarding"
          element={
            <PrivateRoute roles={["SUPER_ADMIN"]}>
              <OnboardingView />
            </PrivateRoute>
          }
        >
          <Route path="step1" element={<Step1 />} />
          <Route path="step2" element={<Step3 />} />
          <Route path="step3" element={<Step2 />} />
          <Route path="step4" element={<Step4 />} />
          <Route path="step5" element={<ReviewOnboarding />} />
        </Route>

        {/* redirect routes ********************** use this to rediret if not logged in **/}


        <Route path="approve-report/:report_id" element={<PrivateRoute roles={permissions[4]}><ApproveReport /></PrivateRoute>} />

        {/* results routes ********************************/}
        <Route path='/results'>
          <Route path="supplier/:assessment_id" element={<PrivateRoute roles={permissions[7]}><SupplierResult /></PrivateRoute>} />
          <Route path="internal/:assessment_id" element={<PrivateRoute roles={permissions[7]}><InternalAssessmentResult /></PrivateRoute>} />
        </Route>

        {/* home routes ************************/}
        <Route path="radio-list" element={<RadioList data={[]} />} />
        <Route path="/checkbox-list" element={<CheckboxList data={[]} />} />

        <Route path="/home" element={<PrivateRoute onlyForLoggedIn><HomeLayout /></PrivateRoute>}>
          
        <Route path="dashboard" element={<BoschPOC/>}/>
          {/* <Route >
            <Route path="dashboard" element={<DashboardLayout />}>
              <Route path="" element={<Dashboard />} />
              <Route path="analytics" element={<AmazonDashboard />} />
            </Route>
          </Route> */}

          <Route>
            <Route path="data-manager" element={<DataManager />} />
          </Route>

          <Route element={<PrivateRoute roles={permissions[3]} />}>
            <Route path="entity-manager" element={<EntityManager />} />
          </Route>

          {/* Targets routes ************************/}

          <Route element={<PrivateRoute roles={permissions[6]} />}>
            <Route path="targets" element={<TargetsLayout />}>
              <Route path="">
                <Route path="" element={<TargetsTable />} />
              </Route>
              <Route path="limits" element={<LimitsTable />} />
            </Route>
          </Route>

          {/* reporting routes ************************/}
          <Route element={<PrivateRoute roles={permissions[4]} />}>
            <Route path="reporting" element={<ReportingLayout />}>
              <Route path="templates" element={<AllTemplates />} />
              <Route path="reports" element={<AllReports />} />
            </Route>
          </Route>

          {/* Emissions routes *************************/}
          <Route element={<PrivateRoute roles={permissions[5]} />}>
            <Route path="emissions" element={<EmissionsLayout />}>
              <Route path="" element={<Overview />} />
              <Route path="configurations" element={<Configurations />} />
            </Route>
          </Route>

          {/* matiriality routes ************************/}

          <Route element={<PrivateRoute roles={permissions[7]} />}>
            <Route path="materiality" element={<MatirialityAssessMentLayout />}>
              <Route path="assessments">
                <Route path="" element={<AllMatirialityAssessMents />} />
                <Route path=":assessment_id">
                  <Route path="" element={<AssessmentDetails />} />
                  <Route path="result" element={<MatirialityViewResults />} />
                </Route>
              </Route>
              <Route path="stakeholders" element={<AllStakeholders />} />
            </Route>
          </Route>

          {/* supplier routes ************************/}

          <Route element={<PrivateRoute roles={permissions[7]} />}>
            <Route path="suppliers" element={<Suppliers />}>
              <Route path="assessments">
                <Route path="" element={<AllAsessments />} />
                <Route path=":assessment_id" element={<AssessmentsDetails />} />
              </Route>
              <Route path="all-suppliers" element={<AllSuppliers />} />
            </Route>
          </Route>

          {/* <Route path="analytics" element={<Analytics />} /> */}

          <Route element={<PrivateRoute roles={permissions[8]} />}>
            <Route path="settings" element={<Settings />} >
              {/* vault routes ************************/}
              <Route path="vault" element={<VaultLayout />}>
                <Route path="" element={<AllVaultFolders />} />             {/*if the path is empty, it will render the AllVaultFolders component*/}
                <Route path=":folder_id" >
                  <Route path="" element={<FolderContent />} />             {/*if no fy id, we will render User Folder or system folder*/}
                  <Route path=":fy_id" >
                    <Route path="" element={<FYFolders />} />               {/*if no metric id , it will render the FYFolders component*/}
                    <Route path=":metric_id" element={<MetricFolder />} />  {/* it will render the MetricFolder component*/}
                  </Route>
                </Route>
              </Route>
              <Route path="users" element={<Users />} />
              <Route path="logs" element={<Logs />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="permissions" element={<Permissions />} />
              <Route path="general-settings" element={<GeneralSettings />} />
              <Route path="labels" element={<Labels />} />
              <Route path="data-download" element={<DataDownload />} />
            </Route>
          </Route>



          {/* internal assessment routes ************************/}
          {/* <Route path="internal" element={<Internal />}>
            <Route path="assessments">
              <Route path="" element={<AllInternalAsessments />} />
              <Route path=":assessment_id" element={<InternalAssessmentsDetails />} />
            </Route>
            <Route path="all-suppliers" element={<AllInternalSuppliers />} />
          </Route> */}

          {/* ***********************************/}
        </Route>

        {/* ***************open routes***************/}

        <Route
          path="/brsr-supplier-assessment"
          element={<MainSupplierForm />}
        />
        <Route
          path="/brsr-core-section"
          element={<CosupplierFillAssessment />}
        />
        <Route path="/internal-assessment" element={<MainInternalForm />} />
        <Route path="/survey-responses" element={<SurveyResponses />} />
        <Route path="/test" element={<TestComponent />} />
        {/* ***********************************/}
        <Route path="/no-permission" element={<NoPermission />} />
        <Route path="*" element={<NoPermission is404 />} />
      </Routes>
    </Suspense>
  );
}

export default RootRoutes;
