import { BETabButton } from "../../../../Components/BETabButton";
import { PrimaryTheme } from "../../../../Config/Theme/theames";
import UsersIcon from "../../../../Components/BEIcons/UsersIcon";
import DataEntryIcon from "../../../../Components/BEIcons/DataEntryIcon";
import { useAppDispatch } from "../../../../Config/Hooks/useAppDispatch";
import { useTypedSelector } from "../../../../Config/Hooks/useTypedSelector";
import { useLocation, useNavigate } from "react-router-dom";
import { setSelectedYear } from "../../../../Redux/ReportingReducer";
import { YearSelectOptions } from "../../../../Components/BEMultipleSelect/YearSelectOptions";
import { BEButton } from "../../../../Components/BEFormItems/BEButton";
import AddIcon from "../../../../Components/BEIcons/AddIcon";
import AddNewReport from "../../Views/Drawers/AddReport";
import React from "react";
import { setDateSlicerYearDM } from "../../../../Redux/DataManagerReducer";

const TopSection = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [openAddNewReport, setOpenAddNewReport] = React.useState(false)
  const selectedYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);


  return (
    <div className="global-top-section">

      <BETabButton
        title="REPORTS"
        icon={
          <UsersIcon
            fill={
              location.pathname.includes("reports")
                ? PrimaryTheme.primaryGreen
                : PrimaryTheme.primaryGray
            }
          />
        }
        active={location.pathname.includes("reports")}
        onClick={() => navigate("/home/reporting/reports")}
      />
            {/* <BETabButton
        title="TEMPLATES"
        icon={
          <DataEntryIcon
            stroke={
              // PrimaryTheme.primaryGreen
              location.pathname.includes("templates")
                ? PrimaryTheme.primaryGreen
                : PrimaryTheme.primaryGray
            }
          />
        }
        active={location.pathname.includes("templates")}
        onClick={() => navigate("/home/reporting/templates")}
      /> */}
     {
      !location.pathname.includes("templates") &&
      <>
      <div
        className="year"
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "auto",
          gap: "0.5rem",
          fontWeight: 600,
        }}
      >
        Showing Report for:
       <YearSelectOptions
        onChange={(value: number) => dispatch(setDateSlicerYearDM(value))}
        value={selectedYear}
      />
      </div>
      <BEButton
      size="large"
      className="primary"
      onClick={() => setOpenAddNewReport(true)
      }>
       + Add new report
      </BEButton>
      </>
      }
      <AddNewReport open={openAddNewReport} setOpen={setOpenAddNewReport} />
    </div>
  );
};

export default TopSection;
