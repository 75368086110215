import * as React from 'react';
import { useParams } from 'react-router-dom';
import { SystemFolder } from './SystemFolders';
import { UsersFolder } from './UsersFolder';

export interface IFolderContentProps {
}

export function FolderContent (props: IFolderContentProps) {
  const params = useParams();
  return (
    <div>
        {
          Number(params.folder_id)=== 0? <SystemFolder/> : <UsersFolder/>
        }
    </div>
  );
}
