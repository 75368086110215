import { BEDrawer } from '../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { BEInput } from '../../../../../Components/BEFormItems/BEInput';
import { LableRequired } from '../../../../../Components/BEFormItems/LableRequired';
import { Col, Form, Row, Select } from 'antd';
import { selectedMetricType } from '../../../../../Redux/Types/dataManagerTypes';
import React from 'react';
import { updateTarget } from '../../../Actions/targetAction';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';

export interface ISetupTargetsProps {
    open: boolean;
    setOpen: Function;
    targetData: selectedMetricType;
}

export function SetupTargets(props: ISetupTargetsProps) {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = React.useState(false);
    const [targetType, setTargetType] = React.useState(props.targetData.target_type);
    const [form] = Form.useForm();
    const onCancel = () => {
        props.setOpen(false)
    }

    const options = [
        { value: 'A', label: 'absolute increase' },
        { value: 'B', label: 'absolute decrease' },
        { value: 'C', label: '% increase' },
        { value: 'D', label: '% decrease' }
    ];

    const handleChange = (value: any) => {
        !value ? setTargetType('A') : setTargetType(value);
    }

    const handleForm = async (values: any) => {
        const body = {
            baseline_value: values.baselineValue,
            target_value: values.targetValue,
            status: 1,
            target_type: targetType
        };
        setLoading(true);
        await dispatch(updateTarget(props.targetData.id, body));
        setLoading(false);
        onCancel()
    }

    React.useEffect(() => {
        form.resetFields(["baselineValue", "targetValue"]);
        setTargetType(props.targetData.target_type);
        if (props.targetData.status || props.targetData.target_value !== "-1.00") {
            form.setFieldsValue({
                targetValue: props.targetData.target_value,
                baselineValue: props.targetData.baseline_value
            })
        }
    }, [props.targetData]);

    return (
        <BEDrawer
            open={props.open}
            setOpen={props.setOpen}
            heading='Setup targets'
            width='fit-content'
            footer={
                <>
                    <BEButton className='primary' size='large'
                        loading={loading}
                        onClick={() => {
                            form.submit()
                        }}
                    >Set Target</BEButton>
                    <BEButton size='large'
                        onClick={onCancel}
                    >Cancel</BEButton>
                </>
            }
        >
            <div className='add-materiality-assessment'>
                <Row className='setupHead' align="middle">
                    <Col span={11} offset={2}>
                        <b>Metric Name</b>
                    </Col>
                    <Col span={11}>
                        {props.targetData.title}
                    </Col>
                </Row>
                <Form
                    form={form}
                    onFinish={handleForm}
                >
                    <Row>
                        {(targetType === 'C' || targetType === 'D') &&
                            <Col span={11}>
                                <Row>
                                    <LableRequired>Baseline value</LableRequired>
                                </Row>
                                <Row>
                                    <Form.Item
                                        name="baselineValue"
                                        rules={[{ required: true, message: "Please enter value" }]}
                                    >
                                        <BEInput
                                            style={{ height: "40px" }}
                                        />
                                    </Form.Item>
                                </Row>
                            </Col>
                        }
                    </Row>
                    <Row>
                        <Col span={11}>
                            <Row>
                                <LableRequired>Target Type</LableRequired>
                            </Row>
                            <Row>
                                <Select
                                    style={{ width: "100%" }}
                                    size='large'
                                    value={targetType}
                                    defaultValue={props.targetData.target_type}
                                    onChange={handleChange}
                                    options={options}
                                />
                            </Row>
                        </Col>
                        <Col offset={2} span={11}>
                            <Row>
                                <LableRequired>{(targetType === 'C' || targetType === 'D') ? "Target %": "Target value"}</LableRequired>
                            </Row>
                            <Row>
                                <Form.Item
                                    name="targetValue"
                                    rules={[{ required: true, message: "Please enter value" }]}
                                >
                                    <BEInput
                                        style={{ height: "40px" }}
                                    />
                                </Form.Item>
                            </Row>
                        </Col>
                    </Row>

                </Form>

            </div >
        </BEDrawer >
    );
}

