import { Card } from 'antd'
import React, { useState } from 'react'
import { PrimaryTheme } from '../../../../Config/Theme/theames'
import Scope1Table from './Scope1Table';
import Scope2Table from './Scope2Table';
import { getScope1Configurations, getScope2Configurations } from '../../Actions';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import EditConversionFactors from './EditConversionFactors';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import InfoIcon from '../../../../Components/BEIcons/InfoIcon';

const Configurations = () => {
  const dispatch = useAppDispatch()
  const scope1Configurations = useTypedSelector(state => state.emissions.configurations.scope1Configurations)
  const scope2Configurations = useTypedSelector(state => state.emissions.configurations.scope2Configurations)

  React.useEffect(() => {
    dispatch(getScope1Configurations());
    dispatch(getScope2Configurations());
  }, []);

  return (
    <div style={{ padding: "1rem 0" }}>

      <div style={{ margin: "1rem 0" }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <p style={{ fontSize: "1.2rem", fontWeight: 700 }}>Scope 1 </p>
        </div>
        <Card style={{ background: PrimaryTheme.primaryGreyLight,marginTop:'1rem' }}>
          <div style={{display:'flex',gap:'1rem'}}>
           <div style={{height:'2.5rem'}}> <InfoIcon fill={PrimaryTheme.primaryGray} inheritSize/></div>
            <div>
              <p>
                Scope 1 emissions primarily arise from the combustion of fuel (on-site & in owned vehicles) and usage of refrigerants in air conditioning/refrigerators. The below table can be used to view conversion factor data used for Scope 1 emission calculations.
              </p>
            </div>
          </div>
        </Card>
        <Scope1Table />
      </div>

      <div style={{ margin: "1rem 0" }}>
        <p style={{ fontSize: "1.2rem", fontWeight: 700 }}>Scope 2</p>
        <Card style={{ background: PrimaryTheme.primaryGreyLight,marginTop:'1rem' }}>
          <div style={{display:'flex',gap:'1rem'}}>
           <div style={{height:'2.5rem'}}> <InfoIcon fill={PrimaryTheme.primaryGray} inheritSize/></div>
            <div>
              <p>
              Scope 2 emissions are indirect GHG emissions associated with the purchase of electricity & other forms of energy (e.g., steam, cooling) The below table can be used to view location-specific grid emission factors used to calculate Scope 2 emissions from purchased electricity.
              </p>
            </div>
          </div>
        </Card>
        <Scope2Table />
      </div>
    </div>
  );
}

export default Configurations