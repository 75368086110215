import React from 'react'
import IconProps from './Interface/IconProps'
import { PrimaryTheme } from '../../Config/Theme/theames';

const DeleteIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : '1rem'}
      height={props.inheritSize ? "100%" : '1rem'}
      viewBox="0 0 24 24"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_2271)">
        <path
          d="M1.71387 6H22.2853"
          stroke={props.stroke ? props.stroke : "white"}
          strokeWidth="1.71429"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.28516 6H19.7137V21.4286C19.7137 21.8832 19.533 22.3193 19.2116 22.6407C18.8902 22.9622 18.4541 23.1429 17.9994 23.1429H5.99944C5.54478 23.1429 5.10875 22.9622 4.78725 22.6407C4.46577 22.3193 4.28516 21.8832 4.28516 21.4286V6Z"
          stroke={props.stroke ? props.stroke : "white"}
          strokeWidth="1.71429"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.71387 6.00028V5.14314C7.71387 4.0065 8.16539 2.9164 8.96912 2.11267C9.77284 1.30895 10.8629 0.857422 11.9996 0.857422C13.1362 0.857422 14.2263 1.30895 15.03 2.11267C15.8338 2.9164 16.2853 4.0065 16.2853 5.14314V6.00028"
          stroke={props.stroke ? props.stroke : "white"}
          strokeWidth="1.71429"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.42773 11.1445V18.0043"
          stroke={props.stroke ? props.stroke : "white"}
          strokeWidth="1.71429"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5713 11.1445V18.0043"
          stroke={props.stroke ? props.stroke : "white"}
          strokeWidth="1.71429"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2271">
          <rect
            width={props.inheritSize ? "100%" : '1rem'}
            height={props.inheritSize ? "100%" : '1rem'}
            fill={props.fill ? props.fill : `black`}
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DeleteIcon