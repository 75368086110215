import { ColumnsType } from 'antd/es/table'
import React, { useState } from 'react'
import { BETable } from '../../../Components/BETable'
import BESwitch from '../../../Components/BEFormItems/BESwitch'
import { TopSection } from '../Components/TopSection'

const columns: ColumnsType<any> = [
  {
    title: 'SR. NO.',
    render: (text, record, index) => {
      return index + 1
    }
  },
  {
    title: 'CATEGORY',
    dataIndex: 'category'
  },
  {
    title: 'TITLE',
    dataIndex: 'title'
  },
  {
    title: 'FREQUENCY',
    dataIndex: 'frequency'
  },
  {
    title: 'NOTIFICATION',
    dataIndex: 'notifications',
    render: (text, record) => (
      <div onMouseEnter={() => {
      }}>
        <BESwitch
          loading={false}
          checked={record.notifications}
          onClick={() => {
          }}
        />
      </div>)
  },
  {
    title: 'VIA EMAIL',
    dataIndex: 'viaEmail',
    render: (text, record) => (
      <div onMouseEnter={() => {
      }}>
        <BESwitch
          loading={false}
          checked={record.viaEmail}
          onClick={() => {
          }}
        />
      </div>)
  },
  {
    title: 'IN APP',
    dataIndex: 'inApp',
    render: (text, record) => (
      <div onMouseEnter={() => {
      }}>
        <BESwitch
          loading={false}
          checked={record.inApp}
          onClick={() => {
          }}
        />
      </div>)
  }
]

const Notifications = () => {
  const [notifications, setNotifications] = useState([
    {
      "category": "Data Manager",
      "title": "Forthnightly status updates on Data Manager",
      "frequency": "Forthnightly",
      "inApp": false,
      "viaEmail": false,
      "email": "ashokk@gmail.com",
      "notifications": false,
      "status": "Active"

    },
    {
      "category": "Login & Configuration",
      "title": "User added as an Administrator / Business Group Administrator or Business Unit Administrator",
      "frequency": "Once",
      "inApp": true,
      "viaEmail": false,
      "email": "example@gmail.com",
      "notifications": true,
      "status": "Active"
    },
    {
      "category": "Materiality",
      "title": "Survey completion status alert (70% or more)",
      "frequency": "Once",
      "inApp": true,
      "viaEmail": false,
      "email": "materiality@example.com",
      "notifications": true,
      "status": "Active"
    },
    {
      "category": "Account Management",
      "title": "System configuration completed",
      "frequency": "Once",
      "inApp": false,
      "viaEmail": false,
      "email": "emission@example.com",
      "notifications": false,
      "status": "Active"
    },
    {
      "category": "Suppliers",
      "title": "Survey completion status alert (70% or more)",
      "frequency": "Once",
      "inApp": true,
      "viaEmail": false,
      "email": "supplier@example.com",
      "notifications": true,
      "status": "Active"
    },
    {
      "category": "Internal",
      "title": "Survey completion status alert (70% or more)",
      "frequency": "Once",
      "inApp": true,
      "viaEmail": false,
      "email": "reporting@example.com",
      "notifications": true,
      "status": "Active"
    },
    {
      "category": "Targets and Limits",
      "title": "Target reached for a metric",
      "frequency": "Once",
      "inApp": true,
      "viaEmail": false,
      "email": "target@example.com",
      "notifications": true,
      "status": "Active"
    },
    {
      "category": "Targets and Limits",
      "title": "Limit exceeded on a metric",
      "frequency": "Once",
      "inApp": true,
      "viaEmail": false,
      "email": "target@example.com",
      "notifications": true,
      "status": "Active"
    },
  ]
  );
  return (
    <>
    <TopSection />
    <div style={{ margin: '2rem 1.5rem' }}>
      <BETable columns={columns} data={notifications} pagination />
    </div>
    </>
  )
}

export default Notifications