import { useEffect, useState } from "react";
import React from "react";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
//***will replace this with react-google-autocomplete later for custom component */

interface Props {
  form?: any;
  navToLoc?: boolean;
  setNavToLoc?: Function;
  setCenter?: Function;
  value?: string;
  setValue?: Function;
}

export default function GooglePlaces({
  form,
  navToLoc,
  setCenter,
  setNavToLoc,
  value,
  setValue,
}: Props) {
  const [latLang, setLatLang] = useState<any>();
  const setValueAndShow = async (val: any) => {
    setValue && setValue(val.label);
    form && form.setFieldValue("address", val.label);
    const geocode = await geocodeByAddress(val.label);
    const latlang = await getLatLng(geocode[0]);
    setLatLang(latlang);
  };

  useEffect(() => {
    if (navToLoc && setCenter) {
      setCenter(latLang);
    }
    if(setNavToLoc) setNavToLoc(false);
  }, [navToLoc]);
  return (
    <div className="google-places">
      <GooglePlacesAutocomplete
        selectProps={{
          onChange: setValueAndShow,
          placeholder: value === "" ? "" : value,
        }}
        apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
      />
    </div>
  );
}
