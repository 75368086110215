import * as React from 'react';
import { BEDrawer } from '../../../../Components/BEDrawer';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { BETable } from '../../../../Components/BETable';
import { ColumnType } from 'antd/es/table';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { addMetrics } from '../../../Actions/OnboardingStep4Actions';
import { BEMessage } from '../../../../Components/BEMessage';
import { Col, Row } from 'antd';
import { BEInput } from '../../../../Components/BEFormItems/BEInput';
import { Checkbox } from 'antd';
import RightArrow from '../../../../assets/icons/RightArrow.svg'
import BEStatusTag from '../../../../Components/BEStatusTag';
import { postMyDerivedMetrics, deleteMyDerivedMetrics } from '../../../../Features/DataManager/Actions';
import { CalculatedMetricsDrawer } from './CalculatedMetricDrawer';
import { findBECodesOfAllChildren, findBECodesOfAllParents, getMetricsChildrenFromBEcode } from '../../../Actions/calculatedMetricsActions';
import { setChildDerivedMetricsTobeAdded, setChildSelectedMetricsTobeAdded } from '../../../../Redux/SettingsReducer';
import ToolTip from '../../../../Components/BEToolTip';
import { BEEyeButton } from '../../../../Components/BEEyeButton';

export interface IAddMetricsProps {
  open: boolean
  setOpen: Function
}



export function AddMetrics(props: IAddMetricsProps) {
  const dispatch = useAppDispatch()
  const allMetrics = useTypedSelector((state) => state.onBoarding.metrics.allMetrics.data)
  const currentCategory = useTypedSelector((state) => state.onBoarding.metrics.currentCategory)
  const currentGroup = useTypedSelector((state) => state.onBoarding.metrics.currentGroup)
  const currentPillar = useTypedSelector((state) => state.onBoarding.metrics.currentPillar);
  const selectedMetrics = useTypedSelector((state) => state.onBoarding.metrics.selectedMetrics.data);
  const selectedDerivedMetrics = useTypedSelector((state) => state.dataManager.metrics.fullMyDerivedMetrics);
  const metricsCurrentYear = useTypedSelector((state) => state.onBoarding.metrics.currentYear);
  const derivedMetricsRepresentationGraph = useTypedSelector((state) => state.settings.derivedMetricsRepresentationGraph);
  const childDerivedMetricsTobeAdded = useTypedSelector((state) => state.settings.childDerivedMetricsTobeAdded);
  const childSelectedMetricsTobeAdded = useTypedSelector((state) => state.settings.childSelectedMetricsTobeAdded);
  const [thisGroupMetrics, setThisGroupMetrics] = React.useState<any>([])
  const [checkedDerivedMetrics, setCheckedDerivedMetrics] = React.useState<any>([])
  const [checkedMetrics, setCheckedMetrics] = React.useState<any>([])
  const [loading, setLoading] = React.useState<boolean>(false);
  const [filteredMetrics, setFilteredMetrics] = React.useState<any>([]);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [openCalculatedMetricsDrawer, setOpenCalculatedMetricsDrawer] = React.useState<boolean>(false);
  const [dataForCalculatedMetrics, setDataForCalculatedMetrics] = React.useState<any>(null);
  const [allChecked, setAllChecked] = React.useState<boolean>(false);
  const [tempCheckedDerivedMetrics, setTempChildDerivedMetrics] = React.useState<any[]>([]);
  const [tempCheckedSelectedMetrics, setTempChildSelectedMetrics] = React.useState<any[]>([]);

  const columns: ColumnType<any>[] = [
    {
      title: ""
      // <Checkbox onChange={(e: any) => {
      //   handleCheckAll(e)
      // }}
      //   checked={allChecked}
      // />
      ,
      render: (text: any, record: any) => {
        return (
          <div onMouseOver={() => {
            setDataForCalculatedMetrics(record);
            const BEcodes: string[] = findBECodesOfAllChildren(derivedMetricsRepresentationGraph.data, [record.bcode]);
            const derivedChildrenBEcodes = BEcodes.filter((code) => derivedMetricsRepresentationGraph.data.find((item) => item.derived_metric === code));
            const selectedChildrenBEcodes = BEcodes.filter((code) => !derivedChildrenBEcodes.includes(code));
            const derivedBEcodes = findBECodesOfAllParents(derivedMetricsRepresentationGraph.data, [record.bcode]);
            const allDerivedBEcodes = Array.from(new Set<string>([...derivedChildrenBEcodes, ...derivedBEcodes]));
            setTempChildSelectedMetrics(selectedChildrenBEcodes.map((item) => allMetrics.find((metric: any) => metric.bcode === item)?.id));
            setTempChildDerivedMetrics(allDerivedBEcodes.map((item: any) => allMetrics.find((metric: any) => metric.bcode === item)?.id));
          }}>
            <Checkbox
              disabled={record.hasOwnProperty('direct') ? selectedDerivedMetrics.data?.find((metric: any) => metric.derived_metric === record.id) : selectedMetrics?.find((metric: any) => metric.metric === record.id)}
              onChange={async (e: any) => {
                if (record.hasOwnProperty('direct')) {
                  if (e.target.checked) {
                    const filteredParent = tempCheckedDerivedMetrics.filter((item: any) => !childDerivedMetricsTobeAdded.includes(item));
                    const filteredChildren = tempCheckedSelectedMetrics.filter((item: any) => !childSelectedMetricsTobeAdded.includes(item));
                    dispatch(setChildDerivedMetricsTobeAdded([...childDerivedMetricsTobeAdded, ...filteredParent]));
                    dispatch(setChildSelectedMetricsTobeAdded([...childSelectedMetricsTobeAdded, ...filteredChildren]));
                    setOpenCalculatedMetricsDrawer(true);
                  }
                  else {
                    const BEcodes: string[] = findBECodesOfAllChildren(derivedMetricsRepresentationGraph.data, [record.bcode]);
                    const derivedChildrenBEcodes = BEcodes.filter((code) => derivedMetricsRepresentationGraph.data.find((item) => item.derived_metric === code));
                    const selectedChildrenBEcodes = BEcodes.filter((code) => !derivedChildrenBEcodes.includes(code));
                    const derivedChildrenIds = derivedChildrenBEcodes.map((item) => allMetrics.find((metric: any) => metric.bcode === item)?.id);
                    const selectedChildrenIds = selectedChildrenBEcodes.map((item) => allMetrics.find((metric: any) => metric.bcode === item)?.id);
                    dispatch(setChildDerivedMetricsTobeAdded(childDerivedMetricsTobeAdded.filter((metric: any) => !derivedChildrenIds.includes(metric))));
                    dispatch(setChildSelectedMetricsTobeAdded(childSelectedMetricsTobeAdded.filter((metric: any) => !selectedChildrenIds.includes(metric))));
                  }
                }
                else {
                  if (e.target.checked) {
                    const filteredParent = tempCheckedDerivedMetrics.filter((item: any) => !childDerivedMetricsTobeAdded.includes(item));
                    dispatch(setChildDerivedMetricsTobeAdded([...childDerivedMetricsTobeAdded, ...filteredParent].filter((item: any) => item !== record.id)));
                    dispatch(setChildSelectedMetricsTobeAdded([...childSelectedMetricsTobeAdded, record.id]));
                  }
                  else {
                    dispatch(setChildSelectedMetricsTobeAdded(childSelectedMetricsTobeAdded.filter((item: any) => item !== record.id)));
                  }
                }
              }}
              checked={
                record.hasOwnProperty('direct') ? checkedDerivedMetrics.includes(record.id) || selectedDerivedMetrics.data?.find((metric: any) => metric.derived_metric === record.id) : selectedMetrics?.find((metric: any) => metric.metric === record.id) ? true : checkedMetrics.includes(record.id) || false
              }
            />
          </div>
        )
      }

    },

    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
      render: (text: any, record: any) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '10px' }}>{
              // record.hasOwnProperty('direct') ? record.metric :
              record.title
            }</span>
            {record.description !== '' && record.description !== "nan" &&
                            <BEEyeButton title={undefined} discription={record.description}>
                            </BEEyeButton>
                        }
            {
              record.hasOwnProperty('direct') && <ToolTip title='The values for C metrics are calculated.'>
                <BEStatusTag status='success'>C</BEStatusTag>
              </ToolTip>
            }
          </div>
        )
      },
      sorter: (a, b) => a[a.hasOwnProperty('direct') ? 'metric' : 'title'].localeCompare(b[b.hasOwnProperty('direct') ? 'metric' : 'title']),
    },
    {
      title: 'Group',
      dataIndex: 'group',

      key: 'group',
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit'
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      render: (text: any, record: any) =>
        record.hasOwnProperty('direct') &&
        <div
          onMouseOver={() => {
            setDataForCalculatedMetrics(record);
          }}
          style={{ cursor: 'pointer', height: "1rem", width: "6rem", display: "flex" }}
          onClick={() => {
            setOpenCalculatedMetricsDrawer(true);
          }}
        >
          <div style={{ flexGrow: "1" }} />
          <img src={RightArrow} width={16} alt="right-arrow" />
        </div>
    }
  ];

  React.useEffect(() => {
    console.log(checkedDerivedMetrics, checkedMetrics);
  }, [checkedDerivedMetrics, checkedMetrics]);

  React.useEffect(() => {
    let notToInclude: number[] = [];
    let toInclude: number[] = [];
    childDerivedMetricsTobeAdded.forEach((item: any) => {
      const allChildren = findBECodesOfAllChildren(derivedMetricsRepresentationGraph.data, [allMetrics.find((metric) => metric.id === item)?.bcode as string])
        .map((item: any) => allMetrics.find((metric: any) => metric.bcode === item)?.id);
      if (!childSelectedMetricsTobeAdded.some((metric) => allChildren.includes(metric))) {
        notToInclude.push(item)
      } else {
        toInclude.push(item)
      }
    })
    console.log(allMetrics)
    console.log(childSelectedMetricsTobeAdded);
    childSelectedMetricsTobeAdded.forEach((item: any) => {
      const allParents: number[] = findBECodesOfAllParents(derivedMetricsRepresentationGraph.data, [allMetrics.find((metric) => metric.id === item)?.bcode as string])
        .map((item: any) => allMetrics.find((metric: any) => metric.bcode === item)?.id) as number[];
      toInclude = [...toInclude, ...allParents].filter((item) => !childSelectedMetricsTobeAdded.includes(item));
    })
    setCheckedDerivedMetrics([...childDerivedMetricsTobeAdded.filter((item: number) => !notToInclude.includes(item)), ...toInclude]);
    setCheckedMetrics(childSelectedMetricsTobeAdded)
  }, [childDerivedMetricsTobeAdded, childSelectedMetricsTobeAdded]);

  React.useEffect(() => {
    const allMetricsOfThisPage = filteredMetrics
    let flag: boolean = true;
    allMetricsOfThisPage?.map((item: any) => {
      if (item.hasOwnProperty('direct')) {
        // item.id should be a selectedDerivedMetrics.derived_metric or item.id should be in checkedDerivedMetrics
        if (!selectedDerivedMetrics.data?.find((metric: any) => metric.derived_metric === item.id) && !checkedDerivedMetrics.includes(item.id)) {
          flag = false;
        }
      }
      else {
        if (!selectedMetrics?.find((metric: any) => metric.metric === item.id) && !checkedMetrics.includes(item.id)) {
          flag = false;
        }
      }
    })
    setAllChecked(flag)
  }, [checkedDerivedMetrics, checkedMetrics, filteredMetrics, currentPage, selectedDerivedMetrics, selectedMetrics])


  React.useEffect(() => {
    let tempMetrics: any = [];
    if (currentCategory !== 'Energy' && currentCategory !== 'Waste' && currentCategory !== 'Water') {
      allMetrics?.map((item: any) => {
        if (item.pillars === currentPillar && item.category === currentCategory && item.group === currentGroup) {
          tempMetrics.push(item)
        }
      })
    } else {
      allMetrics?.map((item: any) => {
        if (item.pillars === currentPillar && item.category === currentCategory && item.tab === currentGroup) {
          tempMetrics.push(item)
        }
      })
    }
    setThisGroupMetrics(tempMetrics)
  }, [currentGroup, currentCategory, currentPillar, allMetrics, selectedMetrics])

  React.useEffect(() => {
    setFilteredMetrics(thisGroupMetrics)
  }, [thisGroupMetrics, currentPage])


  const handleAddMetrics = async () => {
    if (checkedMetrics.length === 0 && checkedDerivedMetrics.length === 0) {
      BEMessage.error('Please select atleast one metric')
      return
    }
    setLoading(true);
    let metricsToAdd: any = [];
    let derivedMetricsToAdd: any = [];
    const checkedMetricsToSend = Array.from(new Set([...checkedMetrics]));
    checkedMetricsToSend.filter((item: any) => !selectedMetrics?.find((metric: any) => metric.metric === item)).
      map((item: any) => {
        metricsToAdd.push({
          metric: item,
          year: metricsCurrentYear,
          bcode: allMetrics?.find((metric: any) => metric.id === item)?.bcode
        })
      })
    const checkedDerivedMetricsToSend = Array.from(new Set([...checkedDerivedMetrics]));
    checkedDerivedMetricsToSend.filter((item: any) => !selectedDerivedMetrics.data?.find((metric: any) => metric.derived_metric === item)).
      map((item: any) => {
        derivedMetricsToAdd.push({
          derived_metric: item,
          year: metricsCurrentYear,
        })
      })

    let [res1, res2] = await Promise.all([dispatch(addMetrics(metricsToAdd.filter((item: any) => item.bcode !== undefined && item.metric !== undefined))), dispatch(postMyDerivedMetrics(derivedMetricsToAdd.filter((item: any) => item.derived_metric !== undefined)))])
    if (res1 && res2) {
      BEMessage.success('Metrics added successfully')
      setLoading(false);
      setCheckedMetrics([])
      setCheckedDerivedMetrics([])
      props.setOpen(false);
    }
    else setLoading(false);
  }
  return (
    <BEDrawer
      open={props.open}
      width='fit-content'
      setOpen={props.setOpen}
      heading='Add Metrics'
      footer={<div>
        <BEButton
          onClick={handleAddMetrics}
          loading={loading}
          className='primary' size='large'> + Add Metrics</BEButton>
        <BEButton
          onClick={() => props.setOpen(false)}
          size='large' style={{ marginLeft: '1rem' }}>Cancel</BEButton>
      </div>}
    >
      <Row style={{ margin: '1rem 0' }}>
        <Col span={16}>
          {
            props.open &&
            <BEInput
              onChange={(e: any) => {
                const value = e.target.value;
                const filteredData = thisGroupMetrics.filter((item: any) => item.title.toLowerCase().includes(value.toLowerCase()))
                setFilteredMetrics(filteredData)
              }}
              search placeholder='Enter name of metric' size='large' />
          }
        </Col>
      </Row>
      <BETable
        handleChangePage={(page: any) => {
          setCurrentPage(page)
        }}
        rowKey='id'
        columns={columns}
        data={filteredMetrics}
        loading={false}
      />
      <CalculatedMetricsDrawer open={openCalculatedMetricsDrawer} setOpen={setOpenCalculatedMetricsDrawer} data={dataForCalculatedMetrics} forDataManager={false} />
    </BEDrawer>
  );
}
