import React from 'react';
import { PrimaryTheme } from '../../../../../../Config/Theme/theames';
import { getCompanyDetails } from '../../../../../../views/Actions/OnboardingActions';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';

export interface ISupplierTopNavProps {
}

export function SupplierTopNav(props: ISupplierTopNavProps) {
  const companyDetails = useTypedSelector(state => state.companies.companyDetails);
  const [logo, setLogo] = React.useState<any>("");
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (companyDetails.status === 'idle') {
      dispatch(getCompanyDetails());
    }
  }, []);

  React.useEffect(() => {
    setLogo(companyDetails.data?.logo);
  }, [companyDetails]);

  return (
    <div style={{ position: "fixed", width: "100%", zIndex: "2", backgroundColor: PrimaryTheme.primaryBlack, display: 'flex', alignItems: 'center', height: '79px', padding: '0 1rem', color: 'white' }}>
      <img
        src={logo
          ? logo
          : "https://placehold.co/400?text=Company+Logo"
        }
        alt={companyDetails.data?.logo}
        style={{
          objectFit: "contain",
          width: "150px",
          height: "100%",
        }}
      />
      <p style={{ fontSize: '1.2rem', fontWeight: 600, marginLeft: '1rem' }}>SUPPLIER ASSESSMENT</p>
    </div>
  );
}
