import * as React from 'react';
import { BEDrawer } from '../../../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../../../Components/BEFormItems/BEButton';
import { LableRequired } from '../../../../../../../Components/BEFormItems/LableRequired';
import { BEInput } from '../../../../../../../Components/BEFormItems/BEInput';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../../../../Config/Hooks/useAppDispatch';
import { InviteCoSuppliers,getCoSuppliersInfo } from '../../../../../Actions/supplierFormActions';
import { Form } from 'antd';

export interface IInviteCosupplierProps {
  open: boolean;
  setOpen: Function;
  section: any;
}

export function InviteCosupplier(props: IInviteCosupplierProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);

  const onFininsh = (values: any) => {
    const obj = {
      ...values,
      section: props.section,
      assessment: searchParams.get('assessment'),
      supplier: searchParams.get('supplier'),
    }
    setLoading(true);
    dispatch(InviteCoSuppliers(obj,String(searchParams.get('secret')))).then((res)=>{
        dispatch(getCoSuppliersInfo(String(searchParams.get('secret')),Number(searchParams.get('assessment')),Number(searchParams.get('supplier'))));
        setLoading(false);
        props.setOpen(false);
    })
  }
  return (
    <BEDrawer
      open={props.open}
      width='500'
      setOpen={props.setOpen}
      heading={`Invite Co-Supplier to Fill Section ${props.section}`}
      footer={
          <>
          <BEButton size='large' className='primary'
            onClick={() => {
              form.submit();
            }}
            loading={loading}
          >
            Send Invitation 
          </BEButton>
          <BEButton size='large' className='secondary'
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </BEButton>
          </>
      }
    >
      <Form
      form={form}
      onFinish={onFininsh}
      >
        <LableRequired>Name</LableRequired>
        <Form.Item name='name' rules={[{ required: true, message: 'Please input name of co-supplier!' }]}>
          <BEInput placeholder='Enter name of co-supplier'  size='large'/>
        </Form.Item>
        <LableRequired>Email</LableRequired>
        <Form.Item name='email' rules={[{ required: true, message: 'Please input email of co-supplier!' }]}>
          <BEInput placeholder='Enter email of co-supplier'  size='large'/>
        </Form.Item>
      </Form>

    </BEDrawer>
  );
}
