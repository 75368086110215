import * as React from 'react';
import { BEInput } from '../../../../Components/BEFormItems/BEInput';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { ColumnType } from 'antd/es/table';
import businessUnitOnboardingImage from '../../../../assets/images/OnboardingImages/businessUnitOnboardingImage.png';
import { getBusinessUnits, deleteBusinessUnit } from '../../../Actions/BusinessUnitActions';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { AddBusinessUnit } from '../../Drawers/AddBusinessUnit/AddBusinessUnit';
import BEConfirmModal from '../../../../Components/BEConfirmModal';
import { LimitWords } from '../../../../Config/Functions/UsefullFunctions';
import ToolTip from '../../../../Components/BEToolTip';
import { NewBETable } from '../../../../Components/BETable/newBETable';
import { BEMessage } from '../../../../Components/BEMessage';
import BEStatusTag from '../../../../Components/BEStatusTag';

export interface IBusinessUnitsSectionProps {
}



export function BusinessUnitsSection(props: IBusinessUnitsSectionProps) {
  const businessUnits = useTypedSelector(state => state.entity.BusinessUnits.data);

  const businessGroups = useTypedSelector(state => state.entity.BusinessGroups.data);

  const businessUnitsStatus = useTypedSelector(state => state.entity.BusinessUnits.status);
  const selectedRow = useTypedSelector((state) => state.common.selectedRow)
  const dispatch = useAppDispatch();
  const [openAddBusinessUnit, setOpenAddBusinessUnit] = React.useState(false);
  const [openEditBusinessUnit, setOpenEditBusinessUnit] = React.useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState(false)
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [filteredBusinessUnits, setFilteredBusinessUnits] = React.useState(businessUnits);
  const BURefs = useTypedSelector(state => state.entity.BusinessUnitRefs.data);
  const BusinessGroups = useTypedSelector(state => state.entity.BusinessGroups.data);
  const myMetricsData = useTypedSelector(state => state.dataManager.metrics.myMetricsData);

  const columns: ColumnType<any>[] = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase()),
      render: (value: string, record: any) => {
        return <div>{value} {record.is_headquarter && <BEStatusTag status={'success'}>HQ</BEStatusTag>}</div>
      }
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Owner",
      dataIndex: "owner_name",
    },
    {
      title: "Location",
      dataIndex: "address",
      render: (value: string) => {
        return <div>{LimitWords(value, 3)}</div>
      }
    },
    {
      title: "Business Group",
      dataIndex: "business_group",
      render: (text, record) =>
        <ToolTip title={
          <div>
            {
              BURefs.filter((item: any) => item.business_unit === record.id).map((item: any) => (
                <p>{BusinessGroups.find((item2: any) => item2.id === item.business_group)?.name}</p>
              ))
            }
          </div>
        }>
          <p>
            {BusinessGroups.find((item: any) => item.id === BURefs.filter((item: any) => item?.business_unit === record.id)[0]?.business_group)?.name}
            {BURefs.filter((item: any) => item.business_unit === record.id).length > 1 && ` +${BURefs.filter((item: any) => item.business_unit === record.id).length - 1}`}
          </p>

        </ToolTip>
      //   filters: businessGroups.map((grp) => {
      //     return {text:grp.name, value:grp.id}
      //   }),
      //   onFilter: (value: any, record: any) => {
      //     console.log(value, record)
      //   return record.business_group === Number(value);
      // }
    },
  ];

  React.useEffect(() => {
    if (businessUnitsStatus === 'idle')
      dispatch(getBusinessUnits())
  }, [])

  React.useEffect(() => {
    setFilteredBusinessUnits(businessUnits)
  }, [businessUnits])

  const handleDelete = async () => {
    if (BURefs.filter((item: any) => item.business_unit === selectedRow.id).length !== 0) {
      BEMessage.error('Please remove the business unit from all business groups before deleting.')
      return;
    }
    // else if (myMetricsData.data.filter((item) => item.business_unit === selectedRow.id).length === 0) {
    //  await dispatch(deleteBusinessUnit(selectedRow.id))
    // }
    else {
      setOpenDeleteConfirmation(true)
    }
  }

  return (
    <div className='business-units-section'>
      <div className='headings'>
        {
          // businessUnits?.length !== 0 &&
          // <>
          //   <BEInput
          //     onChange={(e) => {
          //       const value = e.target.value.toLowerCase();
          //       const filteredData = businessUnits?.filter((item: any) => {
          //         return item.name.toLowerCase().includes(value);
          //       });
          //       setFilteredBusinessUnits(filteredData);
          //     }
          //     }
          //     placeholder='Search for business unit' style={{ width: '313px' }} search size="large" />
          //   {/* <BEButton size='large' >+ Add To Business Group</BEButton> */}
          //   <BEButton onClick={() => setOpenAddBusinessUnit(true)} style={{ marginLeft: 'auto' }} size='large' className='primary'>+ Add Business Unit</BEButton>
          // </>
        }

      </div>
      {
        businessUnits?.length === 0 && businessUnitsStatus !== 'loading' ?
          <div className='empty-state'>
            <div >
              <img src={businessUnitOnboardingImage} alt='businessGroupImg' />
            </div>
            <p>Click the button below to add a Business Unit!</p>
            <BEButton onClick={() => setOpenAddBusinessUnit(true)} size='large' className='primary'>+ Add Business Unit</BEButton>
          </div>
          :
          <div>
            {/* <BETable
              handleDelete={() => setOpenDeleteConfirmation(true)}
              handleEdit={() => {
                setOpenEditBusinessUnit(true);
              }}
              data={filteredBusinessUnits} columns={columns}
              loading={businessUnitsStatus === 'loading'}
              pagination /> */}
            <NewBETable
              tableTopLeftComponents={<p><strong>Business Units</strong></p>}
              TableTopRightComponents={<BEButton size='large' onClick={() => setOpenAddBusinessUnit(true)} className='primary'>+ Add Business Unit</BEButton>}
              data={filteredBusinessUnits}
              columns={columns}
              loading={businessUnitsStatus === 'loading'}
              pagination
              search
              searchOnColumns={{
                Name: 'name',
                Description: 'description',
                Owner: 'owner_name',
                Location: 'address',
              }}


              handleDelete={async (bu: any) => {
                handleDelete();
              }}
              handleEdit={() => { setOpenEditBusinessUnit(true) }}
            />
          </div>}

      <BEConfirmModal
        visible={openDeleteConfirmation}
        setVisible={setOpenDeleteConfirmation}
        title="Confirm Delete" message={`Are you sure you want to delete the business unit ${selectedRow.name}? All related data entries will be permanently deleted`}
        onConfirm={async () => {
          await dispatch(deleteBusinessUnit(selectedRow.id));
        }}
      />
      <AddBusinessUnit open={openAddBusinessUnit} setOpen={setOpenAddBusinessUnit} />
      <AddBusinessUnit isEdit open={openEditBusinessUnit} setOpen={setOpenEditBusinessUnit} />
    </div>
  );
}
