import React from 'react'
import IconProps from '../Interface/IconProps';

const WasteIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 12}
      height={props.inheritSize ? "100%" : 15}
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.999349 13.3333C0.999349 14.25 1.74935 15 2.66602 15H9.33268C10.2493 15 10.9993 14.25 10.9993 13.3333V3.33333H0.999349V13.3333ZM2.66602 5H9.33268V13.3333H2.66602V5ZM8.91602 0.833333L8.08268 0H3.91602L3.08268 0.833333H0.166016V2.5H11.8327V0.833333H8.91602Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
    </svg>
  );
}

export default WasteIcon