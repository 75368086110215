import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom';
import TopSection from '../TopSection';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { getScope1Configurations, getScope2Configurations } from '../../Actions';

const EmissionsLayout = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getScope1Configurations())
    dispatch(getScope2Configurations())
  }, [])
  return (
    <div style={{padding: "0 1.5rem"}}>
      <TopSection />
      <Outlet />
    </div>
  );
}

export default EmissionsLayout