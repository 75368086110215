import * as React from 'react';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { getSupplierScore, getSupplierStatus, getBRSRRiskMetrics } from '../../Actions/supplierAction';
import TrackerCard from '../../../../Components/Cards/TrackerCard';
import AssignmentIcon from '../../../../Components/BEIcons/AssignmentIcon';
import PendingResponseIcon from '../../../../Components/BEIcons/PendingResponseIcon';
import TrackerIcon from '../../../../Components/BEIcons/TrackerIcon';
import BEBackButton from '../../../../Components/BEBackButton';
import './style.scss';
import { Row, Col, Card } from 'antd';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import BECardPagination from '../../../../Components/BECardsPagination';
import { BEStatusPoint } from '../../../../Components/BEStatusTag';
import { SupplierScoreTypes } from '../../../../Redux/Types/suppliersTypes';
import BEStatusTag from '../../../../Components/BEStatusTag';
// import { SupplierRiskMatrixData } from '../../../../Config/Data/SupplierRiskMetrics';
import ToolTip from '../../../../Components/BEToolTip';
import { getSupplierSurveys } from '../../Actions/supplierAction';
import { useParams } from 'react-router-dom';
import SurveyTopNav from '../../../Materiality/Views/SurveyResponse/SurveyTopNav';
import { getCompanyDetails } from '../../../../views/Actions/OnboardingActions';


export function InternalAssessmentResult() {

  const  riskLowPercentage = 33.33;
  const {assessment_id} = useParams();
  const supplierStatus = useTypedSelector(state => state.supplier.SupplierStatus);
  // const supplierRiskMetrics = useTypedSelector(state => state.supplier.SuppliersRiskMetrics);
  // const [metricsIndexGap, setMetricsIndexGap] = React.useState<any>(null)
  const supplierQuestions = useTypedSelector(state => state.supplier.SupplierQuestions);
  const allAnswers = useTypedSelector(state => state.supplier.allAnswers);
  const suppliers = useTypedSelector(state => state.supplier.Suppliers);
  const supplierScore = useTypedSelector(state => state.supplier.SupplierScore);
  const allAssessments = useTypedSelector(state => state.supplier.SuppliersSurveys);
  const company = useTypedSelector(state => state.companies.companyDetails  );
  
  const [riskClassification, setRiskClassification] = React.useState<any>({
    high: null,
    medium: null,
    low: null,
    total: 0,
    loading: true
  });
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    setRiskClassification({
      high: null,
      medium: null,
      low: null,
      loading: true
    })
      dispatch(getSupplierScore(assessment_id));
      dispatch(getSupplierStatus(assessment_id));
      dispatch(getBRSRRiskMetrics(assessment_id));
      dispatch(getSupplierSurveys());
      dispatch(getCompanyDetails());
      // dispatch(getSupsplierRiskMetrics(assessment_id));
  }, []);

  React.useEffect(() => {
    if (supplierScore.status === 'success' && supplierStatus.status === 'success') {
      let highRisk = 0;
      let mediumRisk = 0;
      let lowRisk = 0;
      let totalTemp = 0;
      supplierScore.data.forEach((data: any) => {
        if (supplierStatus.data.find((status: any) => status.assessment_supplier === data.assessment_supplier)?.status ===3) {
          totalTemp+= data.score;
          if ((data.score/data.total_questions)*100 < 33.33) {
            highRisk++;
          } else if ((data.score/data.total_questions )*100< 66.66) {
            mediumRisk++;
          }
          else {
            lowRisk++;
          }
        }
      })
      setRiskClassification({
        high: highRisk,
        medium: mediumRisk,
        low: lowRisk,
        loading: false
      })
    }
  }, [supplierScore, supplierStatus]);

  // React.useEffect(() => {
  //   if (supplierRiskMetrics.status === 'success') {
  //     let minIndex = 1000000;
  //       supplierRiskMetrics.data.forEach((data: any) => {
  //         if (data.question < minIndex) {
  //           minIndex = data.question;
  //         }
  //       })
  //     setMetricsIndexGap(minIndex-1);
  //   }
  // }, [supplierRiskMetrics]);

  return (
   <div>
    <SurveyTopNav heading={
      company.status === 'success' ?
      company.data?.firm_name: ' '
    } />
      <div className='supplier-result'>
        
      <h2>
          View Results : {
            allAssessments.data.filter((assessment: any) => Number(assessment.id) === Number(assessment_id))[0]?.title
          }
        </h2>
        <div className='top-cards'>
  
          <TrackerCard
            title='AVERAGE SCORE'
            body={
               supplierScore.status === 'success' && supplierStatus.status === 'success' &&
               ((supplierScore.data.filter((scoreData:any)=>supplierStatus.data.find((statusData:any)=>statusData.assessment_supplier===scoreData.assessment_supplier)?.status===3).reduce((a:any,b:any)=>a+b.score,0)/
               supplierScore.data.filter((scoreData:any)=>supplierStatus.data.find((statusData:any)=>statusData.assessment_supplier===scoreData.assessment_supplier)?.status===3).reduce((a:any,b:any)=>a+b.total_questions,0)*100)).toFixed(2) + '%'
              }
            icon={<AssignmentIcon />}
            loading={supplierScore.status === 'loading' && supplierStatus.status === 'loading'}
          />
  
          <TrackerCard
            title='RISK CLASSIFICATION'
            body={
              supplierScore.status === 'success' ?
                <div style={{
                  color: (supplierScore.data.filter((scoreData:any)=>supplierStatus.data.find((statusData:any)=>statusData.assessment_supplier===scoreData.assessment_supplier)?.status===3).reduce((a:any,b:any)=>a+b.score,0)/
                  supplierScore.data.filter((scoreData:any)=>supplierStatus.data.find((statusData:any)=>statusData.assessment_supplier===scoreData.assessment_supplier)?.status===3).reduce((a:any,b:any)=>a+b.total_questions,0)*100) < 33.33 ? PrimaryTheme.secondaryRedError : (supplierScore.data.filter((scoreData:any)=>supplierStatus.data.find((statusData:any)=>statusData.assessment_supplier===scoreData.assessment_supplier)?.status===3).reduce((a:any,b:any)=>a+b.score,0)/
                  supplierScore.data.filter((scoreData:any)=>supplierStatus.data.find((statusData:any)=>statusData.assessment_supplier===scoreData.assessment_supplier)?.status===3).reduce((a:any,b:any)=>a+b.total_questions,0)*100) < 66.66 ? PrimaryTheme.amber : PrimaryTheme.primaryGreen
  
                }}>
                  {
                    supplierScore.data.filter((scoreData:any)=>supplierStatus.data.find((statusData:any)=>statusData.assessment_supplier===scoreData.assessment_supplier)?.status===3).length > 0 ?
                    (supplierScore.data.filter((scoreData:any)=>supplierStatus.data.find((statusData:any)=>statusData.assessment_supplier===scoreData.assessment_supplier)?.status===3).reduce((a:any,b:any)=>a+b.score,0)/
                    supplierScore.data.filter((scoreData:any)=>supplierStatus.data.find((statusData:any)=>statusData.assessment_supplier===scoreData.assessment_supplier)?.status===3).reduce((a:any,b:any)=>a+b.total_questions,0)*100) < 33.33 ? 'High Risk' : (supplierScore.data.filter((scoreData:any)=>supplierStatus.data.find((statusData:any)=>statusData.assessment_supplier===scoreData.assessment_supplier)?.status===3).reduce((a:any,b:any)=>a+b.score,0)/
                    supplierScore.data.filter((scoreData:any)=>supplierStatus.data.find((statusData:any)=>statusData.assessment_supplier===scoreData.assessment_supplier)?.status===3).reduce((a:any,b:any)=>a+b.total_questions,0)*100) < 66.66 ? 'Medium Risk' : 'Low Risk'
                    : '-'
                  }
                </div>
                : '-'
            }
            icon={<PendingResponseIcon />}
            loading={supplierScore.status === 'loading'}
          />
  
          <TrackerCard
            title='RESPONDENTS'
            body={supplierStatus.status === 'success' ? supplierStatus.data.length : '-'}
            icon={<TrackerIcon />}
            loading={supplierStatus.status === 'loading'}
          />
        </div>
        <br />
        <h2>Risk Classification</h2>
        <div className='breakdown-cards'>
          <Card className='breakdown-card' hoverable style={{ width: '200px' }}
            loading={riskClassification.loading}>
            <BEStatusPoint childrenColored style={{ fontSize: '1rem' }} status='error'>
              High
            </BEStatusPoint>
            <p className='body'>{
              (riskClassification.high + riskClassification.medium + riskClassification.low) === 0 ? 0 :
                (riskClassification.high / (riskClassification.high + riskClassification.medium + riskClassification.low) * 100).toFixed(2)
            }%</p>
            <p className='footer'>No.of Business Units: {
              riskClassification.high
            }</p>
          </Card>
          <Card className='breakdown-card' hoverable style={{ width: '200px' }}
            loading={riskClassification.loading}
          >
            <BEStatusPoint childrenColored style={{ fontSize: '1rem' }} status='warning'>
              Medium
            </BEStatusPoint>
            <p className='body'>{(riskClassification.high + riskClassification.medium + riskClassification.low) === 0 ? 0 :
              (riskClassification.medium / (riskClassification.high + riskClassification.medium + riskClassification.low) * 100).toFixed(2)}%</p>
            <p className='footer'>No.of Business Units: {
              riskClassification.medium
            }</p>
          </Card>
          <Card className='breakdown-card' hoverable style={{ width: '200px' }}
            loading={riskClassification.loading}>
            <BEStatusPoint childrenColored style={{ fontSize: '1rem' }} status='success'>
              Low
            </BEStatusPoint>
            <p className='body'>{(riskClassification.high + riskClassification.medium + riskClassification.low) === 0 ? 0 :
              (riskClassification.low / (riskClassification.high + riskClassification.medium + riskClassification.low) * 100).toFixed(2)}%</p>
            <p className='footer'>No.of Business Unit: {
              riskClassification.low
            }</p>
          </Card>
        </div>
        <h2 style={{ margin: '1rem 0' }}>Risks</h2>
  
        <div className='supplier-risk-metrics'>
          <Row gutter={[20,20]}>
            {
              allAnswers.status !== 'loading' &&
              // Object.keys(SupplierRiskMatrixData).map((heading: any) => {
              Array.from(new Set(supplierQuestions.data.map((question: any) => question.attribute))).map((heading: any) => {
                return (
                  <Col span={8}>
                    <div className='risk-breakdown-box'>
                      <div className='title'>
                        {heading}
                      </div>
                      <div className='body'>
                        {
                          supplierQuestions.data.filter((question: any) => question.attribute === heading).map((data: any) => {
                            return (
                              <ToolTip title={
                                <div>
                                   <p>Business Units At Risk</p>
                                   {/* {
                                      supplierRiskMetrics.status === 'success' ?
                                      supplierRiskMetrics.data?.find((metric: any) => metric.question === (data.id + metricsIndexGap))?.suppliers_at_risk.map((supplier: any) => {
                                        return(
                                          <div>
                                            <p>{supplier}</p>
                                          </div>
                                        )
                                      }) 
                                      : '-'
                                   } */}
                                   {
                                    allAnswers.data.filter((answer: any) => answer.question === data.question_id && answer.answer === false).length === 0 && 'None'
                                   }
                                   {  
                                      allAnswers.data.filter((answer: any) => answer.question === data.question_id && answer.answer === false).map((answer: any) => {
                                        return(
                                          <div>
                                            <p>{
                                              suppliers.data.find((supplier: any) => supplier.supplier === answer.supplier)?.name
                                              }</p>
                                          </div>
                                        )
                                      })
                                   }
                                </div>
                              }>
                                <div className='body-row'>
                                  {/* {
                                    Object.keys(data).map((key: any) => {
                                      return( */}
                                  
                                    <div className='body-row-title'>
                                      {data.question}
                                    </div>
                                    <div className='body-row-body'>
                                      <BEStatusTag status={
                                        ( allAnswers.data.filter((answer: any) => answer.question === data.question_id && answer.answer === false).length/allAnswers.data.filter((answer: any) => answer.question === data.question_id).length * 100) < riskLowPercentage ? 'success' :
                                         ( allAnswers.data.filter((answer: any) => answer.question === data.question_id && answer.answer === false).length/allAnswers.data.filter((answer: any) => answer.question === data.question_id).length * 100) < (riskLowPercentage*2) ?  'warning' : 'error'
                                      }>
                                        {
                                          ( allAnswers.data.filter((answer: any) => answer.question === data.question_id && answer.answer === true).length/
                                          allAnswers.data.filter((answer: any) => answer.question === data.question_id).length * 100).toFixed(2)+'%'
                                        }
                                      </BEStatusTag>
                                      {/* <BEStatusTag status={
                                        (supplierRiskMetrics.data?.find((metric: any) => metric.question === (key + metricsIndexGap))?.risk_percentage || 0)< 33.33 ? 'error' : (supplierRiskMetrics.data?.find((metric: any) => metric.question === (key + metricsIndexGap))?.risk_percentage || 0) < 66.66 ? 'warning' : 'success'
                                      }>
                                        {
                                          (supplierRiskMetrics.data?.find((metric: any) => metric.question === (key + metricsIndexGap))?.risk_percentage || 0).toFixed(2)
                                        }%
                                      </BEStatusTag> */}
                                     
                                    </div>
                                      {/* )
                                    })
                                  } */}
                                </div>
                              </ToolTip>
                            )
                          })
                        }
                      </div>
                    </div>
                  </Col>
                )
              })
            }
          </Row>
        </div>
      </div>
   </div>
  );
}
