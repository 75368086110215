import { AppDispatch } from "../../Redux/store";
import { setIsLoggedIn } from "../../Redux/UserReducer";

export const AutoLogin = (navigate:Function,location:any) => async (dispatch:AppDispatch) => {
  const access = localStorage.getItem("access");
  if(access) dispatch(setIsLoggedIn(true))
  if(access){
        if(location.pathname === '/login' || location.pathname === '/') navigate('/home/dashboard');
        return true;
  }
  else{
    if(location.pathname.includes('/home') || location.pathname === '/' )  navigate('/login');
  }
}