import * as React from 'react';
import "./style.scss";
import { BEButton } from '../../../../../../../../Components/BEFormItems/BEButton';
import { BECollapse } from '../../../../../../../../Components/BECollapse';
import { Type0Topic } from './Tables/Type0';
import { Type1Topic } from './Tables/Type1';
import { Type2Topic } from './Tables/Type2';
import { Type3Topic } from './Tables/Type3';
import { Type4Topic } from './Tables/Type4';
import { useTypedSelector } from '../../../../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../../../../Config/Hooks/useAppDispatch';
import type { TemplateSelectedTopicTypes } from '../../../../../../../../Redux/Types/reportingTypes';
import { TopicEvidanceTable } from './EvidenceTable';
import BEStatusTag from '../../../../../../../../Components/BEStatusTag';
import { AssignDepartmentByReportDrawer } from '../../../../Assign Department';
import { deleteOmittedReportTopics, getAssignedDepartmentByReportTopics, postOmittedReportTopics } from '../../../../../../Actions/template';
import { Checkbox, Col, Row } from 'antd';
import { GresbMain } from '../../../../../../Components/GresbComponents/GresbMain';

export interface IChildrenRightBodyProps {
}

interface ReportTopicStatusMapType {
    key: number,
    value: string,
    status: 'success' | 'warning' | 'error' | 'info'
}
export const ReportTopicStatusMap: ReportTopicStatusMapType[] = [
    { key: 0, value: 'Pending', status: 'warning' },
    { key: 1, value: 'Completed', status: 'success' },
    { key: 2, value: 'Approved', status: 'success' },
    { key: 3, value: 'Rejected', status: 'error' },
]

export function ChildrenRightTabs(props: IChildrenRightBodyProps) {
    const dispatch = useAppDispatch();
    const currentCategory = useTypedSelector(state => state.reporting.selectedTemplateCategory);
    const currentSection = useTypedSelector(state => state.reporting.selectedTemplateSection);
    const currentGRESBSection = useTypedSelector(state => state.reporting.currentGRESBSection);
    const templateDrafts = useTypedSelector(state => state.reporting.TemplateDrafts);
    const currentDimension = useTypedSelector(state => state.reporting.selectedTemplateDimension);
    const currentReport = useTypedSelector(state => state.reporting.currentReport);
    const selectedTopics = useTypedSelector(state => state.reporting.selectedTemplateTopics);
    const omittedReportTopics = useTypedSelector(state => state.reporting.omittedReportTopics);
    const templates = useTypedSelector(state => state.reporting.TemplateDrafts);
    const topicStatus = useTypedSelector(state => state.reporting.topicStatus);
    const [openAssignDepartment, setOpenAssignDepartment] = React.useState<boolean>(false);
    const [closeCollapse, setCloseCollapse] = React.useState<boolean>(false);
    const role = localStorage.getItem('role');

    React.useEffect(() => {
        dispatch(getAssignedDepartmentByReportTopics());
    }, []);

    React.useEffect(() => {
        setCloseCollapse(true);
    }, [currentCategory, currentSection, currentDimension, currentReport]);

    return (
        <div className='children-right'>
            {/* <div style={{ display: "flex", flexDirection: "row", marginBottom: "1rem" }}>
                <div style={{ flexGrow: 1 }} />
                <BEButton
                    className='primary'
                    onClick={() => {
                        setOpenAssignDepartment(true);
                    }}
                    style={{ fontWeight: "600" }}>
                    Assign
                </BEButton>
                <div style={{ flexGrow: 1 }} />
                <span
                    onClick={() => {
                        setIsView(true);
                        setOpenAssignDepartment(true);
                    }}
                    style={{ textDecoration: "underline", cursor: "pointer" }}>
                    View departments
                </span>
            </div> */}
            <div className='data-table-box'>
                {
                    // uneque topics for the selected category
                    Array.from(new Set(selectedTopics.data.filter(
                        (item: TemplateSelectedTopicTypes) => (item.category === currentCategory && item.section === currentSection && (item.dimension === currentDimension || !currentDimension)))
                        .map((item: TemplateSelectedTopicTypes) => item.unique_code)))
                        // .sort((a: string, b: string) => a.localeCompare(b))
                        .map((unique_code: string) => {
                            let topic = selectedTopics.data.filter((item: TemplateSelectedTopicTypes) => item.unique_code === unique_code)
                            .sort((a, b) => a.topic_id - b.topic_id)[0]!.topic; // topic up down
                            let disclosureCode = selectedTopics.data.filter((item: TemplateSelectedTopicTypes) => item.unique_code === unique_code)[0]!.disclosure_code;
                            let checked = omittedReportTopics.data.find((item) => item.topic_id === selectedTopics.data.find((item: TemplateSelectedTopicTypes) => item.unique_code === unique_code)?.id
                                && item.report === currentReport?.id && item.section === currentSection) ? true : false
                            return (
                                <BECollapse
                                    closeCollapse={checked || closeCollapse}
                                    heading={
                                        <div className='topic-heading'>
                                            <p>
                                                {
                                                    disclosureCode !== 'nan' ?
                                                        disclosureCode + ' ' + topic
                                                        : topic
                                                }
                                            </p>

                                        </div>
                                    }
                                    headingRightContent={
                                        <div className='right-heading'>
                                            {checked ?
                                                <BEStatusTag status='info'>
                                                    Omitted
                                                </BEStatusTag> :
                                                templateDrafts.data.find((item) => item.id === currentReport?.template)?.framework !== 'GRESB' &&
                                                <>
                                                    <BEStatusTag status={
                                                        !topicStatus.data.find((item) => item.unique_code === unique_code) ? ReportTopicStatusMap[0!]?.status :
                                                            ReportTopicStatusMap.find((item) => item.key === topicStatus.data.find((item) => item.unique_code === unique_code)!?.status)!?.status
                                                    }>
                                                        {
                                                            !topicStatus.data.find((item) => item.unique_code === unique_code) ? ReportTopicStatusMap[0].value :
                                                                ReportTopicStatusMap.find((item) => item.key === topicStatus.data.find((item) => item.unique_code === unique_code)!?.status)!?.value
                                                        }
                                                    </BEStatusTag>
                                                    
                                                    <BEStatusTag status='info'>
                                                        {
                                                            selectedTopics.data.filter((item: TemplateSelectedTopicTypes) => item.unique_code === unique_code)[0!]?.table_type !== 0 ? 'Table' :
                                                                selectedTopics.data.filter((item: TemplateSelectedTopicTypes) => item.unique_code === unique_code)[0!]?.unit === 'Description' ? 'Description' : 'Number'
                                                        }
                                                    </BEStatusTag>
                                                </>
                                            }
                                        </div>
                                    }
                                    headingStyle={{ fontWeight: "600", justifyContent: 'space-between' }}
                                >
                                    {
                                        templates.data.find((item) => item.id === currentReport?.template)?.framework !== 'GRESB' ?
                                            <div>
                                                <Row justify={"end"} style={{ margin: "1rem 0.5rem" }}>
                                                    <Col style={{ fontWeight: "500", float: "right" }}>
                                                        <Checkbox
                                                            checked={checked}
                                                            onChange={
                                                                async (e) => {
                                                                    const topic = selectedTopics.data.find((item: TemplateSelectedTopicTypes) => item.unique_code === unique_code)?.id;
                                                                    const report = currentReport?.id;
                                                                    const section = currentSection;
                                                                    const data = [{
                                                                        topic_id: topic,
                                                                        report: report,
                                                                        section: section
                                                                    }];
                                                                    if (!checked) {
                                                                        await dispatch(postOmittedReportTopics(data));
                                                                    } else {
                                                                        const omittedReportTopicId = omittedReportTopics.data
                                                                            .find((item) => item.topic_id === topic
                                                                                && item.report === report
                                                                                && item.section === section
                                                                            )?.id;
                                                                        await dispatch(deleteOmittedReportTopics(omittedReportTopicId));
                                                                    }
                                                                }}>
                                                            Topic is not applicable to the business
                                                        </Checkbox>
                                                    </Col>
                                                </Row>
                                                <div className='data-table'>
                                                    {
                                                        // render the table based on the table type
                                                        selectedTopics.data.filter((item: TemplateSelectedTopicTypes) => item.unique_code === unique_code)[0]!.table_type === 0 ?
                                                            <Type0Topic uniqueCode={unique_code} nonEditable={role === 'AUDITOR'} />
                                                            : selectedTopics.data.filter((item: TemplateSelectedTopicTypes) => item.unique_code === unique_code)[0]!.table_type === 1 ?
                                                                <Type1Topic uniqueCode={unique_code} nonEditable={role === 'AUDITOR'} />
                                                                : selectedTopics.data.filter((item: TemplateSelectedTopicTypes) => item.unique_code === unique_code)[0]!.table_type === 3 ?
                                                                    <Type3Topic uniqueCode={unique_code} nonEditable={role === 'AUDITOR'} />
                                                                    : selectedTopics.data.filter((item: TemplateSelectedTopicTypes) => item.unique_code === unique_code)[0!]?.unit === 'Checkbox' ?
                                                                        <Type4Topic uniqueCode={unique_code} nonEditable={role === 'AUDITOR'} />
                                                                        : selectedTopics.data.filter((item: TemplateSelectedTopicTypes) => item.unique_code === unique_code)[0]!.table_type === 2 ?
                                                                            <Type2Topic uniqueCode={unique_code} nonEditable={role === 'AUDITOR'} />
                                                                            : null

                                                    }
                                                </div>
                                                <TopicEvidanceTable uniqueCode={unique_code} />
                                            </div>
                                            :
                                            <GresbMain uniqueCode={unique_code} />
                                    }
                                </BECollapse>
                            )

                        })
                }
            </div>
            <AssignDepartmentByReportDrawer open={openAssignDepartment} setOpen={setOpenAssignDepartment} />

        </div>
    );
}
