import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine, LabelList, Label } from 'recharts';
import { graphColors, graphColors2, graphColors3 } from '../../../Config/Theme/graphColors';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import { AddCommaToNumber } from '../../../Config/Functions/UsefullFunctions';

interface IStackedBarChartProps {
  data: any[];                            /// data should be in this format [{name:'name',data:[1,2,3]}]
  xAxisLabels: any[];
  chartTitle?: string;
  yAxisTitle?: string;
  height?: string;
  yAxisLabel?: string;
  averageLine?: number;
  colorTheme?: number;
}

const StackedColumnCharts = (props: IStackedBarChartProps) => {

  const [data, setChartData] = React.useState<any>([]);

  React.useEffect(() => {
    if (props.data.length>0 && props.xAxisLabels.length>0) {
      let tempData: any = [];
      props.xAxisLabels.map((item: any, index: any) => {
        let tempObj: any = {
          name: item,
        }
        props.data.forEach((element: any) => {
          if (element.data[index])
          tempObj[element.name] = element.data[index]
        })
        tempData.push(tempObj);
      })
      setChartData(tempData)
    }

  }, [props.data, props.xAxisLabels])

  const renderLabelContent = (props: number) => {
    return AddCommaToNumber(props, false, true, true);
  };

  return (
    <div style={{height: props?.height ? props?.height : '400px',width:'100%'}}>
      <h2>{props.chartTitle}</h2>
      <ResponsiveContainer width='100%' height='100%'>
        <BarChart
          width={600}
          height={300}
          data={data}
          margin={{
            top: 20, right: 30, left: 20, bottom: 5,
          }}
          style={{fontSize: '10px'}}
        >
          
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis padding={{ top: 40 }} 
          // label={"(" + props.yAxisLabel + ")"} 
          >
              <Label value={props.yAxisLabel} angle={-90} style={{ textAnchor: 'middle' }} />
          </YAxis>
          <Tooltip formatter={renderLabelContent}/>
          <Legend />
          {/* <Bar dataKey="pv" stackId="a" fill="#8884d8" /> */}
          {
            props.data.map((item: any, index: any) => {
              return (
              <>
                  <Bar dataKey={item.name} stackId="a" fill={
                    !props.colorTheme ?  
                    graphColors[index%graphColors.length]:
                    props.colorTheme === 2 ?
                    graphColors2[index%graphColors2.length]:
                    props.colorTheme === 3 ?
                    graphColors3[index%graphColors3.length]:
                    'white'
                  } 
                    >
                    {/* <LabelList dataKey={item.name} style={{ fill: 'black' }} formatter={renderLabelContent}/> */}
                  </Bar>
               
              </>
                )
            })
          }
          {props.averageLine &&
          <ReferenceLine
          y={props.averageLine}
          stroke={PrimaryTheme.amber}
          label={{ position: "insideBottomRight",  value: 'AVERAGE', fill: PrimaryTheme.amber}}
          />}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StackedColumnCharts;