import { ColumnsType } from 'antd/es/table';
import * as React from 'react';
import { NewBETable } from '../../../../../../../Components/BETable/newBETable';
import { metricType } from '../../../../../../../Redux/Types/dataManagerTypes';
import { useTypedSelector } from '../../../../../../../Config/Hooks/useTypedSelector';
import { AllMonths, ChangeDateFormat, getFYByDate, getMonthsByQuarter, showFYFormatByYear } from '../../../../../../../Config/Functions/UsefullFunctions';
import dayjs from 'dayjs';
import ToolTip from '../../../../../../../Components/BEToolTip';
import { LogsType } from '../../../../../../../Redux/Types/settingsTypes';

export interface IDataManagerLogsProps {
  metric:metricType
}

export function DataManagerLogs (props: IDataManagerLogsProps) {
  const users = useTypedSelector(state => state.userMgmt);
  const filteredLogs = useTypedSelector(state => state.dataManager.logs.filteredLogs);
  const yearEnd = useTypedSelector(state => state.companies.companyDetails.data?.reporting_year_end);
  const yearEndMonth = useTypedSelector(state => state.companies.yearEnd);
  const currentMonth = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentMonth);
  const [monthsByQuarter, setMonthsByQuarter] = React.useState<number[]>([]);

  const columns:ColumnsType<LogsType> = [
     {
      title: 'Date and Time',
      key: 'date',
      render: (record:LogsType) => {
        return <p>{
          dayjs(record.timestamp).format('DD MMM,YYYY hh:mm A')
          }</p>
      },
      sorter: (a, b) => {
        return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
      }
    },
    {
      title: 'User',
      key: 'user',
      render: (record:LogsType) => {
        const user = users.data.find((user:any) => user.id === record.user);
        return <p>{`${user?.first_name} ${user?.last_name}`}</p>
      }
    },
    {
      title: 'Summary',
      width:'300px',
      render: (record:LogsType) => {
        return <p>{record.message} for "{
          showFYFormatByYear(getFYByDate(record.month_of_entry,yearEnd || ''),yearEnd || '')
          }
        "</p>
      }
    } 
    //,
  //   {
  //     title:'Business Unit',
  //     key:'business_unit',
  //     render:(record:LogsType) => {
  //       return<p>
  //         {
  //           businessUnits.filter((bu:any) => bu.id === record.business_unit)[0].name
  //         }
  //       </p>
  //   }
  // },
  // {
  //   title:'Business Group',
  //   key:'business_group',
  //   render:(record:LogsType) => {
  //     const Bgs = buRefs.filter((buRef:any) => buRef.business_unit === record.business_unit);
  //     return <ToolTip title={Bgs.map((bg:any) => {
  //       return <p>{businessGroups.find((bg2:any) => bg2.id === bg.business_group)?.name}</p>
  //     })}>
  //       <p>
  //         {
  //          businessGroups.find((bg:any) => bg.id === Bgs[0].business_group)?.name+ 
  //           (Bgs.length > 1 ? ` +${Bgs.length-1} ` : '')
  //         }
  //       </p>
  //     </ToolTip>
  // }}
  ,{
    title:'Month',
    key:'month',
    render:(record:LogsType) => {
      return <p>{
          dayjs(record.month_of_entry).format('MMMM')
        }</p>
    }
  },{
    title:'Financial Year',
    key:'year',
    render:(record:LogsType) => {
      return <p>{
          showFYFormatByYear(getFYByDate(record.month_of_entry,yearEnd || ''),yearEnd || '')
        }</p>
      },
  }
  ];

  React.useEffect(() => {
    const monthsOfQuarter = getMonthsByQuarter(currentMonth, yearEndMonth);
    setMonthsByQuarter(monthsOfQuarter);
  }, [currentMonth, yearEndMonth]);

  return (
    <div className='data-manager-logs'>
      <NewBETable columns={columns} data={
        filteredLogs.data.filter((log) => log.my_metric === props.metric.id
          && (currentMonth === 0 || dayjs(log.month_of_entry).format('MMMM') === AllMonths[currentMonth - 1]
          || monthsByQuarter.includes(dayjs(log.month_of_entry).month() + 1)
        )
        ).sort((a, b) => dayjs(a.month_of_entry).isBefore(dayjs(b.month_of_entry)) ? -1 : 1)
      } />
    </div>
  );
}
