import * as React from 'react';
import { StepCollapse } from '../../../../../../Components/BECollapse/StepCollapse';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { 
  getMaterialityIndustries,
  getMaterialityTopics,
  getMaterialityTopicsBySurveyId,
  deleteMaterialityTopics
} from '../../../../Actions/matirialityActions';
import { GlobalLoader } from '../../../../../../Components/GlobalLoader';
import BEMultipleSelect from '../../../../../../Components/BEMultipleSelect';
import { LableRequired } from '../../../../../../Components/BEFormItems/LableRequired';
import { Checkbox } from 'antd';
import { 
  setCreateSurveyStep,
  setCurrentMaterialitySurvey,
  setIndustries,
  setSelectedTopics,
  setTopics,
} from '../../../../../../Redux/MatirialityReducer';
import { BEButton } from '../../../../../../Components/BEFormItems/BEButton';
import BEDragList from '../../../../../../Components/BEDragList';
import { MatirialityTopicType } from '../../../../../../Redux/Types/metirialityTypes';
import { postMaterialityTopics } from '../../../../Actions/matirialityActions';
import { BEMessage } from '../../../../../../Components/BEMessage';

export interface IStep2Props {
  open: boolean;
  setOpen?: Function
  onCancle: Function
}

export function Step2(props: IStep2Props) {
  const dispatch = useAppDispatch();
  const industries = useTypedSelector((state) => state.matiriality.industries);
  const topics = useTypedSelector((state) => state.matiriality.topics);
  const selectedTopics = useTypedSelector((state) => state.matiriality.selectedTopics);
  const currentSurvey = useTypedSelector((state) => state.matiriality.currentMaterialitySurvey);
  const [loading, setLoading] = React.useState(false);
  const [chosenTopics, setChosenTopics] = React.useState<any>([]);
  
  React.useEffect(() => {
    if(currentSurvey?.topicsChosen){
      getChosenTopics()
    }
  }, [currentSurvey]);

  const getChosenTopics = async() => {
    let data:any = await dispatch(getMaterialityTopicsBySurveyId(currentSurvey?.id || 0))
    data.sort((a:MatirialityTopicType,b:MatirialityTopicType)=>a.priority-b.priority)
    setChosenTopics(data);
    dispatch(setSelectedTopics(data))
  }

  const onEdit = async() => {
      // to be optimised
      setLoading(true)
      let data = selectedTopics.map((each:MatirialityTopicType,index:number)=>({
        topic: each.topic,
        priority: index + 1,
        survey: currentSurvey?.id,
      }));
      let flag = true;
      for(let i=0;i<chosenTopics.length;i++){
        let res = await dispatch(deleteMaterialityTopics(chosenTopics[i].id));
        if(!res) flag = false;
      }
      let res = await dispatch(postMaterialityTopics(data))
      if(res && flag){
        BEMessage.success("Topics Updated Successfully");
        dispatch(setCreateSurveyStep(3));
        dispatch(setSelectedTopics([]));
        dispatch(setTopics([]))
      }
      else BEMessage.error("Something went wrong");
      setLoading(false)
  }

  const onSubmit = async() => {
    if(currentSurvey?.topicsChosen) return onEdit()
    setLoading(true)
    let data = selectedTopics.map((each:MatirialityTopicType,index:number)=>({
      topic: each.topic,
      priority: index + 1,
      survey: currentSurvey?.id,
    }))
    let res = await dispatch(postMaterialityTopics(data))
    if(res){
      BEMessage.success("Topics Added Successfully");
      dispatch(setCreateSurveyStep(3));
      dispatch(setSelectedTopics([]));
      dispatch(setTopics([]))
    }
    else BEMessage.error("Something went wrong");
    setLoading(false)
    
  }

  React.useEffect(() => {
    dispatch(getMaterialityIndustries())
  }, []);

  const handleSelect = (e: MatirialityTopicType) => {
    console.log(e)
    if (selectedTopics.findIndex((item: MatirialityTopicType) => item.topic === e.topic) !== -1) {
      dispatch(setSelectedTopics(selectedTopics.filter((item: any) => item.topic !== e.topic)))
    } else {
      dispatch(setSelectedTopics([...selectedTopics, e]))
    }
  }

  const setData = (data: any) => {
    dispatch(setSelectedTopics(data))
  }


  return (
    <div>
      <StepCollapse
        step={2}
        heading='Choose Topics'
        subheading='Select the topics which you want to include in this survey, and also define their order of priority for the company'
        open={props.open}
        setOpen={props.setOpen}
        disabled={!currentSurvey}
      >
        <div className='choose-topics-matiriality'>
            <div>
              <LableRequired>
                Industry
              </LableRequired>
              <BEMultipleSelect
                name='industries'
                onChange={(e: any) => dispatch(getMaterialityTopics(e))}
                labelFeild='title'
                valueFeild='industry_id'
                single
                placeholder='Select Industry'
                data={industries.data}
                loading={industries.status === 'loading'} 
              />
              <div className='topics'>
                {
                  topics.data?.map((item:MatirialityTopicType) => (
                    <div className='topic'>
                      <Checkbox
                      style={{marginBottom:'0.5rem'}}
                        checked={
                          selectedTopics?.findIndex((i:MatirialityTopicType) => i.topic === item.topic) !== -1
                        }
                        onChange={() => handleSelect(item)}
                      >
                        {item.topic}
                      </Checkbox>
                    </div>
                  ))
                }
              </div>
            </div>
          <div>
                <BEDragList
                data={selectedTopics}
                setData={setData}
                field='topic'
                value='id'
                height='20rem'
                />
          </div>
        </div>
        <div style={{padding:'1rem'}}>
        <BEButton 
                loading={loading}
                onClick={onSubmit} size='large' className='primary' disabled={!selectedTopics.length}>Save & Next</BEButton>
                <BEButton size='large' style={{ marginLeft: '1rem' }}
                  onClick={() => props.onCancle()}
                >Cancel</BEButton>
        </div>
      </StepCollapse>
    </div>
  );
}
