import { handleAPICall } from "../../Config/Functions/HandleAPICall";
import { navigateTo } from "../../Navigation/NavigationManager";
import { AppDispatch, RootState } from "../../Redux/store";
import { BEMessage } from "../../Components/BEMessage";
import { 
    GET_ALL_METRICS,
    ADD_METRICS,GET_SELECTED_METRICS,
    DELETE_METRICS,PATCH_SELECTED_METRICS_STATUS,
    GET_METRICS_BY_FRAMEWORK
 } from "../../Utils/Routes/Onboarding";
import {
     setAllMetrics,
     setAllMetricsStatus,
     setAllSelectedMetrics,
     setSelectedMetricsStatus,

} from "../../Redux/OnboardingReducer";
import { getDerivedMetrics } from "../../Features/DataManager/Actions";
import { setFinancialYears } from "../../Redux/CommonReducer";

export const getEveryMetrics = () => async  (dispatch:AppDispatch) => {
    const [derivedMetrics,allMetrics] = await Promise.all([dispatch(getDerivedMetrics()),dispatch(getAllMetrics())])
        dispatch(setAllMetrics({data:[...derivedMetrics.map((item:any)=>{
          return {
            ...item
          }
        })
          ,...allMetrics],
          status:'success'
        }))
}

export const getMetricsByFramework = (metric:string) => async (dispatch:AppDispatch) => {
    const [data, error] = await handleAPICall(GET_METRICS_BY_FRAMEWORK(metric));
    if (data) {
        return data.data;
    }
    else{
        console.log(error);
        return null;
    }
}

export const getAllMetrics = () => async (dispatch:AppDispatch) => {
    dispatch(setAllMetricsStatus('loading'));
    const [data, error] = await handleAPICall(GET_ALL_METRICS());
    if (data) {
        let filteredMetrics =data.data.filter((item:any)=>item.pillars !== "nan" )
        dispatch(setAllMetrics({
            data:filteredMetrics,
            status:'success'
        }));
        return filteredMetrics;
    }
    else{
        console.log(error);
        dispatch(setAllMetricsStatus('error'));
        return null;
    }
}

export const getSelectedMetrics = () => async (dispatch:AppDispatch,getState:()=>RootState) => {
    dispatch(setAllSelectedMetrics({data:[],status:'loading'}))
    const [data, error] = await handleAPICall(GET_SELECTED_METRICS());
    if (data) {
        dispatch(setAllSelectedMetrics({
            data:data.data,
            status:'success'
        }));
    }
    else{
        console.log(error);
        dispatch(setSelectedMetricsStatus('error'));
    }
}

export const patchSelectedMetricsStatus = (id:number,data:any) => async (dispatch:AppDispatch) => {
    const [res, error] = await handleAPICall(PATCH_SELECTED_METRICS_STATUS(id,data));
    if (res) {
        dispatch(getSelectedMetrics());
        BEMessage.success('Status updated successfully');
        return true;    
    }
    else{
        console.log(error);
        return false;
    }
}

export const deleteMetrics = (id:string,setLoading:Function) => async (dispatch:AppDispatch) => {
    setLoading(true);
    const [data, error] = await handleAPICall(DELETE_METRICS(id));
    if (data) {
        dispatch(getSelectedMetrics());
        BEMessage.success('Metric deleted successfully');
    }
    else{
        console.log(error);
    }
    setLoading(false);
}

export const addMetrics = (data:any,setLoading?:Function,setClose?:Function) => async (dispatch:AppDispatch) => {
    setLoading && setLoading(true);
    const [res, error] = await handleAPICall(ADD_METRICS(data));
    if (res) {
        dispatch(getSelectedMetrics());
        setClose &&  setClose();
        setLoading && setLoading(false);
        return true
    }
    else{
        console.log(error);
        BEMessage.error('Failed to add metrics, Please try again later');
        setLoading && setLoading(false);
        return null
    }
    
}
