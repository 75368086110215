import * as React from 'react';
import { VaultBreadCrumb } from '../BreadCrumb';
import { Outlet } from 'react-router-dom';
import { setMyMetricsDM } from '../../../../Redux/DataManagerReducer';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import dayjs from 'dayjs';
import { setFYData } from '../../../../Redux/VaultReducer';
import { getVaultFiles, getVaultFolders } from '../../Actions';
import { getAllReports } from '../../../Reporting/Actions/template';
import { getFYByDate } from '../../../../Config/Functions/UsefullFunctions';
import { ViewTabVault } from '../ViewTab';


export interface IVaultLayoutProps {
}

export function VaultLayout (props: IVaultLayoutProps) {
  const dispatch = useAppDispatch();
  const { allMetrics } = useTypedSelector(state => state.onBoarding.metrics);
  const selectedMetricsRef = useTypedSelector(state => state.onBoarding.metrics.allSelectedMetrics);
  const role = localStorage.getItem('role');
  const MymetricsData = useTypedSelector(state => state.dataManager.metrics.myMetricsData);
  const VaultFolders = useTypedSelector((state) => state.vault.Folders);
  const VaultFiles = useTypedSelector((state) => state.vault.Files);
  const MyMetrics = useTypedSelector(state => state.dataManager.metrics.myMetrics);
  const yearEnd = useTypedSelector(state => state.companies.companyDetails.data?.reporting_year_end);
  const allReports = useTypedSelector(state => state.reporting.allReports);

  React.useEffect(()=>{
    if(allReports.status === 'idle')  dispatch(getAllReports());
    if(VaultFolders.status === 'idle')  dispatch(getVaultFolders());
    if(VaultFiles.status === 'idle')  dispatch(getVaultFiles());
  },[])

  React.useEffect(() => {
    if (allMetrics.status === 'success' && selectedMetricsRef.status === 'success' ) {
      
        let tempMetrics: any = [];

        selectedMetricsRef.data?.map((item: any) => {
            let metric = allMetrics.data?.find((metric: any) => metric.id === item.metric);
            if (metric) {
                tempMetrics.push({
                    ...metric,
                    id: item.id,
                    status: item.status,
                    metricId: metric.id,
                });
            }
        });

        // allMetrics.data?.map((item: any) => {
        //     if (selectedMetricsMap.hasOwnProperty(item.id)) {
        //         const tempItem = {
        //             ...item,
        //             id: selectedMetricsMap[item.id].id,
        //             status: selectedMetricsMap[item.id].status,
        //             metricId: item.id,
        //         }
        //         if (item.annual === true) tempItem['pillars'] = 'A'  // adding a new pillar annual for annual metrics
        //         if (role !== 'CONTRIBUTOR' || item.applicable_to_all !== false) //remove metrics which are not applicable to all for contributor
        //             tempMetrics.push(tempItem)
        //     }
        // });
        dispatch(setMyMetricsDM({
            status: 'success',
            data: tempMetrics
        }));
    }
}, [allMetrics, selectedMetricsRef]);

React.useEffect(() => {
  if(MymetricsData.status === 'success' && MyMetrics.status === 'success'){
    setFYDataFun()
  }
}, [MymetricsData,MyMetrics ]);


const setFYDataFun = async() =>{
  let tempFYData:any = {};
  for (const item of MymetricsData.data) {
    if (item.evidence === null) continue; // Skip if evidence is null

    let yearOfEntry = getFYByDate(item.date_of_entry,yearEnd || ''); // Await the async operation

    // Ensure tempFYData[yearOfEntry] is initialized and mutable
    tempFYData[yearOfEntry] = tempFYData[yearOfEntry] || {};

    // Ensure the metric array exists and is mutable, then push the item
    tempFYData[yearOfEntry][item.my_metric] = tempFYData[yearOfEntry][item.my_metric] || [];
    tempFYData[yearOfEntry][item.my_metric].push(item);
  }
    dispatch(setFYData({
      status: 'success',
      data: tempFYData
    }));
}
  return (
    <div>
        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',margin:'0 3rem'}}>
          <VaultBreadCrumb />
          <ViewTabVault />
        </div>
        <Outlet />
    </div>
  );
}
