import * as React from 'react';
import welcomeImage from '../../../../assets/images/WelcomeSvgs/welcomeConfigurationImage.svg';
import { WelcomeScreen } from '..';

export interface IConfigurstionWelcomeScreenProps {
  onClick:Function;
}

export function ConfigurstionWelcomeScreen (props: IConfigurstionWelcomeScreenProps) {
  return (
    <div>
      <WelcomeScreen
        buttonText='Go to Home'
        description='We are happy to partner with you on your sustainability journey! Our goal at Breathe ESG is to help your company take the best decisions possible as you go about measuring, managing and reporting on ESG data.'
        image={welcomeImage}
        onClick={props.onClick}
      />
    </div>
  );
}
