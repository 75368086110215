import { handleAPICall } from "../../Config/Functions/HandleAPICall";
import { setDerivedMetricsRepresentationGraph } from "../../Redux/SettingsReducer";
import { DerivedMetricsRepresentationGraphType } from "../../Redux/Types/settingsTypes";
import { AppDispatch, RootState } from "../../Redux/store";
import { GET_DERIVED_METRICS_REPRESENTATION_GRAPH } from "../../Utils/Routes/SettingsRouts";

export const getDerivedMetricsRepresentationGraph = () => async (dispatch: AppDispatch) => {
    dispatch(setDerivedMetricsRepresentationGraph({
        status: "loading",
        data: []
    }));
    const [data, error] = await handleAPICall(GET_DERIVED_METRICS_REPRESENTATION_GRAPH());
    if (data) {
        dispatch(setDerivedMetricsRepresentationGraph({
            status: "success",
            data: data.data
        }));    }
    else {
        console.log(error);
        dispatch(setDerivedMetricsRepresentationGraph({
            status: "error",
            data: []
        }));
        dispatch(setDerivedMetricsRepresentationGraph([]));
    }
}

// gets metrics derived_child_metric and child_metric BEcodes from the parent BEcode
export const getMetricsChildrenFromBEcode = (data: DerivedMetricsRepresentationGraphType[], BEcode: string) => {
    const childSet: Set<string> = new Set<string>();
    data.filter((item) => item.derived_metric === BEcode).forEach((item) => {
        if (item.child_metric !== "nan")
            childSet.add(item.child_metric);
        if (item.derived_child_metric !== "nan")
            childSet.add(item.derived_child_metric);
    });
    return Array.from(childSet);
}

export const nestDataBasedOnBECodes = (rootCodes: string[], data: DerivedMetricsRepresentationGraphType[], forDataManager: boolean) => (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const allMetrics = forDataManager ? state.dataManager.metrics.myMetrics : state.onBoarding.metrics.allMetrics;
    const selectedMetrics = state.onBoarding.metrics.selectedMetrics;
    const myDerivedMetrics = state.dataManager.metrics.myDerivedMetrics;
    let graphData: any[] = [];
    if (rootCodes.length === 0) return graphData;
    rootCodes.forEach((code) => {
        const childrenBEcodes = getMetricsChildrenFromBEcode(data, code);
        // const derivedMetricData = data.find((item) => item.derived_metric === code);
        const metricData = allMetrics.data?.find((item) => item.bcode === code);
        const childrenData = dispatch(nestDataBasedOnBECodes(childrenBEcodes, data, forDataManager));

        if (forDataManager) {
            if (!childrenData.length) {
                selectedMetrics.data.filter((metric) => metric.bcode === code).length > 0 &&
                    graphData.push({
                        id: metricData?.id,
                        title: metricData?.title,
                        unit: metricData?.unit,
                        group: metricData?.group,
                        bcode: metricData?.bcode,
                        children: childrenData
                    });
            } else {
                myDerivedMetrics.data.filter((metric) => metric.bcode === code).length > 0 &&
                    graphData.push({
                        id: metricData?.id,
                        title: metricData?.title,
                        unit: metricData?.unit,
                        group: metricData?.group,
                        bcode: metricData?.bcode,
                        children: childrenData
                    });
            }
        } else {
            graphData.push({
                id: metricData?.id,
                title: metricData?.title,
                unit: metricData?.unit,
                group: metricData?.group,
                bcode: metricData?.bcode,
                children: childrenData 
            });
        }
    });
    return graphData;
}

// get all Metrics bcodes of children of a derived metric from its bcode
export const findBECodesOfAllChildren = (data: DerivedMetricsRepresentationGraphType[], BEcodes: string[]) => {
    BEcodes.forEach((code) => {
        const childrenBEcodes = getMetricsChildrenFromBEcode(data, code);
        BEcodes.push(...findBECodesOfAllChildren(data, childrenBEcodes));
    })
    return BEcodes;
}

export const findBECodesOfAllParents = (data: DerivedMetricsRepresentationGraphType[], BEcodes: string[]) => {
    BEcodes.forEach((code) => {
        const parentBEcodes = Array.from(new Set<string>(data.filter((item) => item.child_metric === code || item.derived_child_metric === code).map((item) => item.derived_metric)));
        BEcodes.push(...findBECodesOfAllParents(data, parentBEcodes));
    })
    return BEcodes;
}



// for the derived metrics representation graph to find Roots
// const findRootsOfGraphs = (data: DerivedMetricsRepresentationGraphType[]) => {
//     let childSet: Set<string> = new Set<string>();
//     let rootSet: Set<string> = new Set<string>();
//     data.forEach((item: any) => {
//         if (item.child_metric !== "nan")
//             childSet.add(item.child_metric);
//         if (item.derived_child_metric !== "nan")
//             childSet.add(item.derived_child_metric);
//     });
//     data.map((item) => {
//         if (!childSet.has(item.derived_metric)) {
//             rootSet.add(item.derived_metric);
//         }
//     });
//     return Array.from(rootSet);
// }

// const nestDataFromListToGraph = (data: DerivedMetricsRepresentationGraphType[]) => (dispatch: AppDispatch) => {
//     const BEcodesOfRootNodes = findRootsOfGraphs(data);
//     const nestedData = dispatch(nestDataBasedOnBECodes(BEcodesOfRootNodes, data));
//     return nestedData;
// }