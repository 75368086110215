import React from 'react'
import IconProps from './Interface/IconProps'

const CheckOutlineIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 24}
      height={props.inheritSize ? "100%" : 24}
      viewBox="0 0 24 24"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1_2250)">
        <path
          d="M18.0003 0.857422H6.00028C3.15996 0.857422 0.857422 3.15996 0.857422 6.00028V18.0003C0.857422 20.8407 3.15996 23.1431 6.00028 23.1431H18.0003C20.8407 23.1431 23.1431 20.8407 23.1431 18.0003V6.00028C23.1431 3.15996 20.8407 0.857422 18.0003 0.857422Z"
          stroke={props.stroke ? props.stroke : "white"}
          stroke-width="1.71429"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.0006 8.14258L10.1434 16.714L6.71484 14.1426"
          stroke={props.stroke ? props.stroke : "white"}
          stroke-width="1.71429"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2250">
          <rect
            width={props.inheritSize ? "100%" : 24}
            height={props.inheritSize ? "100%" : 24}
            fill={props.fill ? props.fill : "white"}
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CheckOutlineIcon