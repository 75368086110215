import { Checkbox, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { BEInput } from "../../../../../Components/BEFormItems/BEInput";
import { PrimaryTheme } from "../../../../../Config/Theme/theames";
import { BEButton } from "../../../../../Components/BEFormItems/BEButton";
import { Frameworks } from "../../../../../data/frameworks";
import { useTypedSelector } from "../../../../../Config/Hooks/useTypedSelector";
import { useAppDispatch } from "../../../../../Config/Hooks/useAppDispatch";
import { setSelectedFrameworks } from "../../../../../Redux/OnboardingReducer";
import ChevronDownIcon from "../../../../../Components/BEIcons/ChevronDownIcon";
import CrossIcon from "../../../../../Components/BEIcons/CrossIcon";
import { Radio } from "antd";
import { getMetricsByFramework, addMetrics } from "../../../../Actions/OnboardingStep4Actions";
import { BEMessage } from "../../../../../Components/BEMessage";
import useOutsideClick from "../../../../../Config/Hooks/useOutsideClick";

const MetricSelector = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [frameworks, setFrameworks] = useState<any>(['BRSR']);
  const selectedFrameworks = useTypedSelector((state) => state.onBoarding.frameworks.selectedFrameworks);
  const [selected, setSelected] = useState<any>('');
  const [loading, setLoading] = useState(false);
  const selectedMetrics = useTypedSelector((state) => state.onBoarding.metrics.selectedMetrics.data);
  const metricsYear = useTypedSelector((state) => state.onBoarding.metrics.currentYear);
  const ref = React.useRef(null);

  useOutsideClick(ref, () => {
    setOpen(false);
  });

  const handleApply = async () => {
    if (selected === '') {
      BEMessage.error('Please select a framework')
      return;
    }
    setLoading(true);
    const frameworkMetrics = await dispatch(getMetricsByFramework(selected));
    let metricsToAdd: any = [];
    frameworkMetrics.map((item: any) => {
      if (!selectedMetrics.find((i: any) => i.metric === item.id)) {
        metricsToAdd.push({
          metric: item.id,
          year: metricsYear,
          bcode: item.bcode
        })
      }
    })
    if (metricsToAdd.length === 0) {
      BEMessage.info('All metrics corresponding to this framework are already added')
      setOpen(false);
      setLoading(false);
      return;
    }
    let res2 = await dispatch(addMetrics(metricsToAdd));
    if (res2) BEMessage.success(`${metricsToAdd.length} metrics corresponding to ${selected} framework added successfully`)
    // else BEMessage.error('Something went wrong')
    setLoading(false);
    setOpen(false);
  }

  return null;
  return (
    <div
      ref={ref}
      style={{
        zIndex: 4,
        width: "300px",
        position: 'relative',
        top: '0.25rem'
      }}
    >
      {/* <div className="top-text">
        Apply framework specific metrics:
      </div> */}
      <BEInput
        placeholder="Apply framework specific metrics:"
        size="large"
        value={selected}
        onClick={() => setOpen(!open)}
        // onChange={(e) => {
        //   const filteredData = Frameworks.filter((fr) =>
        //     fr.code.toLowerCase().includes(e.target.value.toLowerCase())
        //   );
        //   setFrameworks(filteredData);
        // }}
        suffix={
          <div
            style={{ display: "flex", alignItems: "center", height: open ? '0.5em' : '1rem', cursor: 'pointer' }}
            onClick={() => setSelected([])}
          >
            {open ? (
              <CrossIcon stroke={PrimaryTheme.primaryGray} inheritSize />
            ) : (
              <ChevronDownIcon fill={PrimaryTheme.primaryGray} inheritSize />
            )}
          </div>
        }
      />
      <div
        style={{
          display: open ? "inherit" : "none",
          width:'90%',
          position: "absolute",
          padding: "1rem",
          background: "white",
          borderRadius: "8px",
          border: `1px solid ${PrimaryTheme.primaryGreenLight}`,
          boxSizing: "content-box",
          boxShadow: `0px 4px 6px 2px ${PrimaryTheme.primaryGreyLight}`,
        }}
      >
        {/* <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
          {frameworks.map((framework: any) => {
            return (
              <Checkbox
                style={{ marginBottom: "0.5rem", width: "50%" }}
                onChange={() => handleChange(framework)}
                checked={
                  selected?.findIndex((i: any) => i.code === framework.code) !==
                  -1
                }
              >
                {framework.code}
              </Checkbox>
            );
          })}
        </div> */}
        <Radio.Group
          onChange={(e) => {
            setSelected(e.target.value);
          }}
          value={selected}
        >
          {frameworks.map((framework: any) => {
            return (
              <Radio
                style={{ marginBottom: "0.5rem", width: "50%" }}
                value={framework}
              >
                {framework}
              </Radio>
            );
          })}
        </Radio.Group>

        <BEButton
          loading={loading}
          className="primary"
          size="large"
          style={{ width: "100%" }}
          onClick={() => {
            handleApply();
          }}
        >
          Apply
        </BEButton>
      </div>
    </div>
  );
};

export default MetricSelector;
