import * as React from 'react';
import { BETabButton } from '../../../../Components/BETabButton';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import UsersIcon from '../../../../Components/BEIcons/UsersIcon';
import DataEntryIcon from '../../../../Components/BEIcons/DataEntryIcon';
import { Select } from 'antd';
import { useLocation,useNavigate } from 'react-router-dom';
import './style.scss';
import { setAssessmentYear } from '../../../../Redux/SupplierReducer';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import BEMultipleSelect from '../../../../Components/BEMultipleSelect';
import { YearSelectOptions } from '../../../../Components/BEMultipleSelect/YearSelectOptions';
import { setDateSlicerYearDM } from '../../../../Redux/DataManagerReducer';

export interface ITopSectionProps {
}

export function TopSection (props: ITopSectionProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const assessmentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const yearEnd = useTypedSelector(state => state.companies.yearEnd);

  return (
    <div className='global-top-section' >
      <BETabButton
        title='SUPPLIER ASSESSMENTS'
        icon={<DataEntryIcon stroke={
          // PrimaryTheme.primaryGreen
          location.pathname.includes('assessment') ? PrimaryTheme.primaryGreen : PrimaryTheme.primaryGray
        }/>}
        active={location.pathname.includes('assessment')}
        onClick={() => navigate('/home/suppliers/assessments')}
      />
      <BETabButton
        title='SUPPLIERS'
        icon={<UsersIcon fill={location.pathname.includes('all-suppliers') ? PrimaryTheme.primaryGreen : PrimaryTheme.primaryGray
        }/>}
        active={location.pathname.includes('all-suppliers')}
        onClick={() => navigate('/home/suppliers/all-suppliers')}
      />
      {
        <div className='year' style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', gap: '0.5rem' }}>
          <YearSelectOptions
          disabled={location.pathname !== '/home/suppliers/assessments'}
            onChange={(value:number) => dispatch(setDateSlicerYearDM(value))}
            value={assessmentYear}
          />
        </div>
      }
    </div>
  );
}
