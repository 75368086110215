import * as React from 'react';
import AddTemplateStep1 from './Steps/Step1';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { Frameworks } from '../../../../../data/frameworks';
import './style.scss';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import AddTemplateStep2 from './Steps/Step2';
import BEConfirmModal from '../../../../../Components/BEConfirmModal';
import { EditTemplate } from '../EditTemplate';

export interface IAddTemplateProps {
  open: boolean;
  setOpen: Function;
}

export function AddTemplate(props: IAddTemplateProps) {
  const step1Ref = React.useRef<any>({ onButtonClick: () => { } })
  const step2Ref = React.useRef<any>({ onButtonClick: () => { } })
  const [step, setStep] = React.useState(1);
  React.useEffect(() => {
    if (props.open) {
      setStep(1);
    }
  }, [props.open])
  return (
    <>
      <BEDrawer
        heading='Add template'
        secondryHeading={step === 1 ?
          {
            heading: 'Step 1: Choose your framework',
            subHeading: 'Select the framework you want to use for reporting.',
            theame: 'light'
          }
          :
          {
            heading: 'Step 2: Design your template',
            subHeading: 'Design you template by adding name.',
            theame: 'light'

          }
        }
        open={props.open}
        setOpen={props.setOpen}
        width='100%'
        footer={
          <>
            <BEButton className='primary' size='large' onClick={async () => {
              if (step === 1) {
                if (step1Ref?.current?.onButtonClick)
                  await step1Ref.current.onButtonClick();
              } else {
                if (step2Ref?.current?.onButtonClick) {
                  await step2Ref.current.onButtonClick();
                }
              }
            }}>Select & Next</BEButton>
            <BEButton size='large' onClick={() => props.setOpen(false)}>Cancel</BEButton>
          </>
        }
      >

        <div className='add-template'>
          {
            step === 1 &&
            <AddTemplateStep1
              ref={step1Ref}
              setStep={setStep}
            />
          }
          {
            step === 2 &&
            <AddTemplateStep2
              ref={step2Ref}
              setStep={setStep}
              setOpenEditTemplate={props.setOpen}
            />
          }
        </div>

      </BEDrawer>
    </>
  );
}
