import * as React from 'react';
import { Pagination } from 'antd';
import './style.scss';

export interface IBEPaginationProps {
    total: number;
    pageSize: number;
    onChange?: (page: number) => void;
    defaultCurrent?: number;
    current?: number;
}
//extream right and left to be added
export function BEPagination(props: IBEPaginationProps) {
    return (
        <div className='be-pagination'>
            <Pagination
                defaultCurrent={
                    props.defaultCurrent ? props.defaultCurrent : 1
                }
                onChange={props.onChange}
                showSizeChanger={false} total={props.total}
                pageSize={props.pageSize}
                current={props.current}
            />
        </div>
    );
}
