import * as React from 'react';
import SaveIcon from '../../../../Components/BEIcons/SaveIcon';
import './style.scss'
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import Fuel from '../../../../assets/images/OnboardingImages/fuel.png';
import Energy from '../../../../assets/images/OnboardingImages/energy.png';
import Air from '../../../../assets/images/OnboardingImages/air.png';
import Forests from '../../../../assets/images/OnboardingImages/forest.png';
import Recycle from '../../../../assets/images/OnboardingImages/RecycleIcon.png';
import Reuse from '../../../../assets/images/OnboardingImages/reuse.png';
import Waste from '../../../../assets/images/OnboardingImages/waste.png';
import WaterDrop from '../../../../assets/images/OnboardingImages/water_drop.png';
import WaterDropFilled from '../../../../assets/images/OnboardingImages/water_drop_filled.png';
import Water from '../../../../assets/images/OnboardingImages/water.png';
import TreatedWater from '../../../../assets/images/OnboardingImages/TreatedWater.png';
import Employee from '../../../../assets/images/OnboardingImages/person_2.png';
import PermanentEmployees from '../../../../assets/images/OnboardingImages/person_pin_circle.png';
import NonPermanentEmployees from '../../../../assets/images/OnboardingImages/people_alt.png';
import OnlyWorkers from '../../../../assets/images/OnboardingImages/engineering.png';
import OnlyEmployees from '../../../../assets/images/OnboardingImages/Id Card.png';
import Groups from '../../../../assets/images/OnboardingImages/groups.png';
import Diversity from '../../../../assets/images/OnboardingImages/diversity_3.png';


export interface INoMetricMessageProps {
    setOpenAdd: Function
}

export function NoMetricMessage(props: INoMetricMessageProps) {
    const currentGroup: string = useTypedSelector(state => state.onBoarding.metrics.currentGroup);
    const [currentGroupIndex, setCurrentGroupIndex] = React.useState(-1);

    React.useEffect(() => {
        console.log(currentGroup);
        setCurrentGroupIndex(iconChoose.findIndex(item => item.name === currentGroup));
    }, [currentGroup]);

    const iconChoose = [
        {
            name: "Fuels",
            icon: Fuel
        },
        {
            name: "Electricity",
            icon: Energy
        },
        {
            name: "Air pollutants generated",
            icon: Air
        },
        {
            name: "Biodiversity impacts",
            icon: Forests
        },
        {
            name: "Recycling",
            icon: Recycle
        },
        {
            name: "Re-use",
            icon: Reuse
        },
        {
            name: "Waste generated and disposed",
            icon: Waste
        },
        {
            name: "Other recovery operations",
            icon: Waste
        },
        {
            name: "Treated water discharge",
            icon: TreatedWater
        },
        {
            name: "Water use in areas of water stress",
            icon: Water
        },
        {
            name: "Water consumption",
            icon: Water
        },
        {
            name: "Untreated water discharge",
            icon: WaterDropFilled
        },
        {
            name: "Total Water discharge",
            icon: WaterDrop
        },
        {
            name: "Employees and/or workers",
            icon: Employee
        },
        {
            name: "Only employees",
            icon: OnlyEmployees
        },
        {
            name: "Only workers",
            icon: OnlyWorkers
        },
        {
            name: "Only employees:permanent",
            icon: PermanentEmployees
        },
        {
            name: "Only employees:non-permanent",
            icon: NonPermanentEmployees
        },
        {
            name: "Measure of Board diversity",
            icon: Diversity
        },
        {
            name: "Operations",
            icon: Groups
        },
        {
            name: "Occupancy",
            icon: Groups
        },
        {
            name: "Sales",
            icon: Groups
        },
        {
            name: "Patents and Trademarks",
            icon: Groups
        }
    ];

    return (
        <div className='empty-state'>
            <div className='img'>
                {
                    currentGroupIndex === -1 ?
                        <SaveIcon fill={PrimaryTheme.primaryGreenDark}/>
                        :
                        <img src={iconChoose[currentGroupIndex]?.icon} alt={iconChoose[currentGroupIndex]?.name} />
                }
            </div>
            <p>Click the button below to add {currentGroup} metrics</p>
            <BEButton
                onClick={() => props.setOpenAdd(true)}
                className='primary' size='large'> + Add Metrics</BEButton>
        </div>
    );
}
