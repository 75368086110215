import * as React from 'react';
import { EditTemplateTopSection } from './TopSection';
import { EditTemplateMainSection } from './MainSection';

export interface IRightSectionEditTemplateProps {
}

export function RightSectionEditTemplate (props: IRightSectionEditTemplateProps) {
    const title = "Fetch from redux"
  return (
    <>
        {/* <EditTemplateTopSection title={title} /> */}
        <EditTemplateMainSection />
    
    </>
  );
}
