import { Meta, StoryObj } from "@storybook/react";
import BEAddIcon from "../Components/BEIcons/AddIcon";
import BEArrowDownIcon from "../Components/BEIcons/ArrowDownIcon";
import BECheckFilledIcon from "../Components/BEIcons/CheckFilledIcon";
import BECheckIcon from "../Components/BEIcons/CheckIcon";
import BECheckOutlineIcon from "../Components/BEIcons/CheckOutlineIcon";
import BEChevronDownIcon from "../Components/BEIcons/ChevronDownIcon";
import BEChevronLeftIcon from "../Components/BEIcons/ChevronLeftIcon";
import BEChevronRightIcon from "../Components/BEIcons/ChevronRightIcon";
import BEChevronUpIcon from "../Components/BEIcons/ChevronUpIcon";
import BECloseIcon from "../Components/BEIcons/CloseIcon";
import BECompanyIcon from "../Components/BEIcons/CompanyIcon"
import BEDeleteIcon from "../Components/BEIcons/DeleteIcon";
import BEDownloadIcon from "../Components/BEIcons/DownloadIcon";
import BEEditIcon from "../Components/BEIcons/EditIcon";
import BEEntityMappingIcon from "../Components/BEIcons/EntityMappingIcon";
import BEErrorIcon from "../Components/BEIcons/ErrorIcon";
import BEESGMetricsIcon from "../Components/BEIcons/ESGMetricsIcon";
import BEFilterIcon from "../Components/BEIcons/FilterIcon";
import BEInfoFilledIcon from "../Components/BEIcons/InfoFilledIcon";
import BEInfoIcon from "../Components/BEIcons/InfoIcon";
import BEProgressIcon from "../Components/BEIcons/ProgressIcon";
import BESaveIcon from "../Components/BEIcons/SaveIcon";
import BESearchIcon from "../Components/BEIcons/SearchIcon";
import BETextAreaIcon from "../Components/BEIcons/TextAreaIcon";
import BEUploadedIcon from "../Components/BEIcons/UploadedIcon";
import BEUploadIcon from "../Components/BEIcons/UploadIcon";
import BEUsersIcon from "../Components/BEIcons/UsersIcon";
import BEWarningIcon from "../Components/BEIcons/WarningIcon";
import Icon from "./Icon";

export const AddIcon = () => {
  return (
    <Icon>
      <BEAddIcon fill="black" stroke="black" />
    </Icon>
  );
}

export const ArrowDownIcon = () => {
  return (
    <Icon>
      <BEArrowDownIcon fill="black" stroke="black" />
    </Icon>
  );
};


export const CheckFilledIcon = () => {
  return (
    <Icon>
      <BECheckFilledIcon />
    </Icon>
  );
};
export const CheckIcon = () => {
  return (
    <Icon>
      <BECheckIcon stroke="black" />
    </Icon>
  );
};
export const CheckOutlineIcon = () => {
  return (
    <Icon>
      <BECheckOutlineIcon stroke="black" />
    </Icon>
  );
};
export const ChevronDownIcon = () => {
  return (
    <Icon>
      <BEChevronDownIcon fill="black" stroke="black" />
    </Icon>
  );
};
export const ChevronLeftIcon = () => {
  return (
    <Icon>
      <BEChevronLeftIcon fill="black" stroke="black" />
    </Icon>
  );
};
export const ChevronRightIcon = () => {
  return (
    <Icon>
      <BEChevronRightIcon fill="black" stroke="black" />
    </Icon>
  );
};
export const ChevronUpIcon = () => {
  return (
    <Icon>
      <BEChevronUpIcon fill="black" stroke="black" />
    </Icon>
  );
};
export const CloseIcon = () => {
  return (
    <Icon>
      <BECloseIcon fill="black" stroke="black" />
    </Icon>
  );
};
export const CompanyIcon = () => {
  return (
    <Icon>
      <BECompanyIcon stroke="black" />
    </Icon>
  );
};
export const DeleteIcon = () => {
  return (
    <Icon>
      <BEDeleteIcon stroke="black" />
    </Icon>
  );
};
export const DownloadIcon = () => {
  return (
    <Icon>
      <BEDownloadIcon fill="black" stroke="black" />
    </Icon>
  );
};
export const EditIcon = () => {
  return (
    <Icon>
      <BEEditIcon stroke="black" />
    </Icon>
  );
};
export const EntityMappingIcon = () => {
  return (
    <Icon>
      <BEEntityMappingIcon fill="black" stroke="black" />
    </Icon>
  );
};
export const ErrorIcon = () => {
  return (
    <Icon>
      <BEErrorIcon />
    </Icon>
  );
};
export const ESGMetricsIcon = () => {
  return (
    <Icon>
      <BEESGMetricsIcon fill="black" stroke="black" />
    </Icon>
  );
};
export const FilterIcon = () => {
  return (
    <Icon>
      <BEFilterIcon fill="black" stroke="black" />
    </Icon>
  );
};

export const InfoFilledIcon = () => {
  return (
    <Icon>
      <BEInfoFilledIcon stroke="black" />
    </Icon>
  );
};

export const InfoIcon = () => {
  return (
    <Icon>
      <BEInfoIcon fill="black" stroke="black" />
    </Icon>
  );
};

export const ProgressIcon = () => {
  return (
    <Icon>
      <BEProgressIcon fill="black" stroke="black" />
    </Icon>
  );
};

export const SaveIcon = () => {
  return (
    <Icon>
      <BESaveIcon fill="black" stroke="black" />
    </Icon>
  );
};
export const SearchIcon = () => {
  return (
    <Icon>
      <BESearchIcon stroke="black" />
    </Icon>
  );
};
export const TextArea = () => {
  return (
    <Icon>
      <BETextAreaIcon fill="black" stroke="black" />
    </Icon>
  );
};
export const UploadedIcon = () => {
  return (
    <Icon>
      <BEUploadedIcon stroke="black" />
    </Icon>
  );
};
export const UploadIcon = () => {
  return (
    <Icon>
      <BEUploadIcon stroke="black" />
    </Icon>
  );
};
export const UsersIcon = () => {
  return (
    <Icon>
      <BEUsersIcon fill="black" stroke="black" />
    </Icon>
  );
};
export const WarningIcon = () => {
  return (
    <Icon>
      <BEWarningIcon fill="black" stroke="black" />
    </Icon>
  );
};


const meta = {
  title: "Icon",
  component: Icon,
  parameters: {
    layout: "fullscreen",
  },
} satisfies Meta<typeof Icon>;

export default meta;