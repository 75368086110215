import { Progress } from 'antd';
import * as React from 'react';
import './style.scss';
import { PrimaryTheme } from '../../Config/Theme/theames';

export interface IBEProgressBarProps extends React.HTMLAttributes<HTMLDivElement> {
  width?: string;
  valuePercent: number;
  textColor?: string;
}

export function BEProgressBar(props: IBEProgressBarProps) {
  return (
    <div>
      <div style={{ fontSize: '0.75rem', fontWeight: 500, color: props?.textColor ? props?.textColor : "white" }}>Overall Progress:{" "}
        <span style={{ color: PrimaryTheme.primaryGreen }}>
          {props.valuePercent}%
        </span>
      </div>
      <Progress
        className='progress-bar'
        showInfo={false}
        strokeColor={PrimaryTheme.primaryGreen} strokeWidth={8} trailColor='white'
        percent={props.valuePercent}
        style={{ width: props.width ? props.width : '180px' }} />
    </div>
  );
}
