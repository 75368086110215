import type { StatusType } from "../Types/userTypes";
import { EntityType } from "../Types/entityTypes";

import {
    Reducer,
    createSlice,
} from "@reduxjs/toolkit";
import { clearStore } from "../ExtraReducrActions";


const initialState: EntityType = {
    BusinessGroups:{
        status:'idle',
        data:[]
    },
    BusinessUnits:{
        status:'idle',
        data:[]
    },
    BusinessUnitContributors:{
        status:'idle',
        data:[]
    },
    BusinessUnitRefs:{
        status:'idle',
        data:[]
    },
    RecentlyViewedGroups:{
        status:'idle',
        data:[]
    },
    RecentlyViewedUnits:{
        status:'idle',
        data:[]
    }
}
export const userSlice = createSlice({
    name: "user",
    initialState,
    extraReducers: (builder) => builder.addCase(clearStore, () => initialState),
    reducers: {
        setBusinessGroups: (state, action) => {
            state.BusinessGroups = action.payload;
        },
        setBusinessGroupsStatus: (state, action) => {
            state.BusinessGroups.status = action.payload;
        },
        setBusinessUnits: (state, action) => {
            state.BusinessUnits = action.payload;
        },
        setBusinessUnitsStatus: (state, action) => {
            state.BusinessUnits.status = action.payload;
        },
        setBusinessUnitContributors: (state, action) => {
            state.BusinessUnitContributors = action.payload;
        },
        setBusinessUnitRefs: (state, action) => {
            state.BusinessUnitRefs = action.payload;
        },
        setRecentlyViewedGroups: (state, action) => {
            state.RecentlyViewedGroups = action.payload;
        },
        setRecentlyViewedUnits: (state, action) => {
            state.RecentlyViewedUnits = action.payload;
        },
    }
});

export const {
    setBusinessGroups,
    setBusinessUnits,
    setBusinessUnitContributors,
    setBusinessUnitRefs,
    setRecentlyViewedGroups,
    setRecentlyViewedUnits,
} = userSlice.actions;

const entityReducer: Reducer<EntityType> = userSlice.reducer;

export default entityReducer;
