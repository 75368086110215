
import * as React from 'react';
import './style.scss';
import { TopTabsFilter } from './TopTabsFilter';
import { getEveryMetrics, getSelectedMetrics } from '../../Actions/OnboardingStep4Actions';
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { GlobalLoader } from '../../../Components/GlobalLoader';
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';
import { OnboardingTopNav } from '../OnboardingView/OnboardingTopNav';
import { getMyDerivedMetrics } from '../../../Features/DataManager/Actions';
import { setFullMyDerivedMetricsDM } from '../../../Redux/DataManagerReducer';
import { setMetricCurrentYear, setSelectedMetrics } from '../../../Redux/OnboardingReducer';
import { getCompanyDetails } from '../../Actions/OnboardingActions';
import { getFYByDate} from '../../../Config/Functions/UsefullFunctions';
import dayjs from 'dayjs';

export interface IStep4Props {
  isNotOnboarding?: boolean;
}



export function Step4(props: IStep4Props) {
  const dispatch = useAppDispatch()
  const allMetricsStatus = useTypedSelector(state => state.onBoarding.metrics.allMetrics.status)
  const companyDetails = useTypedSelector(state => state.companies.companyDetails);
  const allSelectedMetrics = useTypedSelector(state => state.onBoarding.metrics.allSelectedMetrics);
  const metricsCurrentYear = useTypedSelector(state => state.onBoarding.metrics.currentYear);                   //current selected year
  const allFullMyDerivedMetrics = useTypedSelector(state => state.dataManager.metrics.allFullMyDerivedMetrics); // all selected derived metrics

  React.useEffect(() => {
    if (allSelectedMetrics.status === 'idle') dispatch(getSelectedMetrics())
    dispatch(getMyDerivedMetrics())
    if (companyDetails.status === 'idle') dispatch(getCompanyDetails())
    dispatch(getEveryMetrics())
  }, []);

  React.useEffect(() => {
    // ******************* setting the default FY for the metrics *******************
    if (companyDetails.status === 'success') {
      console.log('companyDetails', companyDetails)
      dispatch(setMetricCurrentYear(getFYByDate(dayjs().format('YYYY-MM-DD'), companyDetails.data?.reporting_year_end || '2024-02-01')))
    }
    console.log('current year', metricsCurrentYear)
  }, [companyDetails])

  React.useEffect(() => {
    // ******************* setting the selected metrics and selected derived metrics for the current year from the all metrics *******************
    if (allSelectedMetrics.status === 'success') {
      dispatch(setSelectedMetrics({
        data: allSelectedMetrics.data.filter((item: any) => item.year === metricsCurrentYear),
        status: 'success'
      }))
    }
    if (allFullMyDerivedMetrics.status === 'success') {
      dispatch(setFullMyDerivedMetricsDM({
        data: allFullMyDerivedMetrics.data.filter((item: any) => item.year === metricsCurrentYear),
        status: 'success'
      }))
    }
  }, [allSelectedMetrics, metricsCurrentYear, allFullMyDerivedMetrics])




  return (
    <div className="onboarding-step-4">
      {!props.isNotOnboarding && <OnboardingTopNav title="Select and add the ESG Metrics that your company want to report" />}
      {allMetricsStatus === "success" ? (
        <>
          <TopTabsFilter />
        </>
      ) : (
        <GlobalLoader height="80vh" />
      )}
    </div>
  );
}
