import * as React from 'react';
import { Table } from 'antd';
import { ColumnType, ColumnsType } from 'antd/es/table';
import './style.scss';
import { TableActions } from './TableActions';
import ArrowDownIcon from '../BEIcons/ArrowDownIcon';
import { PrimaryTheme } from '../../Config/Theme/theames';
import FilterIcon from '../BEIcons/FilterIcon';
import { Checkbox } from 'antd';
import pagination from '../../stories/CardPagination.stories';
import { BEInput } from '../BEFormItems/BEInput';
import { setSelectedRow } from '../../Redux/CommonReducer';
import { useAppDispatch } from '../../Config/Hooks/useAppDispatch';
import './style.scss';

interface ObjectType {
  [key: string]: string // {keyname: 'value'} to filter on
}

export interface INewBETableProps {
  tableProps?: any

  formatTitle?: boolean;
  data: Array<any>,
  columns: ColumnsType<any>,

  handleEdit?: Function
  handleDelete?: Function

  loading?: boolean,
  pagination?: boolean
  rowKey?: string          //id by default

  setSelectedRows?: Function
  selectedRows?: Array<any>   // keys of selected rows
  disabledRows?: Array<any>     // keys of disabled rows
  // defaultSelectedRows?: Array<React.Key>                                        //diectly set selected rows for default selection************

  className?: string
  rowClassName?: any

  search?: boolean
  searchText?: string
  searchOnColumns?: ObjectType  // columns to search on  // {keyname: 'value'} to filter on
  filterColumnObject?: any

  tableTopLeftComponents?: React.ReactNode
  TableTopRightComponents?: React.ReactNode
}

export function NewBETable(props: INewBETableProps) {

  const dispatch = useAppDispatch();
  const [page, setPage] = React.useState<number>(1);
  const [coloumns, setColoumns] = React.useState<ColumnsType<any>>([]);
  const [rowsOfthisPage, setRowsOfthisPage] = React.useState<Array<any>>([]);   // this is used when pagination is on to show only the rows of this page
  const [filteredRows, setFilteredRows] = React.useState<any>([]);       //this is all the rows after filtering and searching
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [columnSearchValue, setColumnSearchValue] = React.useState<string>('');
  const [lastFilterdRows, setLastFilterdRows] = React.useState<any>([]);  // this is used to check if filteredRows has changed or not

  // set column symbols for sorting and filtering
  React.useEffect(() => {
    let tempColumns: ColumnsType<any> = [];
    if (props.selectedRows && props.setSelectedRows) {   // handle select all checkbox and selected rows
      tempColumns.push({
        title: <Checkbox
          // checked if all the rowkeys of this page are in selected rows if paginagtion is on else check if all the rowkeys of filtered rows are in selected rows
          checked={
            props.pagination ?
              filteredRows.slice((page - 1) * 10, page * 10).every((item: any) => props.selectedRows?.includes(item[props.rowKey || 'id']))
              :
              filteredRows.every((item: any) => props.selectedRows?.includes(item[props.rowKey || 'id']))
          }
          disabled={
            //if all rows of this page are in disabled rows if pagination is on else if all rows of filtered rows are in disabled rows
            !props.disabledRows ? false :
              props.pagination ?
                filteredRows.slice((page - 1) * 10, page * 10).every((item: any) => props.disabledRows?.includes(item[props.rowKey || 'id']))
                :
                filteredRows.every((item: any) => props.disabledRows?.includes(item[props.rowKey || 'id']))
          }
          onChange={(e) => {
            if (!props.setSelectedRows || !props.selectedRows) return
            if (!pagination) {
              let filteredKeys: any =
                !props.disabledRows ?
                  filteredRows.map((item: any) => item[props.rowKey || 'id']) :
                  filteredRows.filter((item: any) => !props.disabledRows?.includes(item[props.rowKey || 'id'])).map((item: any) => item[props.rowKey || 'id'])
              // console.log(filteredKeys,'<--')
              const selectedRowsExeptFilteredRows = props.selectedRows.filter((item: any) => !filteredKeys.includes(item))
              if (e.target.checked) {
                props.setSelectedRows([...selectedRowsExeptFilteredRows, ...filteredRows.map((item: any) => item[props.rowKey || 'id'])])
              } else {
                props.setSelectedRows(selectedRowsExeptFilteredRows)
              }
            }
            else {
              let thisPageKeys:any =
                !props.disabledRows ?
                  filteredRows.slice((page - 1) * 10, page * 10).map((item: any) => item[props.rowKey || 'id']) :
                  filteredRows.slice((page - 1) * 10, page * 10).filter((item: any) => !props.disabledRows?.includes(item[props.rowKey || 'id'])).map((item: any) => item[props.rowKey || 'id'])
              // console.log(thisPageKeys,'<--')
                  const selectedRowsExeptRowsOfthisPage =
                props.selectedRows.filter((item: any) => !thisPageKeys.includes(item))
              if (e.target.checked) {
                props.setSelectedRows([...selectedRowsExeptRowsOfthisPage, ...filteredRows.slice((page - 1) * 10, page * 10).map((item: any) => item[props.rowKey || 'id'])])
              } else {
                props.setSelectedRows(selectedRowsExeptRowsOfthisPage)
              }
            }
          }}
        />,
        key: 'select',
        render: (text: any, record: any) => {
          return <Checkbox
            disabled={props.disabledRows?.includes(record[props.rowKey || 'id'])}
            checked={props.selectedRows?.includes(record[props.rowKey || 'id'])}
            onChange={(e) => {
              if (!props.setSelectedRows || !props.selectedRows) return
              if (e.target.checked) {
                props.setSelectedRows([...props.selectedRows, record[props.rowKey || 'id']])
              } else {
                props.setSelectedRows(props.selectedRows.filter((item: any) => item !== record[props.rowKey || 'id']))
              }
            }}
          />
        }
      })
    }

    props.columns.map((column) => {
      tempColumns.push({
        ...column,
        title: !column.title ? '' : (typeof column.title === "string" && !props.formatTitle) ? String(column.title)?.toUpperCase() : column.title,
        sortIcon: column.sorter ? ({ sortOrder: SortOrder }) => !SortOrder ?
          <div style={{ height: '16px' }}><ArrowDownIcon inheritSize /></div> : SortOrder === 'ascend' ?
            <div style={{ rotate: '180deg', margin: 0, padding: 0, height: '16px' }}><ArrowDownIcon inheritSize fill={PrimaryTheme.primaryGreen} /></div> :
            <div style={{ height: '16px' }}><ArrowDownIcon inheritSize fill={PrimaryTheme.primaryGreen} /></div> : undefined,
        filterIcon: column.filters ? <div style={{ height: '16px' }}><FilterIcon inheritSize /></div> : undefined,
        showSorterTooltip: false
      });
    })
    if (props.handleDelete || props.handleEdit) {
      tempColumns.push({
        title: 'Actions', render: (record) =>
          <div onMouseEnter={() => { dispatch(setSelectedRow(record)); }}>
            <TableActions
              handleDelete={props.handleDelete}
              handleEdit={props.handleEdit}
            />
          </div>
      })
    }
    setColoumns(tempColumns);
  }, [props.columns, filteredRows, props.disabledRows, page]);
  // *************************************** //

  // handle rows to show in table acoording to filter,search and pagination

  React.useEffect(() => {
    // use filterColumnObject to filter rows
    let filteredDataBYFilterColumnObject: any[] = []
    if (props.filterColumnObject) {
      props.data.map((item: any) => {
        let isItemValid = true;
        Object.keys(props.filterColumnObject).map((key) => {
          if (item[key] !== props.filterColumnObject[key]) isItemValid = false
        })
        if (isItemValid) filteredDataBYFilterColumnObject.push(item);
      })
    }
    else filteredDataBYFilterColumnObject = props.data

    // then use searchValue to filter rows on searchOnColumnvalues
    let filteredDataBYSearchValue: any[] = []

    // if searchOnColumns is not provided or is empty then search on all columns
    if (!props.searchOnColumns) {
      let allColumns = props.data[0] ? Object.keys(props.data[0]) : [];
      filteredDataBYFilterColumnObject.map((item: any) => {
        let isItemValid = false;
        allColumns.map((key) => {
          if (!searchValue || searchValue === '') isItemValid = true
          else if (String(item[key]).toLowerCase().includes(searchValue?.toLowerCase())) isItemValid = true
        })
        if (isItemValid) filteredDataBYSearchValue.push(item);
      })
    }
    else {
      filteredDataBYFilterColumnObject.map((item: any) => {
        if (columnSearchValue === '' || searchValue === '') {
          filteredDataBYSearchValue.push(item);
        }
        else if (String(item[columnSearchValue]).toLowerCase().includes(searchValue?.toLowerCase())) {
          filteredDataBYSearchValue.push(item);
        }
      })
    }
    setFilteredRows(filteredDataBYSearchValue);
  }, [props.data, searchValue, props.filterColumnObject, props.searchOnColumns, columnSearchValue]);

  // change page to 1 when filteredRows changes
  React.useEffect(() => {
    //check if lastFilteRows every element key is equal to filteredRows every element key
    let isSame = true;
    if (lastFilterdRows.length !== filteredRows.length) isSame = false;
    else {
      for (let i = 0; i < lastFilterdRows.length; i++) {
        if (lastFilterdRows[i][props.rowKey || 'id'] !== filteredRows[i][props.rowKey || 'id']) isSame = false;
      }
    }
    if (!isSame) {
      setLastFilterdRows(filteredRows);
      setPage(1);
    }

  }, [filteredRows])

  // handle pagination
  React.useEffect(() => {
    if (props.pagination) {
      setRowsOfthisPage(filteredRows.slice((page - 1) * 10, page * 10));
    }
    else {
      setRowsOfthisPage(filteredRows);
    }
  }, [page, filteredRows]);


  // *************************************** //
  // handleDefaultSelectedRows
  // React.useEffect(() => {
  //   if (props.defaultSelectedRows && props.setSelectedRows) {
  //     props.setSelectedRows(props.defaultSelectedRows)
  //   }
  // }, [])

  // change columnSearchValue to first value of searchOnColumns in the beginning
  React.useEffect(() => {
    if (props.searchOnColumns) {
      if (columnSearchValue === '') setColumnSearchValue(Object.values(props.searchOnColumns)[0])
    }
  }, [props.searchOnColumns])

  return (
    <div className='be-table'>
      {
        props.search &&
        <div style={{ display: 'flex', margin: '1rem 0', gap: '1rem', alignItems: 'center' }}>
          {props.tableTopLeftComponents}
          <BEInput
            placeholder={props.searchText || 'Search'}
            style={{ width: '313px' }} size='large'
            search
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            searchForColumns={props.searchOnColumns}
            columnSearchValue={columnSearchValue}
            onChangeColumnSearch={(val: any) => setColumnSearchValue(val)}
          />
          <div style={{ marginLeft: 'auto', display: 'flex', gap: '1rem', alignItems: 'center' }}>
            {props.TableTopRightComponents}
          </div>
        </div>

      }

      <Table
        {...props.tableProps}
        dataSource={filteredRows}
        columns={coloumns}
        loading={props.loading}
        className={props.className}
        rowClassName={props.rowClassName}
        pagination={props?.pagination ?
          {
            position: ['bottomCenter'],
            current: page,
            onChange: (page: number) => {
              setPage(page);
            },
            showSizeChanger: false,
          }
          : false}
      />
    </div>
  );
}
