import React from 'react'
import IconProps from '../Interface/IconProps';

const PQSIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 16}
      height={props.inheritSize ? "100%" : 20}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.833984L0.5 4.16732V9.16732C0.5 13.7923 3.7 18.1173 8 19.1673C12.3 18.1173 15.5 13.7923 15.5 9.16732V4.16732L8 0.833984ZM8 9.99232H13.8333C13.3917 13.4256 11.1 16.484 8 17.4423V10.0007H2.16667V5.25065L8 2.65898V9.99232Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
    </svg>
  );
}

export default PQSIcon