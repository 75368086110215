import React from "react";
import IconProps from "./Interface/IconProps";

const ZoomInIcon = (props: IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 24}
      height={props.inheritSize ? "100%" : 24}
      viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.9707 11.4713H12.1807L11.9007 11.2013C13.1007 9.80133 13.7207 7.89133 13.3807 5.86133C12.9107 3.08133 10.5907 0.861329 7.79072 0.521329C3.56072 0.00132874 0.0107219 3.56133 0.520722 7.79133C0.860722 10.5913 3.08072 12.9113 5.86072 13.3813C7.89072 13.7213 9.80072 13.1013 11.2007 11.9013L11.4707 12.1813V12.9713L15.7307 17.2213C16.1407 17.6313 16.8007 17.6313 17.2107 17.2213L17.2207 17.2113C17.6307 16.8013 17.6307 16.1413 17.2207 15.7313L12.9707 11.4713ZM6.97072 11.4713C4.48072 11.4713 2.47072 9.46133 2.47072 6.97133C2.47072 4.48133 4.48072 2.47133 6.97072 2.47133C9.46072 2.47133 11.4707 4.48133 11.4707 6.97133C11.4707 9.46133 9.46072 11.4713 6.97072 11.4713ZM6.97072 4.47133C6.69072 4.47133 6.47072 4.69133 6.47072 4.97133V6.47133H4.97072C4.69072 6.47133 4.47072 6.69133 4.47072 6.97133C4.47072 7.25133 4.69072 7.47133 4.97072 7.47133H6.47072V8.97133C6.47072 9.25133 6.69072 9.47133 6.97072 9.47133C7.25072 9.47133 7.47072 9.25133 7.47072 8.97133V7.47133H8.97072C9.25072 7.47133 9.47072 7.25133 9.47072 6.97133C9.47072 6.69133 9.25072 6.47133 8.97072 6.47133H7.47072V4.97133C7.47072 4.69133 7.25072 4.47133 6.97072 4.47133Z"
        fill={props.fill ? props.fill : "black"} />
    </svg>
  );
};

export default ZoomInIcon;
