import React, { useEffect, useState } from "react";
import "./styles.scss";
import { PrimaryTheme } from "../../Config/Theme/theames";
import { useLocation, useNavigate } from "react-router-dom";
import CompanyLogo from "../../assets/images/Company/Yamaha_logo.png";
import CompanyLogoCollapsed from "../../assets/images/Company/Yahama_Logo_Collapsed.png";
import ChevronFilled from "../BEIcons/ChevronFilled";
import Dashboard from "../BEIcons/Dashboard";
import DataManager from "../BEIcons/DataManager";
import EntityManager from "../BEIcons/EntityManager";
import Targets from "../BEIcons/Targets";
import Emissions from "../BEIcons/Emissions";
import Reporting from "../BEIcons/Reporting";
import Analytics from "../BEIcons/Analytics";
import Materiality from "../BEIcons/Materiality";
import Suppliers from "../BEIcons/Suppliers";
import SettingsIcon from "../BEIcons/SettingsIcon";
import Logout from "../BEIcons/Logout";
import { setSelectedMenuItem } from "../../Redux/CommonReducer";
import { useAppDispatch } from "../../Config/Hooks/useAppDispatch";
import { useTypedSelector } from "../../Config/Hooks/useTypedSelector";
import BEConfirmModal from "../BEConfirmModal";
import { logoutAction } from "../../views/Actions/LoginActions";
import { setMenuCollapsed, increaseMenuClicks } from "../../Redux/CommonReducer";
import ToolTip from "../BEToolTip";
import InternalIcon from "../BEIcons/InternalIcon";
import dayjs from "dayjs";
import { menubarWidth, menubarWidthCollapsed } from "../../Config/Constants";


const menuItems = [
  {
    label: "Dashboard",
    icon: Dashboard,
    link: "/home/dashboard",
    section: 1
  },
  // {
  //   label: "Data Manager",
  //   icon: DataManager,
  //   link: "/home/data-manager",
  //   section: 2
  // },
  // {
  //   label: "Entity Manager",
  //   icon: EntityManager,
  //   link: "/home/entity-manager",
  //   section: 3
  // },
  // {
  //   label: "Targets",
  //   icon: Targets,
  //   link: "/home/targets",
  //   section: 6
  // },
  // {
  //   label: "Reporting",
  //   icon: Reporting,
  //   link: "/home/reporting/reports",
  //   section: 4
  // },
  // {
  //   label: "Emissions",
  //   icon: Emissions,
  //   link: "/home/emissions",
  //   section: 5
  // },
  // {
  //   label: "Analytics",
  //   icon: Analytics,
  //   link: "/home/analytics",
  // },
  // {
  //   label: "Materiality",
  //   icon: Materiality,
  //   link: "/home/materiality/assessments",
  //   section: 7
  // },
  // {
  //   label: "Suppliers",
  //   icon: Suppliers,
  //   link: "/home/suppliers/assessments",
  //   section: 7
  // },
  //  {
  //   label: "Internal",
  //   icon: InternalIcon,
  //   link: "/home/internal/assessments",
  // },
  // {
  //   label: "Settings",
  //   icon: SettingsIcon,
  //   link: "/home/settings",
  //   section: 8
  // },
];



const BEMenuBar = () => {
  const role = localStorage.getItem('role');
  const [displayMenuItems, setDisplayMenuItems] = useState([] as any[]);
  const clicks = useTypedSelector((state) => state.common.menuClicks);
  const [showList, setShowList] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const collapsed = useTypedSelector((state) => state.common.menuCollapsed);
  const version = useTypedSelector((state) => state.common.version);
  const activeMenuItem = useTypedSelector((state) => state.common.menuBar.activeMenuItem);
  const userPermissions = useTypedSelector((state) => state.user.permissions);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  

  const selectMenuItemFromPathname = () => {
    displayMenuItems.forEach((item, ind) => {
      if (location.pathname.includes(item.link)) {
        dispatch(setSelectedMenuItem(ind));
        return;
      }
    });
  };

  useEffect(() => {
    let tempMenuItems:any = menuItems.slice(role === 'CONTRIBUTOR' ? 1 : 0,2);
    for(let i = 2; i < menuItems.length; i++){
      if (userPermissions.data.find((perm) => perm.section === menuItems[i].section && perm.role === role)?.permission) {
        tempMenuItems.push(menuItems[i]);
      }
    }
    setDisplayMenuItems(tempMenuItems);
  }, [userPermissions]);

  useEffect(() => {
    selectMenuItemFromPathname();
    if(clicks === 0 && location.pathname === '/home/dashboard') dispatch(setMenuCollapsed(false));
    if(clicks === 1 && location.pathname !== '/home/dashboard') dispatch(setMenuCollapsed(true))
    dispatch(increaseMenuClicks());                  
  }, [location.pathname]);

  useEffect(() => {
    if (collapsed) {
      setShowList(false)
    }
    else
      setTimeout(() => {
        setShowList(true)
      }, 400)
  }, [collapsed])

  const handleMenuClick = (link: string) => {
    navigate(link);
  }

  return (
    <div
      className="menu-bar"
      style={{
        minHeight: "100vh",
        background: PrimaryTheme.secondaryBlack,
        position: "sticky",
        top: 0,
        color: "#ffffff",
        alignSelf: "flex-start",
        width: collapsed ? menubarWidthCollapsed : menubarWidth,
        paddingTop: "1rem",
      }}
    >
      <div
        className="collapse-btn"
        style={collapsed ? {} : { transform: "rotateZ(-180deg)" }}
        onClick={() => {
          dispatch(setMenuCollapsed(!collapsed));
        }}
      >
        <ChevronFilled />
      </div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div
          className="logo"
          onClick={() => navigate("/home/dashboard")}
        >
          <img
            src={!showList ? CompanyLogoCollapsed : CompanyLogo}
            height={
              showList ? "40px" : "30px"
            }
          />{" "}
        </div>
      </div>

      <div className="menu-list">
        {

          displayMenuItems.map((item, ind) => {
            return collapsed ? (
              <ToolTip title={item.label} placement="right">
                <div
                  key={ind}
                  style={{
                    color:
                      activeMenuItem === ind
                        ? PrimaryTheme.secondaryGreenLight
                        : PrimaryTheme.primaryGreenLight,
                  }}
                  className="list-itm"
                  onClick={() => handleMenuClick(item.link)}
                >
                  <item.icon
                    stroke={
                      activeMenuItem === ind
                        ? PrimaryTheme.secondaryGreenLight
                        : PrimaryTheme.primaryGreenLight
                    }
                  />
                </div>
              </ToolTip>
            ) : (
              <div
                key={ind}
                style={{
                  color:
                    activeMenuItem === ind
                      ? PrimaryTheme.secondaryGreenLight
                      : PrimaryTheme.primaryGreenLight,
                }}
                className="list-itm"
                onClick={() => handleMenuClick(item.link)}
              >
                <item.icon
                  stroke={
                    activeMenuItem === ind
                      ? PrimaryTheme.secondaryGreenLight
                      : PrimaryTheme.primaryGreenLight
                  }
                />

                {showList && <span style={{ width: "80%" }}>{item.label}</span>}
              </div>
            );
          })

        }
        {/* <div
          onClick={() => setShowLogoutModal(true)}
          className="list-itm-red"
        // style={{ color: PrimaryTheme.secondaryRedError }}
        >

          <Logout />

          {showList && <span style={{ width: "80%", opacity: "0.9" }}>Logout</span>}
        </div> */}
      </div>
      {/* <BEConfirmModal
        title="Confirm Logout"
        message="Are you sure you want to logout?"
        visible={showLogoutModal}
        setVisible={setShowLogoutModal}
        onConfirm={async () => {
          await dispatch(logoutAction());
        }}
      /> */}

     
    </div>
  );
};

export default BEMenuBar;
