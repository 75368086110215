import * as React from 'react';
import { Radio, Checkbox, Form, Progress } from 'antd';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import { BEButton } from '../../../../../../Components/BEFormItems/BEButton';
import { SupplierQuestionsTypes } from '../../../../../../Redux/Types/suppliersTypes';
import { useSearchParams } from 'react-router-dom';
import {
  postSupplierAssessmentAnswers,
  editSupplierAnswer
} from '../../../../Actions/supplierFormActions';
import { BEMessage } from '../../../../../../Components/BEMessage';
import { setAssessmentStatus, setCurrentSection } from '../../../../../../Redux/SupplierReducer';
import { changeSupplierStatus } from '../../../../Actions/supplierFormActions';
import { setSupplierAnswers } from '../../../../../../Redux/SupplierReducer';
import { updateSupplierAnswers } from '../../../../../../Redux/SupplierReducer';
import { BEEyeButton } from '../../../../../../Components/BEEyeButton';
import { PrimaryTheme } from '../../../../../../Config/Theme/theames';
import { getSupplierSurveys } from '../../../../Actions/supplierAction';

export interface IRightSectionProps {
  isFillSection?: boolean;
  sections: any;
}

export function RightSection(props: IRightSectionProps) {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentSection = useTypedSelector(state => state.supplier.CurrentSection);
  const assessmentStatus = useTypedSelector(state => state.supplier.AssessmentStatus);
  const supplierAssessmentQuestions = useTypedSelector(state => state.supplier.SupplierQuestions);
  const supplierAssessmentAnswers = useTypedSelector(state => state.supplier.SupplierAnswers);
  const [loadingEdit, setLoadingEdit] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (supplierAssessmentAnswers.status === 'success') {
      let dataToSet: any = {};
      supplierAssessmentQuestions.data.map((item: SupplierQuestionsTypes) => {
        if (supplierAssessmentAnswers.data[item.id]) {
          dataToSet[item.id] = supplierAssessmentAnswers.data[item.id].answer;
        }
      })
      form.setFieldsValue(dataToSet);
    }
  }, [currentSection])



  const saveAnswer = async (questionId: any, option: any) => {
    let obj: any = {
      question: Number(questionId),
      answer: option,
    }
    console.log(questionId, option)
    form.setFieldsValue({
      [questionId]: option
    });
    if (!supplierAssessmentAnswers.data.hasOwnProperty(questionId)) {
      dispatch(updateSupplierAnswers({
        [questionId]: {
          ...obj,
          pillar: currentSection
        }
      }))

      // if (Object.keys(supplierAssessmentAnswers.data).length === 0) {
      //   await dispatch(changeSupplierStatus(String(searchParams.get('secret')), Number(assessmentStatus)));
      // }
      await dispatch(postSupplierAssessmentAnswers([obj], String(searchParams.get('secret'))));

    }
    else {
      await dispatch(editSupplierAnswer({ answer: option }, String(searchParams.get('secret')), Number(supplierAssessmentAnswers.data[questionId].id)))

    }
  }

  const onFinish = async (values: any) => {
    dispatch(getSupplierSurveys());
    if (supplierAssessmentQuestions.data.length === Object.keys(supplierAssessmentAnswers.data).length) {
      setLoading(true)
      await dispatch(changeSupplierStatus(String(searchParams.get('secret')), Number(assessmentStatus)));
      setLoading(false)
    }
    else {
      const sectionQuestionsLength = supplierAssessmentQuestions.data.filter((item: SupplierQuestionsTypes) => item.attribute === currentSection).length;
      const sectionAnswersLength = Object.keys(supplierAssessmentAnswers.data).filter((item: any) => supplierAssessmentQuestions.data.find((item2: any) => item2.id === Number(item))?.attribute === currentSection).length;
      if (sectionQuestionsLength !== sectionAnswersLength) {
        BEMessage.error('Please fill all the questions of this section');
        return;
      }
      const indexOfCurrentSection = props.sections.indexOf(currentSection);
      if (indexOfCurrentSection === props.sections.length - 1) dispatch(setCurrentSection(props.sections[0]));
      else dispatch(setCurrentSection(props.sections[indexOfCurrentSection + 1]));
    }
  }


  return (
    <>
      <div className='right-section'>
        <Form
          onFinish={onFinish}
          form={form}
        >
          {
            supplierAssessmentQuestions.data.filter((item: any) => item.attribute === currentSection).map((item: SupplierQuestionsTypes) => {

              return (
                <div className='question-box'>

                  <BEEyeButton
                    title={'Description'}
                    discription={item?.description}
                  >
                    <p className='question'>{item.question} </p>
                  </BEEyeButton>
                  <Form.Item name={item.id}>
                    <Radio.Group className='options'
                    >
                      <Radio value={true}
                        onClick={() => { saveAnswer(item.id, 1) }}
                      >Yes</Radio>
                      <Radio value={false}
                        onClick={() => { saveAnswer(item.id, 0) }}
                      >No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              )
              // }
            })
          }
        </Form>
      </div>

      <div className='progress-bar'>
        <BEButton className='primary' size='large'
          onClick={() => { form.submit() }}
          loading={loading}
        >
          {
            Object.keys(supplierAssessmentAnswers.data).length === supplierAssessmentQuestions.data.length
              ? 'Submit' : 'Save & Next'
          }

        </BEButton>
        <div style={{ flexGrow: 0.97 }} />
        <div>
          <div style={{ fontSize: '1.1rem', fontWeight: 600 }}>Overall Progress:
            <span style={{ color: PrimaryTheme.primaryGreen, marginLeft: "1rem" }}>
              {
                Math.round((Object.keys(supplierAssessmentAnswers.data).length / supplierAssessmentQuestions.data.length) * 100)
              }%
            </span>
          </div>
          <Progress showInfo={false}
            strokeColor={PrimaryTheme.primaryGreen} strokeWidth={8} trailColor='white'
            percent={Math.round((Object.keys(supplierAssessmentAnswers.data).length / supplierAssessmentQuestions.data.length) * 100)}
            style={{ width: '180px' }} />
        </div>
      </div>
    </>
  );
}
