import React from 'react'
import IconProps from './Interface/IconProps'

const ChevronRightIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 24}
      height={props.inheritSize ? "100%" : 24}
      viewBox="0 0 24 24"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99953 6.70978C8.60953 7.09978 8.60953 7.72978 8.99953 8.11978L12.8795 11.9998L8.99953 15.8798C8.60953 16.2698 8.60953 16.8998 8.99953 17.2898C9.38953 17.6798 10.0195 17.6798 10.4095 17.2898L14.9995 12.6998C15.3895 12.3098 15.3895 11.6798 14.9995 11.2898L10.4095 6.69978C10.0295 6.31978 9.38953 6.31978 8.99953 6.70978Z"
        fill={props.fill ? props.fill : "#333333"}
      />
    </svg>
  );
}

export default ChevronRightIcon