import * as React from 'react';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { KPIData } from './KPIData';
import { BEInput } from '../../../../../Components/BEFormItems/BEInput';
import { DerivedMetricData } from './KPIData/DerivedMetricData';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { AssignDepartmentDrawer } from '../Drawers/AssignDepartment';
export interface IKPIdataProps {
}

export function KPISdata(props: IKPIdataProps) {
  const currentPillar = useTypedSelector(state => state.onBoarding.metrics.currentPillar)
  const currentCategory = useTypedSelector(state => state.onBoarding.metrics.currentCategory)
  const myMetrics = useTypedSelector(state => state.dataManager.metrics.myMetrics.data)
  const currentGroup = useTypedSelector(state => state.onBoarding.metrics.currentGroup)
  const searchTabMetrics = useTypedSelector(state => state.dataManager.metrics.searchTabMetrics);
  const [openAssignDepartment, setOpenAssignDepartment] = React.useState<boolean>(false);
  const [filteredMetrics, setFilteredMetrics] = React.useState<any>([]);
  const role = localStorage.getItem('role');

  React.useEffect(() => {
    if (currentPillar === searchTabMetrics.tabsValue || searchTabMetrics.tabsValue === "All") {
      setFilteredMetrics([...searchTabMetrics.data].sort((a: any, b: any) => a.hasOwnProperty('direct') ? -1 : 1));
    } else {
      setFilteredMetrics([...myMetrics].sort((a: any, b: any) => a.hasOwnProperty('direct') ? -1 : 1));
    }
  }, [myMetrics, searchTabMetrics.data]);



  return (
    <div className='kpis-data'>
      <BEInput placeholder='Search KPIs'
        search
        style={{ width: '313px', margin: '2rem 0 1rem 0' }} size='large'
        onChange={(e: any) => {
          const value = e.target.value;
          setFilteredMetrics(myMetrics?.filter((item: any) => item.title.toLowerCase().includes(value.toLowerCase())))
        }}
      />
      {
        (role === 'SUPER_ADMIN' || role === 'ADMIN') &&
        <BEButton
          className='primary'
          style={{ float: "right", margin: '2rem 0 1rem 0' }}
          onClick={() => setOpenAssignDepartment(true)}
        >
          Assign
        </BEButton>
      }
      {
        (currentCategory !== 'Energy' && currentCategory !== 'Waste' && currentCategory !== 'Water') ?
          filteredMetrics?.filter((item: any) => item.pillars === currentPillar && item.category === currentCategory && item.group === currentGroup)
            .map((item: any, index: any) => {
              // if (item.applicable_to_all === true || currentBusinessUnit === 0 || businessUnits.data.find((unit: any) => unit.id === currentBusinessUnit)?.is_headquarter === true) {
              if (item.hasOwnProperty('direct'))
                return (
                  <DerivedMetricData key={index} metric={item} />
                )
              else
                return (
                  <KPIData key={index} kpi={item} />
                )
              // }
            })
          :
          filteredMetrics?.filter((item: any) => item.pillars === currentPillar && item.category === currentCategory && item.tab === currentGroup)
            .map((item: any, index: any) => {
              // if (item.applicable_to_all === true || currentBusinessUnit === 0 || businessUnits.data.find((unit: any) => unit.id === currentBusinessUnit)?.is_headquarter === true) {
              if (item.hasOwnProperty('direct'))
                return (
                  <DerivedMetricData key={index} metric={item} />
                )
              else
                return (
                  <KPIData key={index} kpi={item} />
                )
              // }
            })
      }
      <AssignDepartmentDrawer
        open={openAssignDepartment}
        setOpen={setOpenAssignDepartment}
      />
    </div>
  );
}
