import * as React from 'react';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { BETable } from '../../../../Components/BETable';
import { ColumnsType } from 'antd/es/table';
import { BEInput } from '../../../../Components/BEFormItems/BEInput';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import Tracker from '../../Components/Tracker';
import { AddTargets } from '../Drawers/AddTargets';
import { getSelectedMetrics } from '../../../../views/Actions/OnboardingStep4Actions';
import { calcProgress, calcProgressStatus, getTargetValue, updateTarget } from '../../Actions/targetAction';
import { SetupTargets } from '../Drawers/SetupTargets';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { selectedMetricType } from '../../../../Redux/Types/dataManagerTypes';
import EditIcon from '../../../../Components/BEIcons/EditIcon';
import BESwitch from '../../../../Components/BEFormItems/BESwitch';
import BEStatusTag from '../../../../Components/BEStatusTag';
import { Progress } from 'antd';
import ToolTip from '../../../../Components/BEToolTip';
import { AddCommaToNumber } from '../../../../Config/Functions/UsefullFunctions';
export interface ITargetsTableProps {
}

export function TargetsTable(props: ITargetsTableProps) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const myMetrics = useTypedSelector((state) => state.onBoarding.metrics.allSelectedMetrics);
  const myMetricsData = useTypedSelector((state) => state.dataManager.metrics.myMetricsData);
  const myMetricsMapper = useTypedSelector(state => state.onBoarding.metrics.allMetrics);
  const [filteredMetrics, setFilteredMetrics] = React.useState<any>([]);
  const [openSetuptargets, setOpenSetupTargets] = React.useState(false);
  const [currTarget, setCurrTarget] = React.useState(null);
  const assessmentYear =useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const [load, setLoad] = React.useState(myMetrics.status === "loading");
  const [switchLoad, setSwitchLoad] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [targetData, setTargetData] = React.useState<selectedMetricType>({} as selectedMetricType);
  const conicColors = { '70%': PrimaryTheme.primaryGreen, '100%': "#FFBF00"};
  const threeColors = { '80%': PrimaryTheme.primaryGreen, '85%': "#FFBF00", '100%': "#E35335"};

  const options = [
    { value: 'A', label: 'absolute increase' },
    { value: 'B', label: 'absolute decrease' },
    { value: 'C', label: '% increase' },
    { value: 'D', label: '% decrease' }
  ];

  const columns: ColumnsType<any> = [
    {
      title: 'Sr. No.',
      key: 'sno',
      render: (text, object, index) => (page - 1) * 10 + index + 1
    },
    {
      title: 'Metric Name',
      dataIndex: 'title',
      render: (text, record) =>
        <div className='metric-title'>
          {record.title}
          {getTag(record.title) === 2 && <BEStatusTag status='success'>A</BEStatusTag>}
          {getTag(record.title) === 1 && <BEStatusTag status='success'>HQ-A</BEStatusTag>}
        </div>
    },
    {
      title: 'Current Value',
      dataIndex: 'curr_value',
      render: (text, record) =>  AddCommaToNumber(Number(getCurrentValue(record.id)), false, true) + ` ${getMetricUnit(record.title)}`
    },
    {
      title: 'Type',
      dataIndex: 'target_type',
      render: (text, record) => (options.filter((item) => item.value === record.target_type)[0].label)
    },
    {
      title: 'Target Value',
      dataIndex: 'target_value',
      render: (text, record) => AddCommaToNumber(Number(getTargetValue(record)), false, true) + ` ${getMetricUnit(record.title)}`
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text, record) => (
        <div onMouseEnter={() => {
          setCurrTarget(record.id);
        }}>
          <BESwitch
            loading={switchLoad && currTarget === record.id}
            checked={record.status}
            onClick={async () => {
              const body = {
                status: record.status ? 0 : 1
              };
              setSwitchLoad(true);
              await dispatch(updateTarget(record.id, body));
              setSwitchLoad(false);
            }}
          />
        </div>)
    },
    {
      title: 'Progress',
      dataIndex: 'progress',
      render: (text, record) =>
        // <BEStatusTag
        //   status={calcProgressStatus(record, getCurrentValue(record.id))}
        // > {calcProgressStatus(record, getCurrentValue(record.id)) === "success" ? "100%" :
        //   (
        //     record.status && record.target_value !== '-1.00' ?
        //       (calcProgress(record.baseline_value, record.target_value, getCurrentValue(record.id), record.target_type))
        //         .toFixed(2) + getUnit(record.target_type)
        //       :
        //       ""
        //   )}
        // </BEStatusTag>
        <div style={{ display: "flex", flexDirection: "column", width: 180}}>
          <div style={{ display: "flex", flexDirection: "row", fontSize:"small", lineHeight:"10px"}}>
            {/* <span>0</span> */}
            <div style={{flexGrow: "0.83"}} /><span>{AddCommaToNumber(Number(getTargetValue(record)), false, true)}{" "}{getMetricUnit(record.title)}</span>
          </div>
          <Progress
          strokeColor={getCurrentValue(record.id) * 100 / (getTargetValue(record) * 1.2) < 70 ?
          PrimaryTheme.primaryGreen :
          (getCurrentValue(record.id) * 100 / (getTargetValue(record) * 1.2) < 95 ? conicColors : threeColors)}
          percent={getCurrentValue(record.id) * 100 / (getTargetValue(record) * 1.2)} size={[180, 10]}
          showInfo={false} />
          <div style={{ display: "flex", flexDirection: "row", fontSize:"small", lineHeight:"10px"}}>
            <div style={{flexGrow: "0.8"}} />
            <span style={{cursor:"pointer"}}><ToolTip title='Target'>^</ToolTip></span>
          </div>
        </div>
    },
    {
      title: 'Edit',
      dataIndex: 'edit',
      render: (text, record) => (
        <div style={{ width: "1rem" }} onClick={() => {
          setTargetData(record);
          setOpenSetupTargets(true);
        }}>
          <EditIcon inheritSize={true} stroke={PrimaryTheme.primaryGreyDark} />
        </div>)
    }
  ];

  React.useEffect(() => {
    if (myMetrics.status === "idle") {
      dispatch(getSelectedMetrics());
    }
    setLoad(myMetrics.status === "loading");
    setFilteredMetrics(myMetrics.data);
  }, [myMetrics]);

  const getCurrentValue = (id: number) => {
    return myMetricsData.data.filter((item) => item.my_metric === id).reduce((total: number, currentItem: any) => {
      return total + currentItem.value;
    }, 0)
  };

  const getUnit = (target: string) => {
    switch (target) {
      case 'B': return ""
        break;
      case 'A': return ""
        break;
      case 'C': return "%"
        break;
      case 'D': return "%"
        break;
    }
  };

  const changePage = (page: number) => {
    setPage(page)
  };

  const getTag = (title: string) => {
    const metric = myMetricsMapper.data.filter((item) => item.title === title)[0]
    const tag = metric.annual ? (metric.applicable_to_all ? 2 : 1) : 0
    return tag
  };

  const getMetricUnit = (title: string) => {
    const metric = myMetricsMapper.data.find((item) => item.title === title)
    return metric?.unit
  };

  return (
    <div style={{ padding: "1rem 0" }}>
      <Tracker />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <BEInput
          style={{ width: "313px" }}
          search
          placeholder="Search Targets"
          size="large"
          onChange={(e) => {
            const value = e.target.value.toLowerCase();
            const filteredData = myMetrics.data?.filter((item: any) => {
              return item.title.toLowerCase().includes(value);
            });
            setFilteredMetrics(filteredData);
          }}
        />
        <BEButton
          className="primary"
          style={{ marginLeft: "auto" }}
          size="large"
          onClick={() => {
            setOpen(true);
          }}
        >
          + Add Targets
        </BEButton>
      </div>
      <BETable
        rowKey='sno'
        loading={load}
        columns={columns}
        handleChangePage={changePage}
        data={filteredMetrics.filter((item: any) => item.target_value !== '-1.00'
          && item.year === assessmentYear)}
        pagination
      />
      <AddTargets open={open} setOpen={setOpen} />
      <SetupTargets open={openSetuptargets} setOpen={setOpenSetupTargets} targetData={targetData} />
    </div>
  );
}
