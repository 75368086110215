import React from 'react'
import IconProps from './Interface/IconProps';

const TrackerIcon= (props:IconProps) => {
  return (
    <svg  width={props.inheritSize ? "100%" : 20}
    height={props.inheritSize ? "100%" : 20} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.2133 3.78683L12.2733 4.72683C13.2333 5.6935 13.8333 7.02683 13.8333 8.50016C13.8333 11.4468 11.4466 13.8335 8.49992 13.8335C5.55325 13.8335 3.16659 11.4468 3.16659 8.50016C3.16659 5.78016 5.19992 3.54016 7.83325 3.2135V4.56016C5.93992 4.88016 4.49992 6.52016 4.49992 8.50016C4.49992 10.7068 6.29325 12.5002 8.49992 12.5002C10.7066 12.5002 12.4999 10.7068 12.4999 8.50016C12.4999 7.3935 12.0533 6.3935 11.3266 5.6735L10.3866 6.6135C10.8666 7.10016 11.1666 7.76683 11.1666 8.50016C11.1666 9.9735 9.97325 11.1668 8.49992 11.1668C7.02659 11.1668 5.83325 9.9735 5.83325 8.50016C5.83325 7.26016 6.68659 6.22683 7.83325 5.92683V7.3535C7.43325 7.58683 7.16659 8.00683 7.16659 8.50016C7.16659 9.2335 7.76659 9.8335 8.49992 9.8335C9.23325 9.8335 9.83325 9.2335 9.83325 8.50016C9.83325 8.00683 9.56659 7.58016 9.16659 7.3535V1.8335H8.49992C4.81992 1.8335 1.83325 4.82016 1.83325 8.50016C1.83325 12.1802 4.81992 15.1668 8.49992 15.1668C12.1799 15.1668 15.1666 12.1802 15.1666 8.50016C15.1666 6.66016 14.4199 4.9935 13.2133 3.78683Z"
 fill={props.stroke ? props.stroke : "white"}/>
</svg>

  );
}

export default TrackerIcon