import type { StatusType } from "../Types/userTypes";
import { BoschDashboardType } from "../Types/boschDashboard";

import {
    Reducer,
    createSlice,
} from "@reduxjs/toolkit";
import { clearStore } from "../ExtraReducrActions";


const initialState: BoschDashboardType = {
   BoschData: {
        status: "idle",
        data: [],
    },
}
export const userSlice = createSlice({
    name: "user",
    initialState,
    extraReducers: (builder) => builder.addCase(clearStore, () => initialState),
    reducers: {
        setBoschData: (state, action) => {
            state.BoschData = action.payload;
        }
    }
});

export const {
    setBoschData,
} = userSlice.actions;

const boschDashboardReducer: Reducer<BoschDashboardType> = userSlice.reducer;

export default boschDashboardReducer;
