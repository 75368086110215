import * as React from 'react';
import { TopSection } from './TopSection';
import { ReportTabs } from './RightBodyTabs';
import { PrimaryTheme } from '../../../../../../Config/Theme/theames';
import { ChildrenRightTabs } from './RightBodyTabs/ChildrenRightTabs';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import InfoIcon from '../../../../../../Components/BEIcons/InfoIcon';

export interface IRightSectionEditReportProps { }

export function RightSectionEditReport(props: IRightSectionEditReportProps) {
  const selectedTopics = useTypedSelector(state => state.reporting.selectedTemplateTopics);

  return (
    <>
      <TopSection />
      {
        selectedTopics.data.length === 0 ?
          <div className='no-data-box'>
            <div>
              <div className='info-icon'><InfoIcon inheritSize fill={PrimaryTheme.primaryGray} /></div>
              <p>No topics found</p>
            </div>
          </div>
          :
          <>
            <div style={{ marginTop: "4rem", backgroundColor: "#F5F9FA" }}>
              <ReportTabs />
            </div>
            <ChildrenRightTabs />
          </>
      }
    </>

  );
}
