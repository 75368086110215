import React from 'react';
import { BEInput } from '../../../../../../Components/BEFormItems/BEInput'
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../../Config/Hooks/useAppDispatch';
import { setSearchTabMetrics } from '../../../../../../Redux/DataManagerReducer';
import './style.scss'
import { setCurrentCategory, setCurrentGroup, setCurrentPillar } from '../../../../../../Redux/OnboardingReducer';
import { TopicStatusTypeDM } from '../../../../../../Redux/Types/dataManagerTypes';
import { getTopicStatusDM } from '../../../../Actions';
import { departmentSelectTypeDM, metricTypeDM, topicStatusMapDM } from '../StatusFilter';

interface ObjectType {
    [key: string]: string
}
export interface ITopTabSearchDMProps {
}

export function TopTabSearch(props: ITopTabSearchDMProps) {
    const [searchValue, setSearchValue] = React.useState("");
    const myMetrics = useTypedSelector(state => state.dataManager.metrics.myMetrics);
    const searchTabMetrics = useTypedSelector(state => state.dataManager.metrics.searchTabMetrics);
    const [currentItemId, setCurrentItemId] = React.useState<number>(0);
    const [tabValue, settabValue] = React.useState(searchTabMetrics.tabsValue);
    const [searchDropdown, setSearchDropdown] = React.useState(false);
    const searchForColumns = useTypedSelector(state => state.dataManager.pillars);
    const statusValue = useTypedSelector(state => state.dataManager.filter.TopicStatus);
    const metricType = useTypedSelector(state => state.dataManager.filter.MetricTypeDM);
    const departmentType = useTypedSelector(state => state.dataManager.filter.DepartmentTypeDM);
    const assignedDepartmentByCategory = useTypedSelector(state => state.dataManager.assignedDepartmentByCategory);

    const dispatch = useAppDispatch()
    const onChangeColumnSearch = (val: string) => {
        settabValue(val);
    };
    const handleSearchFocus = () => {
        setSearchDropdown(true);
    };
    const handleSearchBlur = () => {
        if (!currentItemId) {
            setSearchDropdown(false);
        }
    };
    const handleMetricSelect = (item: any) => {
        setSearchDropdown(false);
        dispatch(setSearchTabMetrics({
            tabsValue: searchTabMetrics.tabsValue,
            data: searchTabMetrics.data,
            dropDownMetric: item
        }));
        dispatch(setCurrentPillar(item.pillars));
        dispatch(setCurrentCategory(item.category));
        if (item.category !== 'Energy' && item.category !== 'Water' && item.category !== 'Waste') {
            dispatch(setCurrentGroup(item.group));
        } else {
            dispatch(setCurrentGroup(item.tab));
        }
        setSearchValue(item.title);

        // using setTimeout since changing pillars and changing category, group 
        // setTimeout(() => {
        //     dispatch(setCurrentCategory(item.category));
        // }, 100);

        // setTimeout(() => {
        //     if(item.category!=='Energy') {
        //         dispatch(setCurrentGroup(item.group));
        //     } else {
        //         dispatch(setCurrentGroup(item.tab));
        //     }
        // }, 200);
    }

    const setSearchTabMetricsFunc = async () => {
        let tempData: any[] = [];
        for (let i = 0; i < myMetrics.data.length; i++) {
            const metric: any = myMetrics.data[i!];
            let ItemStatus = await dispatch(getTopicStatusDM(metric?.id));
            let ItemType = "All";
            if (metric?.annual) {
                ItemType = "Annual";
                if (!metric?.applicable_to_all) {
                    ItemType = "HQ-A";
                }
            } else if (metric?.hasOwnProperty("direct")) {
                ItemType = "Calculated";
            }
            let ItemDepartment = "All";

            if (metric?.category === 'Energy' || metric?.category === 'Water' || metric?.category === 'Waste') {
                ItemDepartment = assignedDepartmentByCategory.data.find((item) => item.category === metric?.tab)?.department || "All";
            } else {
                ItemDepartment = assignedDepartmentByCategory.data.find((item) => item.category === metric?.group)?.department || "All";
            }
            if (myMetrics.data[i!].title.toLowerCase().includes(searchValue.toLowerCase())
                && (topicStatusMapDM.find((status) => status.value === ItemStatus)?.name === statusValue || statusValue === 'All')
                && (metricTypeDM.find((type) => type.name === ItemType)?.name === metricType || metricType === 'All')
                && (departmentSelectTypeDM.find((type) => type.name === ItemDepartment)?.name === departmentType || departmentType === 'All')
            ) {
                tempData = [...tempData, metric];
            }
        }
        if (tabValue === "All") {
            dispatch(setSearchTabMetrics({
                tabsValue: "All",
                data: tempData,
                dropDownMetric: searchTabMetrics.dropDownMetric
            }))
        } else {
            dispatch(setSearchTabMetrics({
                tabsValue: tabValue,
                data: tempData.filter((item: any) => item.pillars === tabValue),
                dropDownMetric: searchTabMetrics.dropDownMetric
            }))
        }
    }
    React.useEffect(() => {
        setSearchTabMetricsFunc();
    }, [searchValue, tabValue, myMetrics, statusValue, metricType, departmentType]);
    if (myMetrics.status !== 'success') return null;
    return (
        <div
            style={{
                position: 'relative'
                // position: "absolute",1
                // top: "12.5rem",
                // right: "3rem",
                // zIndex: 4,
                // fontSize: "0.85rem",
            }}
            onMouseOut={() => setCurrentItemId(0)}
        >
            <BEInput
                onFocus={handleSearchFocus}
                onBlur={handleSearchBlur}
                size='large'
                search
                searchForColumns={searchForColumns}
                onChangeColumnSearch={onChangeColumnSearch}
                columnSearchValue={tabValue}
                value={searchValue}
                selectSize='large'
                onChange={(e) => setSearchValue(e.target.value)}
            />
            {searchDropdown &&
                <div className='tabSearchDropdown'>
                    {searchTabMetrics.data.map((item: any) =>
                        <div
                            onMouseOver={() => {
                                setCurrentItemId(item.id);
                            }}
                            onClick={() => {
                                handleMetricSelect(item);
                            }}
                            className='list-items'
                        >
                            {item.title}
                        </div>
                    )}
                </div>}
        </div>
    )
}

