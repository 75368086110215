export const GRESB_ASSET_DETAILS_VARIABLE_CODES = [
    'asset_name', 'property_type_code', 'construction_year', 'asset_ownership', 'asset_size', 'country', 'city', 'state_province', 'address', 'asset_gav', 'optional_information'
]

export const GRESB_ASSET_DETAILS_VARIABLE_CODES_MAPPING: any = {
    asset_name: 'name',
    property_type_code: 'property_type',
    construction_year: 'construction_year',
    asset_ownership: 'ownership_percentage',
    asset_size: 'asset_size',
    country: 'country',
    city: 'city',
    state_province: 'state',
    address: 'address',
    asset_gav: 'value',
    optional_information: 'additional_information'
}