import React from 'react'
import IconProps from '../Interface/IconProps';

const BiodiversityIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 20}
      height={props.inheritSize ? "100%" : 17}
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 13.4998L16.7833 8.49984H18.3333L12.5 0.166504L10 3.7415L7.5 0.166504L1.66667 8.49984H3.21667L0 13.4998H5.83333V16.8332H9.16667V13.4998H10.8333V16.8332H14.1667V13.4998H20ZM12.5 3.07484L15.1333 6.83317H13.7333L16.95 11.8332H13.9333L11.7917 8.49984H13.3333L11.0167 5.1915L12.5 3.07484ZM3.05 11.8332L6.26667 6.83317H4.86667L7.5 3.07484L10.1333 6.83317H8.73333L11.95 11.8332H3.05Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
    </svg>
  );
}

export default BiodiversityIcon