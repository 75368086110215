import * as React from 'react';
import { BETabs } from '../../../../../Components/BETabs';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { setCurrentGroup } from '../../../../../Redux/OnboardingReducer';
import { KPISdata } from '../KPISdata';
import { current } from '@reduxjs/toolkit';

export interface IInnerTabsFilterProps {
}

export function InnerTabsFilterDM (props: IInnerTabsFilterProps) {
    const dispatch = useAppDispatch()
    const currentPillar = useTypedSelector(state => state.onBoarding.metrics.currentPillar)
    const currentCategory = useTypedSelector(state => state.onBoarding.metrics.currentCategory)
    const myMetrics = useTypedSelector(state => state.dataManager.metrics.myMetrics.data)
    const searchTabMetrics = useTypedSelector(state => state.dataManager.metrics.searchTabMetrics)
    const currentGroup = useTypedSelector(state => state.onBoarding.metrics.currentGroup)
    const currentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear)

    React.useEffect(() => {
      // For energy, the current category will be set using tab, but for others it will be set using group
      if (searchTabMetrics.dropDownMetric) {
        if(searchTabMetrics.dropDownMetric.category!=='Energy' && searchTabMetrics.dropDownMetric.category!=='Water' && searchTabMetrics.dropDownMetric.category!=='Waste') {
          dispatch(setCurrentGroup(searchTabMetrics.dropDownMetric.group));
        } else {
          dispatch(setCurrentGroup(searchTabMetrics.dropDownMetric.tab));
        }
      } else {
        if(currentCategory!=='Energy' && currentCategory!=='Water' && currentCategory!=='Waste')
          dispatch(setCurrentGroup(Array.from(new Set(myMetrics?.filter((item:any)=>item.pillars === currentPillar && item.category === currentCategory).map((item:any)=>item.group)))[0]))
        else {
          dispatch(setCurrentGroup(Array.from(new Set(myMetrics?.filter((item:any)=>item.pillars === currentPillar && item.category === currentCategory).map((item:any)=>item.tab)))[0]))
        }
      }
    }, [currentCategory, myMetrics])

  return (
    <div className='inner-tabs-filter'>
     <BETabs
      destroyInactiveTabPane
      
      onChange={(key)=>dispatch(setCurrentGroup(key))}
      activeKey={currentGroup}

      items={
        (currentCategory!=='Energy' && currentCategory!=='Water' && currentCategory!=='Waste')?
        Array.from(new Set(myMetrics?.filter((item:any)=>item.pillars === currentPillar && item.category === currentCategory).map((item:any)=>item.group)))
        .map((item:any,index)=>{
          return {
            key:item,
            label:item,
            children:
            <div className='tab-content'>
              <KPISdata />          
            </div>
          }
        })
        :
       Array.from(new Set(myMetrics?.filter((item:any)=>item.pillars === currentPillar && item.category === currentCategory).map((item:any)=>item.tab)))
        .map((item:any,index)=>{
          return {
            key:item,
            label:item,
            children:
            <div className='tab-content'>
              <KPISdata />          
            </div>
          }
        })
      }
     />

    </div>
  );
}
