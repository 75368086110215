import { 
  GET_BOSCH_DATA,
 } from "../../../Utils/Routes/BoschDashboard";

 import { 
  setBoschData,
 } from "../../../Redux/BoschDashboard";

  import { handleAPICall } from "../../../Config/Functions/HandleAPICall";
import { AppDispatch } from "../../../Redux/store";

export const getBoschData = () => async (dispatch:AppDispatch) => {
  dispatch(setBoschData({
          status: "loading",
          data: []
      }));
  const [data, error] = await handleAPICall(GET_BOSCH_DATA());
  if (data) {
    let dataToSet = data.data.map((item:any) => {
        return {
            ...item,
            fuel: item.fuel/1000,
        }
    });
      dispatch(setBoschData({
          status: "success",
          data: dataToSet
      }));
  }
  else{
      console.log(error);
      dispatch(setBoschData({
          status: "error",
          data: []
      }));
  }
}



