import * as React from 'react';
import { Tabs,TabsProps } from 'antd';
import './style.scss';

export interface ITabsProps extends TabsProps{
}

export function BETabs (props: ITabsProps) {
    const { children, ...otherProps } = props;
  return (
    <Tabs {...otherProps} className='be-tabs'>
        {children}
    </Tabs>
  );
}
