import * as React from 'react';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { ViewTab } from '../../../../Components/BETabs/ViewTab';
import { setVaultView } from '../../../../Redux/VaultReducer';

export interface IViewTabVaultProps {
}

export function ViewTabVault (props: IViewTabVaultProps) {
  const dispatch = useAppDispatch();
  const view = useTypedSelector(state => state.vault.view);
  return (
    <div>
        <ViewTab setViewFunction={setVaultView} viewType={view}/>
    </div>
  );
}
