import * as React from 'react';
import { CompanyType } from '../../../../Redux/Types/companyTypes';
import { PrimaryTheme } from '../../../../Config/Theme/theames';

export interface ICompanyCardProps {
    company: CompanyType;
    id: string;
}

export function CompanyCard(props: ICompanyCardProps) {
    return (
        <div className='company-card' style={{
            width: '269.5px',
            height: '180px',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '8px',
            border: `1px solid ${PrimaryTheme.primaryGreyLight}`,
        }}>
            {/* <div style={{ height: '40px', width: '40px' }}>
                <span style={{
                    fontSize: '38px',
                    fontWeight: '600',
                    color: `rgb(${Math.floor(Math.random() * 255)},${Math.floor(Math.random() * 255)},${Math.floor(Math.random() * 255)})`
                }}>
                    {
                        props.company.firm_name[0]
                    }
                </span>
            </div> */}
            <img width= '229px' height= '100px' style={{objectFit: "contain"}} src={props.company.logo} alt="company-logo" />
            <p style={{
                width:'80%',
                fontWeight: '600',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
            }}>
                {
                    props.company.firm_name
                }
            </p>
        </div>
    );
}
