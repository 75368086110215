import * as React from "react";
import { PrimaryTheme } from "../../../../../../Config/Theme/theames";
import { MetricIconNameList } from "../../../../../../views/Onboarding/Step4/SideFilter/SIdeItem";
import { useTypedSelector } from "../../../../../../Config/Hooks/useTypedSelector";

import './style.scss'

export interface ISideItemProps {
  active?: boolean;
  onClick?: Function;
  name?: string;
  style?: React.CSSProperties;
  innerStyle?: React.CSSProperties;
  noIcon?: boolean;
}



export function SideItemDM(props: ISideItemProps) {
  const [icon, setIcon] = React.useState<React.ReactNode>(null);
  const allMetrics = useTypedSelector(state => state.onBoarding.metrics.allMetrics.data)
  const selectedMetrics = useTypedSelector(state => state.onBoarding.metrics.selectedMetrics.data)
  const searchTabMetrics = useTypedSelector(state => state.dataManager.metrics.searchTabMetrics);
  const [selectedMetricCnt, setSelectedMetricCnt] = React.useState(0);
  const currentPillar = useTypedSelector(state => state.onBoarding.metrics.currentPillar);
  const myMetrics = useTypedSelector(state => state.dataManager.metrics.myMetrics);
  React.useEffect(() => {
    MetricIconNameList.forEach((itm) => {
      if (itm.name === props.name) {
        setIcon(
          <itm.icon
            stroke={
              props.active
                ? PrimaryTheme.primaryGreen
                : PrimaryTheme.primaryGray
            }
            inheritSize
          />
        );
      }
    });
  }, [props.active]);

  React.useEffect(() => {
    let cnt = 0;
    if (searchTabMetrics.tabsValue === currentPillar || searchTabMetrics.tabsValue === "All") {
      searchTabMetrics.data.forEach((metric: any) => {
        if (metric.category === props.name) cnt++;
      })
    } else {
      selectedMetrics.forEach((metric: any) => {
        if (metric.category === props.name) cnt++;
      })
    }
    setSelectedMetricCnt(cnt)
  }, [selectedMetrics, searchTabMetrics.data])
  return (
    <div
      className="side-filter-item"
      onClick={() => props.onClick && props.onClick()}
      style={{
        backgroundColor: props.active
          ? PrimaryTheme.primaryGreenLight
          : "transparent",
        ...props.style,
      }}
    >
      <div style={{...props.innerStyle,
      gridTemplateColumns:props.noIcon?'1fr':'1fr 5fr'
      }}>
        {
          !props.noIcon &&
          <div style={{ height: "20px", width: "20px" }}>{icon}</div>}
        <p
          style={{
            color: props.active
              ? PrimaryTheme.primaryGreen
              : PrimaryTheme.primaryGray,
          }}
        >
          {`${props.name} ${selectedMetricCnt?`(${selectedMetricCnt})`:``}`}
        </p>
      </div>
    </div>
  );
}
