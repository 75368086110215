import React from 'react'
import IconProps from './Interface/IconProps';

const WarningIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 24}
      height={props.inheritSize ? "100%" : 24}
      viewBox="0 0 24 24"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.86902 19.999C1.48306 20.6656 1.96411 21.5 2.73445 21.5H21.2656C22.0359 21.5 22.5169 20.6656 22.131 19.999L12.8654 3.99482C12.4803 3.32954 11.5197 3.32954 11.1346 3.99483L1.86902 19.999ZM13 17.5C13 18.0523 12.5523 18.5 12 18.5C11.4477 18.5 11 18.0523 11 17.5C11 16.9477 11.4477 16.5 12 16.5C12.5523 16.5 13 16.9477 13 17.5ZM13 13.5C13 14.0523 12.5523 14.5 12 14.5C11.4477 14.5 11 14.0523 11 13.5V11.5C11 10.9477 11.4477 10.5 12 10.5C12.5523 10.5 13 10.9477 13 11.5V13.5Z"
        fill={props.fill ? props.fill : "#F04F6D"}
      />
    </svg>
  );
}

export default WarningIcon