import type { StatusType } from "../Types/userTypes";
import { DataManagerState } from "../Types/dataManagerTypes";

import {
    Reducer,
    createSlice,
} from "@reduxjs/toolkit";
import { clearStore } from "../ExtraReducrActions";


const initialState: DataManagerState = {
    metrics: {
        //all metrics,current( piller group and catagory) and metrics refs are in onboarding reducer
        myMetrics: {
            status: 'idle',
            data: []
        },
        myMetricsData: {
            status: 'idle',
            data: []
        },
        myMetricsDataByMonth: {
            status: 'idle',
            data: []
        },
        dateSlicer: {
            currentYear: 0,
            currentMonth: 0,
        },
        tableDataDM:{},
        tableDerivedMetricsDataDM:{
            status: 'idle',
            data: {}
        },
        tableFullYearDerivedMetricsDataDM:{
            status: 'idle',
            data: {}
        },
        fullYearTableDataDM:{},
        metricStatus: {
            status: 'idle',
            data: []
        },
        derivedMetrics: {
            status: 'idle',
            data: []
        },
        myDerivedMetrics:{
            status: 'idle',
            data: []
        },
        allFullMyDerivedMetrics:{
            status: 'idle',
            data: []
        },
        fullMyDerivedMetrics:{
            status: 'idle',
            data: []
        },
        myDerivedMetricsData: {
            status: 'idle',
            data: []
        },
        myDerivedMetricsDataByMonth: {
            status: 'idle',
            data: []
        },
        searchTabMetrics:{
            tabsValue: "All", // will be "All" by default
            data: [],
            dropDownMetric: null
        },
        myDashboardMetrics: {
            status: 'idle',
            data: []
        },
        myDerivedDashboardMetrics: {
            status: 'idle',
            data: []
        },
        omittedMetrics: {
            status: 'idle',
            data: []
        }
    },
    logs:{
        filteredLogs:{
            status: 'idle',
            data: []
        },
    },
    labels: {
        status: 'idle',
        data: []
    },
    pillars: {},
    filter:{
        TopicStatus:'All',
        MetricTypeDM:'All',
        DepartmentTypeDM:'All',
    },
    assignedDepartmentByCategory: {
        status: 'idle',
        data: []
    },
    contributorsByMetrics: {
        status: 'idle',
        data: []
    }
}
export const userSlice = createSlice({
    name: "user",
    initialState,
    extraReducers: (builder) => builder.addCase(clearStore, () => initialState),
    reducers: {
        setMyMetricsDM: (state, action) => {
            state.metrics.myMetrics = action.payload;
        },
        setMyMetricsDataDM: (state, action) => {
            state.metrics.myMetricsData = action.payload;
        },
        setDateSlicerMonthDM: (state, action) => {
            state.metrics.dateSlicer.currentMonth = action.payload;
        },
        setDateSlicerYearDM: (state, action) => {
            state.metrics.dateSlicer.currentYear = action.payload;
        },
        settableDataDM: (state, action) => {
            state.metrics.tableDataDM = action.payload;
        },
        setTableDerivedMetricsDataDM: (state, action) => {
            state.metrics.tableDerivedMetricsDataDM = action.payload;
        },
        setFullYearDerivedMetricsDataDM: (state, action) => {
            state.metrics.tableFullYearDerivedMetricsDataDM = action.payload;
        },
        setFullYearTableDataDM: (state, action) => {
            state.metrics.fullYearTableDataDM = action.payload;
        },
        setMetricStatusDM: (state, action) => {
            state.metrics.metricStatus = action.payload;
        },
        setDerivedMetricsDM: (state, action) => {
            state.metrics.derivedMetrics = action.payload;
        },
        setMyDerivedMetricsDM: (state, action) => {
            state.metrics.myDerivedMetrics = action.payload;
        },
        setMyMetricsDataByMonth: (state, action) => {
            state.metrics.myMetricsDataByMonth = action.payload;
        },
        setAllFullMyDerivedMetricsDM: (state, action) => {
            state.metrics.allFullMyDerivedMetrics = action.payload;
        },
        setFullMyDerivedMetricsDM: (state, action) => {
            state.metrics.fullMyDerivedMetrics = action.payload;
        },
        setMyDerivedMetricsDataDM: (state, action) => {
            state.metrics.myDerivedMetricsData = action.payload;
        },
        setMyDerivedMetricsDataByMonthDM: (state, action) => {
            state.metrics.myDerivedMetricsDataByMonth = action.payload;
        },
        setSearchTabMetrics: (state, action) => {
            state.metrics.searchTabMetrics = action.payload;
        },
        setMyDashboardMetrics: (state, action) => {
            state.metrics.myDashboardMetrics = action.payload;
        },
        setMyDerivedDashboardMetrics: (state, action) => {
            state.metrics.myDerivedDashboardMetrics = action.payload;
        },
        setFilteredLogsDM: (state, action) => {
            state.logs.filteredLogs = action.payload;
        },
        setPillarsDM: (state, action) => {
            state.pillars = action.payload;
        },
        setCurrentTopicStatusDM: (state, action) => {
            state.filter.TopicStatus = action.payload;
        },
        setCurrentMetricTypeDM: (state, action) => {
            state.filter.MetricTypeDM = action.payload;
        },
        setCurrentDepartmentDM: (state, action) => {
            state.filter.DepartmentTypeDM = action.payload;
        },
        setAssignedDepartmentByCategory: (state, action) => {
            state.assignedDepartmentByCategory = action.payload;
        },
        setOmittedMetrics: (state, action) => {
            state.metrics.omittedMetrics = action.payload;
        },
        setLabels: (state, action) => {
            state.labels = action.payload;
        },
        setContributorsByMetrics: (state, action) => {
            state.contributorsByMetrics = action.payload;
        }
    }
});

export const {
    setMyMetricsDM,
    setMyMetricsDataDM,
    setDateSlicerMonthDM,
    setDateSlicerYearDM,
    settableDataDM,
    setTableDerivedMetricsDataDM,
    setFullYearTableDataDM,
    setMetricStatusDM,
    setDerivedMetricsDM,
    setMyDerivedMetricsDM,
    setAllFullMyDerivedMetricsDM,  
    setFullMyDerivedMetricsDM,
    setMyDerivedMetricsDataDM,
    setMyDerivedMetricsDataByMonthDM,
    setFullYearDerivedMetricsDataDM,
    setSearchTabMetrics,
    setFilteredLogsDM,
    setMyDashboardMetrics,
    setMyDerivedDashboardMetrics,
    setPillarsDM,
    setCurrentTopicStatusDM,
    setAssignedDepartmentByCategory,
    setCurrentMetricTypeDM,
    setMyMetricsDataByMonth,
    setCurrentDepartmentDM,
    setContributorsByMetrics,
    setLabels,
    setOmittedMetrics
} = userSlice.actions;

const DataManagerReducer: Reducer<DataManagerState> = userSlice.reducer;

export default DataManagerReducer;
