import * as React from "react";
import { notification } from "antd";
import "./style.scss";
import CheckFilledIcon from "../BEIcons/CheckFilledIcon";
import { PrimaryTheme } from "../../Config/Theme/theames";
import ErrorIcon from "../BEIcons/ErrorIcon";
import InfoFilledIcon from "../BEIcons/InfoFilledIcon";

interface CustomNotification {
  success: (message: string, description: string, action: () => void, showButton?: boolean) => void;
  error: (message: string, description: string, action: () => void) => void;
  info: (message: string, description: string, action: () => void) => void;
  destroy: () => void;
}

const defaultDuration = 2;

export const BENotify: CustomNotification = {
  success: (message: string, description: string, action: () => void, showButton?: boolean) => {
    notification.open({
      message: <div>{message}</div>,
      description: (
        <div>
          <div style={{ color: "gray" }}>{description}</div>
          {showButton &&
            <div style={{ color: PrimaryTheme.secondaryGreenLight, fontWeight: 700, cursor: 'pointer', marginTop: '0.6rem', fontSize: '1rem' }} onClick={action}>
              Save and Next
            </div>
          }
        </div>
      ),
      icon: <CheckFilledIcon fill={PrimaryTheme.primaryGreen} />,
      duration: defaultDuration,
      // closeIcon:null,
      placement: "bottom",
    });
  },
  error: (message: string, description: string, action: () => void) => {
    notification.open({
      message: <div>{message}</div>,
      description: (
        <div>
          <div style={{ color: "gray" }}>{description}</div>
          <div style={{ color: PrimaryTheme.primaryRedError, fontWeight: 700, cursor: 'pointer', marginTop: '0.6rem', fontSize: '1rem' }} onClick={action}>Contact Us</div>
        </div>
      ),
      icon: <ErrorIcon />,
      duration: defaultDuration,
      // closeIcon:null,
      placement: "bottom",
    });
  },
  info: (message: string, description: string, action: () => void) => {
    notification.open({
      message: <div>{message}</div>,
      description: (
        <div>
          <div style={{ color: "gray" }}>{description}</div>
          <div style={{ color: PrimaryTheme.primaryBlueInfo, fontWeight: 700, cursor: 'pointer', marginTop: '0.6rem', fontSize: '1rem' }} onClick={action}>Contact Us</div>
        </div>
      ),
      icon: <InfoFilledIcon />,
      duration: defaultDuration,
      // closeIcon:null,
      placement: "bottom",
    });
  },
  destroy: () => {
    notification.destroy();
  },
};
