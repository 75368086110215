import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { Form, Radio } from 'antd';
import { LableRequired } from '../../../../../Components/BEFormItems/LableRequired';
import { BEInput } from '../../../../../Components/BEFormItems/BEInput';
import BEMultipleSelect from '../../../../../Components/BEMultipleSelect';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { patchGlobalStakeholder, postGlobalStakeholder, postMaterialityStakeholders } from '../../../Actions/matirialityActions';
import { setRespondantsToAssessment, setStakeholdersToAssessment } from '../../../../../Redux/MatirialityReducer';
import { BEEyeButton } from '../../../../../Components/BEEyeButton';

export const matirialityStakeholderCatagories = [
  'Employees',
  'Executive Committee',
  'Board of Directors',
  'Investors & Shareholders',
  'Suppliers & Contractors',
  'Government',
  'Customers',
  'Communities',
]

export interface IAddStakeHolderProps {
  open: boolean;
  setOpen: Function;
  isGlobal?: boolean;
  isEdit?: boolean;
}

export function AddStakeHolder(props: IAddStakeHolderProps){

  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const currentSurvey = useTypedSelector((state) => state.matiriality.currentMaterialitySurvey);
  const selectedRow = useTypedSelector((state) => state.common.selectedRow);
  const [loading, setLoading] = React.useState(false);
  const [repeat, setRepeat] = React.useState(false);


  const onSubmit = async () => {
    console.log(form.getFieldsValue())
    let obj = form.getFieldsValue();
    setLoading(true)
    if (props.isGlobal) {
      if (obj.internalExternal === undefined) delete obj.internalExternal
      // let body = []
      // console.log(body)
      if (props.isEdit) await dispatch(patchGlobalStakeholder(selectedRow?.id,obj))
      else await dispatch(postGlobalStakeholder([{ ...obj }]));
      setLoading(false)
      form.resetFields();
      !repeat && props.setOpen(false);
    }
    else {
      obj.survey = currentSurvey?.id;
      await dispatch(postMaterialityStakeholders([obj])).then(() => {
        setLoading(false);
        form.resetFields();
        !repeat && props.setOpen(false);
      });
      dispatch(
        setRespondantsToAssessment({
          status: "idle",
          data: [],
        })
      );
      dispatch(
        setStakeholdersToAssessment({
          status: "idle",
          data: [],
        })
      );
    }
  }

  React.useEffect(() => {
    form.resetFields()
    if (props.isEdit && props.open) {
      form.setFieldsValue(selectedRow)
    }
  }, [props.open])

  return (
    <BEDrawer
      width="442"
      open={props.open}
      setOpen={props.setOpen}
      heading={props.isEdit ? "Update Stakeholder" : "Add Stakeholder"}
      footer={
        <>
          <BEButton
            className="primary"
            size="large"
            onMouseEnter={() => setRepeat(false)}
            onClick={() => form.submit()}
            loading={!repeat && loading}
          >
            {props.isEdit ? "Update Stakeholder" : "Add Stakeholder"}
          </BEButton>
          {!props.isEdit && (
            <BEButton
              size="large"
              onMouseEnter={() => setRepeat(true)}
              onClick={() => form.submit()}
              loading={repeat && loading}
            >
              Save & Add Another
            </BEButton>
          )}
        </>
      }
    >
      <Form onFinish={onSubmit} form={form}>
        {props.isGlobal ? (
          "Stakeholder Type"
        ) : (
          <LableRequired>Stakeholder Type</LableRequired>
        )}
        <Form.Item
          name="internalExternal"
          rules={
            props.isGlobal
              ? []
              : [{ required: true, message: "Please Select Stakeholder Type" }]
          }
        >
          <Radio.Group name="internalExternal">
            <Radio value={false}>Internal</Radio>
            <Radio value={true}>External</Radio>
          </Radio.Group>
        </Form.Item>

        <LableRequired>Name</LableRequired>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please Enter Name",
            },
          ]}
        >
          <BEInput placeholder="Enter Name" size="large" />
        </Form.Item>
        <LableRequired>Job Role</LableRequired>
        <Form.Item
          name="role"
          rules={[
            {
              required: true,
              message: "Please Enter Role",
            },
          ]}
        >
          <BEInput placeholder="Enter Role" size="large" />
        </Form.Item>

        {!props.isGlobal && (
          <>
            <BEEyeButton
              title=""
              discription={"Refers to the Company the stakeholder belongs to"}
            >
              <LableRequired>Organisation</LableRequired>
            </BEEyeButton>
            <Form.Item
              name="organization"
              rules={[{ required: true, message: "Please Enter Oganisation" }]}
            >
              <BEInput placeholder="Enter Oganisation" size="large" />
            </Form.Item>
          </>
        )}

        <LableRequired>Category</LableRequired>
        <BEMultipleSelect
          placeholder="Select Category"
          data={matirialityStakeholderCatagories}
          single
          isDataStrArray
          name="category"
        />

        <LableRequired>Email</LableRequired>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: "Please Enter Email", type: "email" },
          ]}
        >
          <BEInput placeholder="Enter Email" size="large" />
        </Form.Item>
      </Form>
    </BEDrawer>
  );
}
