import { clearStore } from "../ExtraReducrActions";
import type { StatusType } from "../Types/userTypes";

import {
  Reducer,
  createSlice,
} from "@reduxjs/toolkit";
import { VaultType } from "../Types/vaultTypes";


const initialState: VaultType = {
  Files: {
    status: 'idle',
    data: [],
  },
  Folders: {
    status: 'idle',
    data: [],
  },
  currentPillar: 'E',
  FYData: {
    status: 'idle',
    data: {},
  },
  view: 'grid',
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: (builder) => builder.addCase(clearStore, () => initialState),
  reducers: {
    setVaultFIles: (state, action) => {
      state.Files = action.payload;
    },
    setVaultFolders: (state, action) => {
      state.Folders = action.payload;
    },
    setVaultCurrentPillar: (state, action) => {
      state.currentPillar = action.payload;
    },
    setFYData: (state, action) => {
      state.FYData = action.payload;
    },
    setVaultView: (state, action) => {
      state.view = action.payload;  
    },
  },
});

export const {
  setVaultFIles,
  setVaultFolders,
  setVaultCurrentPillar,
  setFYData,
  setVaultView,
} = userSlice.actions;

const VaultReducer: Reducer<VaultType> = userSlice.reducer;

export default VaultReducer;
