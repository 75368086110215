import * as React from 'react';
import { Spin } from 'antd';
import { PrimaryTheme } from '../../Config/Theme/theames';
import './style.scss';

export interface IGlobalLoaderProps {
    height?: string;
    size?: 'small' | 'default' | 'large';
}

export function GlobalLoader(props: IGlobalLoaderProps) {
    return (
        <div style={{
            height: props.height ? props.height : '100vh',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Spin  size={props.size ? props.size : 'large'} style={{color:PrimaryTheme.primaryGreen}} />
        </div>
    );
}


