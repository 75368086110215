import React, { FunctionComponent } from 'react';
import { Navigate } from 'react-router-dom';
import { Role } from '../../Redux/Types/userTypes';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

interface IPrivateRoute {
  children?: React.ReactNode,
  roles?: Role[],
  rediectIfNotLoggedIn?: boolean,
  onlyForLoggedIn?: boolean
}
const PrivateRoute: FunctionComponent<IPrivateRoute> = (props) => {
  const location = useLocation();
  const userRole = JSON.parse(localStorage.getItem('userInfo') || '{}').role;

  const hasPermission = () => {
    if(props.onlyForLoggedIn){
      if(userRole) return true;
      return false;
    }
    if (props.roles) {  //if empty array, then no permission will be given
      if (props.roles.includes(userRole as Role)) {
        return true;
      }
      return false;
    }
    return true;
  };

  return (
    <>
      {hasPermission() && <>{
        props.children ? props.children : <Outlet />
      }</>}

      {!hasPermission() && (
        userRole ?
          <Navigate to={{ pathname: '/no-permission' }} /> :
          props.rediectIfNotLoggedIn ?
            <Navigate to={{ 
              pathname: '/login',
              search: `?redirect=${location.pathname}` 
            }} /> :
            <Navigate to={{ pathname: '/login' }} />
      )}
    </>
  );
};

export default PrivateRoute;