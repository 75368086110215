import { Col, Form, Input, Modal, Row, Select } from 'antd';
import React, { useEffect } from 'react'
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { BEInput } from '../../../../../Components/BEFormItems/BEInput';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { editScope1Configuration, editScope2Configuration, getScope2Specifications, resetScope1ConfigurationsById } from '../../../Actions';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { setCurrentScope1Configuration } from '../../../../../Redux/EmmisionsReducer';
import ToolTip from '../../../../../Components/BEToolTip';
import InfoIcon from '../../../../../Components/BEIcons/InfoIcon';
import { PrimaryTheme } from '../../../../../Config/Theme/theames';
import BEConfirmModal from '../../../../../Components/BEConfirmModal';

interface EditFormProps {
  open: boolean;
  setOpen: Function;
  isScope1?: boolean
}

const EditConversionFactors = (props: EditFormProps) => {
  const role = localStorage.getItem('role');
  const dispatch = useAppDispatch()
  const currentScope1Configuration = useTypedSelector(state => state.emissions.configurations.currentScope1Configuration)
  const scope2Specifications = useTypedSelector(state => state.emissions.configurations.scope2Specifications);
  const businessUnits = useTypedSelector(state => state.entity.BusinessUnits);
  const [form] = Form.useForm()
  const [source, setSource] = React.useState<any>('CEA');
  const [year, setYear] = React.useState<any>("FY 2021-2022");
  const [loading, setLoading] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);

  React.useEffect(() => {
    if (currentScope1Configuration) {
      form.setFieldsValue(currentScope1Configuration)
      form.setFieldValue("ef", currentScope1Configuration?.specification);
      // setYear(currentScope1Configuration)
      if (props.isScope1) {
        if (currentScope1Configuration?.emission_category === 'Fugitive emissions') setSource("DEFRA");
        else setSource("IPCC");
        if (currentScope1Configuration.config_type === 1) setSource("CUSTOM");
        if (currentScope1Configuration.config_type === 2) setSource("CUSTOM_DC");
      }
    }
  }, [currentScope1Configuration, form])


  React.useEffect(() => {
    if (!props.open) dispatch(dispatch(setCurrentScope1Configuration(null)));
    if (!props.isScope1 && props.open) dispatch(getScope2Specifications());
    if (!props.isScope1) setSource(currentScope1Configuration?.source)
  }, [props.open])

  const sourceMap:any = {
    'IPCC': 0,
    'CUSTOM': 1,
    'CUSTOM_DC': 2,
    'DEFRA': 0,
  }

  const onFinish = async (values: any) => {
    const businessUnitID = businessUnits.data?.find((obj: any) => obj.name === values.business_unit)?.id;
    setLoading(true)
    if (props.isScope1) {
      // if (source !== "CUSTOM" && source !== "CUSTOM_DC") {
      //   await dispatch(resetScope1ConfigurationsById(Number(currentScope1Configuration?.id)))
      // }
      // else if (source === "CUSTOM") {
      //   await dispatch(
      //     editScope1Configuration(Number(currentScope1Configuration?.id), {
      //       ...currentScope1Configuration,
      //       ...values,
      //       config_type: 1,
      //       business_unit: businessUnitID,
      //     })
      //   );
      // }
      // else if (source === "CUSTOM_DC") {
      //   let newValues: any = {}
      //   //make all values 0 except co2, ch4, n2o
      //   for (let key in values) {
      //     if (key !== 'co2' && key !== 'ch4' && key !== 'n2o') {
      //       newValues[key] = 0
      //     }
      //   }
      //   await dispatch(
      //     editScope1Configuration(Number(currentScope1Configuration?.id), {
      //       ...values,
      //       ...newValues,
      //       config_type: 2,
      //       business_unit: businessUnitID,
      //     })
      //   );
      // }
      let dataToSend:any = {
        ...currentScope1Configuration,
        ...values, 
        business_unit: businessUnitID,
        config_type: sourceMap[source],
      }

      await dispatch(
        editScope1Configuration(Number(currentScope1Configuration?.id), dataToSend)
      );
      
    }
    else await dispatch(
      editScope2Configuration(Number(currentScope1Configuration?.id), {
        ...currentScope1Configuration,
        specification: values.ef,
        ...values,
        business_unit: businessUnitID,
      })
    );
    setLoading(false)
    props.setOpen(false)
  }

  const setFeildvaluesOnSpecificationChange = (year:any,specification:any) => {
    const activeSpec = scope2Specifications?.find(
      (obj: any) => obj?.specification === specification && obj?.ef_year === year
    );
    // console.log(currentScope1Configuration?.specification, value)
    // form.setFieldValue('ef', activeSpec?.ef)
    form.setFieldValue("co2", activeSpec?.co2);
    form.setFieldValue("ch4", activeSpec?.ch4);
    form.setFieldValue("n2o", activeSpec?.n2o);
    form.setFieldValue("co2e", activeSpec?.co2e);
  }

  return (
    <BEDrawer
      open={props.open}
      setOpen={props.setOpen}
      heading={"Conversion Factors"}
      width="600px"
      footer={
        <div>
          {
            role === 'SUPER_ADMIN' &&
            <BEButton
              className="primary"
              size="large"
              onClick={() => setOpenDelete(true)}
              // disabled={(source !== "CUSTOM" && props.isScope1)
              //   // || (source === "CEA" && currentScope1Configuration?.specification === form.getFieldValue('ef'))
              // }
              loading={loading}
              style={{ marginRight: "1rem" }}
            >
              Save
            </BEButton>
          }
          <BEButton size="large" onClick={() => props.setOpen(false)}>
            Cancel
          </BEButton>
        </div>
      }
    >
      {/* <div style={{ fontSize: "1.4rem", fontWeight: 600 }}>
        Conversion Factors
      </div> */}
      <p style={{ fontSize: "1.1rem" }}>
        Metric name: {currentScope1Configuration?.metric}
      </p>
      <Form form={form} style={{ marginTop: "1rem" }} onFinish={onFinish}>
        <Row>
          <Col span={12} style={{ display: "flex", flexDirection: "row" }}>
            Source for the factor below is:
            <ToolTip title={<div>
              <p>IPCC {"(default)"}: Conversion factors & calculation methodology for fuels sourced from IPCC</p>
              <p>Custom: Customize conversion factors as per your requirement</p>
              <p>Custom {"(direct conversion)"}: Bypass IPCC calculation methodology for fuels and input direct emission factors</p>
              <p>DEFRA {"(default)"}: Emission factors for refrigerants sourced from DEFRA</p>
            </div>}>
              {
              props.isScope1 &&
                <div style={{height: "1rem"}}>
                <InfoIcon inheritSize fill={PrimaryTheme.primaryGray} />
              </div>
              }
            </ToolTip>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Select
                placeholder="Select source of factor"
                value={source}
                onChange={(value) => setSource(value)}
                disabled={!props.isScope1 || role !== 'SUPER_ADMIN'}
              >
                {props.isScope1 &&
                  (currentScope1Configuration?.emission_category ===
                    "Fugitive emissions" ? (
                    <Select.Option value="DEFRA">DEFRA (default)</Select.Option>
                  ) : (
                    <Select.Option value="IPCC">IPCC (Default)</Select.Option>
                  ))}
                {props.isScope1 && (
                  <>
                    <Select.Option value="CUSTOM">Custom</Select.Option>
                    {
                      currentScope1Configuration?.emission_category !== "Fugitive emissions" &&
                      <Select.Option value="CUSTOM_DC">Custom (Direct Conversion)</Select.Option>
                    }
                  </>
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {!props.isScope1 && (
          <>
            <Row>
              <Col span={12}>EF Specification:</Col>
              <Col span={12}>
                <Form.Item name="specification">
                  <Select
                    disabled={
                      (source === "Default" && !props.isScope1) || role !== 'SUPER_ADMIN'}
                    placeholder="Select specification"
                    onChange={(value) => setFeildvaluesOnSpecificationChange(form.getFieldValue('ef_year'),value)}
                  >
                    {Array.from(new Set(scope2Specifications.filter((spec1: any) => spec1?.location === currentScope1Configuration?.location).map((spec: any) => spec?.specification))).
                      map((spec: any) => {
                        return (
                          <Select.Option value={spec}>
                            {spec}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>Year:</Col>
              <Col span={12}>
                <Form.Item name="ef_year">
                  <Select
                    disabled={

                      (source === "Default" && !props.isScope1) ||
                      role !== 'SUPER_ADMIN'
                    }
                    placeholder="Select EF Year"
                    value={year}
                    onChange={(value) =>{ setYear(value)
                      setFeildvaluesOnSpecificationChange(value,form.getFieldValue('specification'))
                    }}
                  >
                    {
                      Array.from(new Set(scope2Specifications.filter((spec1: any) => spec1?.location === currentScope1Configuration?.location).map((spec: any) => spec?.ef_year))).
                        map((year: any) => {
                          return (
                            <Select.Option value={year}>
                              {year}
                            </Select.Option>
                          );
                        })
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}

        <Row>
          <Col span={12}>
            CO2 emission factor{" "}
            {props.isScope1 ? (source === 'CUSTOM_DC' ? "(kg per unit)" : "(kg per TJ)") : "(tonnes per MWh)"}
          </Col>
          <Col span={12}>
            <Form.Item name="co2">
              <BEInput
                placeholder="Enter CO2 emission factor"
                disabled={

                  (source === "Default" && !props.isScope1) ||
                  source === "IPCC" ||
                  source === "CEA" ||
                  source === "DEFRA" ||
                  ((source === "CUSTOM" || source === "CUSTOM_DC") &&
                    currentScope1Configuration?.emission_category ===
                    "Fugitive emissions")
                  || role !== 'SUPER_ADMIN'
                }
              ></BEInput>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            CH4 emission factor{" "}
            {props.isScope1 ? (source === 'CUSTOM_DC' ? "(kg per unit)" : "(kg per TJ)") : "(tonnes per MWh)"}
          </Col>
          <Col span={12}>
            <Form.Item name="ch4">
              <BEInput
                placeholder="Enter CH4 emission factor"
                disabled={
                  (source === "Default" && !props.isScope1) ||
                  source === "IPCC" ||
                  source === "CEA" ||
                  source === "DEFRA" ||
                  ((source === "CUSTOM" || source === "CUSTOM_DC") &&
                    currentScope1Configuration?.emission_category ===
                    "Fugitive emissions")
                  || role !== 'SUPER_ADMIN'
                }
              ></BEInput>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            N2O emission factor{" "}
            {props.isScope1 ? (source === 'CUSTOM_DC' ? "(kg per unit)" : "(kg per TJ)") : "(tonnes per MWh)"}
          </Col>
          <Col span={12}>
            <Form.Item name="n2o">
              <BEInput
                placeholder="Enter N2O emission factor"
                disabled={
                  (source === "Default" && !props.isScope1) ||
                  source === "IPCC" ||
                  source === "CEA" ||
                  source === "DEFRA" ||
                  ((source === "CUSTOM" || source === "CUSTOM_DC") &&
                    currentScope1Configuration?.emission_category ===
                    "Fugitive emissions")
                  || role !== 'SUPER_ADMIN'
                }
              ></BEInput>
            </Form.Item>
          </Col>
        </Row>

        {!props.isScope1 && (
          <Row>
            <Col span={12}>CO2e emission factor (tonnes per MWh)</Col>
            <Col span={12}>
              <Form.Item name="co2e">
                <BEInput
                  placeholder="Enter CO2e emission factor"
                  disabled={
                    (source === "Default" && !props.isScope1) ||
                    source === "IPCC" ||
                    source === "CEA" ||
                    source === "DEFRA" ||
                    source === "CUSTOM_DC" ||
                    (source === "CUSTOM" &&
                      currentScope1Configuration?.emission_category ===
                      "Fugitive emissions")
                    || role !== 'SUPER_ADMIN'
                  }
                ></BEInput>
              </Form.Item>
            </Col>
          </Row>
        )}

        {props.isScope1 && (
          <div style={{
            display: source === "CUSTOM_DC" ? "none" : "inherit",
          }}>
            <Row>
              <Col span={12}>Net calorific value (TJ per Gg)</Col>
              <Col span={12}>
                <Form.Item name="net_calorific_value">
                  <BEInput
                    placeholder="Enter net calorific value"
                    disabled={
                      source === "IPCC" ||
                      source === "DEFRA" ||
                      source === "CUSTOM_DC" ||
                      (source === "CUSTOM" &&
                        currentScope1Configuration?.emission_category ===
                        "Fugitive emissions")
                      || role !== 'SUPER_ADMIN'
                    }
                  ></BEInput>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={12}>Fuel density (kg per litre)</Col>
              <Col span={12}>
                <Form.Item name="kg_per_litre">
                  <BEInput
                    placeholder="Enter fuel density value"
                    disabled={
                      source === "IPCC" ||
                      source === "DEFRA" ||
                      source === "CUSTOM_DC" ||
                      (source === "CUSTOM" &&
                        (currentScope1Configuration?.emission_category ===
                          "Fugitive emissions" ||
                          currentScope1Configuration?.unit === "kg" ||
                          currentScope1Configuration?.unit === "m3"))
                      || role !== 'SUPER_ADMIN'
                    }
                  ></BEInput>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={12}>Fuel density (kg per m3)</Col>
              <Col span={12}>
                <Form.Item name="kg_per_cubic_meter">
                  <BEInput
                    placeholder="Enter fuel density value"
                    disabled={
                      source === "IPCC" ||
                      source === "DEFRA" ||
                      source === "CUSTOM_DC" ||
                      (source === "CUSTOM" &&
                        (currentScope1Configuration?.emission_category ===
                          "Fugitive emissions" ||
                          currentScope1Configuration?.unit === "kg" ||
                          currentScope1Configuration?.unit === "Litres (l)"))
                      || role !== 'SUPER_ADMIN'
                    }
                  ></BEInput>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={12}>Refrigerant GWP (kg CO2e per kg)</Col>
              <Col span={12}>
                <Form.Item name="gwp_refrigerant">
                  <BEInput
                    placeholder="Enter GWP 100 value"
                    disabled={
                      source === "IPCC" ||
                      source === "DEFRA" ||
                      source === "CUSTOM_DC" ||
                      (source === "CUSTOM" &&
                        currentScope1Configuration?.emission_category !==
                        "Fugitive emissions")
                      || role !== 'SUPER_ADMIN'
                    }
                  ></BEInput>
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}
      </Form>
      <BEConfirmModal
        title="Edit Configuration"
        message="Are you sure you want to edit this configuration? It will trigger a recalculation of all the emissions data."
        visible={openDelete}
        setVisible={setOpenDelete}
        onConfirm={async () => {
          form.submit();
        }}
      />
    </BEDrawer>
  );
}


export default EditConversionFactors