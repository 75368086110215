import React from 'react';
import type { Meta, StoryObj } from '@storybook/react';
import { BECardPagination } from '../Components/BECardsPagination';


const dummyComponent = ()=>{
    return(
        <div>test</div>
    )
}

const Pagination = ()=>{
  return (
    <></>
    // <BECardPagination Component={dummyComponent} cardDataArray={[1,2,3,4,5,6,7,8,9,10]} />
  )
}

const pagination = {
  title: 'CardPagination',
  component: Pagination,
  parameters: {
    layout: 'centered',
  }
} satisfies Meta<typeof Pagination>;



//  

export default pagination;
export { Pagination};


