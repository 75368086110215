import * as React from 'react';
import { DerivedMetricDataTable } from './MetricDataTable/DerivedMetricDataTable';
import { DerivedMetricTrendGraaph } from './TrendGraph/DerivedMericTrendGraph';
import { BETabs } from '../../../../../../Components/BETabs';
import { BECollapse } from '../../../../../../Components/BECollapse';
import BEStatusTag from '../../../../../../Components/BEStatusTag';
import ToolTip from '../../../../../../Components/BEToolTip';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';
import { AddCommaToNumber } from '../../../../../../Config/Functions/UsefullFunctions';

export interface IDerivedMetricDataProps {
  metric: any;
}

export function DerivedMetricData(props: IDerivedMetricDataProps) {
  const [totalValue, setTotalValue] = React.useState<number>(0);
  const tableDerivedMetricsDataDM = useTypedSelector(state => state.dataManager.metrics.tableDerivedMetricsDataDM);
  React.useEffect(() => {
    const total = tableDerivedMetricsDataDM.data[props.metric.id]?.reduce((acc: number, item: any) => {
      return acc + item.value;
    }, 0);
    setTotalValue(total ? total : 0);
  }, [props.metric, tableDerivedMetricsDataDM.data]);

  return (
    <div style={{ marginTop: '1rem' }}>
      <BECollapse
        headingRightContent={
          <BEStatusTag status="info">
            {AddCommaToNumber(totalValue, false, true)}{' '}{props.metric.unit}
          </BEStatusTag>
        }
        headingStyle={{ height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        heading={
          <div style={{ display: 'flex', gap: '1rem' }}>
            <p style={{ fontWeight: '600', fontSize: '1rem' }}>{props.metric.title}</p>
            <ToolTip title='The values for C metrics are calculated.'>
              <BEStatusTag status='success'>C</BEStatusTag>
            </ToolTip>
          </div>
        }
      >

        <BETabs
          tabBarStyle={{ marginLeft: 20, marginTop: '1rem' }}
          items={[
            {
              key: 'data-table',
              label: 'Data Table',
              children: <DerivedMetricDataTable
                metric={props.metric}
              />
            },
            // {
            //   key: 'trend-graph',
            //   label: 'Trend Graph',
            //   children: <DerivedMetricTrendGraaph 
            //     //metric = {props.metric}
            //   />
            // }
          ]}
        />
      </BECollapse>
    </div>
  );
}
