import * as React from 'react';
import './style.scss'
import { NewBETable } from '../../../../../Components/BETable/newBETable';
import { ColumnsType } from 'antd/es/table';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { BoschData } from '../../../../../Redux/Types/boschDashboard';
import { Select } from 'antd';
import EmissionCard from '../../../../../Components/Cards/EmissionCard';
import { BEWrapper } from '../../../../../Components/BEWrapper';
import StackedColumnCharts from '../../../../../Components/BECharts/StackedColumnCharts';
import { AddCommaToNumber, rotateArray } from '../../../../../Config/Functions/UsefullFunctions';
import { shortMonths } from '../../../../../Config/Data/genralData';
import dayjs from 'dayjs';
import { FUEL,FLEET,ROUTE, VEHICLE_TYPE, TITLE } from '..';

export interface IFilterByScreenProps {
  type: typeof FUEL | typeof FLEET | typeof ROUTE | typeof VEHICLE_TYPE | typeof TITLE;
}


export function FilterByScreen (props: IFilterByScreenProps) {
  
  const data = useTypedSelector((state) => state.boschDashboard.BoschData);
  const [filteredData, setFilteredData] = React.useState<BoschData[]>([]);
  const [chartData, setChartData] = React.useState<any>([]);
  const yearEnd = useTypedSelector((state) => state.companies.yearEnd);
  const [cardsData, setCardsData] = React.useState<any>({
    totalEmissions: 0,
    emissionPerKm: 0,
    emissionPerLtr: 0,
    emissionPerTrip: 0,
  });
  const [filter, setFilter] = React.useState<any>({
    fuelType: undefined,
    fleetId: undefined,
    vehicleType: undefined,
    routeId: undefined
  });
  
  const typeOfTOFilterMap = {
    [FUEL]: ['fuelType'],
    [FLEET]: ['fleetId', 'vehicleType'],
    [ROUTE]: ['routeId'],
    [VEHICLE_TYPE]: ['vehicleType'],
    [TITLE]: ['title']
  }
  const typeOfToFilterHeaderMap = {
    [FUEL]: ['Fuel'],
    [FLEET]: ['Fleet', 'Vehicle'],
    [ROUTE]: ['Route'],
    [VEHICLE_TYPE]: ['Vehicle Type'],
    [TITLE]: ['Title']
  }

  //yahamah
  const columns: ColumnsType<any> = [
       {
        title: "ID",
        dataIndex: "_id",
       },
        {
          title: "Title",
          dataIndex: "title",
        },
        {
          title: "Date",
          dataIndex: "date",
          render: (date:any) => dayjs(date).format('MMM DD,YYYY')
        },
        {
          title: "Quantity",
          dataIndex: "quantity",
          render: (quantity:any) => AddCommaToNumber(quantity,false,true,true,4)
        },
        {
          title: "Unit",
          dataIndex: "unit",
        },
        {
          title: "CH4",
          dataIndex: "ch4",
          render: (ch4:any) => AddCommaToNumber(ch4,false,true,true,4)
        },
        {
          title: "N2O",
          dataIndex: "n2o",
          render: (n2o:any) => AddCommaToNumber(n2o,false,true,true,4)
        },
        {
          title: "CO2",
          dataIndex: "co2",
          render: (co2:any) => AddCommaToNumber(co2,false,true,true,4)
        },
        {
          title: "Tco2e",
          dataIndex: "Tco2e",
          render: (Tco2e:any) => AddCommaToNumber(Tco2e,false,true,true,4)
        },

      // "_id": "66d07199040724020a9deb8f",
      // "quantity": 50000.0,
      // "date": "2023-01-04T18:30:00+05:30",
      // "unit": "litres",
      // "title": "Motor Gasoline-On-site",
      // "ch4": 4.917299999999999,
      // "n2o": 0.9834599999999998,
      // "co2": 113589.62999999999,
      // "Tco2e": 113.98793129999999
  ]
  
  //bosch
  // const columns: ColumnsType<any> = [
  //   {
  //     title: 'Trip ID',
  //     dataIndex: 'tripId',
  //     key: 'tripId',
  //   },
  //   {
  //     title: 'Total Emissions',
  //     dataIndex: 'tco2e',
  //     key: 'tco2e',
  //   },
  //   {
  //     title: 'Fuel(ltrs)',
  //     dataIndex: 'fuel',
  //     key: 'fuel',
  //   },
  //   {
  //     title: 'Fuel Type',
  //     dataIndex: 'fuelType',
  //     key: 'fuelType',
  //   },
  //   {
  //     title: 'Fleet ID',
  //     dataIndex: 'fleetId',
  //     key: 'fleetId',
  //   },
  //   {
  //     title: 'Route ID',
  //     dataIndex: 'routeId',
  //     key: 'routeId',
  //   },
  //   {
  //     title: 'Vehicle Type',
  //     dataIndex: 'vehicleType',
  //     key: 'vehicleType',
  //   },
  //   {
  //     title: 'Vehicle ID',
  //     dataIndex: 'vehicleId',
  //     key: 'vehicleId',
  //   },
  //   {
  //     title: 'Vehicle Load Capacity',
  //     dataIndex: 'vehicleLoadCapacity',
  //     key: 'vehicleLoadCapacity',
  //   },
  //   {
  //     title: 'Running Time',
  //     dataIndex: 'runningTime',
  //     key: 'runningTime',
  //   },
  //   {
  //     title: 'Idle Time',
  //     dataIndex: 'idleTime',
  //     key: 'idleTime',
  //   },
  //   {
  //     title: 'Trip Start Time',
  //     dataIndex: 'tripStartTime',
  //     key: 'tripStartTime',
  //   },
  //   {
  //     title: 'Trip End Time',
  //     dataIndex: 'tripEndTime',
  //     key: 'tripEndTime',
  //   },
  //   {
  //     title: 'Trip Distance',
  //     dataIndex: 'distance',
  //     key: 'distance',
  //   },
  // ]


  React.useEffect(() => {
    let tempData:any = {};
    let filterKey = typeOfTOFilterMap[props.type][0];
    filteredData.forEach((item:any) => {

      //bosch
      // const itemMonth=  dayjs(item.tripEndTime,'DD-MM-YYYYTHH:mm:ss').month() ;

      //yahamah
      const itemMonth=  dayjs(item.date).month();
      if(!tempData[item[filterKey]]){
        tempData[item[filterKey]] = new Array(12).fill(0)
        // tempData[item[filterKey]][itemMonth] += item.tco2e;    //bosch
        tempData[item[filterKey]][itemMonth] = item.Tco2e;        //yahamah
      }
      else {
        // tempData[item[filterKey]][itemMonth] += item.tco2e;   //bosch
        tempData[item[filterKey]][itemMonth] = item.Tco2e;       //yahamah
      }
    });

    let dataToSet = [];
    for (const key in tempData) {
      dataToSet.push({
        name: key,
        data: rotateArray(tempData[key],yearEnd)
      })
    }
    setChartData(dataToSet);

      
  }, [filteredData,yearEnd]);

  React.useEffect(() => {
    const filterKeys = typeOfTOFilterMap[props.type];
    let tempData = data.data.filter((item:any) => {
      for (const key of filterKeys) {
        if (filter[key] && item[key] !== filter[key]) {
          return false;
        }
      }
      return true;
    });
    setFilteredData(tempData);

    let totalEmissions = tempData.reduce((acc, item) => acc + item.tco2e, 0);
    let emissionPerKm =( totalEmissions / tempData.reduce((acc, item) => acc + item.distance, 0))*1000;
    let emissionPerLtr = (totalEmissions / tempData.reduce((acc, item) => acc + item.fuel, 0))*1000;
    let emissionPerTrip = totalEmissions / tempData.length;
    setCardsData({
      totalEmissions,
      emissionPerKm,
      emissionPerLtr,
      emissionPerTrip
    });
  }, [filter, data.data, props.type]);

  return (
    <div className='filter-by-screen'>
      <BEWrapper>
       <div>
        
         <div className='filters'>
            {
              typeOfTOFilterMap[props.type].map((item,index) => (
                <div className='filter'>
                  <Select
                    style={{width: '145px'}}
                    value={filter[item]}
                    onChange={(value) => setFilter({...filter, [item]: value})}
                    placeholder={typeOfToFilterHeaderMap[props.type][index]}
                    showSearch
                    allowClear
                    onClear={() => setFilter({...filter, [item]: undefined})}
                  >
                    {
                      Array.from(new Set(data.data.map((item1:any) => item1[item]))).sort( (a:any,b:any) => a-b)
                      .map((item1:any) => (
                        <Select.Option value={item1}>{item1}</Select.Option>
                      ))
                    }
                  </Select>

                </div>
              ))
            }
        </div>
        <div className='cards'>
          {/* <EmissionCard value={
            cardsData.totalEmissions
          } unit='tCO2e' description='Total Emissions' />
        
          <EmissionCard value={cardsData.emissionPerKm} unit='kgCO2e/km' description='Emission per km' />

          <EmissionCard value={
            cardsData.emissionPerLtr
            } unit='kgCO2e/ltr' description='Emission per ltr' />

          <EmissionCard value={
            cardsData.emissionPerTrip
            } unit='tCO2e' description='Emission per trip' /> */}
          
        </div>

        <BEWrapper>
          <StackedColumnCharts 
          colorTheme={3}
            data={chartData} 
            xAxisLabels={rotateArray(shortMonths,yearEnd)}
            yAxisLabel='tCO2e'
          />
        </BEWrapper>
       </div>
      </BEWrapper>


      <div className='data-table'>
        {/* <p>Trip Data</p> */}
        <p>Data</p>
        <NewBETable
          columns={columns}
          data={filteredData}
          pagination={true}
          tableProps={{
            scroll: { x: 1500 }
          }}
        />
      </div>
    </div>
  );
}
