import * as React from 'react';
import { BETabs } from '../../../../../../../Components/BETabs';
import { useAppDispatch } from '../../../../../../../Config/Hooks/useAppDispatch';
import { ChildrenRightTabs } from './ChildrenRightTabs';
import { useTypedSelector } from '../../../../../../../Config/Hooks/useTypedSelector';
import { Label } from 'recharts';
import { setSelectedTemplateCategory } from '../../../../../../../Redux/ReportingReducer';
import { TemplateSelectedTopicTypes } from '../../../../../../../Redux/Types/reportingTypes';
import InfoIcon from '../../../../../../../Components/BEIcons/InfoIcon';
import { getOmittedReportTopics } from '../../../../../Actions/template';

export interface IReportTabsProps {
}

export function ReportTabs(props: IReportTabsProps) {
    const dispatch = useAppDispatch();
    const currentSection = useTypedSelector(state => state.reporting.selectedTemplateSection);
    const currentDimension = useTypedSelector(state => state.reporting.selectedTemplateDimension);
    const selectedTopics = useTypedSelector(state => state.reporting.selectedTemplateTopics);
    const currentCategory = useTypedSelector(state => state.reporting.selectedTemplateCategory);
    const omitterReportTopics = useTypedSelector(state => state.reporting.omittedReportTopics);
    
    React.useEffect(() => {
        dispatch(setSelectedTemplateCategory(selectedTopics.data.filter(
            (item: TemplateSelectedTopicTypes) => item.section === currentSection && (item.dimension === currentDimension || !currentDimension))
            [0!]?.category || null
        ));
    },[currentDimension,currentSection]);

    React.useEffect(() => {
        if (omitterReportTopics.status === 'idle') {
            dispatch(getOmittedReportTopics());
        }
    },[]);

    if(selectedTopics.data.length === 0) return null

    return (
        <BETabs
            destroyInactiveTabPane
            size='large'
            type='card'
            activeKey={currentCategory || 'All'}
            tabBarGutter={20}
            tabBarStyle={{ margin: "2rem 1rem 0 1rem" }}
            onChange={(key) => { dispatch(setSelectedTemplateCategory(key)) }}
            items={
                Array.from(new Set(selectedTopics.data.filter(
                    (item: TemplateSelectedTopicTypes) => item.section === currentSection && (item.dimension === currentDimension || !currentDimension))
                    .map((item2: TemplateSelectedTopicTypes) => item2.category)))
                    // .sort((a: string, b: string) => a.localeCompare(b))
                .map((category: string) => {
                    return {
                        key: category,
                        label: category,
                    }
                })
            }
        />
    );
}
