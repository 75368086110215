import React from 'react'
import IconProps from '../Interface/IconProps';

const WaterIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 14}
      height={props.inheritSize ? "100%" : 17}
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00065 0.166504C2.55898 3.95817 0.333984 7.23317 0.333984 9.99984C0.333984 14.1498 3.50065 16.8332 7.00065 16.8332C10.5007 16.8332 13.6673 14.1498 13.6673 9.99984C13.6673 7.23317 11.4423 3.95817 7.00065 0.166504ZM7.00065 15.1665C4.20898 15.1665 2.00065 13.0248 2.00065 9.99984C2.00065 8.04984 3.62565 5.4665 7.00065 2.38317C10.3757 5.4665 12.0007 8.0415 12.0007 9.99984C12.0007 13.0248 9.79232 15.1665 7.00065 15.1665ZM3.52565 10.1665C3.83398 10.1665 4.08398 10.3832 4.14232 10.6832C4.48398 12.5332 6.04232 13.1665 7.17565 13.0748C7.53398 13.0582 7.83398 13.3415 7.83398 13.6998C7.83398 14.0332 7.56732 14.3082 7.23398 14.3248C5.45898 14.4332 3.38398 13.4165 2.90898 10.8915C2.84232 10.5165 3.14232 10.1665 3.52565 10.1665Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
    </svg>
  );
}

export default WaterIcon