import React from 'react'
import IconProps from './Interface/IconProps';

const FilterIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 24}
      height={props.inheritSize ? "100%" : 24}
      viewBox="0 0 24 24"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
      stroke={props.stroke ? props.stroke : "black"}
    >
      <path
        d="M4.25457 5.61C6.27457 8.2 10.0046 13 10.0046 13V19C10.0046 19.55 10.4546 20 11.0046 20H13.0046C13.5546 20 14.0046 19.55 14.0046 19V13C14.0046 13 17.7246 8.2 19.7446 5.61C20.2546 4.95 19.7846 4 18.9546 4H5.04457C4.21457 4 3.74457 4.95 4.25457 5.61Z"
        fill={props.fill ? props.fill : "#333333"}
        stroke={props.stroke ? props.stroke : "black"}
      />
    </svg>
  );
}

export default FilterIcon