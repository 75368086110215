import * as React from 'react';
import companyLogo from '../../../assets/images/Company/Logo_horizontal_white.png'
import { PrimaryTheme } from '../../../Config/Theme/theames';
export interface ILeftComponentLoginProps {
    step: number;
}

export function LeftComponentLogin (props: ILeftComponentLoginProps) {
  return (
    <div className='left-section'>
        <div>
            <p>WELCOME TO</p>
            <br/>
            <img src={companyLogo} className='logo-img'/>
            <br/><br/>
            <p style={{width:'70%',color:PrimaryTheme.primaryGray,lineHeight:'24px'}}>Measure, Manage and Report your ESG data</p>
            <br/><br/><br/>
            <p>Sounds Interesting? <a href="mailto:info@breatheesg.com" style={{color:PrimaryTheme.primaryGreen}}>Get in touch!</a></p>
        </div>
    </div>
  );
}
