import * as React from 'react';
import { PrimaryTheme } from '../../../../../../Config/Theme/theames';
import logo from '../../../../../../assets/images/Company/Logo_horizontal_white.png'
import { Progress } from 'antd';
import { useTypedSelector } from '../../../../../../Config/Hooks/useTypedSelector';

export interface ISupplierTopNavProps {
}

export function SupplierTopNav(props: ISupplierTopNavProps) {
  const supplierAssessmentQuestions = useTypedSelector(state => state.supplier.SupplierQuestions);
  const supplierAssessmentAnswers = useTypedSelector(state => state.supplier.SupplierAnswers);


  return (
    <div style={{position: "fixed", width: "100%", zIndex:"2", backgroundColor: PrimaryTheme.primaryBlack, display: 'flex', alignItems: 'center', height: '79px', padding: '0 1rem', color: 'white' }}>
      <img src={logo} alt="" style={{ width: '150px' }} />
      <p style={{ fontSize: '1.2rem', fontWeight: 600, marginLeft: '2rem' }}>INTERNAL ASSESSMENT</p>
    </div>
  );
}
