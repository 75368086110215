import { Radio } from 'antd';
import * as React from 'react';
import './style.scss';
import { GresbMain } from '../GresbMain';
import { arrangeGresbData, postGresbEntityDataToPortal } from '../../../Actions/gresbActions';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { fillReportData } from '../../../Actions/template';
import { UploadEvidenceDrawer } from '../UploadEvidence';

export interface IRadioListProps {
    data: any[];
    isFirstLevel?: boolean;
}

export default function RadioList(props: IRadioListProps) {
    const [arrangedData, setArrangedData] = React.useState<any[]>([]);
    const [selected, setSelected] = React.useState<any>({});
    const [open, setOpen] = React.useState<boolean>(false);
    const [evidenceData, setEvidenceData] = React.useState<any>(null);
    const currentCategory = useTypedSelector(state => state.reporting.selectedTemplateCategory);
    const currentReport = useTypedSelector(state => state.reporting.currentReport);
    const reportData = useTypedSelector(state => state.reporting.reportData.data);
    const dispatch = useAppDispatch();

    // arrange Data on basis of unique topics
    React.useEffect(() => {
        const arrangedData = arrangeGresbData(props.data);
        let selectedData: any = {};
        props.data.forEach((item) => {
            const data = populateData(item);
            selectedData = {
                ...selectedData,
                ...data
            }
        });
        setSelected(selectedData);
        setArrangedData(arrangedData);
    }, [props.data, currentCategory, reportData]);

    // To populate data in the form
    const populateData = (data: any) => {
        const topicId = data?.id;
        const topicData = reportData[topicId!] ? reportData[topicId!][0!] : null;
        return {
            [data.disclosure_detail_code]: topicData?.data
        }
    }

    // function to handle saving the data
    const handleAddData = async (disclosure_detail_code: string) => {
        const data = props.data.find((item) => item.disclosure_detail_code === disclosure_detail_code);
        let value = data?.value;
        const dataToSend = {
            data: value
        }
        const variableCode = data?.variable_code;
        const otherData = props.data.filter((item) => item.variable_code === variableCode && item.disclosure_detail_code !== disclosure_detail_code);
        otherData.forEach(async (item) => {
            const demoDataToSend = {
                data: "nan"
            }
            await dispatch(fillReportData({
                ...demoDataToSend,
                esg_report: currentReport?.id,
                topic: item.id,
            }));
        });
        await dispatch(fillReportData({
            ...dataToSend,
            esg_report: currentReport?.id,
            topic: data?.id,
        })).then(async () => {
            // GRESB doesn't accept 1.0 and 2.0 as values for Radio buttons
            value = value?.toString()?.split('.')[0];
            const portalDataUpdated = await dispatch(postGresbEntityDataToPortal(variableCode, value));
        });
    }

    const containsEvidence = (children: any[]) => {
        return children.some((child) => child.responseType === 'Evidence' || child.responseType === 'Evidence(H)' || child.responseType === 'Evidence(F,H)');
    }

    const getEvidenceData = (children: any[]) => {
        return children.find((child) => child.responseType === 'Evidence' || child.responseType === 'Evidence(H)' || child.responseType === 'Evidence(F,H)')?.children[0];
    }

    return (
        <div className='radio-list-parent'>
            {arrangedData.map((data, index1) => {
                const value = data.children.find((item: any) => selected[item.disclosure_detail_code]?.toString()?.split('.')[0] === item.value?.toString()?.split('.')[0])?.disclosure_detail_code;
                return (
                    <div key={index1} className='radio-question-container'>
                        {
                            !props.isFirstLevel &&
                            <p className='radio-list-question'>
                                {data?.topic}
                            </p>
                        }

                        <Radio.Group
                            className='radio-list-container'
                            onChange={(e) => handleAddData(e.target.value)}
                            value={value}
                        >
                            {
                                data.children?.map((item: any, index: number) => {
                                    const value = selected[item.disclosure_detail_code];
                                    const isSelected = value?.toString()?.split('.')[0] === item.value?.toString()?.split('.')[0];
                                    return (
                                        <div>
                                            <div
                                                key={index}
                                                className={`radio-list-option
                                                    ${item.children.length > 0 ?
                                                        isSelected ?
                                                            'selected' : '' : 'not-expandable'
                                                    } `}
                                            >
                                                <Radio value={item.disclosure_detail_code}>
                                                    <span className='radio-list-option-text'>
                                                        {item.accounting_metric}
                                                    </span>
                                                    {
                                                        containsEvidence(item.children) && isSelected &&
                                                        <span
                                                            onClick={() => {
                                                                setEvidenceData(getEvidenceData(item.children));
                                                                setOpen(true);
                                                            }}
                                                            className='radio-list-upload'
                                                        >
                                                            Upload evidence
                                                        </span>
                                                    }
                                                </Radio>
                                            </div>
                                            {
                                                isSelected && item.children.length > 0 &&
                                                <div className='radio-list-child-container'>
                                                    <GresbMain data={item.children} />
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </Radio.Group>
                    </div>
                )
            })}
            <UploadEvidenceDrawer
                open={open}
                setOpen={setOpen}
                evidenceData={evidenceData}
            />
        </div>
    );
}
