import React, { useEffect } from "react";
import { BEDrawer } from "../../../Components/BEDrawer";
import { BEButton } from "../../../Components/BEFormItems/BEButton";
import { Form, Select, Space } from "antd";
import { LableRequired } from "../../../Components/BEFormItems/LableRequired";
import { BEInput } from "../../../Components/BEFormItems/BEInput";
import { addUser, editUser, getAllUsers } from "../../Actions/UsersManagementActions";
import { useAppDispatch } from "../../../Config/Hooks/useAppDispatch";
import { useTypedSelector } from "../../../Config/Hooks/useTypedSelector";
import { BEEyeButton } from "../../../Components/BEEyeButton";
import BEMultipleSelect from "../../../Components/BEMultipleSelect";
import { UserDependents } from "../../../Config/Data/genralData";
import { roleMap } from "../../../Config/Data/UsersMap";
import { BEMessage } from "../../../Components/BEMessage";

interface BEAddUserProps {
  open: boolean;
  setOpen: Function;
  isEdit?: boolean;
}

const AddUser = (props: BEAddUserProps) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const addStatus = useTypedSelector((state) => state.userMgmt.addStatus);
  const currentUser = useTypedSelector((state) => state.common.selectedRow);
  const users = useTypedSelector((state) => state.userMgmt);
  // const [departmentHead, setDepartmentHead] = React.useState(false);

  const onFinish = async (values: any) => {
    const existingUserDepartments = users.data.filter((user) => user.role === "DEPARTMENT_HEAD").map((user) => user.department);
    if (values.role === "DEPARTMENT_HEAD" && existingUserDepartments.includes(values.department)) {
      if (props.isEdit) {
        if (currentUser.role === "DEPARTMENT_HEAD" && currentUser.department !== values.department) {
          BEMessage.error("Department head already exists for this department");
          return;
        }
      } else {
        BEMessage.error("Department head already exists for this department");
        return;
      }
    }
    let objToSend: any = values;
    objToSend["status"] = values.status === "Active" ? true : false;
    // objToSend["department_head"] = departmentHead;
    if (props.isEdit) {
      await dispatch(editUser(objToSend, currentUser.id));
    } else {
      // delete objToSend.is_active;
      await dispatch(addUser(objToSend));
    }
    form.resetFields();
    props.setOpen(false);
  };

  useEffect(() => {
    if (users.status === "idle")
      dispatch(getAllUsers());
  }, []);

  useEffect(() => {
    if (props.isEdit) {
      form.setFieldsValue({
        first_name: currentUser.first_name,
        last_name: currentUser.last_name,
        email: currentUser.email,
        role: currentUser.role,
        department: currentUser.department,
        status: currentUser.status ? "Active" : "Inactive",
      });
      // setDepartmentHead(currentUser?.department_head);
    }
  }, [currentUser]);
  return (
    <div>
      <BEDrawer
        open={props.open}
        setOpen={props.setOpen}
        heading={props.isEdit ? "Edit User" : "Add User"}
        width="400"
        footer={
          <div>
            <BEButton
              size="large"
              className="primary"
              onClick={() => {
                form.submit();
              }}
              loading={addStatus === "loading"}
            >
              {props.isEdit ? "+ Edit User" : "+ Add User"}
            </BEButton>
            <BEButton
              style={{ marginLeft: "0.5rem" }}
              size="large"
              onClick={() => {
                form.resetFields();
                props.setOpen(false);
              }}
            >
              Cancel
            </BEButton>
          </div>
        }
      >
        <Form form={form} onFinish={onFinish}>
          <LableRequired>First Name </LableRequired>
          <Form.Item
            name="first_name"
            rules={[{ required: true, message: "Please enter first name" }]}
          >
            <BEInput size="large" placeholder="First name of user" />
          </Form.Item>

          <LableRequired>Last Name </LableRequired>
          <Form.Item
            name="last_name"
            rules={[{ required: true, message: "Please enter last name" }]}
          >
            <BEInput size="large" placeholder="Last name of user" />
          </Form.Item>
          <LableRequired>Email </LableRequired>
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please enter email" }]}
          >
            <BEInput size="large" placeholder="User's email ID" />
          </Form.Item>

          {
            (currentUser.role !== 'SUPER_ADMIN' || !props.isEdit) &&
            <>
              <LableRequired>Role </LableRequired>
              <Form.Item
                name="role"
                rules={[{ required: true, message: "Please enter role" }]}
              >
                <Select size="large" placeholder="Role of the user">
                  {
                    ['ADMIN', 'CONTRIBUTOR', 'AUDITOR', 'DEPARTMENT_HEAD'].map((item) => {
                      return <Select.Option value={item}>
                        <Space>{roleMap[item]}</Space>
                      </Select.Option>
                    })
                  }
                </Select>
              </Form.Item>
            </>
          }
          
            <BEEyeButton
              title=""
              discription={'Refers to the Business Function the user belongs to in your organization.'}
            >
              <LableRequired>Department</LableRequired>
            </BEEyeButton>
          
          <BEMultipleSelect single isDataStrArray data={UserDependents} name="department" />

          {/* <Form.Item>
            <Checkbox name="department_head"
              checked={departmentHead}
              onChange={(e) => setDepartmentHead(e.target.checked)}
            >Is this the Department head</Checkbox>
          </Form.Item> */}

          {props.isEdit && (
            <>
              <LableRequired>Status </LableRequired>
              <Form.Item
                name="status"
                rules={[{ required: true, message: "Please enter status" }]}
              >
                <Select size="large" placeholder="Status of the user">
                  <Select.Option value="Active">
                    <Space>Active</Space>
                  </Select.Option>
                  <Select.Option value="Inactive">
                    <Space>Inactive</Space>
                  </Select.Option>
                </Select>
              </Form.Item>
            </>
          )}
        </Form>
      </BEDrawer>
    </div>
  );
};

export default AddUser;
