import * as React from 'react';
import { BETabs } from '../../../../Components/BETabs';
import { SIdeFilter } from '../SideFilter';
import { InnerTabsFilter } from '../InnerTabsFilter';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { setCurrentPillar} from '../../../../Redux/OnboardingReducer';
import MetricSelector from './MetricSelector';

interface pillerType {
  [key: string]: string;
}

export const PillerMap: pillerType = {
  'E': 'Environment',
  'S': 'Social',
  'G': 'Governance',
  'O': 'Operational metrics',
  'A': 'Annual metrics'              // adding a new pillar annual for annual metrics (set this on frontend)
}

export const pillarOrder = ['E', 'S', 'G', 'O', 'A']

export interface ITopTabsFilterProps {
}

function InnerTabs(props: any) {
  return (
    <div className='inner-tabs'>
      <SIdeFilter />
      <InnerTabsFilter />
    </div>
  )
}

export function TopTabsFilter(props: ITopTabsFilterProps) {
  const dispatch = useAppDispatch()
  const activeTab = useTypedSelector(state => state.onBoarding.metrics.currentPillar)
  const allMetrics = useTypedSelector(state => state.onBoarding.metrics.allMetrics.data);
  const setActiveTab = (key: string) => {
    dispatch(setCurrentPillar(key))
  }
  React.useEffect(() => {
    if (activeTab === '') {
      dispatch(setCurrentPillar('E'))
    }
  }, [])
  return (
    <div style={{ position: 'relative' }}>
      <BETabs
        destroyInactiveTabPane
        className='outer-tabs'
        style={{ marginTop: '1rem' }}
        size='large'
        type='card'
        activeKey={activeTab}
        tabBarGutter={20}
        tabBarStyle={{ marginLeft: 40, marginTop: '2rem' }}
        onChange={(key) => setActiveTab(key)}

        items={

          [...Array.from(new Set(allMetrics?.map((item: any) => item.pillars))).sort((a: any, b: any) => pillarOrder.indexOf(a) - pillarOrder.indexOf(b))
            .map((item: any, index) => {
              return {
                key: item,
                label: PillerMap[item],
                children: <div className='tab-content'><InnerTabs /></div>
              }
            }),
            // {
            //   key:'D',
            //   label:'Derived Metrics',
            //   children:<div className='tab-content'><DerivedMetrics /></div>
            // }
          ]
        }
      />
      <div
        style={{
          position: "absolute",
          right: "1rem",
          top: "0rem",
          display: "flex",
          alignItems: "end",
          gap: "1rem",
        }}
      >
        <MetricSelector />
      </div>
    </div>
  );
}
