import * as React from 'react';
import BEMultipleSelect from '../../../../../../../Components/BEMultipleSelect';
import { Frameworks } from '../../../../../../../data/frameworks';
import ArrowDown from '../../../../../../../Components/BEIcons/SwitchIcon';
import { PrimaryTheme } from '../../../../../../../Config/Theme/theames';
import { rotateArray } from '../../../../../../../Config/Functions/UsefullFunctions';
import { useAppDispatch } from '../../../../../../../Config/Hooks/useAppDispatch';
import { setSelectedFramework } from '../../../../../../../Redux/ReportingReducer';
import { BEMessage } from '../../../../../../../Components/BEMessage';
import { useTypedSelector } from '../../../../../../../Config/Hooks/useTypedSelector';
import { getTopicsForGRESB } from '../../../../../Actions/template';


export interface IAddTemplateStep1Props {
  setStep: Function;
  ref: any;
}

const AddTemplateStep1: React.ForwardRefRenderFunction<{ onButtonClick: () => void }, IAddTemplateStep1Props> = (props,ref) => {
  const [currentFramework, setCurrentFramework] = React.useState<any>(null);
  const selectedFramework = useTypedSelector(state => state.reporting.selectedFrameWork);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    setCurrentFramework(Frameworks[0]);
  }, []);

  React.useEffect(() => {
    dispatch(setSelectedFramework(currentFramework));
  }, [currentFramework]);
  
  // handle button click from templates to provoke the next step  *-
  const onButtonClick = () => {
    // if(selectedFramework?.code === 'ESRS'){
    //   BEMessage.error('This framework is under update. Please select another framework.');
    // }
    // else 
    if (selectedFramework?.code === 'GRESB') {
      dispatch(getTopicsForGRESB());
    }
    props.setStep(2);
  }
  React.useImperativeHandle(ref, () => ({ onButtonClick }), [selectedFramework]);

  return (
    <div className='add-template-step1'>
      <div style={{ width: '100%' }}>

        <div className='search-box'>
          <div className='search-box-input'>
            <BEMultipleSelect single valueFeild='code' data={Frameworks}
              onChange={(value: any) => setCurrentFramework(
                Frameworks.find((item: any) => item.code === value) || null
              )}
              labelFeild='title'
              placeholder='Search for framework'
            />
          </div>
        </div>
        <div className='framework-box'>
          <div className='framework-icon-box'>
            <img src={currentFramework?.img} alt='framework-icon' className='framework-icon' />
          </div>
          <div className='framework-info-box'>
            <div>
              <p className='framework-title'>{currentFramework?.fullName}</p>
              <div className='framework-description'>
                {
                  currentFramework?.description?.map((item: string, index: number) => {
                    return (
                      <p key={index}>• {item}</p>
                    );
                  })
                }
              </div>
            </div>
          </div>
        </div>

        
        <div className='framework-slider-box'>
            <div className='framework-slider'>
              <div className='arrow-left'
                onClick={() => setCurrentFramework(Frameworks[
                  Frameworks.indexOf(currentFramework) - 1] || Frameworks[Frameworks.length - 1]
                  )}
              ><ArrowDown stroke={PrimaryTheme.secondaryGray} inheritSize/></div>
              {
                //TOTAL 7 FRAMEWORKS on the slider
                //position cureentFramework on 4th position and slice the array to show 7 frameworks
  
                rotateArray(Frameworks, Frameworks.indexOf(currentFramework) - 3, true).slice(0, 7).map((item: any, index: number) => {
                  return (
                    <div key={index} className='framework-slider-item' onClick={() => setCurrentFramework(item)}>
                      <img src={item.img} alt='framework-icon' className='framework-slider-icon' />
                    </div>
                  );
                })
              }
              <div className='arrow-right'
                onClick={() => setCurrentFramework(Frameworks[
                  Frameworks.indexOf(currentFramework) + 1 === Frameworks.length ? 0 : Frameworks.indexOf(currentFramework) + 1
                ])}
              ><ArrowDown stroke={PrimaryTheme.secondaryGray} inheritSize/></div>
            </div>
          </div>


      </div>
    </div>
  );
}

export default React.forwardRef(AddTemplateStep1);
