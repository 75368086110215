import * as React from 'react';
import EditableTable, { EditableColumnTypes } from '../../../../../../../../../../Components/BETable/ExcelLikeTable';
import { BEButton } from '../../../../../../../../../../Components/BEFormItems/BEButton';
import { useTypedSelector } from '../../../../../../../../../../Config/Hooks/useTypedSelector';
import { TemplateSelectedTopicTypes } from '../../../../../../../../../../Redux/Types/reportingTypes';
import { useAppDispatch } from '../../../../../../../../../../Config/Hooks/useAppDispatch';
import { fillReportData } from '../../../../../../../../Actions/template';
import { BEMessage } from '../../../../../../../../../../Components/BEMessage';

export interface IType3TopicProps {
  uniqueCode: string;
  nonEditable?: boolean;
}

export function Type3Topic(props: IType3TopicProps) {
  const dispatch = useAppDispatch();
  const selectedTopics = useTypedSelector(state => state.reporting.selectedTemplateTopics);
  const currentReport = useTypedSelector(state => state.reporting.currentReport);
  const reportData = useTypedSelector(state => state.reporting.reportData.data);
  const [dataSource, setDataSource] = React.useState<any>([]);

  React.useEffect(() => {
    let allUniqueDataLable2 = Array.from(new Set(selectedTopics.data.filter((item1: TemplateSelectedTopicTypes) => props.uniqueCode === item1.unique_code && item1.data_label === 'nan'  ).map((item2: TemplateSelectedTopicTypes) => item2.data_label_2)));
    let allUniqueDataLable = Array.from(new Set(selectedTopics.data.filter((item1: TemplateSelectedTopicTypes) => props.uniqueCode === item1.unique_code  ).map((item2: TemplateSelectedTopicTypes) => item2.data_label)));
    let allUniqueAccountingMetric = Array.from(new Set(selectedTopics.data.filter((item1: TemplateSelectedTopicTypes) => props.uniqueCode === item1.unique_code  ).map((item2: TemplateSelectedTopicTypes) => item2.accounting_metric)))
      // .sort((a: string, b: string) => a.localeCompare(b));
    let tempData: any = [];
    allUniqueAccountingMetric.forEach((accountingMetric: string) => {
      let tempObj: any = { name: accountingMetric };
      allUniqueDataLable2.forEach((dataLabel2: string) => {
        let topicID = selectedTopics.data.filter((item1: TemplateSelectedTopicTypes) => props.uniqueCode === item1.unique_code  && item1.data_label_2 === dataLabel2 && item1.accounting_metric === accountingMetric)[0]?.id;
        if (topicID) {
          if (reportData[topicID]) tempObj[dataLabel2] = reportData[topicID][0]?.data;
          else tempObj[dataLabel2] = '';
        }
        // set value here
      });
      allUniqueDataLable.forEach((dataLabel: string) => {
        tempObj[dataLabel] = '';
        selectedTopics.data.filter((item1: TemplateSelectedTopicTypes) => props.uniqueCode === item1.unique_code   && item1.data_label === dataLabel).forEach((item2: TemplateSelectedTopicTypes) => {
          let topicID = selectedTopics.data.filter((item3: TemplateSelectedTopicTypes) => props.uniqueCode === item3.unique_code  && item3.data_label === dataLabel && item3.data_label_2 === item2.data_label_2 && item3.accounting_metric === accountingMetric)[0]?.id;
          if (topicID) {
            if (reportData[topicID]) tempObj[dataLabel + '#' + item2.data_label_2] = reportData[topicID][0]?.data;
            else tempObj[dataLabel + '#' + item2.data_label_2] = '';
          }
          else tempObj[dataLabel + '#' + item2.data_label_2] = 'N/A';
          // set value here
        })
      });
      tempData.push(tempObj);
    });
    setDataSource(tempData);
  }, [selectedTopics, reportData, props.uniqueCode]);

  const columns: EditableColumnTypes[] = [
    {
      title: '',
      dataIndex: 'name',
      width: '30%',
      render: (text: any) => <b>{text}</b>
    },
    ...Array.from(new Set(selectedTopics.data.filter((item1: TemplateSelectedTopicTypes) => props.uniqueCode === item1.unique_code   &&  item1.data_label !== 'nan').map((item2: TemplateSelectedTopicTypes) => item2.data_label)))
      // .sort((a: string, b: string) => a.localeCompare(b))
      .map((dataLable: string) => {
        return {
          title:<p className='table-title'> {dataLable}</p>,
          dataIndex: dataLable,
          rules: {
            isNumber: true,
            // isRequired: true
          },
          children: [
            ...Array.from(new Set(selectedTopics.data.filter((item1: TemplateSelectedTopicTypes) => props.uniqueCode === item1.unique_code   && item1.data_label === dataLable).map((item2: TemplateSelectedTopicTypes) => item2.data_label_2)))
              // .sort((a: string, b: string) => a.localeCompare(b))
              .map((dataLabel2: string) => {
                return {
                  title: (dataLabel2),
                  dataIndex: (dataLable + '#' + dataLabel2),
                  rules: {
                    isNumber: selectedTopics.data.filter((item4: TemplateSelectedTopicTypes) => props.uniqueCode === item4.unique_code  && item4.data_label_2 === dataLabel2 && item4.data_label === dataLable)[0!]?.unit !== 'Description' ? true : false,
                    // isRequired: true
                  },
                  editable: props.nonEditable ? false : true,
                }
              })
          ]
        }
      }),
    ...Array.from(new Set(selectedTopics.data.filter((item1: TemplateSelectedTopicTypes) => props.uniqueCode === item1.unique_code   && item1.data_label === 'nan').map((item2: TemplateSelectedTopicTypes) => item2.data_label_2))).map((dataLabel2: string) => {
      return {
        title: <p className='table-title'>{dataLabel2}</p>,
        dataIndex: dataLabel2,
        rules: {
          isNumber: selectedTopics.data.filter((item4: TemplateSelectedTopicTypes) => props.uniqueCode === item4.unique_code  && item4.data_label_2 === dataLabel2)[0!]?.unit !== 'Description' ? true : false,
          // isRequired: true
        },
        editable: true,
      }
    })
  ]

  const handleSave = (row: any, dataIndex: string) => {
    console.log(row, dataIndex);
    console.log(row);
    let data_label: string = '', data_label_2: string = '';
    if (dataIndex.includes('#')) {
      data_label = dataIndex.split('#')[0];
      data_label_2 = dataIndex.split('#')[1];
    }
    else data_label_2 = dataIndex;
    let topicID = selectedTopics.data.filter((item1: TemplateSelectedTopicTypes) =>
      props.uniqueCode === item1.unique_code   &&
      (item1.data_label === data_label || data_label === '') &&
      item1.data_label_2 === data_label_2 &&
      item1.accounting_metric === row.name
    )[0].id;
    if(!topicID) {
      BEMessage.error('This topic is not applicable for this template. Please contact admin.')
      return true;
    }
    dispatch(fillReportData({
      data: row[dataIndex],
      topic: topicID,
      esg_report: currentReport?.id,
    }))
    return true;
  }


  return (
    <div>
      <EditableTable
        data={dataSource}
        columns={columns}
        handleSave={handleSave}
      />
    </div>
  );
}
