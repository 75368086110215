import * as React from 'react';
import { NewBETable } from '../../../../../../../Components/BETable/newBETable';
import { ColumnsType } from 'antd/es/table';
import { useTypedSelector } from '../../../../../../../Config/Hooks/useTypedSelector';
import { getTopicsByFramework } from '../../../../../Actions/template';
import { useAppDispatch } from '../../../../../../../Config/Hooks/useAppDispatch';
import { Select } from 'antd';
import { Frameworks } from '../../../../../../../data/frameworks';
import { setSelectedIndustry, setSelectedTemplateTopics } from '../../../../../../../Redux/ReportingReducer';


export interface IEditTemplateMainSectionProps {
}

export function EditTemplateMainSection(props: IEditTemplateMainSectionProps) {
  const dispatch = useAppDispatch();
  const templateFrameworkTopics = useTypedSelector(state => state.reporting.templateFrameworkTopics);
  const selectedTemplateDimension = useTypedSelector(state => state.reporting.selectedTemplateDimension);
  const selectedTemplateSection = useTypedSelector(state => state.reporting.selectedTemplateSection);
  const selectedTemplate = useTypedSelector(state => state.reporting.currentTemplate);
  const [tableData, setTableData] = React.useState<any[]>([]);
  const selectedRows = useTypedSelector(state => state.reporting.selectedTemplateTopics);
  const [Industies, setIndustries] = React.useState<any[]>(['All', 'none']);
  const selectedIndustry = useTypedSelector(state => state.reporting.selectedIndustry);

  const columns: ColumnsType<any> = [
    {
      title: "Disc. code",
      dataIndex: "disclosure_code",
      render: (text: any, record: any) => {
        return <div>{
          record.disclosure_code === "nan" ? '-' : record.disclosure_code
        }</div>
      }
    },
    {
      title: "Topic",
      dataIndex: "topic",
      render: (text: any, record: any) => {
        return <div>{
          record.topic === "nan" ? '-' : record.topic
        }</div>
      }
    },
    {
      title: "Accounting metric",
      dataIndex: "accounting_metric",
      render: (text: any, record: any) => {
        return <div>{
          record.accounting_metric === "nan" ? '-' : record.accounting_metric
        }</div>
      }
    },
    {
      title: "Data Label",
      dataIndex: "data_label",
      render: (text: any, record: any) => {
        return <div>{
          record.data_label === "nan" ? '-' : record.data_label
        }</div>
      }
    },
    {
      title: "Data Label 2",
      dataIndex: "data_label_2",
      render: (text: any, record: any) => {
        return <div>{
          record.data_label_2 === "nan" ? '-' : record.data_label_2
        }</div>
      }
    },
    {
      title: "Unit",
      dataIndex: "unit",
      render: (text: any, record: any) => {
        return <div>{
          record.unit === "nan" ? '-' : record.unit
        }</div>
      }
    }
  ];

  React.useEffect(() => {
    if (templateFrameworkTopics.status === 'success') {
      let tempIndustries: any = ['All'];
      templateFrameworkTopics.data.forEach((item) => {
        if (item.hasOwnProperty('industry') && tempIndustries.includes(item?.industry) === false) {
          tempIndustries.push(item.industry);
        }
      });
      setIndustries(tempIndustries);
    }
  }, [templateFrameworkTopics]);

  const handleChangeIndustry = (value: any) => {
    dispatch(setSelectedIndustry(value));
  }

  React.useEffect(() => {
    const tempTableData = templateFrameworkTopics.data.filter((item) =>
    (
      item.section === selectedTemplateSection &&
      (item.dimension === selectedTemplateDimension || !selectedTemplateDimension) &&
      (selectedIndustry === 'All' || !item.hasOwnProperty('industry') || item?.industry === selectedIndustry)
    ));
    setTableData(tempTableData);
  }, [selectedTemplateDimension, selectedTemplateSection, selectedIndustry]);

  const setSelectedRows = (data: any) => {
    let dataToSet = templateFrameworkTopics.data.filter((item) => data.findIndex((item2: any) => item2 === item.id) !== -1);
    dispatch(setSelectedTemplateTopics({
      data: dataToSet,
      status: 'success'
    }));
    console.log(data)
  }

  return (

    <div style={{ padding: "1rem" }}>
      <NewBETable
        selectedRows={selectedRows.data.map((item) => item.id)}
        disabledRows={Frameworks.find((item) => item.code === selectedTemplate?.framework)?.allSelected ? selectedRows.data.map((item) => item.id) : undefined}
        setSelectedRows={setSelectedRows}
        search
        searchOnColumns={{
          Topic: 'topic',
          Accounting_Metric: 'accounting_metric',
          Data_Label: 'data_label',
          Unit: 'unit',
        }}
        data={tableData}
        columns={columns}
        pagination
        TableTopRightComponents={<>
          <div style={{ fontWeight: "600", fontSize: "1rem" }}>Industry</div>
          <Select
            style={{ width: "150px" }}
            value={selectedIndustry}
            onChange={handleChangeIndustry}
            showSearch
          >
            {Industies.map((item) => {
              return <Select.Option value={item}>{item}</Select.Option>
            })}
          </Select>
        </>}
      />
    </div>
  );
}
