import { handleAPICall } from "../../Config/Functions/HandleAPICall";
import { navigateTo } from "../../Navigation/NavigationManager";
import { AppDispatch } from "../../Redux/store";
import { BEMessage } from "../../Components/BEMessage";
import { setUsers, setAddUser } from "../../Redux/UserManagementReducer";
import {
  ADD_USER,
  DELETE_USER,
  EDIT_USER,
  GET_ALL_USERS,
  USERS_BULK_UPLOAD
} from "../../Utils/Routes/UserManagementRoutes";
import { GET_USER_PROFILE, PATCH_USER_PROFILE } from "../../Utils/Routes/UserRouts";
import { setUserProfile } from "../../Redux/UserReducer";

export const bulkUploadUsers = (body: any) => async (dispatch: AppDispatch) => {
  const [data, error] = await handleAPICall(USERS_BULK_UPLOAD(body));

  if (data) {
    console.log(data);
    dispatch(getAllUsers());
    return data;
  } else {
    console.log(error);
    return null;
  }
};

export const getAllUsers = () => async (dispatch: AppDispatch) => {
  dispatch(setUsers({ status: "loading", data: [] }));

  const [data, error] = await handleAPICall(GET_ALL_USERS());

  if (data) {
      console.log(data);
      dispatch(setUsers({
          status: 'success',
          data: data.users
      }))
  } else {
      console.log(error);
      dispatch(
        setUsers({
          status: "error",
          data: [],
        })
      );
  }
};

export const addUser = (body: any) => async (dispatch: AppDispatch) => {
  dispatch(setAddUser({
    addStatus: 'loading'
  }))
  const [data, error] = await handleAPICall(ADD_USER(body));

  if (data) {
    BEMessage.success('User Added Successfully')
    dispatch(setAddUser({
      addStatus: 'success'
    }))
    dispatch(getAllUsers());
  } else {
    BEMessage.error(error?.error);
    dispatch(
      setAddUser({
        addStatus: "error",
      })
    );
  }
};

export const editUser = (body: any,id:number) => async (dispatch: AppDispatch) => {
  dispatch(setAddUser({
    addStatus: 'loading'
  }))
  const [data, error] = await handleAPICall(EDIT_USER(body, id));
  if (data) {
    BEMessage.success('User Edited Successfully')
    dispatch(setAddUser({
      addStatus: 'success'
    }))
    dispatch(getAllUsers());
    console.log(data);
  } else {
    BEMessage.error(error?.error);
    dispatch(
      setAddUser({
        addStatus: "error",
      })
    );
    console.log(error);
  }
};


export const deleteUser = (id: number) => async (dispatch: AppDispatch) => { 
  const [data, error] = await handleAPICall(DELETE_USER(id));

  if (data) {
    BEMessage.success("User Deleted Successfully");
    dispatch(getAllUsers());
    console.log(data);
  } else {
    BEMessage.error(error.response.data.error);
    console.log(error);
  }
}

export const getUserProfile = () => async (dispatch: AppDispatch) => {
  dispatch(setUserProfile({
    status: "loading",
    data: null
  }));
  const [data, error] = await handleAPICall(GET_USER_PROFILE());
  if (data) {
    dispatch(setUserProfile({
      status: "success",
      data: data.profile
    }));
    return data.profile;
  } else {
    console.log(error);
    dispatch(setUserProfile({
      status: "error",
      data: null
    }));
    return null;
  }
}

export const patchUserProfile = (data: any, isFormData: boolean) => async (dispatch: AppDispatch) => {
  const [dataRes, error] = await handleAPICall(PATCH_USER_PROFILE(data, isFormData));
  if (dataRes) {
    dispatch(getUserProfile());
    BEMessage.success("User profile updated successfully.");
  } else {
    console.log(error);
    BEMessage.error("Failed to update user profile.");
  }
}