import * as React from "react";
import { Modal, message } from "antd";
import "./style.scss";
import ErrorIcon from "../BEIcons/ErrorIcon";
import InfoFilledIcon from "../BEIcons/InfoFilledIcon";
import WarningIcon from "../BEIcons/WarningIcon";
import CheckFilledIcon from "../BEIcons/CheckFilledIcon";
import { PrimaryTheme } from "../../Config/Theme/theames";
import { BEButton } from "../BEFormItems/BEButton";

//***//loading to be implimented properly any way */

interface CustomNotification {
  success: (description: string) => void;
  error: (description: string) => void;
  info: (description: string) => void;
  warning: (description: string) => void;
  delete: (
    description: string,
    hint: string,
    onConfirmation: Function,
    loading: boolean,
    key: number | string
  ) => void;
  destroy: (key:string|number) => void;
}

const defaultDuration = 2;

export const BEMessage: CustomNotification = {
  success: (description: string) => {
    message.open({
      content: (
        <div className="success-message">
          <div style={{ height: "1rem" }}>
            <CheckFilledIcon inheritSize fill="white" stroke="green" />
          </div>
          {description}
        </div>
      ),
      duration: defaultDuration,
    });
  },
  error: (description: string) => {
    message.open({
      content: (
        <div className="error-message">
          <div style={{ height: "1rem" }}>
            <ErrorIcon inheritSize fill="white" stroke="red" />
          </div>
          {description}
        </div>
      ),
      duration: defaultDuration,
    });
  },
  info: (description: string) => {
    message.open({
      content: (
        <div className="info-message">
          <div style={{ height: "1rem" }}>
            <InfoFilledIcon inheritSize />
          </div>
          {description}
        </div>
      ),
      duration: defaultDuration,
    });
  },
  warning: (description: string) => {
    message.open({
      content: (
        <div className="warning-message ">
          <div style={{ height: "1rem" }}>
            <WarningIcon inheritSize fill="white" />
          </div>
          {description}
        </div>
      ),
      duration: defaultDuration,
    });
  },
  delete: (
    description: string,
    hint: string,
    onConfirmation,
    loading: boolean,
    key: number | string
  ) => {
    message.open({
      content: (
        <div className="delete-confimation">
          <div style={{ height: "2rem" }}>
            <WarningIcon inheritSize fill="red" />
          </div>
          <div style={{ fontSize: "18px" }}>{description}</div>
          <div style={{ color: PrimaryTheme.primaryGray }}>{hint}</div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              margin: "0.6rem 0",
            }}
          >
            <BEButton onClick={() => message.destroy(key)}>
              No, Go Back
            </BEButton>
            <BEButton
              loading={loading}
              style={{
                color: "white",
                backgroundColor: PrimaryTheme.primaryRedError,
              }}
              onClick={(e) => {
                onConfirmation().then(() => {
                  message.destroy(key);
                });
              }}
            >
              Confirm
            </BEButton>
          </div>
        </div>
      ),
      duration: 0,
      style: { marginBottom: "20vh" },
      key: key,
    });
  },
  destroy: (key:string|number) => {
    message.destroy(key);
  },
};