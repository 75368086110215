import React from 'react'
import IconProps from './Interface/IconProps'

const MenuIcon = (props: IconProps) => {
    return (
        <svg width="3" height="11" viewBox="0 0 3 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="1.22222" cy="1.22222" r="1.22222" fill="#525252" />
            <circle cx="1.22222" cy="5.50005" r="1.22222" fill="#525252" />
            <circle cx="1.22222" cy="9.77789" r="1.22222" fill="#525252" />
        </svg>
    );
}

export default MenuIcon