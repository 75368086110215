import API from "../../API";

export const GET_ALL_BUSINESS_GROUPS = async () => {
    return await API.GET("/enterprise/business-groups/");
}

export const POST_BUSINESS_GROUP = async (data:any) => {
    return await API.POST("/enterprise/business-groups/", data);
}

export const GET_BUSINESS_UNITS_BY_BG = async (id:any) => {
    return await API.GET(`/enterprise/business-units/${id}/`);
}

export const GET_ALL_BUSINESS_UNITS = async () => {
    return await API.GET("/enterprise/business-units/");
}

export const POST_BUSINESS_UNIT = async (data:any) => {
    return await API.POST(`/enterprise/business-units/`, data);
}

export const UPDATE_BUSINESS_UNIT_OWNER = async (data: any) => {
    return await API.PATCH(`/enterprise/business-units/${data.id}/`, data);
}

export const GET_TEMPLATES_OF_BUSINESS_UNIT = async (id: number) => {
    return await API.GET(`/enterprise/assign-template-to-business-unit/${id}/`);
}

export const GET_BUSINESS_UNIT_DETAILS = async (id: number) => {
    return await API.GET(`/enterprise/business-units/${id}/`);
}

export const EDIT_BUSINESS_GROUP = async (id:any,data: any) => {
    return await API.PATCH(`/enterprise/business-groups/${id}/`, data);
}

export const DELETE_BUSINESS_GROUP = async (id: number) => {
    return await API.DELETE(`/enterprise/business-groups/${id}/`);
}

export const DELETE_BUSINESS_UNIT = async (id: number) => {
    return await API.DELETE(`/enterprise/business-units/${id}/`);
}

export const EDIT_BUSINESS_UNIT = async (id:any,data: any) => {
    return await API.PATCH(`/enterprise/business-units/${id}/`, data);
}

export const POST_BUSINESS_UNIT_CONTRIBUTOR  = async (data: any) => {
    return await API.POST(`/enterprise/business-unit-contributors/`, data);
}

export const GET_BUSINESS_UNIT_CONTRIBUTORS = async (id: number) => {
    return await API.GET(`/enterprise/business-unit-contributors/${id}/`);
}

export const DELETE_BUSINESS_UNIT_CONTRIBUTOR = async (id: number) => {
    return await API.DELETE(`/enterprise/business-unit-contributors/${id}/`);
}

export const GET_BUSINESS_UNIT_REFS = async () => {
    return await API.GET(`/enterprise/business-group-units/`);
}

export const DELETE_BUSINESS_UNIT_REF = async (id: number) => {
    return await API.DELETE(`/enterprise/business-group-units/${id}/`);
}

export const POST_BUSINESS_UNIT_REF = async (data: any) => {
    return await API.POST(`/enterprise/business-group-units/`, data);
}

export const GET_RECENTLY_VIEWED_BUSINESS_GROUPS = async () => {
    return await API.GET(`/enterprise/recently-viewed-business-groups/`);
}

export const POST_RECENTLY_VIEWED_BUSINESS_GROUPS = async (data: any) => {
    return await API.POST(`/enterprise/recently-viewed-business-groups/`, data);
}

export const DELETE_RECENTLY_VIEWED_BUSINESS_GROUPS = async (id: number) => {
    return await API.DELETE(`/enterprise/recently-viewed-business-groups/${id}/`);
}

export const PATCH_RECENTLY_VIEWED_BUSINESS_GROUPS = async (id: number, data: any) => {
    return await API.PATCH(`/enterprise/recently-viewed-business-groups/${id}/`, data);
}

export const GET_RECENTLY_VIEWED_BUSINESS_UNITS = async () => {
    return await API.GET(`/enterprise/recently-viewed-business-units/`);
}

export const POST_RECENTLY_VIEWED_BUSINESS_UNITS = async (data: any) => {
    return await API.POST(`/enterprise/recently-viewed-business-units/`, data);
}

export const DELETE_RECENTLY_VIEWED_BUSINESS_UNITS = async (id: number) => {
    return await API.DELETE(`/enterprise/recently-viewed-business-units/${id}/`);
}

export const PATCH_RECENTLY_VIEWED_BUSINESS_UNITS = async (id: number, data: any) => {
    return await API.PATCH(`/enterprise/recently-viewed-business-units/${id}/`, data);
}
