import * as React from 'react';
import './style.scss';
import { BEDrawer } from '../../../../Components/BEDrawer';
import { BEButton } from '../../../../Components/BEFormItems/BEButton';
import { Step4 } from '../../../../views/Onboarding/Step4';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { Select } from 'antd';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { setMetricCurrentYear } from '../../../../Redux/OnboardingReducer';
import { showFYFormatByYear } from '../../../../Config/Functions/UsefullFunctions';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { Addyear } from '../AddYear';
import { BEMessage } from '../../../../Components/BEMessage';

interface IEsgMetricsConfigurationsProps {
  open: boolean;
  setOpen: Function;
}

export function EsgMetricsConfigurations(props: IEsgMetricsConfigurationsProps) {
  const dispatch = useAppDispatch();
  const recentYears = useTypedSelector(state => state.common.financialYears.data);
  const yearEnd = useTypedSelector(state => state.companies.companyDetails.data?.reporting_year_end);
  const metricsCurrentYear = useTypedSelector(state => state.onBoarding.metrics.currentYear);
  const [openAddYear, setOpenAddYear] = React.useState(false);
  const permissions = useTypedSelector(state => state.user.permissions);
  const [hasPermission, setHasPermission] = React.useState(true);
  const role = localStorage.getItem('role');
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const permission = permissions.data.find((permission) => permission.section === 9 && permission.role === role)?.permission;
    if (permission) setHasPermission(true);
    else setHasPermission(false);
  }, [permissions]);

  React.useEffect(() => {
    if (props.open) {
      if (hasPermission) setOpen(true);
      else {
        setOpen(false);
        BEMessage.error('You do not have permission to access this section');
        props.setOpen(false);
      }
    } else {
      setOpen(false);
    }
  }, [hasPermission, props.open]);

  return (
    <BEDrawer
      padding={0}
      width='100%'
      heading='Select and add the ESG Metrics that your company wants to report'
      open={open}
      setOpen={props.setOpen}
      footer={
        <BEButton onClick={() => props.setOpen(false)} className='primary' size='large'>
          Save & Close
        </BEButton>
      }
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', padding: '1rem 1rem 0 0', backgroundColor: 'white' }}>
        <p style={{ marginLeft: 'auto', fontSize: '1rem' }}><strong>For:</strong></p>
        {/* <YearSelectOptions onChange={(value: any) => dispatch(setMetricCurrentYear(value))} value={metricsCurrentYear} /> */}
        <Select
          style={{ width: 150, margin: 0 }}
          value={metricsCurrentYear}
          size="large"
          onChange={(value) => {
            if (value === 0)
              setOpenAddYear(true)
            else
              dispatch(setMetricCurrentYear(value))
          }}
        >
          {
            recentYears.map((year) => (
              <Select.Option value={year}>{
                showFYFormatByYear(year, yearEnd || '')
              }</Select.Option>
            ))
          }
          <Select.Option value={0}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '1rem', borderTop: `1px solid ${PrimaryTheme.primaryGreenLight}` }}>
              <BEButton> Add Year +</BEButton>
            </div>
          </Select.Option>
        </Select>
      </div>
      <Step4 isNotOnboarding />
      <Addyear open={openAddYear} setOpen={setOpenAddYear} />
    </BEDrawer>
  );
}
