import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { Form, Select } from 'antd';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { LableRequired } from '../../../../../Components/BEFormItems/LableRequired';
import { BEInput } from '../../../../../Components/BEFormItems/BEInput';
import GooglePlaces from '../../../../../Components/GooglePlaces';
import { BETextArea } from '../../../../../Components/BEFormItems/BETextArea';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { addGresbAssetData } from '../../../Actions/gresbActions';

export interface IAddAssetProps {
    open: boolean;
    setOpen: Function;
}

export function AddAsset(props: IAddAssetProps) {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = React.useState(false);
    const currentReport = useTypedSelector(state => state.reporting.currentReport);
    const gresbSlugData = useTypedSelector(state => state.reporting.gresbSlugData.data);


    const onFinish = async (values: any) => {
        setLoading(true);
        const data = {
            ...values,
            report: currentReport?.id,
            business_unit: null,
            province: values.state,
            additional_information: values.additional_information ? values.additional_information : null
        }
        await dispatch(addGresbAssetData(data));
        setLoading(false);
        props.setOpen(false);
    }

    return (
        <BEDrawer
            width='25%'
            heading='Add new asset'
            open={props.open}
            setOpen={props.setOpen}
            footer={
                <div>
                    <BEButton size='large' className='primary' loading={loading}
                        onClick={() => {
                            form.submit();
                        }}
                    >
                        Save
                    </BEButton>
                    <BEButton
                        style={{ marginLeft: '0.5rem' }} size='large' onClick={() => { props.setOpen(false) }}>
                        Cancel
                    </BEButton>

                </div>
            }
        >
            <Form form={form} onFinish={onFinish} >
                <LableRequired>Asset Name</LableRequired>
                <Form.Item name='name' rules={[{ required: true, message: 'Please enter asset name' }]}>
                    <BEInput placeholder='Enter asset name' />
                </Form.Item>

                <LableRequired>Construction year</LableRequired>
                <Form.Item
                    name='construction_year'
                    rules={[{
                        required: true,
                        message: 'Please enter valid contruction year',
                        max: 4,
                        min: 4
                    }]}>
                    <BEInput placeholder='Enter contruction year' />
                </Form.Item>

                <LableRequired>Asset ownership {'(%)'}</LableRequired>
                <Form.Item name='ownership_percentage' rules={[{ required: true, message: 'Please enter asset ownership percent' }]}>
                    <BEInput
                        type='number'
                        placeholder='Enter asset ownership percent'
                    />
                </Form.Item>

                <LableRequired>Asset size m<sup>2</sup></LableRequired>
                <Form.Item name='size' rules={[{ required: true, message: 'Please enter asset size' }]}>
                    <BEInput
                        type='number'
                        placeholder='Enter asset size'
                    />
                </Form.Item>

                <LableRequired>Country</LableRequired>
                <Form.Item name='country' rules={[{ required: true, message: 'Please select country ' }]}>
                    <Select
                        onChange={(value) => {
                            form.setFieldValue('country', value);
                        }}
                    >
                        {gresbSlugData['country'!]?.map((option: any, index: any) => (
                            <Select.Option key={index} value={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <LableRequired>City</LableRequired>
                <Form.Item name='city' rules={[{ required: true, message: 'Please enter city' }]}>
                    <BEInput placeholder='Enter city' />
                </Form.Item>

                <LableRequired>State / Province</LableRequired>
                <Form.Item name='state' rules={[{ required: true, message: 'Please enter state/province' }]}>
                    <BEInput placeholder='Enter state/province' />
                </Form.Item>

                <LableRequired>Address</LableRequired>
                <Form.Item name='address' rules={[{ required: true, message: 'Please enter address' }]}>
                    <GooglePlaces form={form} />
                </Form.Item>

                <LableRequired>Gross asset value</LableRequired>
                <Form.Item name='value' rules={[{ required: true, message: 'Please enter gross  asset value' }]}>
                    <BEInput
                        // prefix='₹'
                        type='number'
                        placeholder='Enter asset gross value'
                    />
                </Form.Item>

                <LableRequired>Property type</LableRequired>
                <Form.Item name='property_type' rules={[{ required: true, message: 'Please enter property type' }]}>
                    <Select
                        onChange={(value) => {
                            form.setFieldValue('property_type', value);
                        }}
                    >
                        {gresbSlugData['equity_asset_type'!]?.map((option: any, index: any) => (
                            <Select.Option key={index} value={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>


                <p>Additional information</p>
                <Form.Item name='additional_information'>
                    <BETextArea
                        name='additional_information'
                        placeholder='Enter additional information'
                        form={form}
                    />
                </Form.Item>
            </Form>

        </BEDrawer>
    );
}
