import { setSelectedTemplateDimension, setSelectedTemplateSection } from "../../../Redux/ReportingReducer";
import { AppDispatch } from "../../../Redux/store";

const getUniqueSections = (data: any[]) => {
    const set = new Set<any>();
    data.forEach((item) => {
        set.add(item.section);
    });
    return set;
};

const getUniqueDimensions = (data: any) => {
    const set = new Set<any>();
    data.forEach((item: any) => {
        set.add(item.dimension);
    });
    return set;
};

const filterDataBySection = (section: string, data: any[]) => {
    const filteredData = data.filter((item) => item.section === section);
    return filteredData;
}

const filterDataByDimension = (dimension: string, data: any[]) => {
    const filteredData = data.filter((item) => item.dimension === dimension);
    return filteredData;
}


export const filterDataForLeftSection = (dataToFilter: any[]) => async (dispatch: AppDispatch) => {
    const set: Set<any> = getUniqueSections(dataToFilter);
    let data = [] as any[];
    set.forEach((section) => {
        const sectionedData = filterDataBySection(section, dataToFilter);
        const uniqueDimensions = getUniqueDimensions(sectionedData);
        let subsectionArray = [] as any[];
        uniqueDimensions.forEach((dimension) => {
            const dimensionedData = filterDataByDimension(dimension, dataToFilter);
            const subsectionItem = {name: dimension, data: dimensionedData,
                onClick: () => {
                    dispatch(setSelectedTemplateSection(section));
                    dispatch(setSelectedTemplateDimension(dimension));
                }
            };
            if(subsectionItem.name !== 'nan')
            subsectionArray.push(subsectionItem);
        });
        let item:any = {section: section,
            onClick: () => {
                dispatch(setSelectedTemplateSection(section));
                dispatch(setSelectedTemplateDimension(null));
            }
        };
        if(subsectionArray.length >0)  item.subSections = subsectionArray;
        // else{
        //     item.subSections = sectionedData;
        // }
        data.push(item);
    })
    return data;
}