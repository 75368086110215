import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import FileUpload from '../../../../../Components/BEFileUpload/FileUpload';
import { Form } from 'antd';
import { BEInput } from '../../../../../Components/BEFormItems/BEInput';
import { LableRequired } from '../../../../../Components/BEFormItems/LableRequired';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';
import { BEMessage } from '../../../../../Components/BEMessage';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { postReportingEvidence } from '../../../Actions/template';

export interface IAddReportingEvidenceDrawerProps {
  open: boolean;
  setOpen: Function;
  currentUniqueCode: string;
  isGresb?: boolean;
}

export function AddReportingEvidenceDrawer(props: IAddReportingEvidenceDrawerProps) {
  const dispatch = useAppDispatch();
  const [file, setFile] = React.useState<any>(null);
  const currentReport = useTypedSelector(state => state.reporting.currentReport);
  const [loading, setLoading] = React.useState<boolean>(false)
  const [form] = Form.useForm();

  React.useEffect(() => {
    form.resetFields();
  }, [props.open])

  const onFinish = async (values: any) => {
    if (!file) {
      BEMessage.error('Please upload evidence');
      return;
    }
    setLoading(true);
    await dispatch(postReportingEvidence({
      title: values.title,
      evidence: file,
      unique_code: props.currentUniqueCode,
      esg_report: currentReport?.id
    }))
    setLoading(false);
    props.setOpen(false);
  };
  return (
    <div>
      <BEDrawer
        width='fit-content'
        open={props.open}
        setOpen={props.setOpen}
        heading='Add Reporting Evidence'
        footer={
          <>
            <BEButton size='large' className='primary' loading={loading}
              onClick={() => {
                form.submit();
              }}>Save</BEButton>
            <BEButton size='large' onClick={() => props.setOpen(false)}>Cancel</BEButton>
          </>
        }
      >
        <Form form={form} onFinish={onFinish}>
          <LableRequired >
            {props.isGresb ? "Additional notes" : "Name"}
          </LableRequired>
          <Form.Item name='title' rules={[{ required: true, message: 'Please enter title' }]}>
            <BEInput
              placeholder={props.isGresb ? "Additional notes" : "Name"}
            />
          </Form.Item>
        </Form>
        <p style={{ marginBottom: '0.5rem', fontSize: '1rem' }}>
          <LableRequired style={{ marginBottom: '0.5rem' }}>
            Upload evidence
          </LableRequired>
        </p>
        <FileUpload
          handleFileChange={(file: any) => setFile(file)} refreshUpload={props.open}
          fileType='any'
          hint='File can be in any format up to 5MB in size'
          fileSize={5000000}
        />
      </BEDrawer>
    </div>
  );
}
