
import React from 'react'
import IconProps from './Interface/IconProps';

const SwitchIcon = (props:IconProps) => {
  return (
    <svg width={props.inheritSize ? "100%" : 10}
    height={props.inheritSize ? "100%" : 10} viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.957539 1.21075C0.567539 1.60075 0.567539 2.23075 0.957539 2.62075L4.83754 6.50075L0.957539 10.3808C0.567539 10.7708 0.567539 11.4008 0.957539 11.7908C1.34754 12.1808 1.97754 12.1808 2.36754 11.7908L6.95754 7.20075C7.34754 6.81075 7.34754 6.18075 6.95754 5.79075L2.36754 1.20075C1.98754 0.820754 1.34754 0.820754 0.957539 1.21075Z"
     fill={props.stroke ? props.stroke : "white"}/>
    </svg>
    
  );
}

export default SwitchIcon