import * as React from 'react';
import './style.scss';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { getAllRespondantsForAssessment, getMaterialitySurveyAnswers, getMaterialitySurveyTopics } from '../../Actions/matirialityActions';
import { ResultsPriorityTable } from './ResultPriorityTable';
import CustomGraph from './CustomGraph';
import BEBackButton from '../../../../Components/BEBackButton';
import { Col, Row } from 'antd';
import TrackerCard from '../../../../Components/Cards/TrackerCard';
import RespondantsIcon from '../../../../Components/BEIcons/RespondantsIcon';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import { useParams } from 'react-router-dom';

export interface IMatirialityViewResultsProps {
}

export function MatirialityViewResults(props: IMatirialityViewResultsProps) {
  const dispatch = useAppDispatch();
  const surveyAnswers = useTypedSelector(state => state.matiriality.surveyAnswers.data);
  const surveyTopics = useTypedSelector(state => state.matiriality.surveyTopics.data);
  const surveyAnswersStatus = useTypedSelector(state => state.matiriality.surveyAnswers.status);
  const surveyTopicsStatus = useTypedSelector(state => state.matiriality.surveyTopics.status);
  const respondantsToAssessment = useTypedSelector((state) => state.matiriality.respondentsForAssessment);
  const [tableData, setTableData] = React.useState<any[]>([]);
  const AllSurveys = useTypedSelector(state => state.matiriality.AllSurveys.data);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [pending, setPending] = React.useState(0)
  const { assessment_id } = useParams();

  React.useEffect(() => {
    if (surveyAnswers.length > 0 && surveyTopics.length > 0) {
      try {
        let BusinessPriorityArrey: any[] = [];
        let stakeholdersPriorityArrey: any[] = [];
        let topicsMap: any = {};
        let allTopicsWeight: any = {};
        let allTopicsBusinessWeight: any = {};

        surveyTopics.forEach((element: any, index: number) => {
          topicsMap[element.id] = element["topic"];
          allTopicsWeight[element.id] = 0;
          allTopicsBusinessWeight[element.id] = surveyTopics.length - index + 1;
        });
        console.log('all', surveyTopics);
        for (let element of surveyAnswers) {
          if (element.category !== "Executive Committee" && element.category !== "Board of Directors") {
            allTopicsWeight[element.topic] =
              allTopicsWeight[element.topic] +
              surveyTopics.length -
              element.priority +
              1;
          }
          else {
            allTopicsBusinessWeight[element.topic] =
              allTopicsBusinessWeight[element.topic] +
              surveyTopics.length -
              element.priority +
              1;
          }
        }

        let weightedTopicsBusiness: Array<{ topic: string; weight: any }> = [];
        for (const [key, value] of Object.entries(allTopicsBusinessWeight)) {
          weightedTopicsBusiness.push({ topic: key, weight: value });
        }
        weightedTopicsBusiness.sort((a, b) => (a.weight < b.weight ? 1 : -1));
        for (let element of weightedTopicsBusiness) {
          BusinessPriorityArrey.push(element.topic);
        }

        let weightedTopics: Array<{ topic: string; weight: any }> = [];
        for (const [key, value] of Object.entries(allTopicsWeight)) {
          weightedTopics.push({ topic: key, weight: value });
        }
        weightedTopics.sort((a, b) => (a.weight < b.weight ? 1 : -1));
        for (let element of weightedTopics) {
          stakeholdersPriorityArrey.push(element.topic);
        }

        let resultData: any = [];
        // console.log('stake',stakeholdersPriorityArrey);
        // console.log('busi',BusinessPriorityArrey);

        for (let i = 0; i < stakeholdersPriorityArrey.length; i++) {
          let sid = Number(stakeholdersPriorityArrey[i]);
          let obj: any = {
            stakeholder_priority: BusinessPriorityArrey.length === 0 ? 0 : i + 1,
            business_priority: stakeholdersPriorityArrey.length === 0 ? 0 : BusinessPriorityArrey.indexOf(String(sid)) + 1,
            topic_id: sid,
            topic_name: topicsMap[sid],
          }
          resultData.push(obj);
        }

        console.log('res1', resultData);
        setTableData(resultData);
        setLoading(false);
      } catch (error) { }
    }
  }, [surveyAnswersStatus, surveyTopicsStatus]);

  React.useEffect(() => {
    dispatch(getMaterialitySurveyAnswers(Number(assessment_id)));
    dispatch(getMaterialitySurveyTopics(Number(assessment_id)));
  }, []);

  React.useEffect(() => {
    if (respondantsToAssessment.status === 'success') {
      const arr = respondantsToAssessment.data.filter((ite) => {
        return !ite.status
      })
      setPending(arr.length)
    }
    else if (respondantsToAssessment.status === 'idle') dispatch(getAllRespondantsForAssessment(Number(assessment_id)));
  }, [respondantsToAssessment])

  return (
    <div className="matiriality-view-results">
      <Row>
        <Col span={20}>
          <BEBackButton title={"Viewing Result for assessment " +
            AllSurveys.filter((item: any) => item?.id === Number(assessment_id))[0]?.title
          } />
          <CustomGraph TableData={tableData} />
        </Col>
        <Col span={4}>
          <TrackerCard
            title="RESPONSES"
            body={`${Math.floor(
              ((respondantsToAssessment.data.length - pending) / respondantsToAssessment.data.length) *
              100
            )}%`}
            loading={respondantsToAssessment.status === 'loading'}
            icon={
              <RespondantsIcon
                inheritSize
                stroke={PrimaryTheme.secondaryGray}
              />
            }
          />
        </Col>
      </Row>
      <ResultsPriorityTable
        topics={surveyTopics}
        answers={surveyAnswers}
        data={tableData}
        loading={loading}
      />
    </div>
  );
}
