import React from 'react'
import IconProps from '../Interface/IconProps';

const TAndEIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 18}
      height={props.inheritSize ? "100%" : 18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.33268 3.99935H0.666016V15.666C0.666016 16.5827 1.41602 17.3327 2.33268 17.3327H13.9993V15.666H2.33268V3.99935ZM15.666 0.666016H5.66602C4.74935 0.666016 3.99935 1.41602 3.99935 2.33268V12.3327C3.99935 13.2493 4.74935 13.9993 5.66602 13.9993H15.666C16.5827 13.9993 17.3327 13.2493 17.3327 12.3327V2.33268C17.3327 1.41602 16.5827 0.666016 15.666 0.666016ZM15.666 12.3327H5.66602V2.33268H15.666V12.3327ZM7.33268 6.49935H13.9993V8.16602H7.33268V6.49935ZM7.33268 8.99935H10.666V10.666H7.33268V8.99935ZM7.33268 3.99935H13.9993V5.66602H7.33268V3.99935Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
    </svg>
  );
}

export default TAndEIcon;