import React from 'react'
import IconProps from '../Interface/IconProps';

const DEOIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 20}
      height={props.inheritSize ? "100%" : 17}
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33333 12.2507C4.25381 12.2507 5 11.5045 5 10.584C5 9.66351 4.25381 8.91732 3.33333 8.91732C2.41286 8.91732 1.66667 9.66351 1.66667 10.584C1.66667 11.5045 2.41286 12.2507 3.33333 12.2507Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
      <path
        d="M1.01667 13.5673C0.4 13.834 0 14.434 0 15.109V16.4173H3.75V15.0757C3.75 14.384 3.94167 13.734 4.275 13.1673C3.96667 13.1173 3.65833 13.084 3.33333 13.084C2.50833 13.084 1.725 13.259 1.01667 13.5673Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
      <path
        d="M16.6667 12.2507C17.5871 12.2507 18.3333 11.5045 18.3333 10.584C18.3333 9.66351 17.5871 8.91732 16.6667 8.91732C15.7462 8.91732 15 9.66351 15 10.584C15 11.5045 15.7462 12.2507 16.6667 12.2507Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
      <path
        d="M18.9833 13.5673C18.275 13.259 17.4917 13.084 16.6667 13.084C16.3417 13.084 16.0333 13.1173 15.725 13.1673C16.0583 13.734 16.25 14.384 16.25 15.0757V16.4173H20V15.109C20 14.434 19.6 13.834 18.9833 13.5673Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
      <path
        d="M13.5333 12.7923C12.5583 12.359 11.3583 12.0423 10 12.0423C8.64167 12.0423 7.44167 12.3673 6.46667 12.7923C5.56667 13.1923 5 14.0923 5 15.0757V16.4173H15V15.0757C15 14.0923 14.4333 13.1923 13.5333 12.7923ZM6.725 14.7507C6.8 14.559 6.95 14.4007 7.13333 14.3173C8.05 13.909 9.01667 13.709 9.99167 13.709C10.975 13.709 11.9333 13.9173 12.85 14.3173C13.0417 14.4007 13.1833 14.559 13.2583 14.7507H6.725Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
      <path
        d="M7.5 8.91732C7.5 10.3007 8.61667 11.4173 10 11.4173C11.3833 11.4173 12.5 10.3007 12.5 8.91732C12.5 7.53398 11.3833 6.41732 10 6.41732C8.61667 6.41732 7.5 7.53398 7.5 8.91732ZM10.8333 8.91732C10.8333 9.37565 10.4583 9.75065 10 9.75065C9.54167 9.75065 9.16667 9.37565 9.16667 8.91732C9.16667 8.45898 9.54167 8.08398 10 8.08398C10.4583 8.08398 10.8333 8.45898 10.8333 8.91732Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
      <path
        d="M2.06667 7.96732C1.80833 7.33398 1.66667 6.71732 1.66667 6.08398C1.66667 3.93398 3.35 2.25065 5.5 2.25065C7.73333 2.25065 8.68333 3.70065 10 5.24232C11.3083 3.71732 12.25 2.25065 14.5 2.25065C16.65 2.25065 18.3333 3.93398 18.3333 6.08398C18.3333 6.71732 18.1917 7.33398 17.9333 7.96732C18.475 8.22565 18.9167 8.65065 19.2083 9.16732C19.7083 8.16732 20 7.15065 20 6.08398C20 3.00065 17.5833 0.583984 14.5 0.583984C12.7583 0.583984 11.0917 1.39232 10 2.67565C8.90833 1.39232 7.24167 0.583984 5.5 0.583984C2.41667 0.583984 0 3.00065 0 6.08398C0 7.15065 0.291667 8.16732 0.8 9.16732C1.09167 8.65065 1.53333 8.22565 2.06667 7.96732Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
    </svg>
  );
}

export default DEOIcon