import * as React from 'react';
import './style.scss';
import ToolTip from '../BEToolTip';
import InfoIcon from '../BEIcons/InfoIcon';

export interface IBEEyeButtonProps {
  title: any;
  discription: any;
  children?: any;
  style?: React.CSSProperties;
  placement?: any;
}

export function BEEyeButton(props: IBEEyeButtonProps) {
  return (
    <div style={{display:'flex',gap:'0.2rem',alignItems:'center',...props.style}}>
            <p style={{margin:'0',padding:'0'}}>{props.children}</p>
      <ToolTip

        title={() => {
          return (
            <div className='be-info-icon'>
              <div className="tool-title">
                {props.title}
              </div>
              <div className="tool-body">
                {props.discription}
              </div>
            </div>
          );
        }}
        placement={props.placement ? props.placement : "right"}

      >
        <div style={{ height: "1.1rem" }}>
          <InfoIcon inheritSize fill="gray" />
        </div>
      </ToolTip>
    </div>
  );
}
