import React, { useState } from 'react'
import { Modal } from 'antd';
import WarningIcon from '../BEIcons/WarningIcon';
import { PrimaryTheme } from '../../Config/Theme/theames';
import { BEButton } from '../BEFormItems/BEButton';

interface BENoBuModalProps {
  title: string,
  message: string,
  visible: boolean,
  setVisible: Function,
  onConfirm: Function
}

const BENoBuModal = (props: BENoBuModalProps) => {
  const [loading, setLoading] = useState(false)

  const handleOk = async () => {
    if (!props.visible) return
    setLoading(true)
    const res = await props.onConfirm()
    setLoading(false)
    handleCancel()
  }

  const handleCancel = () => {
    props.setVisible(false)
  }
  return (
    <Modal
      title={[
        <div style={{ height: "2rem" }}>
          <WarningIcon inheritSize fill={PrimaryTheme.primaryRedError} />
        </div>,
        <div style={{ fontSize: "18px", textAlign: "center" }}>{props.title}</div>,
      ]}
      confirmLoading={loading}
      width={"350px"}
      open={props.visible}
      closable={false}
      mask={true}
      style={{ position: "absolute", top: "30vh", left: "43vw" }}
      footer={
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <BEButton
            loading={loading}
            onClick={handleOk} style={{
              backgroundColor: PrimaryTheme.primaryRedError,
              color: "white",
            }}>Logout</BEButton>
        </div>
      }
      onOk={handleOk}
    >
      <div style={{ color: PrimaryTheme.primaryGray, textAlign: "center" }}>
        {props.message}
      </div>
    </Modal>
  );
}

export default BENoBuModal