import React from 'react'
import IconProps from '../Interface/IconProps';

const EWBIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 18}
      height={props.inheritSize ? "100%" : 17}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.841 8.14935L9.34102 0.649349C9.04102 0.349349 8.62435 0.166016 8.16602 0.166016H2.33268C1.41602 0.166016 0.666016 0.916016 0.666016 1.83268V7.66602C0.666016 8.12435 0.849349 8.54102 1.15768 8.84935L8.65768 16.3493C8.95768 16.6493 9.37435 16.8327 9.83268 16.8327C10.291 16.8327 10.7077 16.6493 11.0077 16.341L16.841 10.5077C17.1493 10.2077 17.3327 9.79102 17.3327 9.33268C17.3327 8.87435 17.141 8.44935 16.841 8.14935ZM9.83268 15.1743L2.33268 7.66602V1.83268H8.16602V1.82435L15.666 9.32435L9.83268 15.1743Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
      <path
        d="M4.41602 5.16602C5.10637 5.16602 5.66602 4.60637 5.66602 3.91602C5.66602 3.22566 5.10637 2.66602 4.41602 2.66602C3.72566 2.66602 3.16602 3.22566 3.16602 3.91602C3.16602 4.60637 3.72566 5.16602 4.41602 5.16602Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
      <path
        d="M6.41602 8.95768C6.41602 9.43268 6.60768 9.84935 6.91602 10.166L9.83268 13.0827L12.7493 10.166C13.0577 9.85768 13.2493 9.42435 13.2493 8.95768C13.2493 8.01602 12.4827 7.24935 11.541 7.24935C11.066 7.24935 10.641 7.44102 10.3327 7.74935L9.83268 8.24935L9.33268 7.75768C9.02435 7.44102 8.59102 7.24935 8.12435 7.24935C7.18268 7.24935 6.41602 8.01602 6.41602 8.95768Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
    </svg>
  );
}

export default EWBIcon