import React, { ReactNode } from 'react'
import './index.scss'
import PDF from '../../../../assets/images/FilesIcons/pdf.png';
import EXCEL from '../../../../assets/images/FilesIcons/excel.png';
import FILE from '../../../../assets/images/FilesIcons/file.png';
import IMAGE from '../../../../assets/images/FilesIcons/image.png';
import MenuIcon from '../../../../Components/BEIcons/MenuIcon';
import { Dropdown, MenuProps, Space } from 'antd';
import { FileType } from '../../../../Redux/Types/vaultTypes';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { deleteVaultFiles } from '../../Actions';
import ToolTip from '../../../../Components/BEToolTip';

export const FileIcons:any = {
    pdf: PDF,
    xlsx: EXCEL,
    xls: EXCEL,
    png: IMAGE,
    jpg: IMAGE,
    jpeg: IMAGE,
    gif: IMAGE, 
     //for every other  file type

}

export interface IFileProps {
    fileName: string;
    file:   FileType,
    setOpenEdit:Function;
}

export function File(props: IFileProps) {
    const dispatch = useAppDispatch();
    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <span onClick={() => 
                    window.open(props.file.file, '_blank')
                }>
                    Download
                </span>
            ),
        },
        {
            key: '2',
            label: (
                <span onClick={() =>{ 
                    props.setOpenEdit(props.file);
                }
                }>
                    Edit
                </span>
            ),
        },
        {
            key: '3',
            label: (
                <span onClick={() => 
                    dispatch(deleteVaultFiles(props.file.id))
                }>
                    Delete
                </span>
            ),
        }
    ];
    return (
        <div className='file-container'>
            <div className='menu'>
                <Dropdown menu={{ items }}>
                    <a onClick={(e) => e.preventDefault()}>
                        <MenuIcon />
                    </a>
                </Dropdown>
            </div>
            <div className='circle'>
                <span><img src={
                    FileIcons[props.file.file.split(/[/?]/)[4].split('.')[1] || 'file'] 
                    || FILE
                } /></span>
            </div>
            {   
                props.fileName.length > 15 ?
                <ToolTip title={props.fileName} placement='bottom'>
                    <div className='file-name'>{props.fileName}</div>
                </ToolTip>
                :
                <div className='file-name'>{props.fileName}</div>
            }
        </div>
    )
}

export default File
