import { MenuOutlined } from "@ant-design/icons";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Card, Table, Row, Col, Checkbox } from "antd";
import type { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import ArrowDownIcon from "../BEIcons/ArrowDownIcon";
import { PrimaryTheme } from "../../Config/Theme/theames";
import'./styles.scss'

interface DataType {
  id: number;
  topic: string;
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const RowTable = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === "sort") {
          return (
            <Card style={{ borderRadius: "10px" }} size="small">
              <Row style={{ display: "flex", alignItems: "center" }}>
                <Col span={4} style={{ display: "flex", alignItems: "center" }}>
                  <MenuOutlined
                    ref={setActivatorNodeRef}
                    style={{ touchAction: "none", cursor: "grab" }}
                    {...listeners}
                  />
                </Col>
                <Col span={20}>{child}</Col>
              </Row>
            </Card>
          );
        }
        return child;
      })}
    </tr>
  );
};
interface SelectedTopicsProps {
  data: any;
  setData: Function;
  field: string;
  value: string;
  height?: string;
  disableRemove?: boolean;
}
const BEDragList = (props: SelectedTopicsProps) => {
  const [dataSource, setDataSource] = useState<
    Array<{ id: number; topic: string }>
  >([]);

  const columns: ColumnsType<DataType> = [
    {
      dataIndex: `${props.field}`,
      width: "120px",
      render: (value, record, index) => (
        <Card
          style={{
            borderRadius: "40px",
            width: "100px",
          }}
          size="small"
        >
          <Row style={{ display: "flex", alignItems: "center" }}>
            <Col
              span={8}
              style={{
                display: "flex",
                transform: "rotateZ(-180deg)",
                backgroundColor: PrimaryTheme.primaryGreenLight,
                borderRadius: "50%",
                cursor: index === 0 ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                const temp = [...dataSource];
                if (index) {
                  temp[index] = temp.splice(index - 1, 1, temp[index])[0];
                  setDataSource(temp);
                }
              }}
            >
              <span style={{ height: "1.5rem" }}>
                <ArrowDownIcon
                  inheritSize
                  fill={
                    index === 0
                      ? PrimaryTheme.primaryGray
                      : PrimaryTheme.primaryGreenDark
                  }
                />
              </span>
            </Col>
            <Col
              span={8}
              style={{
                display: "flex",
                justifyContent: "center",
                color: PrimaryTheme.primaryGreen,
              }}
            >
              {index + 1}
            </Col>
            <Col
              span={8}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                backgroundColor: PrimaryTheme.primaryGreenLight,
                borderRadius: "50%",
                cursor:
                  index === dataSource.length - 1 ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                const temp = [...dataSource];
                if (index < dataSource.length - 1) {
                  temp[index] = temp.splice(index + 1, 1, temp[index])[0];
                  setDataSource(temp);
                }
              }}
            >
              <span style={{ height: "1.5rem" }}>
                <ArrowDownIcon
                  inheritSize
                  fill={
                    index === dataSource.length - 1
                      ? PrimaryTheme.primaryGray
                      : PrimaryTheme.primaryGreenDark
                  }
                />
              </span>
            </Col>
          </Row>
        </Card>
      ),
    },
    {
      key: "sort",
      dataIndex: "topic",
      render: (value, record, index) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "400px",
            }}
          >
            <div style={{ width: "80%" }}>{value}</div>{" "}
            {props.disableRemove ? (
              ""
            ) : (
              <Checkbox
                defaultChecked
                onChange={() => {
                  const temp = dataSource.filter((item, ind) => {
                    return ind !== index;
                  });
                  setDataSource(temp);
                }}
              />
            )}
          </div>
        );
      },
    },
  ];

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.id === active.id);
        const overIndex = previous.findIndex((i) => i.id === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };
  useEffect(() => {
    props.setData(dataSource);
  }, [dataSource]);
  useEffect(() => {
    if (props.data.toString() !== dataSource.toString()) {
      let arr: Array<{ id: any; topic: string }> = [];
      props.data.forEach((itm: any) => {
        arr.push({
          id: itm[props.value],
          topic: itm[props.field],
        });
      });
      setDataSource(arr);
    }
  }, [props.data]);

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        // rowKey array
        items={dataSource.map((i, a) => i.id)}
        strategy={verticalListSortingStrategy}
      >
        <Table
        style={{
          maxHeight: props.height || '300px',
        }}
          className="drag-table"
          pagination={false}
          components={{
            body: {
              row: RowTable,
            },
          }}
          rowKey="id"
          columns={columns}
          dataSource={dataSource}
        />
      </SortableContext>
    </DndContext>
  );
};
export default BEDragList;
