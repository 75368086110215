import { ColumnsType } from 'antd/es/table';
import { NewBETable } from '../../../Components/BETable/newBETable';
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';
import React from 'react';
import { YearSelectOptions } from '../../../Components/BEMultipleSelect/YearSelectOptions';
import { setDateSlicerYearDM } from '../../../Redux/DataManagerReducer';
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import BEConfirmModal from '../../../Components/BEConfirmModal';
import DeleteIcon from '../../../Components/BEIcons/DeleteIcon';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import { deleteDataLabels } from '../../DataManager/Actions';
import { TopSection } from '../Components/TopSection';

export interface ILabelsProps {
}

export function Labels(props: ILabelsProps) {
    const allLabels = useTypedSelector((state) => state.dataManager.labels);
    const year = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
    const [tableData, setTableData] = React.useState<any[]>([]);
    const [labelToDelete, setLabelToDelete] = React.useState<any>({});
    const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
    const dispatch = useAppDispatch();
    const columns: ColumnsType<any> = [
        {
            title: "Sr No.",
            dataIndex: "sr_no",
            render: (value, record, index) => {
                return index + 1;
            }
        },
        {
            title: "Label Name",
            dataIndex: "label_name"
        },
        {
            title: "Used (Number of times)",
            dataIndex: "count",
        },
        {
            title: "Action",
            dataIndex: "action",
            align: "center",
            render: (text, record) => {
                return (
                    <div
                        onMouseEnter={() => setLabelToDelete(record)}
                        style={{ height: "1rem", cursor: record.id < 0 ? "not-allowed" : "pointer" }}
                        onClick={() => { setShowDeleteModal(true) }}
                    >
                        <DeleteIcon
                            inheritSize
                            stroke={PrimaryTheme.primaryGray}
                        />
                    </div>
                );
            }

        }
    ];

    const getTableData = () => {
        let labelSet = new Set<string>();
        let tableData: any[] = []
        allLabels.data.map((label, index) => {
            if (!labelSet.has(label.label_name)) {
                labelSet.add(label.label_name);
                let data = {
                    label_name: label.label_name,
                    count: 1
                }
                tableData.push(data);
            } else {
                tableData.map((data) => {
                    if (data.label_name === label.label_name) {
                        data.count += 1;
                    }
                })
            }
        })
        return tableData;
    }

    React.useEffect(() => {
        if (allLabels.status === "success") {
            const data = getTableData();
            setTableData(data);
        }

    }, [allLabels])


    return (
        <>
            <TopSection />
            <div style={{ padding: "0 1.75rem" }}>
                <div style={{ float: "right", padding: "1rem 0" }}>
                    <YearSelectOptions
                        onChange={(value: number) => dispatch(setDateSlicerYearDM(value))}
                        value={year}
                    />
                </div>
                <NewBETable
                    data={tableData}
                    columns={columns}
                    search
                    pagination
                />
                <BEConfirmModal
                    title="Confirm Delete"
                    message={`Please note, deleting this label will impact ${labelToDelete.count} entries.`}
                    visible={showDeleteModal}
                    setVisible={setShowDeleteModal}
                    onConfirm={async () => {
                        await dispatch(deleteDataLabels(labelToDelete.label_name));
                    }}
                />
            </div>
        </>
    );
}
