import type { StatusType } from "../Types/userTypes";
import { MaterialityType } from "../Types/metirialityTypes";

import {
    Reducer,
    createSlice,
} from "@reduxjs/toolkit";
import { clearStore } from "../ExtraReducrActions";


const initialState: MaterialityType = {
    AllSurveys:{
        status: "idle",
        data: []
    },
    industries:{
        status: "idle",
        data: []
    },
    topics:{
        status: "idle",
        data: []
    },
    selectedTopics: [],
    currentMaterialitySurvey: null,
    stakeholders:{
        status: "idle",
        data: []
    },
    respondentsForAssessment: {
        status: 'idle',
        data: []
    },
    stakeholdersForAssessment: {
        status: 'idle',
        data: []
    },
    createSurveyStep: 1,
    surveyTopics:{
        status: "idle",
        data: []
    },
    surveyAnswers:{
        status: "idle",
        data: []
    },
    surveyResponse: {
        status: false,
        data: []
    },
    AssessmentYear: new Date().getFullYear(),
    globalStakeholders: {
        status: 'idle',
        data: []
    }
}
export const userSlice = createSlice({
    name: "user",
    initialState,
    extraReducers: (builder) => builder.addCase(clearStore, () => initialState),
    reducers: {
        setAllSurveys: (state, action) => {
            state.AllSurveys = action.payload;
        },
        setIndustries: (state, action) => {
            state.industries = action.payload;
        },
        setTopics: (state, action) => {
            state.topics = action.payload;
        },
        setSelectedTopics: (state, action) => {
            state.selectedTopics = action.payload;
        },
        setCurrentMaterialitySurvey: (state, action) => {
            state.currentMaterialitySurvey = action.payload;
        },
        setStakeholders: (state, action) => {
            state.stakeholders = action.payload;
        },
        setRespondantsToAssessment: (state, action) => {
            state.respondentsForAssessment = action.payload
        },
        setStakeholdersToAssessment: (state, action) => {
            state.stakeholdersForAssessment = action.payload
        },
        setCreateSurveyStep: (state, action) => {
            state.createSurveyStep = action.payload;
        },
        setSurveyTopics: (state, action) => {
            state.surveyTopics = action.payload;
        },
        setSurveyAnswers: (state, action) => {
            state.surveyAnswers = action.payload;
        },
        setSurveyResponseStatus: (state, action) => {
            state.surveyResponse.status = action.payload;
        },
        setSurveyResponderData: (state, action) => {
            state.surveyResponse.data = action.payload
        },
        setAssessmentYear: (state, action) => {
        state.AssessmentYear = action.payload;
        },
        setGlobalStakeholders: (state, action) => {
            state.globalStakeholders = action.payload
        }
    }
});

export const {
    setAllSurveys,
    setIndustries,
    setTopics,
    setSelectedTopics,
    setCurrentMaterialitySurvey,
    setStakeholders,
    setCreateSurveyStep,
    setSurveyTopics,
    setRespondantsToAssessment,
    setStakeholdersToAssessment,
    setSurveyAnswers,
    setSurveyResponseStatus,
    setSurveyResponderData,
    setAssessmentYear,
    setGlobalStakeholders
} = userSlice.actions;

const matirialityReducer: Reducer<MaterialityType> = userSlice.reducer;

export default matirialityReducer;
