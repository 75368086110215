import React from 'react'
import IconProps from '../Interface/IconProps';

const HRIIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 18}
      height={props.inheritSize ? "100%" : 12}
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.49935 5.99935C8.10768 5.99935 9.41602 4.69102 9.41602 3.08268C9.41602 1.47435 8.10768 0.166016 6.49935 0.166016C4.89102 0.166016 3.58268 1.47435 3.58268 3.08268C3.58268 4.69102 4.89102 5.99935 6.49935 5.99935ZM6.49935 1.83268C7.19102 1.83268 7.74935 2.39102 7.74935 3.08268C7.74935 3.77435 7.19102 4.33268 6.49935 4.33268C5.80768 4.33268 5.24935 3.77435 5.24935 3.08268C5.24935 2.39102 5.80768 1.83268 6.49935 1.83268ZM6.54102 10.166H2.97435C3.79935 9.74935 5.22435 9.33268 6.49935 9.33268C6.59102 9.33268 6.69102 9.34102 6.78268 9.34102C7.06602 8.73268 7.55768 8.23268 8.14935 7.83268C7.54102 7.72435 6.96602 7.66602 6.49935 7.66602C4.54935 7.66602 0.666016 8.64102 0.666016 10.5827V11.8327H6.49935V10.5827C6.49935 10.441 6.51602 10.2993 6.54102 10.166ZM12.7493 8.08268C11.216 8.08268 8.16602 8.92435 8.16602 10.5827V11.8327H17.3327V10.5827C17.3327 8.92435 14.2827 8.08268 12.7493 8.08268ZM13.7577 6.56602C14.391 6.20768 14.8327 5.53268 14.8327 4.74935C14.8327 3.59935 13.8993 2.66602 12.7493 2.66602C11.5993 2.66602 10.666 3.59935 10.666 4.74935C10.666 5.53268 11.1077 6.20768 11.741 6.56602C12.041 6.73268 12.3827 6.83268 12.7493 6.83268C13.116 6.83268 13.4577 6.73268 13.7577 6.56602Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
    </svg>
  );
}

export default HRIIcon