import React from "react";

interface InIconProps {
  children?: React.ReactNode;
}

const Icon = (props: InIconProps) => {
  const { children } = props;
  return <div>{children}</div>;
};

export default Icon;
