import * as React from 'react';
import { useTypedSelector } from '../../../Config/Hooks/useTypedSelector';
import GridViewIcon from '../../BEIcons/ViewIcons/GridViewIcon';
import ListViewIcon from '../../BEIcons/ViewIcons/ListViewIcon';
import { PrimaryTheme } from '../../../Config/Theme/theames';
import './style.scss'
import { useAppDispatch } from '../../../Config/Hooks/useAppDispatch';
import { viewTypes } from '../../../Redux/Types/commonTypes';

interface IViewButtopnProps {
  setViewFunction?: any;
  viewType: viewTypes;
}

export function ViewTab (props: IViewButtopnProps) {
  const dispatch = useAppDispatch();

  return (
    <div className='view-type-container'>
      <div className='grid-view' onClick={()=>dispatch(props.setViewFunction('grid'))}>
        <div >
          <GridViewIcon
            inheritSize={true}
            fill={props.viewType === 'grid' ? PrimaryTheme.primaryGreen : PrimaryTheme.primaryGray}
          />
        </div>
      </div>

      <div className='list-view' onClick={()=>dispatch(props.setViewFunction('list'))}>
        <div>
          <ListViewIcon
            inheritSize={true}
            fill={props.viewType === 'list' ? PrimaryTheme.primaryGreen : PrimaryTheme.primaryGray}
          />
        </div> 
      </div>
    </div>
  );
}
