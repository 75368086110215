import React from 'react'
import IconProps from '../Interface/IconProps';

const BDIcon = (props:IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 20}
      height={props.inheritSize ? "100%" : 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.41407 13.2409L9.59741 12.0576C7.32241 10.0326 5.83074 8.66588 5.83074 7.36589C5.83074 6.49089 6.49741 5.82422 7.37241 5.82422C8.29741 5.82422 8.65574 6.36588 9.60574 7.49088H10.3807C11.3141 6.39922 11.6557 5.82422 12.6141 5.82422C13.3391 5.82422 13.9057 6.27422 14.0891 6.92422C14.3807 6.89088 14.6557 6.87422 14.9224 6.87422C15.2224 6.87422 15.5057 6.89922 15.7807 6.94089C15.5807 5.34922 14.2724 4.15755 12.6224 4.15755C12.5224 4.15755 12.4307 4.18255 12.3307 4.19089C12.4307 3.91588 12.4974 3.63255 12.4974 3.32422C12.4974 1.94089 11.3807 0.824219 9.99741 0.824219C8.61407 0.824219 7.49741 1.94089 7.49741 3.32422C7.49741 3.63255 7.56407 3.91588 7.66407 4.19089C7.56407 4.18255 7.47241 4.15755 7.37241 4.15755C5.57241 4.15755 4.16407 5.56589 4.16407 7.36589C4.16407 9.38255 5.86407 10.9576 8.41407 13.2409Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
      <path
        d="M18.7474 13.5242C18.4807 13.3742 18.1974 13.2826 17.9141 13.2326C17.9724 13.1492 18.0391 13.0826 18.0891 12.9992C18.9891 11.4409 18.4724 9.51589 16.9141 8.61588C15.1724 7.60755 12.9474 8.29088 9.70574 9.36588L10.1391 10.9826C13.0307 10.0326 14.9641 9.41588 16.0891 10.0659C16.8474 10.5076 17.0891 11.4076 16.6557 12.1742C16.1891 12.9742 15.5474 13.0076 14.0974 13.2742L13.7057 13.9492C14.1891 15.2992 14.5141 15.8909 14.0307 16.7159C13.5891 17.4742 12.6891 17.7159 11.9224 17.2826C11.8724 17.2576 11.8307 17.2076 11.7807 17.1742C11.5307 17.7326 11.2474 18.2076 10.9224 18.6159C10.9807 18.6492 11.0307 18.6909 11.0891 18.7325C12.6474 19.6325 14.5724 19.1159 15.4724 17.5576C15.5224 17.4742 15.5474 17.3826 15.5891 17.2909C15.7724 17.5159 15.9891 17.7159 16.2557 17.8659C17.4474 18.5576 18.9807 18.1492 19.6724 16.9492C20.3641 15.7492 19.9391 14.2076 18.7474 13.5242Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
      <path
        d="M10.2641 11.6659C9.64741 14.6492 9.20574 16.6242 8.08074 17.2742C7.32241 17.7159 6.41407 17.4742 5.97241 16.7076C5.50574 15.9075 5.80574 15.3242 6.29741 13.9409L5.91407 13.2659C4.49741 13.0076 3.83074 12.9909 3.35574 12.1659C2.91407 11.4076 3.15574 10.4992 3.92241 10.0576C3.99741 10.0159 4.08074 9.99088 4.16407 9.96589C3.87241 9.49922 3.63074 8.99088 3.48074 8.42422C3.34741 8.48255 3.21407 8.54088 3.08074 8.61588C1.52241 9.51589 1.00574 11.4409 1.90574 12.9992C1.95574 13.0826 2.02241 13.1492 2.08074 13.2326C1.79741 13.2826 1.51407 13.3742 1.24741 13.5242C0.0557396 14.2159 -0.360927 15.7409 0.33074 16.9409C1.02241 18.1409 2.54741 18.5492 3.74741 17.8576C4.01407 17.7076 4.23074 17.5076 4.41407 17.2826C4.45574 17.3742 4.48074 17.4659 4.53074 17.5492C5.43074 19.1075 7.35574 19.6242 8.91407 18.7242C10.6557 17.7159 11.1724 15.4492 11.8724 12.1076L10.2641 11.6659Z"
        fill={props.stroke ? props.stroke : "#333333"}
      />
    </svg>
  );
}

export default BDIcon