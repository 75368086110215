import * as React from 'react';
import { BEDrawer } from '../../../../../Components/BEDrawer';
import { BEButton } from '../../../../../Components/BEFormItems/BEButton';
import { Form } from 'antd';
import { LableRequired } from '../../../../../Components/BEFormItems/LableRequired';
import { BEInput } from '../../../../../Components/BEFormItems/BEInput';
import FileUpload from '../../../../../Components/BEFileUpload/FileUpload';
import SaveIcon from '../../../../../Components/BEIcons/SaveIcon';
import { useAppDispatch } from '../../../../../Config/Hooks/useAppDispatch';
import { patchVaultFiles, postVaultFiles } from '../../../Actions';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from '../../../../../Config/Hooks/useTypedSelector';

export interface IEditFileProps {
    open: boolean;
    setOpen: Function;
    isEdit?: boolean;
    FileId?: number;
}

export function UploadFile(props: IEditFileProps) {

    const [form] = Form.useForm();
    const { folder_id } = useParams();
    const [file, setFile] = React.useState(null);
    const VaultFiles = useTypedSelector((state) => state.vault.Files);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = React.useState(false);
    const onCancel = () => {
        props.setOpen(false)
    };
    const handleForm = async (values: any) => {
        setLoading(true);
        const userInfo = localStorage.getItem("userInfo");
        const userInfoParsed = userInfo ? JSON.parse(userInfo) : null;
        const data = props.isEdit ?
        {
            title: values.filename,
        } :
        {
            title: values.filename,
            file: file,
            uploaded_by: userInfoParsed.id,
            folder: folder_id
        }
        {
            props.isEdit ?
                await dispatch(patchVaultFiles(props.FileId, data)) :
                await dispatch(postVaultFiles(data));
        }
        setLoading(false);
        onCancel()
    };

    React.useEffect(() => {
        setFile(null);
        form.resetFields(["filename"]);
        if(props.isEdit) {
            const file = VaultFiles.data.find((item) => item.id === props.FileId);
            form.setFieldsValue({
                filename: file?.title
            })
        }
    }, [props.open])

    return (
        <BEDrawer
            open={props.open}
            setOpen={props.setOpen}
            heading={props.isEdit ? 'Edit File' : 'Upload File'}
            width='fit-content'
            footer={
                <>
                    <BEButton
                        loading={loading}
                        icon={!props.isEdit &&
                            <div style={{ width: "1rem" }}><SaveIcon inheritSize /></div>}
                        className='primary'
                        size='large'
                        onClick={() => { form.submit() }}
                    >{props.isEdit ? "Edit" : "Save"}
                    </BEButton>
                    <BEButton
                        size='large'
                        onClick={onCancel}
                    >Cancel</BEButton>
                </>
            }
        >
                <Form form={form} onFinish={handleForm}>
                    <LableRequired>File Name</LableRequired>
                    <Form.Item
                        name="filename"
                        rules={[{ required: true, message: "Please enter file name" }]}
                    >
                        <BEInput size='large' style={{ width: "313px"}} />
                    </Form.Item>
                    {
                        !props.isEdit &&
                        <>
                            <LableRequired>Files</LableRequired>
                            <FileUpload
                                fileSize={5000000}
                                required={true}
                                text='Drag and Drop files - Max 5MB'
                                hint='Supported Formats: PDF, PNG, JPEG, SVG, EXCEL'
                                fileType={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/pdf", "image/png", "image/jpeg", "image/svg", "application/pdf"]}
                                handleFileChange={(file: any) => {
                                    setFile(file);
                                }}
                                refreshUpload={props.open}
                            />
                        </>
                    }
                </Form>
        </BEDrawer>
    );
}
function dispatch(arg0: any) {
    throw new Error('Function not implemented.');
}

