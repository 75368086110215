import * as React from 'react';
import FolderIcon from '../../../../assets/icons/VaultIcons/FolderIcon.svg'
import './style.scss'
import { Tooltip } from 'antd';
export interface IVaultFolderProps {
  name: string;
  onClick?: Function;
}

export function VaultFolder(props: IVaultFolderProps) {
  return (
    <div>
      <div className="vault-folder"
        onClick={() => {
          if (props.onClick) props.onClick()
        }}
      >
        <div className="vault-folder_icon">
          <img src={FolderIcon} alt="Folder Icon" />
        </div>

        {
          props.name?.length > 15 ?
            <Tooltip title={props.name}>
              <p className='vault-folder_name'>
                {props.name}
              </p>
            </Tooltip> :
            <p className='vault-folder_name'>
              {props.name}
            </p>
        }
      </div>
    </div>
  );
}
