import * as React from 'react';
import welcomeImage from '../../../../assets/images/WelcomeSvgs/welcomeDashboard.svg';
import { WelcomeScreen } from '..';

export interface IConfigurstionWelcomeScreenProps {
  onClick:Function;
}

export function DashboardWelcomeScreen (props: IConfigurstionWelcomeScreenProps) {
  return (
    <div>
      <WelcomeScreen
        buttonText='Start reporting'
        description='Breathe ESG is now configured for your use. You can now start reporting and tracking the added metrics'
        image={welcomeImage}
        onClick={props.onClick}
        heading='System Configured Successfully!'
      />
    </div>
  );
}