import React from 'react'
import IconProps from './Interface/IconProps'

const CheckIcon = (props: IconProps) => {
  return (
    <svg
      width={props.inheritSize ? "100%" : 24}
      height={props.inheritSize ? "100%" : 24}
      viewBox="0 0 24 24"
      fill={props.fill ? props.fill : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.857422 14.6562L5.53742 20.6733C5.69534 20.8785 5.8977 21.0453 6.12928 21.1612C6.36086 21.2769 6.61567 21.3388 6.87456 21.3419C7.12929 21.345 7.38148 21.291 7.61279 21.1843C7.84408 21.0775 8.04868 20.9205 8.21171 20.7247L23.1431 2.65625"
        stroke={props.stroke ? props.stroke : "white"}
        strokeWidth="2.71429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CheckIcon