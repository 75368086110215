import API from "../../API";


export const GET_MATERIALITY_SURVEY_ASSESSMENT_LIST = async () => {
    return await API.GET(
        `/enterprise/materiality-assessment-survey/`,
    );
    };

export const POST_MATERIALITY_SURVEY_ASSESSMENT_LIST = async (
    body: any
    ) =>
    await API.POST(
        `/enterprise/materiality-assessment-survey/`,
        body,
    );


export const DELETE_MATERIALITY_ASSESSMENT_SURVEY = async (id: number) =>
    await API.DELETE(
        `/enterprise/materiality-assessment-survey/${id}/`,
    );


export const PATCH_MATERIALITY_ASSESSMENT_SURVEY = async (
    id: number,
    body: any
    ) =>
    await API.PATCH(
        `/enterprise/materiality-assessment-survey/${id}/`,
        body,
    );


export const GET_MATERIALITY_ASSESSMENT_INDUSTRIES = async () => {
  return await API.GET(
    `/enterprise/materiality-assessment-industries/`,
  );
};

export const GET_MATERIALITY_ASSESSMENT_TOPICS_BY_ID = async (id: number) => {
    return await API.GET(
        `/enterprise/materiality-assessment-topics/${id}/`,
    );
};

export const GET_MATERIALITY_ASSESSMENT_TOPICS_BY_SURVEY_ID = async (id: number) => {
    return await API.GET(
        `/enterprise/materiality-assessment-survey-topics/${id}/`,
    );
}

export const GET_MATERIALITY_ASSESSMENT_SURVEY_ANSWERS = async (
    id: number
    ) => {
    return await API.GET(
        `/enterprise/materiality-assessment-survey-answers/${id}/`,
    );
};


export const POST_MATERIALITY_ASSESSMENT_SURVEY_TOPICS = async (
    body: any
    ) => {
    console.log(body);
    return await API.POST(
        "/enterprise/materiality-assessment-survey-topics/",
        body,
    );
};

export const DELETE_MATERIALITY_ASSESSMENT_SURVEY_TOPICS = async (
    id: number
    ) => {
    return await API.DELETE(
        `/enterprise/materiality-assessment-survey-topics/${id}/`,
    );
}

export const GET_MATERIALITY_ASSESSMENT_SURVEY_TOPICS_BY_ID = async (
    id: number
    ) => {
    return await API.GET(
        `/enterprise/materiality-assessment-survey-topics/${id}/`,
    );
};



export const GET_MATERIALITY_ASSESSMENT_STAKEHOLDERS_BY_SURVEY_ID = async (
    id: number
    ) =>
    await API.GET(
        `/enterprise/materiality-assessment-stakeholders/${id}/`,
    );

// Survey ID is the Body of the request
// Body is Array<{...stakeholderdetails, survey}>
export const POST_MATERIALITY_ASSESSMENT_STAKEHOLDER_BY_SURVEY_ID = async (
    body: any
    ) =>
    await API.POST(
        "/enterprise/materiality-assessment-stakeholders/",
        body,
    );

export const BULK_UPLOAD_MATERIALITY_ASSESSMENT_STAKEHOLDER = async (body: any) =>{
    return await API.POST("/enterprise/stakeholder-excel-bulk-upload/",body,true);
}

// UPDATE Materiality Assessment Stakeholder
export const PATCH_MATERIALITY_ASSESSMENT_STAKEHOLDER_BY_SURVEY_ID = async (
    body: any,
    id: number
    ) =>
    await API.PATCH(
        "/enterprise/materiality-assessment-stakeholders/" + id + "/",
        body,
        true
    );
// DELETE Materiality Assessment Stakeholder

export const DELETE_MATERIALITY_ASSESSMENT_STAKEHOLDER_BY_SURVEY_ID = async (
    id: number
    ) =>
    await API.DELETE(
        "/enterprise/materiality-assessment-stakeholders/" + id + "/",
    );

export const ENGAGE_STAKEHOLDER = async (body: {
    emails: Array<string>;
    survey_id: number;
    }) => {
    return await API.POST(
        "/enterprise/send-materiality-assessment/",
        body,
    );
};


export const SEND_MATASS_REMAINDER = async (body:any) => {
    return await API.PATCH(
        "/enterprise/send-materiality-assessment/",
        body,
    );
}

export const GET_MATERIALITY_ASSESSMENT_RESPONDENTS = async (id: number) => {
    return await API.GET(`/enterprise/send-materiality-assessment/${id}/`);
}

export const GET_MATERIALITY_ASSESSMENT_STAKEHOLDERS = async (id: number) => {
    return await API.GET(
      `/enterprise/materiality-assessment-stakeholders/${id}/`
    );
}

export const GET_ALL_STAKEHOLDERS = async () => {
    return API.GET("/enterprise/stakeholders/");
}

export const POST_GLOBAL_STAKEHOLDER = async (body: any) => {
    return API.POST("/enterprise/stakeholders/", body);
}

export const PATCH_GLOBAL_STAKEHOLDER = async (id: number, body: any) => {
    return API.PATCH(`enterprise/stakeholders/${id}`, body);
}

export const DELETE_GLOBAL_STAKEHOLDER = async (id: number) => {
    return API.DELETE(`enterprise/stakeholders/${id}`);
}