import * as React from 'react';
import './style.scss'
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { getBoschData } from '../../Actions';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { GlobalLoader } from '../../../../Components/GlobalLoader';
import { ColumnsType } from 'antd/es/table';
import { NewBETable } from '../../../../Components/BETable/newBETable';
import { FilterByScreen } from './FilterByScreen';
import { BETabs } from '../../../../Components/BETabs';

export const FUEL = 'fuel' as const;
export const FLEET = 'fleet' as const;
export const ROUTE = 'route' as const;
export const VEHICLE_TYPE = 'vehicleType' as const;
export const TITLE = 'title' as const;

export interface IBoschPOCProps {
}

export function BoschPOC(props: IBoschPOCProps) {
  const dispatch = useAppDispatch();
  const data = useTypedSelector((state) => state.boschDashboard.BoschData);


  React.useEffect(() => {
    dispatch(getBoschData());
  }, []);

  if (data.status === "loading") return <GlobalLoader height='80vh' />
  return (
    <div className='bosch-dashboard'>
      <h2>Emissions Analytics </h2>
      <div className='tabs-container'>
        <BETabs 
          tabBarStyle={{paddingLeft: '1.5rem'}}
          type='card'
          items={[
            //bosch
            // {
            //   key:'By FuelType',
            //   label:'By Fuel Type',
            //   children: <FilterByScreen type={FUEL}/>
            // },
            // {
            //   key:'By Fleet',
            //   label:'By Fleet',
            //   children: <FilterByScreen type={FLEET}/>
            // },

            //yahamah
            {
              key:'By FuelType',
              label:'By Fuel Type',
              children: <FilterByScreen type={TITLE}/>
            },
          ]}
        />
      </div>
    </div>
  );
}
