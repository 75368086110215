import * as React from 'react';
import { BETabButton } from '../../../../Components/BETabButton';
import { PrimaryTheme } from '../../../../Config/Theme/theames';
import UsersIcon from '../../../../Components/BEIcons/UsersIcon';
import DataEntryIcon from '../../../../Components/BEIcons/DataEntryIcon';
import BEMultipleSelect from '../../../../Components/BEMultipleSelect';
import { BEInput } from '../../../../Components/BEFormItems/BEInput';
import './style.scss';
import { useLocation } from 'react-router-dom';
import { Select } from 'antd';
import { useTypedSelector } from '../../../../Config/Hooks/useTypedSelector';
import { useAppDispatch } from '../../../../Config/Hooks/useAppDispatch';
import { setAssessmentYear } from '../../../../Redux/MatirialityReducer';
import { navigateTo } from '../../../../Navigation/NavigationManager';
import dayjs from 'dayjs';
import { YearSelectOptions } from '../../../../Components/BEMultipleSelect/YearSelectOptions';
import { setDateSlicerYearDM } from '../../../../Redux/DataManagerReducer';

export interface ITopSectionProps {
}

export function TopSection(props: ITopSectionProps) {
  const location = useLocation()
  const assessmentYear = useTypedSelector(state => state.dataManager.metrics.dateSlicer.currentYear);
  const dispatch = useAppDispatch()
  const [activeTab, setActiveTab] = React.useState(0)

  React.useEffect(() => {
    if (location.pathname.includes('/home/materiality/assessments')) setActiveTab(0)
    else setActiveTab(1)
  }, [location])
  return (
    <div className="global-top-section">
      <BETabButton
        title="MATERIALITY ASSESSMENTS"
        icon={
          <DataEntryIcon
            stroke={
              activeTab === 0
                ? PrimaryTheme.primaryGreen
                : PrimaryTheme.primaryGray
            }
          />
        }
        active={activeTab === 0}
        onClick={() => {
          navigateTo("/home/materiality/assessments");
        }}
      />
      <BETabButton
        title="STAKEHOLDERS"
        icon={
          <UsersIcon
            fill={
              activeTab === 1
                ? PrimaryTheme.primaryGreen
                : PrimaryTheme.primaryGray
            }
          />
        }
        active={activeTab === 1}
        onClick={() => {
          navigateTo("/home/materiality/stakeholders");
        }}
      />
      <div
        className="year"
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "auto",
          gap: "0.5rem",
        }}
      >
        <YearSelectOptions
          onChange={(value: number) => dispatch(setDateSlicerYearDM(value))}
          value={assessmentYear}
          disabled={location.pathname !== "/home/materiality/assessments"}
        />
      </div>
    </div>
  );
}
