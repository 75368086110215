import * as React from 'react';
import ReviewStep1 from './ReviewSteps/ReviewStep1';
import ReviewStep2 from './ReviewSteps/ReviewStep2';
import { ReviewStep3 } from './ReviewSteps/ReviewStep3';
import { BETabs } from '../../../Components/BETabs';
import { ReviewStep4 } from './ReviewSteps/ReviewStep4';
import './style.scss'
import { OnboardingTopNav } from '../OnboardingView/OnboardingTopNav';

export interface IReviewOnboardingProps {
}

export function ReviewOnboarding (props: IReviewOnboardingProps) {
  const [activeTab, setActiveTab] = React.useState('1');

  return (
    <div className="review-onboarding">
      <OnboardingTopNav title="Review the configurations for your Company's ESG Metrics" />
      <BETabs
        className="outer-tabs"
        size="large"
        type="card"
        activeKey={activeTab}
        tabBarGutter={20}
        tabBarStyle={{ marginLeft: 40, marginTop: "2rem" }}
        onChange={(key) => setActiveTab(key)}
        items={[
          {
            key: "1",
            label: "About The Company",
            children: <ReviewStep1 />,
          },
          {
            key: "3",
            label: "Users",
            children: <ReviewStep3 />,
          },{
            key: "2",
            label: "Entity Mapping",
            children: <ReviewStep2 />,
          },
          {
            key: "4",
            label: "ESG Metrics & Reporting",
            children: <ReviewStep4 />,
          },
        ]}
      />
    </div>
  );
}
