import * as React from 'react';
import './style.scss';
import SwitchIcon from '../../BEIcons/SwitchIcon';

import { PrimaryTheme } from '../../../Config/Theme/theames';

export interface IBECollapseProps {
    children?: React.ReactNode
    heading?: React.ReactNode
    subheading?: React.ReactNode
    open?: boolean
    setOpen?: Function
    step: number
    disabled?: boolean
}

export function StepCollapse(props: IBECollapseProps) {

    return (
        <div className='step-collapse'>

            <div className='heading' onClick={() =>
                props.setOpen && !props.disabled ? props.setOpen() : null
            }>
                <div className='step' style={{ marginTop: props.open ? '0.4rem' : '0.2rem' }}>
                    {props.step}
                </div>
                <div>
                    <p className='main'>{props.heading}</p>
                    {
                        props.subheading && props.open &&
                        <p className='sub'>{props.subheading}</p>
                    }
                </div>
                <div className='switch-arrow '
                    style={{ transform: props.open ? 'rotateZ(0deg)' : 'rotateZ(90deg)', top: props.open ? '1.8rem' : '0.9rem' }}
                    onClick={() =>
                        props.setOpen && !props.disabled ? props.setOpen() : null
                    }>
                    <SwitchIcon stroke={PrimaryTheme.primaryGray} />
                </div>
            </div>
            {
                props.open &&
                <div className='body-collapse'>
                    {props.open && props.children}
                </div>
            }
        </div>
    );
}
